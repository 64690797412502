import axios from "axios/index";
(function ($, DGS) {
  "use strict";

  function formConfirmation(formClass) {
    /* People award form */
    var requiredClassName = "required";
    var triggerClassName = "trigger";
    var formConfirmationClassName = "form-confirmation";
    var submitButton = "scfSubmitButton";
    var categoryDropDown = "fa-nom-category";
    var ageDropDown = "fa-age";
    var defaultFormClass = "scfForm";
    var editBtn = "edit-btn";
    var confirmBtn = "tall-btn";
    var componentContent = "component-content";
    var confirmationText = "confirmation-text";
    var scfFooterBorder = "scfFooterBorder";
    var faAttachmentsHeader = "fa-attachments-header";
    var commonErrorMessage = $('<p class="scfValidatorRequired">The form is not complete and has not been submitted yet. <br/>Please review your application and fill out the missing fields marked in red.</p>');

    /* Toggle the input validation class */
    function classToggler($form, $element) {
      var name = $.trim($element.val());
      var isRequired = $element.parent().parent().find(".scfRequired").length > 0;
      var entry = $element.closest('[class*="fa-"]');
      if ($element.hasClass('nice-select')) {
        name = $element.closest(".scfDropListGeneralPanel").find("select").val();
      } else if (!$element.is("input") && !$element.is("textarea")) {
        name = $.trim($element.html());
        $element = $element.prev("input");
      }
      function validateValue(value, entry, isRequired) {
        value = $.trim(value);
        if (value === '') {
          return !isRequired;
        }
        if (entry.hasClass("fa-email") || entry.hasClass('fa-nom-email')) {
          return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase());
        }
        if (entry.hasClass("fa-zip") || entry.hasClass("fa-nom-zip")) {
          return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
        }
        if (entry.hasClass("fa-home-phone") || entry.hasClass("fa-nom-home-phone") || entry.hasClass("fa-business-phone") || entry.hasClass("fa-nom-business-phone")) {
          var formats = "+1-xxx-xxx-xxxx|1-xxx-xxx-xxxx|(xxx) xxx-xxxx|(xxx)xxx-xxxx|xxx-xxx-xxxx|xxxxxxxxxx";
          var r = RegExp("^(" + formats.replace(/([\(\)])/g, "\\$1").replace(/\+/g, "\\+").replace(/x/g, "\\d") + ")$");
          return r.test(value);
        }
        return true;
      }
      if (!validateValue(name, entry, isRequired)) {
        $element.addClass(requiredClassName);
      } else {
        $element.removeClass(requiredClassName);
      }
      var categoryDropDownInput = $form.find("." + categoryDropDown + " input");
      var ageDropDownInput = $form.find("." + ageDropDown + " input");

      // Special handling of category with option 'student' and age.
      if (categoryDropDownInput.val() === "Student" && ageDropDownInput.val() === '') {
        ageDropDownInput.addClass(requiredClassName);
      } else if (categoryDropDownInput.val() != "Student" || categoryDropDownInput.val() === "Student" && ageDropDownInput.val() != '') {
        ageDropDownInput.removeClass(requiredClassName);
      }
    }
    function resetDropDown($formElement) {
      var categoryDropDownInput = $formElement.find("." + categoryDropDown + " input");
      var ageDropDownInput = $formElement.find("." + ageDropDown + " input");
      if (categoryDropDownInput.val() != "Student") {
        ageDropDownInput.val('');
      }
    }

    /* single field validation */
    function validateSingleRequiredfield($formElement) {
      $formElement.find("input, textarea, select, .nice-select").each(function () {
        var $input = $(this);
        $input.next("." + triggerClassName).on("DOMSubtreeModified", function () {
          var $triggerClass = $(this);
          classToggler($formElement, $triggerClass);
        });
        $input.on("blur", function (e) {
          classToggler($formElement, $input);
        });
      });
    }

    /* multiple field validation */
    function validateMultipleRequiredFields($formElement) {
      $formElement.find("input, textarea, select, .nice-select").each(function () {
        classToggler($formElement, $(this));
      });
      resetDropDown($formElement);
    }

    /* handle the spots that appear when hitting the form submit btn */
    function formConfirmation($form, confirmationElements, confirmOriginalTokens) {
      $("." + confirmationElements).show();
      var $confimationText = $("." + confirmationElements + "." + confirmationText);

      // scroll to the top of the first confirmation element
      $('html, body').animate({
        scrollTop: $confimationText.offset().top - 20
      }, 0);

      // map each classname with a token
      var confirmCopy = confirmOriginalTokens.data("confirm-tokens");

      // find each class that starts with fa- and replace its corrosponding token
      $form.find("[class*=fa-]").each(function () {
        var inputThis = $(this);
        var className = $.grep(this.className.split(" "), function (v, i) {
          return v.indexOf('fa-') === 0;
        }).join();
        if (inputThis.find("input, textarea, select").val() != "undefined") {
          confirmCopy = confirmCopy.replace("{" + className + "}", inputThis.find("input, textarea, select").val().replace(/(?:\r\n|\r|\n)/g, '<br />'));
        }
      });
      $('.component-content', $confimationText).html(confirmCopy);

      // Insert value of input type file
      var fileInputs = $form.find('input[type=file]');
      var fileInputContent = $confimationText.find('.' + faAttachmentsHeader).after('<p />');
      if (fileInputs.filter(function () {
        return $(this).val();
      }).length > 0) {
        fileInputs.each(function () {
          if ($(this).val() !== '') {
            fileInputContent.next('p').append($(this).val().replace(/^.*[\\\/]/, '') + '<br />');
          }
        });
      } else {
        fileInputContent.hide();
      }

      // confirm button
      $confimationText.find("." + confirmBtn).on('click', function () {
        $form.find("." + submitButton).off("click.aform");
        $form.find("." + submitButton).trigger("click.aform");
      });

      // back button
      $confimationText.find("." + editBtn).on('click', function () {
        $("." + confirmationElements).hide();
        $form.find("." + defaultFormClass).show();
        return false;
      });
    }
    function dropDownToggle($element) {
      // hide and show age dropdown depending on category selection
      $element.find("." + categoryDropDown + " ." + triggerClassName).on("DOMSubtreeModified", function () {
        var $triggerClass = $(this);
        if ($element.find("." + categoryDropDown + " input").val() === "Student (Age 6-25)") {
          $element.find("." + ageDropDown).show();
        } else {
          $element.find("." + ageDropDown).hide();
        }
      });
    }
    function toggleCommonErrorMessage($formElement, show) {
      if (show) {
        $formElement.find("." + scfFooterBorder).find('.scfValidatorRequired').show();
      } else {
        $formElement.find("." + scfFooterBorder).find('.scfValidatorRequired').hide();
      }
    }

    /* form handling */
    function submitHandler(form) {
      var $form = $("." + form);
      if ($form.length === 0) {
        return false;
      }
      dropDownToggle($form);

      // Adds the * to the age dropdown otherwise the server will require it to have a value
      $form.find("." + scfFooterBorder).append(commonErrorMessage);
      $form.find("." + ageDropDown).append('<div class="scfRequired">*</div>');
      validateSingleRequiredfield($form);
      var confirmOriginalTokens = $("." + formConfirmationClassName).data("confirm-tokens", $("." + formConfirmationClassName + " ." + componentContent).html());
      $form.find("." + submitButton).on("click.aform", function () {
        validateMultipleRequiredFields($form);
        if ($form.find("." + requiredClassName).length > 0) {
          toggleCommonErrorMessage($form, {
            show: true
          });
          return false;
        } else {
          $form.find("." + defaultFormClass).hide();
          toggleCommonErrorMessage($form);
          // Show the form confirmations spots and replace text
          formConfirmation($form, formConfirmationClassName, confirmOriginalTokens);
        }
        return false;
      });
    }
    submitHandler(formClass);
  }
  DGS.OnLoad.getInstance().register(function () {
    if ($('.component.form').length) window.DGS.Event.getInstance().trigger(window.DGS.Events.SELECT_ADDED, $('.component.form select'));
    $('#wrapper .component.form input[type="submit"]').attr("disabled", true);
    setTimeout(function () {
      $('#wrapper .component.form input[type="submit"]').removeAttr("disabled");
    }, 2000);
    $("#wrapper .component.form .scfCheckBoxListBorder").each(function () {
      var $this = $(this),
        $span = $('.scfCheckBoxListLabel', $this);
      var $error = $('.scfValidatorRequired', $this);
      if ($error.length) {
        $span.addClass("error");
      }
    });
    formConfirmation("form-custom-validation");
    formConfirmation("focus-on-people");
    $(".component.form").each(function () {
      var $this = $(this);
      if ($this.hasClass("blog-form")) {
        setTimeout(function () {
          $this.addClass("component-ready animate");
        }, 500);
      }
    });
    var $form = $('.component.form.focus-on-people-voting').closest('form');
    if ($form.length > 0) {
      // FOP vote 2018 below
      var $theLang = $('html').attr('lang');
      var dictionary = {
        fopvote18: {
          emailused: "E-mail address has already been used for voting."
        }
      };
      var $submit = $form.find("input[type='submit']");
      var $emailUsefulInfo = $form.find(".scfEmailUsefulInfo").last();
      var $emailInput = $form.find('.scfEmailTextBox').last();
      var wffm = $('.component.form.focus-on-people, .component.form.focus-on-people-voting').wffm({
        moveAsteriskToLabel: true,
        maxEntriesInValidatorSummary: 6,
        niceSelectClass: "square-corners"
      });

      // Double * sometimes added by WFFM. Hide one by force!
      $('.scfRadioButtonListLabel', $form).each(function (i, $e) {
        var $scfRequired = $('.scfRequired', $e);
        var $scfValidatorRequired = $('.scfValidatorRequired', $e);
        if ($scfRequired.length > 0 && $scfValidatorRequired.length > 0) {
          $scfValidatorRequired.hide();
        }
      });

      // Get rid of "email already used" when the user is actually changing the value
      $emailInput.change(function (e) {
        return $emailUsefulInfo.css("display", "none");
      });

      // Use usefulInfo field for custom validation message
      $emailUsefulInfo.addClass("scfValidator");
      $emailUsefulInfo.text(dictionary.fopvote18.emailused);
      DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        $emailUsefulInfo.text(dictionary.fopvote18.emailused);
      }, $theLang);
      $form.submit(function (e) {
        e.preventDefault();
        var email = $emailInput.val().toLowerCase();
        // Webservice fails on empty email, hence the dummy addition.
        axios.get("/webservices/Fopvoting.svc/emailexists/".concat(email || 'dummy')).then(function (resp) {
          if (!resp.data) {
            // Email not already in use.
            e.target.submit();
          } else {
            // Email already in use. Display validation error.
            $emailUsefulInfo.css("display", "inline-block");
            // Submit button sometimes disabled by WFFM. Override!
            $submit.attr('disabled', false);
          }
        });
        return false;
      });
    }
  });
})(jQuery, window.DGS);