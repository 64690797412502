(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.component.b2c-form-wrapper')[0]) {
      var myForm = $('.component.form').wffm({
        niceSelectClass: 'square-corners',
        // make nice-select like rest of the wffm
        moveAsteriskToLabel: true
      });

      /* Form Placeholder */
      $('.component.b2c-form-wrapper .scfSectionContent>.scfSingleLineTextBorder').each(function () {
        var label = $(this).find('.scfSingleLineTextLabel').text();
        $(this).find('input').attr('placeholder', label);
      });

      //Scroll to Same Position
      $(".component.b2c-form-wrapper .scfSubmitButton").click(function () {
        $("html, body").scrollTop($(".component.b2c-form-wrapper").position().top - 175);
      });

      // Hidden Field Values from URL

      var url_string = window.location.href;
      var url = new URL(url_string);
      var utmCampaign = url.searchParams.get("utm_campaign");
      var utmContent = url.searchParams.get("utm_content");
      var utmMedium = url.searchParams.get("utm_medium");
      var utmSource = url.searchParams.get("utm_source");
      $('.component.b2c-form-wrapper .scfSectionContent>.scfHiddenFieldBorder').each(function () {
        if ($(this).hasClass("name.UTM+Campaign")) {
          $(this).find('input').attr('value', utmCampaign);
        } else if ($(this).hasClass("name.UTM+Content")) {
          $(this).find('input').attr('value', utmContent);
        } else if ($(this).hasClass("name.UTM+Medium")) {
          $(this).find('input').attr('value', utmMedium);
        } else if ($(this).hasClass("name.UTM+Source")) {
          $(this).find('input').attr('value', utmSource);
        }
      });
    } //if end
  });
})(jQuery);