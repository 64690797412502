var SpinnerTypes = {
  ISALREADYINSIDEPARENT: 0,
  MOVEINTOPARENT: 1
};
var Restrictions = {
  RESTRICT_FROM_PRICING: 'restrict-from-has-pricing'
};
var AddToCartButtonTypes = {
  BULK_ORDER: 'bulkorder',
  CLIENT_ORDER: 'clientorder'
};
var ChooseYourProductConstants = {
  MAX_MENUITEMS_PER_COLUMN: 8
};
var AudiogramConstants = {
  LEFT: 'left',
  RIGHT: 'right'
};
var ClientOrderMode = {
  LEFT: 'left',
  RIGHT: 'right',
  BINAURAL: 'binaural'
};
var ClientOrderAssignmentStates = {
  ASSIGNABLE: 'Assignable',
  ASSIGNED: 'Assigned',
  FORCEABLE: 'Forceable'
};
var ClientOrderCommonTypes = {
  AUDIOGRAM: 'audiogram'
};
var AgeGroup = {
  ADULT: 'adult',
  CHILD: 'child'
};
var GenderGroup = {
  MALE: 'male',
  FEMALE: 'female'
};
var YesNoGroup = {
  YES: 'yes',
  NO: 'no'
};
var ProductTableConstants = {
  GRID: 'grid',
  LIST: 'list'
};
var Ids = {
  DEFAULT_ID: '-1',
  FAMILY: 0,
  MODEL: 1,
  STYLE: 2,
  FREEADDRESS: 999,
  CATEGORY_ID: 'categoryId',
  FAMILY_ID: 'familyId',
  MODEL_ID: 'modelId',
  STYLE_ID: 'styleId'
};
var LocalStorageKeys = {
  OrdersUrl: 'ordersUrl'
};
var TranslationKeys = {
  SHARED_ERROR: 'AnErrorOccurred',
  SHARED_MORE: 'More',
  SHARED_ADDTOCART: 'AddToCart',
  SHARED_PAGINGUP: 'PagingUp',
  SHARED_PAGINGDOWN: 'PagingDown',
  SHARED_CLIENT_ORDER_RESET: 'ClientOrderReset',
  SHARED_CHARACTERS_LEFT: 'CharactersLeft',
  SHARED_OK: 'Ok',
  SHARED_CANCEL: 'Cancel',
  SHARED_SHOP: 'Shop',
  SHARED_OR: 'Or',
  SHARED_YES: 'Yes',
  SHARED_NO: 'No',
  SHARED_LEFT: 'Left',
  SHARED_RIGHT: 'Right',
  SHARED_REMOVE_LABEL_WITH_X: 'SharedRemoveLabelWithX',
  SHARED_ADDTOCARTMODAL_HEADER: 'AddToCartModalHeader',
  SHARED_ADDTOCARTMODAL_ITEMPREFIX: 'AddToCartModalItemPrefix',
  SHARED_ADDTOCARTMODAL_HEADER_ITEM: 'AddToCartModalHeaderItem',
  SHARED_ADDTOCARTMODAL_HEADER_QUANTITY: 'AddToCartModalHeaderQuantity',
  SHARED_ADDTOCARTMODAL_HEADER_IMAGE: 'AddToCartModalHeaderImage',
  SHARED_ADDTOCARTMODAL_GOTOCHECKOUTBUTTON: 'AddToCartModalGoToCheckoutButton',
  SHARED_ADDTOCARTMODAL_CONTINUESHOPPINGBUTTON: 'AddToCartModalContinueShoppingButton',
  SHARED_REMOVEFROMCARTMODAL_BULKORDERONCART: 'RemoveFromCartModalHeader',
  SHARED_SHOWPRODUCTS: 'ShowProducts',
  SHARED_HIDE: 'Hide',
  SHARED_BUYNOWHIDE: 'BuyNowHide',
  SHARED_BUYNOWSHOW: 'BuyNowShow',
  SHARED_SESSION_EXPIREDTITLE: 'SessionExpiredTitle',
  SHARED_SESSION_EXPIREDTEXT: 'SessionExpiredText',
  SHARED_SESSION_EXPIREDBUTTONTEXT: 'SessionExpiredButtonText',
  SHARED_CART_GOTOCHECKOUT: 'CartGoToCheckoutButton',
  SHARED_CART_YOURRECENTLYITEMS: 'CartYourRecentlyItemsLabel',
  SHARED_CART_CARTITEMSLABEL: 'CartItemsLabel',
  SHARED_CART_CARTITEMSUPDATEDLABEL: 'CartItemsUpdatedLabel',
  SHARED_CART_NOITEMS: 'CartNoItemsLabel',
  SHARED_CART_REMOVETEXT: 'CartRemoveText',
  SHARED_CART_REMOVECANCEL: 'CartRemoveCancel',
  SHARED_CART_REMOVEOK: 'CartRemoveOk',
  SHARED_CART_REMOVEBUTTONTITLE: 'CartRemoveButtonTitle',
  SHARED_CART_PRODUCTS: 'CartProducts',
  SHARED_ERRORHEADER: 'ErrorHeader',
  SHARED_ERRORMESSAGE: 'ErrorMessage',
  SHARED_ERRORBUTTON: 'ErrorButton',
  SHARED_IS_RECHARGEABLE_NOTE: 'isRechargeableNote',
  SHARED_PRINT_DELIVERYHEADER: 'PrintDeliveryHeader',
  SHARED_PRINT_STOCKORDERHEADER: 'PrintStockOrderHeader',
  SHARED_PRINT_CLIENTORDERHEADER: 'PrintClientOrderHeader',
  SHARED_PRINT_PRODUCTNAMELABEL: 'PrintProductNameLabel',
  SHARED_PRINT_QUANTITYLABEL: 'PrintQuantityLabel',
  SHARED_PRINT_PRICELABEL: 'PrintPriceLabel',
  PRICE_UNAVAILABLE_LABEL: 'PriceUnavailableLabel',
  SHARED_PRICING_ITEM_TEXT: 'PricingItemText',
  SHARED_PRINT_SUBTOTALLABEL: 'PrintSubtotalLabel',
  SHARED_PRINT_ITEMNUMBERLABEL: 'PrintItemNumberLabel',
  SHARED_PRINT_NAMELABEL: 'PrintNameLabel',
  SHARED_PRINT_FIRSTNAMELABEL: 'PrintFirstNameLabel',
  SHARED_PRINT_LASTNAMELABEL: 'PrintLastNameLabel',
  SHARED_PRINT_BIRTHDAYLABEL: 'PrintBirthdayLabel',
  SHARED_PRINT_FITTERSNAMELABEL: 'PrintFittersNameLabel',
  SHARED_PRINT_FITTERSDATELABEL: 'PrintFittersDateLabel',
  SHARED_PRINT_SOCIALSECURITYNOLABEL: 'PrintSocialSecurityNoLabel',
  SHARED_PRINT_MEDICAIDNUMBERLABEL: 'PrintMedicAidNumberLabel',
  SHARED_PRINT_VOCREHABNUMBERLABEL: 'PrintVocRehabNumberLabel',
  SHARED_PRINT_MUNCIPALITYCODELABEL: 'PrintMuncipalityCodeLabel',
  SHARED_PRINT_BILLINGADDRESSHEADER: 'PrintBillingAddressHeader',
  SHARED_PRINT_SHIPPINGADDRESSHEADER: 'PrintShippingAddressHeader',
  SHARED_PRINT_ORDERNUMBERLABEL: 'PrintOrderNumberLabel',
  SHARED_PRINT_ORDERDATELABEL: 'PrintOrderDateLabel',
  SHARED_PRINT_AUTHORIZATIONLABEL: 'PrintAuthorizationLabel',
  SHARED_PRINT_TOTALLABEL: 'PrintTotalLabel',
  SHARED_CLIENT_ORDERS_FIRSTNAMEKANJILABEL: 'ClientOrderFirstNameKanjiLabel',
  SHARED_CLIENT_ORDERS_LASTNAMEKANJILABEL: 'ClientOrderLastNameKanjiLabel',
  SHARED_CLIENT_ORDERS_FIRSTNAMEKATAKANALABEL: 'ClientOrderFirstNameKatakanaLabel',
  SHARED_CLIENT_ORDERS_LASTNAMEKATAKANALABEL: 'ClientOrderLastNameKatakanaLabel',
  SHARED_BILLING_SECTION_ACCOUNT_NO: 'BillingSectionAccountNo',
  SHARED_NAME: 'Name',
  SHARED_ITEMNUMBER: 'ItemNumber',
  SHARED_QUANTITY: 'Quantity',
  SHARED_PLEASEADDQUANTITY: 'PleaseAddQuantity',
  SHARED_UPDATECART: 'UpdateCart',
  SHARED_RESULTSPERPAGE: 'ResultsPerPage',
  SHARED_MANDATORYFORMFIELD: 'MandatoryFormField',
  SHARED_ADULT_LABEL: 'AdultLabel',
  SHARED_CHILD_LABEL: 'ChildLabel',
  SHARED_AGE_LABEL: 'AgeLabel',
  SHARED_MALE_LABEL: 'MaleLabel',
  SHARED_FEMALE_LABEL: 'FemaleLabel',
  SHARED_YOUR_PRICE: 'YourPrice',
  SHARED_PRICE: 'Price',
  SHARED_DISCOUNT: 'Discount',
  SHARED_CLIENT_ORDERS_HEADER: 'ClientOrdersHeader',
  SHARED_EAR_SIDE: 'EarSide',
  SHARED_DATE: 'Date',
  SHARED_TIME: 'Time',
  SHARED_FAMILY: 'Family',
  SHARED_MODEL: 'Model',
  SHARED_STYLE: 'Style',
  SHARED_COLOR: 'Color',
  SHARED_COLOR_SELECT_LABEL: 'ColorSelectLabel',
  SHARED_SELECT: 'Select',
  SHARED_DELETE: 'Delete',
  SHARED_WARNING_NOTE_LABEL: 'WarningNoteLabel',
  SHARED_OPENBUTTON: 'OpenButton',
  SHARED_CLOSEBUTTON: 'CloseButton',
  SHARED_SERVICE_DECIDES: 'ServiceDecides',
  SHARED_ACCESS_DENIED_HEADER: 'AccessDeniedHeader',
  SHARED_ACCESS_DENIED_MESSAGE: 'AccessDeniedMessage',
  SHARED_CONFLICTING_SERVICE_ORDER_DETAILS_TEXT: 'SharedConflictingServiceOrderDetailsText',
  SHARED_CONFLICTING_SERVICE_ORDER_RETURN_TO_CART_TEXT: 'SharedConflictingServiceOrderReturnToCart',
  SHOPINITIALIZATIONSPOT_UNHANDLEDEXCEPTION: 'UnhandledException',
  SHOPINITIALIZATIONSPOT_UNHANDLEDEXCEPTION_SHOWDETAILS: 'UnhandledExceptionShowDetails',
  SHOPINITIALIZATIONSPOT_UNHANDLEDEXCEPTION_HIDEDETAILS: 'UnhandledExceptionHideDetails',
  SHOPINITIALIZATIONSPOT_UNHANDLEDEXCEPTION_MESSAGE: 'UnhandledExceptionMessage',
  SHOPINITIALIZATIONSPOT_UNHANDLEDEXCEPTION_SERVICE: 'UnhandledExceptionService',
  SHOPINITIALIZATIONSPOT_UNHANDLEDEXCEPTION_STACKTRACE: 'UnhandledExceptionStackTrace',
  PRODUCTSEARCHSPOT_SEARCHRESULTLABEL: 'SearchResultLabel',
  PRODUCTSEARCHSPOT_SEARCHRESULTHIGHLIGHTLABEL: 'SearchResultHighlightLabel',
  PRODUCTSEARCHSPOT_PAGINGRESULTLABEL: 'PagingResultLabel',
  PRODUCTSEARCHSPOT_SEARCHBARPLACEHOLDERTEXT: 'SearchBarPlaceHolderText',
  PRODUCTSEARCHSPOT_PAGINGRESULTLABEL_NORESULTS: 'SearchResultLabelNoResults',
  PRODUCTLISTSPOT_PAGINGRESULTLABEL: 'PagingResultLabel',
  PRODUCTLISTSPOT_GRIDVIEWLABEL: 'GridViewLabel',
  PRODUCTLISTSPOT_LISTVIEWLABEL: 'ListViewLabel',
  PRODUCTLISTSPOT_NOPRODUCTSRESULTLABEL: 'NoProductsResultLabel',
  PRODUCTLISTSPOT_ACCESSORIES_HEADER_TEXT: 'AccessoriesHeaderText',
  PRODUCTLISTSPOT_ACCESSORIES_SUBHEADER_TEXT: 'AccessoriesSubHeaderText',
  PRODUCTLISTSPOT_SHOP_ALL_ACCESSORIES_TEXT: 'ShopAllAccessoriesText',
  PRODUCTDETAILSSPOT_RELATEDHEADER: 'StockOrderRelatedHeader',
  PRODUCTDETAILSSPOT_VIEWRELATEDPRODUCTS: 'ViewRelatedProducts',
  PRODUCTDETAILSSPOT_CONFLICTING_PRODUCTS_CONTINUE_LABEL: 'ProductdetailsspotConflictingProductsContinueLabel',
  PRODUCTDETAILSSPOT_CONFLICTING_PRODUCTS_BACK_LABEL: 'ProductdetailsspotConflictingProductsBackLabel',
  PRODUCTDETAILSSPOT_CONFLICTING_PRODUCTS_HEADER_LABEL: 'ProductdetailsspotConflictingProductsHeaderLabel',
  PRODUCTDETAILSSPOT_CONFLICTING_PRODUCTS_BODY_LABEL: 'ProductdetailsspotConflictingProductsBodyLabel',
  PRODUCTDETAILSSPOT_REMOVE_PRODUCTS_MODAL_OK: 'ProductdetailsspotRemoveProductsModalOk',
  PRODUCTDETAILSSPOT_REMOVE_PRODUCTS_MODAL_CANCEL: 'ProductdetailsspotRemoveProductsModalCancel',
  PRODUCTDETAILSSPOT_REMOVE_PRODUCTS_MODAL_HEADER: 'ProductdetailsspotRemoveProductsModalHeader',
  PRODUCTDETAILSSPOT_REMOVE_PRODUCTS_MODAL_BODY: 'ProductdetailsspotRemoveProductsModalBody',
  CLIENTORDER_NAVIGATION_STEP1_LABEL: 'ClientOrderNavigationStep1Label',
  CLIENTORDER_NAVIGATION_STEP1_SHORT_LABEL: 'ClientOrderNavigationStep1ShortLabel',
  CLIENTORDER_NAVIGATION_STEP2_LABEL: 'ClientOrderNavigationStep2Label',
  CLIENTORDER_NAVIGATION_STEP2_SHORT_LABEL: 'ClientOrderNavigationStep2ShortLabel',
  CLIENTORDER_NAVIGATION_STEP3_LABEL: 'ClientOrderNavigationStep3Label',
  CLIENTORDER_NAVIGATION_STEP3_SHORT_LABEL: 'ClientOrderNavigationStep3ShortLabel',
  CLIENTORDERSTEP1_HEADER_LABEL: 'ClientOrderStep1HeaderLabel',
  CLIENTORDERSTEP1_SUBHEADER_LABEL: 'ClientOrderStep1SubheaderLabel',
  CLIENTORDERSTEP1_SUBHEADER_DESCRIPTION: 'ClientOrderStep1SubheaderDescription',
  CLIENTORDERSTEP1_CHOOSE_CATALOG_LABEL: 'ClientOrderStep1ChooseCatalogLabel',
  CLIENTORDERSTEP1_ENTER_CLIENT_INFORMATION_LABEL: 'ClientOrderStep1EnterClientInformationLabel',
  CLIENTORDERSTEP1_SOCIALNO_4_DIGITS: 'ClientOrderStep1SocialNo4Digits',
  CLIENTORDERSTEP1_BIRTHDAY_LABEL: 'ClientOrderStep1BirthdayLabel',
  CLIENTORDERSTEP1_CHOOSE_FAMILY_VARIANT_MODEL_LABEL: 'ClientOrderStep1ChooseFamilyVariantModelLabel',
  CLIENTORDERSTEP1_CONTINUE_LABEL: 'ClientOrderStep1ContinueLabel',
  CLIENTORDERSTEP1_AGEGROUPHELPTEXTHEADER: 'ClientOrderStep1AgeGroupHelpTextHeader',
  CLIENTORDERSTEP1_AGEGROUPHELPTEXT: 'ClientOrderStep1AgeGroupHelpText',
  CLIENT_ORDER_STEP_1_AGE_GROUP_HELP_TEXT_ITE: 'ClientOrderStep1AgeGroupHelpTextITE',
  CLIENT_ORDER_STEP_1_AGE_GROUP_ITE_INVALID_DATE: 'ClientOrderStep1AgeGroupIteInvalidDate',
  CLIENTORDERSTEP1_AGEGROUP_WARRANTY_UPDATE_TEXT: 'ClientOrderStep1AgeGroupWarrantyUpdateText',
  CLIENTORDERSTEP1_ERROR_MESSAGE: 'ClientOrderStep1ErrorMessage',
  CLIENTORDERSTEP1_SHOW_DETAILS: 'ClientOrderStep1ShowDetails',
  CLIENTORDERSTEP1_HIDE_DETAILS: 'ClientOrderStep1HideDetails',
  CLIENTORDERSTEP1_QUICK_SELECT_HEADER: 'ClientOrderStep1QuickSelectHeader',
  CLIENTORDERSTEP1_ALL_FAVOURITES_HEADER: 'ClientOrderStep1AllFavouritesHeader',
  CLIENTORDERSTEP1_TEMPLATES_HEADER: 'ClientOrderStep1TemplatesHeader',
  CLIENTORDERSTEP1_NO_FAVOURITES_TO_DISPLAY: 'ClientOrderStep1NoFavouritesToDisplay',
  CLIENTORDERSTEP1_SELECT_PRODUCTS_TO_CONTINUE: 'ClientOrderStep1SelectProductsToContinue',
  CLIENTORDERSTEP1_ADD_TO_FAVOURITES: 'ClientOrderStep1AddToFavourites',
  CLIENTORDERSTEP1_CONFLICTING_PRODUCTS_HEADER_LABEL: 'ClientorderStep1ConflictingProductsHeaderLabel',
  CLIENTORDERSTEP1_CONFLICTING_PRODUCTS_BODY_LABEL: 'ClientorderStep1ConflictingProductsBodyLabel',
  CLIENTORDERSTEP1_CONFLICTING_PRODUCTS_CONTINUE_LABEL: 'ClientorderStep1ConflictingProductsContinueLabel',
  CLIENTORDERSTEP1_CONFLICTING_PRODUCTS_BACK_LABEL: 'ClientorderStep1ConflictingProductsBackLabel',
  CLIENTORDERSTEP1_SSN_VALIDATION_MESSAGE: 'ClientorderStep1SSNVaidationMessage',
  CLIENTORDERSTEP2_FEATURE_HINT: 'FeatureHint_',
  CLIENTORDERSTEP2_HELPER_TEXT_TRANSLATION_PREFIX: 'HelperText_',
  CLIENTORDERSTEP2_REQUIREDFIELDSMESSAGE: 'ReqieredFieldsMessage',
  CLIENTORDERSTEP2_CANCELAUDIOGRAMBUTTON: 'CancelAudiogramButton',
  CLIENTORDERSTEP2_SAVEAUDIOGRAMBUTTON: 'SaveAudiogramButton',
  CLIENTORDERSTEP2_SCROLL_AUDIOGRAM_MOBILE_TEXT: 'ScrollAudiogramMobileText',
  CLIENTORDERSTEP2_SHOWAUDIOGRAMBUTTON: 'ShowAudiogramButton',
  CLIENTORDERSTEP2_ENTERAUDIOGRAMINFORMATION: 'EnterAudiogramInformation',
  CLIENTORDERSTEP2_DBHLLABEL: 'DbHlLabel',
  CLIENTORDERSTEP2_HZLABEL: 'HzLabel',
  CLIENTORDERSTEP2_ACLABEL: 'AcLabel',
  CLIENTORDERSTEP2_DELETE_LEFT_SIDE_GRAPH: 'DeleteLeftSideGraph',
  CLIENTORDERSTEP2_DELETE_RIGHT_SIDE_GRAPH: 'DeleteRightSideGraph',
  CLIENTORDERSTEP2_CANCEL_AUDIOGRAM: 'CancelAudiogram',
  CLIENTORDERSTEP2_ERROR_MESSAGE: 'ClientOrderStep2ErrorMessage',
  CLIENTORDERSTEP2_FORCE_MESSAGE_LINE_1: 'ClientOrderStep2ForceMessageLine1',
  CLIENTORDERSTEP2_FORCE_MESSAGE_LINE_2: 'ClientOrderStep2ForceMessageLine2',
  CLIENTORDERSTEP2_FORCE_MESSAGE_LINE_3: 'ClientOrderStep2ForceMessageLine3',
  CLIENTORDERSTEP2_FORCE_MESSAGE_LINE_4: 'ClientOrderStep2ForceMessageLine4',
  CLIENTORDERSTEP2_RIGHT_ONLY: 'ClientOrderStep2RightOnly',
  CLIENTORDERSTEP2_LEFT_ONLY: 'ClientOrderStep2LeftOnly',
  CLIENTORDERSTEP2_HEADER: 'ClientOrderStep2Header',
  CLIENTORDERSTEP2_PARAGRAPH: 'ClientOrderStep2Paragraph',
  CLIENTORDERSTEP2_BINAURAL: 'ClientOrderStep2Binaural',
  CLIENTORDERSTEP2_INFO: 'ClientOrderStep2Info',
  CLIENTORDERSTEP2_COPY_SELECTIONS: 'ClientOrderStep2CopySelections',
  CLIENTORDERSTEP2_COPY_ERROR: 'ClientOrderStep2CopyError',
  CLIENTORDERSTEP2_COPY_RIGHT: 'ClientOrderStep2CopyRight',
  CLIENTORDERSTEP2_COPY_LEFT: 'ClientOrderStep2CopyLeft',
  CLIENTORDERSTEP2_REQUIRED_FIELDS_INFO: 'ClientOrderStep2RequiredFieldsInfo',
  CLIENTORDERSTEP2_REMOVE_CONFIGURATION_WARNING: 'ClientOrderStep2RemoveConfigurationWarning',
  CLIENTORDERSTEP2_FAVOURITECONFIGURATIONSAVED: 'ClientOrderStep2FavouriteConfigurationSaved',
  CLIENTORDERSTEP2_SERVICE_DECIDES_MESSAGE: 'ClientOrderStep2ServiceDecidesMessage',
  CLIENTORDERSTEP2_SAVEFAVOURITEBUTTON: 'ClientOrderStep2FavouriteButton',
  CLIENTORDERSTEP2_ADDFAVOURITEHEADER: 'ClientOrderStep2AddFavouriteHeader',
  CLIENTORDERSTEP2_EDIT_LABEL: 'ClientOrderStep2EditLabel',
  CLIENTORDERSTEP3_HEADER: 'ClientOrderStep3Header',
  CLIENTORDERSTEP3_OPENBUTTON: 'OpenButton',
  CLIENTORDERSTEP3_CLOSEBUTTON: 'CloseButton',
  CLIENTORDERSTEP3_GOTOCARTBUTTON: 'GoToCartButton',
  CLIENTORDERSTEP3_EDITCONFIGURATION: 'EditConfigurtion',
  CLIENTORDERSTEP3_ADDACCESSCONFIGURATION: 'AddAccessConfigurtion',
  CLIENTORDERSTEP3_RELATEDHEADER: 'ClientOrderRelatedHeader',
  CLIENTORDERSTEP3_ERROR_MESSAGE: 'ClientOrderStep3ErrorMessage',
  CLIENTORDERSTEP3_ONLY_STL_FILES: 'ClientOrderStep3OnlyStlFiles',
  CLIENTORDERSTEP3_MAX_FILE_SIZE_DESCRIPTION: 'ClientOrderStep3MaxFileSizeDescription',
  CLIENTORDERSTEP3_SELECT_IMPRESSION_HEADER: 'ClientOrderStep3SelectImpressionHeader',
  CLIENTORDERSTEP3_SELECT_IMPRESSION_TEXT_LEFT: 'ClientOrderStep3SelectImpressionTextLeft',
  CLIENTORDERSTEP3_SELECT_IMPRESSION_TEXT_RIGHT: 'ClientOrderStep3SelectImpressionTextRight',
  CLIENTORDERSTEP3_WARRANTY_HEADER: 'ClientOrderStep3WarrantyHeader',
  CLIENTORDERSTEP3_WARRANTY_BODY: 'ClientOrderStep3WarrantyBody',
  CLIENTORDERSTEP3_ADD_TO_CART_TEXT: 'ClientOrderStep3AddToCartText',
  CLIENTORDERSTEP3_CHOOSE_FILE_LABEL: 'ClientOrderStep3ChooseFileLabel',
  CLIENTORDERSTEP3_SELECT_FILE_BUTTON_LABEL: 'ClientOrderStep3SelectFileButtonLabel',
  CLIENTORDERSTEP3_IMPRESSIONS_SECTION_HEADER_TEXT: 'ClientOrderStep3ImpressionsSectionHeaderText',
  CLIENTORDERSTEP3_IMPRESSIONS_CHECKBOX_IMPRESSIONS_LABEL: 'ClientOrderStep3ImpressionsCheckboxImpressionsLabel',
  CLIENTORDERSTEP3_IMPRESSIONS_CHECKBOX_OTOSCAN_ID_LABEL: 'ClientOrderStep3ImpressionsCheckboxOtoscanIdLabel',
  CLIENTORDERSTEP3_IMPRESSIONS_CHECKBOX_IMPRESSION_ON_FILE_LABEL: 'ClientOrderStep3ImpressionsCheckboxImpressionOnFileLabel',
  CLIENTORDERSTEP3_IMPRESSIONS_ENTER_OTOSCAN_TEXTBOX_LABEL: 'ClientOrderStep3ImpressionsEnterOtoscanTextboxLabel',
  CLIENTORDERSTEP3_IMPRESSIONS_ENTER_LEFT_IMPRESSION_ON_FILE_TEXTBOX_LABEL: 'ClientOrderStep3ImpressionsEnterLeftImpressionOnFileTextboxLabel',
  CLIENTORDERSTEP3_IMPRESSIONS_ENTER_RIGHT_IMPRESSION_ON_FILE_TEXTBOX_LABEL: 'ClientOrderStep3ImpressionsEnterRightImpressionOnFileTextboxLabel',
  CLIENTORDERSTEP3_IMPRESSIONS_ENTER_YOUR_SERIAL_NO_PLACEHOLDER: 'impressionsEnterYourSerialNoPlaceholder',
  CLIENTORDERSTEP3_IMPRESSIONS_STL_OTISCAN_LIMITATION: 'ClientOrderStep3ImpressionsStlOtiscanLimitation',
  CLIENTORDERSTEP3_IMPRESSIONS_SEND_SEPARATE: 'ClientOrderStep3ImpressionsSendSeparate',
  CLIENTORDERSTEP3_VALID_OTOSCAN_MESSAGE: 'ClientOrderStep3ImpressionsValidOtoscanIdMessage',
  CLIENTORDERSTEP3_INVALID_OTOSCAN_MESSAGE: 'ClientOrderStep3ImpressionsInvalidOtoscanIdMessage',
  CLIENTORDERSTEP3_VALID_IMPRESSION_ON_FILE_MESSAGE: 'impressionsValidImpressionsOnFileMessage',
  CLIENTORDERSTEP3_INVALID_IMPRESSION_ON_FILE_MESSAGE: 'impressionsInvalidImpressionsOnFileMessage',
  CLIENTORDERSTEP3_EXPIRED_IMPRESSION_ON_FILE_MESSAGE: 'impressionsExpiredImpressionsOnFileMessage',
  CLIENTORDERSTEP3_DUPLICATE_IMPRESSION_ON_FILE_MESSAGE: 'impressionsDuplicateImpressionsOnFileMessage',
  CLIENT_ORDERSTEP_3_IMPRESSION_IS_MANDATORY_ERROR_MSG: 'clientOrderstep3ImpressionIsMandatoryErrorMsg',
  CLIENT_ORDER_SMART_CHARGER_HEADER_ONE_TEXT: 'ClientOrderSmartChargerHeaderOneText',
  CLIENT_ORDER_SMART_CHARGER_HEADER_TWO_TEXT: 'ClientOrderSmartChargerHeaderTwoText',
  PLACEBULKORDER: 'PlaceBulkOrder',
  PLACEPATIENTORDER: 'PlacePatientOrder',
  PATIENTORDERS: 'PatientOrders',
  SELECTCATEGORY: 'SelectCategory',
  CHOOSECATEGORY: 'ChooseCategory',
  CHOOSEFAMILY: 'ChooseFamily',
  CHOOSEMODEL: 'ChooseModel',
  CHOOSESTYLE: 'ChooseStyle',
  CHOOSETYPE: 'ChooseType',
  SHORTCUTSLABEL: 'ShortcutsLabel',
  CHECKOUT_REVIEWYOURCART: 'ReviewYourCart',
  CHECKOUT_GOTOCHECKOUT: 'GoToCheckout',
  CHECKOUT_CONTINUESHOPPING: 'ContinueShopping',
  CHECKOUT_BULKORDERONCART: 'BulkOrderOnCart',
  CHECKOUT_SELECTINVOICEINFORMATION: 'SelectInvoiceInformation',
  CHECKOUT_ORDERSUMMARY: 'OrderSummary',
  CHECKOUT_ORDERSUMMARY_COMMENTSTITLE: 'OrderSummaryCommentsTitle',
  CHECKOUT_SUBTOTAL: 'Subtotal',
  CHECKOUT_TAX: 'Tax',
  CHECKOUT_TOTAL: 'Total',
  CHECKOUT_TOTAL_SMALL: 'TotalSmall',
  CHECKOUT_NEWADDRESS: 'NewAddress',
  CHECKOUT_SHIPPINGDETAILSHEADER: 'ShippingDetailsHeader',
  CHECKOUT_DELIVERYTYPE: 'DeliveryType',
  CHECKOUT_ADDRESSNAME1: 'AddressName1',
  CHECKOUT_ADDRESSNAME2: 'AddressName2',
  CHECKOUT_ADDRESSLINE1: 'AddressLine1',
  CHECKOUT_ADDRESSLINE2: 'AddressLine2',
  CHECKOUT_ADDRESSZIPCODE: 'AddressZipCode',
  CHECKOUT_ADDRESSCITY: 'AddressCity',
  CHECKOUT_ADDRESSCOUNTY: 'AddressCounty',
  CHECKOUT_ADDRESSCOUNTRY: 'AddressCountry',
  CHECKOUT_ADDRESSEMAIL: 'AddressEmail',
  CHECKOUT_ADDRESSPHONENUMBER: 'AddressPhoneNumber',
  CHECKOUT_SUBMIT_BUTTON: 'SubmitButton',
  CHECKOUT_PRINT_BUTTON: 'PrintButton',
  CHECKOUT_VALIDATION_REQUIRED_FIELDS: 'ValidationCheckRequiredFields',
  CHECKOUT_CARTWITHUNAVAILABLEPRODUCTS: 'CartWithUnavailableProducts',
  CHECKOUT_CARTUPDATED: 'CartUpdated',
  CHECKOUT_ORDERPOSTED: 'OrderPosted',
  CHECKOUT_PO_NO_TEXT: 'CheckoutPoNoText',
  CHECKOUT_PO_NO_REQUIRED_TEXT: 'CheckoutPoNoRequiredText',
  CHECKOUT_PO_NO_OPTIONAL_TEXT: 'CheckoutPoNoOptionalText',
  CHECKOUT_PO_NO_ERROR_TEXT: 'CheckoutPoNoErrorText',
  CHECKOUT_SMART_CHARGER_SHIPPING_TEXT: 'CheckoutSmartChargerShippingText',
  PO_MISSING_WARNING: 'PoMissingWarning',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_LABEL: 'ExtendedClientInformationLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_ID_LABEL: 'ExtendedClientInformationIdLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_ADDRESS_LABEL: 'ExtendedClientInformationAddressLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_ZIPCODE_LABEL: 'ExtendedClientInformationZipcodeLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_CITY_LABEL: 'ExtendedClientInformationCityLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_EMAIL_LABEL: 'ExtendedClientInformationEmailLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_PHONENUMBER_LABEL: 'ExtendedClientInformationPhoneNumberLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_CUSTOMIZATION_INFORMATION_LABEL: 'ExtendedClientInformationCustomizationInformationLabel',
  CHECKOUT_EXTENDEDCLIENTINFORMATION_CUSTOMIZERS_FULLNAME_LABEL: 'ExtendedClientInformationCustomizersFullNameLabel',
  CHECKOUT_EMPTYCART: 'EmptyCart',
  CHECKOUT_CLICKHERE: 'ClickHereToContinueShopping',
  CHECKOUT_CLIENTORDER: 'ClientOrder',
  CHECKOUT_EDITCONFIGURATION: 'EditConfiguration',
  CHECKOUT_AUDIOGRAMDATA: 'AudiogramData',
  CHECKOUT_EDITAUDIOGRAM: 'EditAudiogram',
  CHECKOUT_ITEM_REMOVED_WARNING: 'CheckoutItemRemovedWarning',
  CHECKOUT_ITEM: 'Item',
  CHECKOUT_ITEMS: 'Items',
  CHECKOUT_ADD_EXISTING_USER_INFO: 'AddExistingUserInfo',
  CHECKOUT_EXISTING_USER: 'ExistingUser',
  CHECKOUT_SERIALNO: 'SerialNo',
  CHECKOUT_USEDFOR: 'UsedFor',
  CHECKOUT_OPTIONS: 'CheckoutOptions',
  CHECKOUT_ADD_COUPON: 'CheckoutAddCoupon',
  CHECKOUT_CONTRACT_DISCOUNT_LABEL: 'CheckoutContractDiscountLabel',
  CHECKOUT_COUPON_DISCOUNT_LABEL: 'CheckoutCouponDiscountLabel',
  CHECKOUT_EDIT_ORDER: 'CheckoutEditOrder',
  CHECKOUT_ENTER_COUPON: 'CheckoutEnterCoupon',
  CHECKOUT_SEE_PROMOTION: 'CheckoutSeePromotion',
  CHECKOUT_EDIT_PROMOTIONITEM: 'CheckoutEditPromotionItem',
  CHECKOUT_WARRANTY_DESCRIPTION: 'CheckoutWarrantyDescription',
  CHECKOUT_PATIENT_ORDERS: 'CheckoutPatientOrders',
  CHECKOUT_BULK_ORDERS: 'CheckoutBulkOrders',
  CHECKOUT_PRODUCT_ORDERS: 'CheckoutProductOrders',
  CHECKOUT_FREE_ITEMS: 'CheckoutFreeItems',
  CHECKOUT_ORDER_TOTAL_HEADER: 'CheckoutOrderTotalHeader',
  CHECKOUT_RUSH_ORDER_URL_TEXT: 'RushOrderURLText',
  CHECKOUT_RUSH_ORDER_URL: 'RushOrderURL',
  CHECKOUT_RUSH_ORDER_HIDE: 'RushOrderHideClass',
  CHECKOUT_COUPON_SAVINGS_TITLE: 'CheckoutCouponSavingsTitle',
  RECENTLYVIEWEDPRODUCTS_HEADER: 'SeeRecentlyViewedProducts',
  US_CHECKOUT_CONFIRMATION_HEADER: 'UsCheckoutConfirmationHeader',
  US_CHECKOUT_CONFIRMATION_TEXT: 'UsCheckoutConfirmationText',
  US_CHECKOUT_SERVICE_ORDER_CONFIRMATION_TEXT: 'UsCheckoutServiceOrderConfirmationText',
  US_CHECKOUT_CONFIRMATION_ORDERNUMBERTEXT: 'UsCheckoutConfirmationOrdernumbertext',
  US_CHECKOUT_CONFIRMATION_ORDERSBUTTON: 'UsCheckoutConfirmationOrdersbutton',
  US_CHECKOUT_CONFIRMATION_VIEW_ORDER_DETAILS: 'UsCheckoutConfirmationViewOrderDetails',
  US_CHECKOUT_CONFIRMATION_CONTINUEBUTTON: 'UsCheckoutConfirmationContinuebutton',
  US_CHECKOUT_CONFIRMATION_PRINTBUTTON: 'UsCheckoutConfirmationPrintbutton',
  US_CHECKOUT_CONFIRMATION_THANKYOU: 'UsCheckoutConfirmationThankyou',
  US_CHECKOUT_CONFIRMATION_ORDER_REQUIREMENTS: 'UsCheckoutConfirmationOrderRequirements',
  US_CHECKOUT_CONFIRMATION_SHIPPING_HANDLING_TAX: 'UsCheckoutConfirmationShippingHandlingTax',
  US_CHECKOUT_CONFIRMATION_ORDERNO: 'UsCheckoutConfirmationOrderno',
  US_CHECKOUT_CONFIRMATION_PONO: 'UsCheckoutConfirmationPoNo',
  US_CHECKOUT_CONFIRMATION_ARIBA_BUTTON_TEXT: 'UsCheckoutConfirmationAribaButtonText',
  US_CHECKOUT_CONFIRMATION_ARIBA_ERROR_MODAL_TEXT: 'UsCheckoutConfirmationAribaErrorModalText',
  CARTPRINT_INFOHEADER: 'CartPrintInfoHeader',
  CARTPRINT_INFOTEXT: 'CartPrintInfoText',
  CHECKOUTPRINT_INFO_FOOTER: 'CartPrintInfoFooter',
  CHECKOUTPRINT_INFOHEADER: 'CheckoutPrintInfoHeader',
  CHECKOUTPRINT_INFOTEXT: 'CheckoutPrintInfoText',
  SHARED_ITEMS_ADDED_TO_YOUR_CART: 'itemsAddedToYourCart',
  US_BULKORDER_ASSIGN_TO_PATIENT: 'UsBulkOrderAssignToPatient',
  US_BULKORDER_ASSIGN_TO_PATIENT_NOTE: 'UsBulkOrderAssignToPatientNote',
  US_BULKORDER_ASSIGN_TO_PATIENT_QUANTITY_EXCEEDED: 'UsBulkOrderAssignToPatientQuantityExceeded',
  US_BULKORDER_ASSIGN_TO_PATIENT_HEADER_TEXT: 'UsBulkOrderAssignToPatientHeaderText',
  US_BULKORDER_ASSIGN_TO_PATIENT_HEADER_NOTE_TEXT: 'UsBulkOrderAssignToPatientHeaderNoteText',
  US_BULKORDER_ASSIGN_TO_PATIENT_ASSIGN_TO_PATIENT_LABEL: 'UsBulkOrderAssignToPatientAssignToPatientLabel',
  US_BULKORDER_ASSIGN_TO_PATIENT_ASSIGN_MORE_PATIENT_LABEL: 'UsBulkOrderAssignToPatientAssignMorePatientLabel',
  US_BULKORDER_ASSIGN_TO_PATIENT_ADD_MORE_PRODUCTS_LABEL: 'UsBulkOrderAssignToPatientAddMoreProductsLabel',
  US_BULKORDER_ASSIGN_TO_PATIENT_ENTER_PATIENT_NAME_LABEL: 'UsBulkOrderAssignToPatientEnterPatientNameLabel',
  US_BULKORDER_VARIANT_SELECTOR_HEADER_TEXT: 'UsBulkOrderVariantSelectorHeaderText',
  US_BULKORDER_BULK_ORDER: 'UsBulkOrderBulkOrder',
  US_BULKORDER_FITTING_LEVELS: 'UsBulkOrderFittingLevels',
  US_BULKORDER_BATTERY_SIZE: 'UsBulkOrderBatterySize',
  US_BULKORDER_UNIT_PRICE: 'UsBulkOrderUnitPrice',
  US_BULKORDER_NET_PRICE: 'UsBulkOrderNetPrice',
  US_BULKORDER_INCREASE_WARRANTY_COVERAGE_FOR_ALL_UNITS: 'UsBulkOrderIncreaseWarrantyCoverageForAllUnits',
  US_BULKORDER_STANDARD_WARRANTY: 'UsBulkOrderStandardWarranty',
  US_BULKORDER_PREFERRED_WARRANTY: 'UsBulkOrderPreferredWarranty',
  US_BULKORDER_STANDARD_WARRANTY_NOT_AVAILABLE: 'UsBulkOrderStandardWarrantyNotAvailable',
  US_BULKORDER_ADD_ADDITIONAL_WARRANTY: 'UsBulkOrderAddAdditionalWarranty',
  US_BULKORDER_ADD_DOMES: 'UsBulkOrderAddDomes',
  US_BULKORDER_ADD_DOME: 'UsBulkOrderAddDome',
  US_BULKORDER_EXCLUDE_DOMES: 'UsBulkOrderExcludeDomes',
  US_BULKORDER_DOME_SUB_HEADER_TEXT: 'UsBulkOrderDomeSubHeaderText',
  US_BULKORDER_ERROR_TOO_MANY_DOMES: 'UsBulkOrderTooManyDomes',
  US_BULKORDER_DOME_DESCRIPTION: 'UsBulkOrderDomeDescription',
  US_BULKORDER_SIZE: 'UsBulkOrderSize',
  US_BULKORDER_DOME_SPEAKER_MODAL_DESC_TEXT: 'UsBulkOrderDomeSpeakerModalDescText',
  US_BULKORDER_DOME_SPEAKER_MODAL_ADD_MORE_TEXT: 'UsBulkOrderDomeSpeakerModalAddMoreText',
  US_BULKORDER_DOME_SPEAKER_MODAL_CONTINUE_TEXT: 'UsBulkOrderDomeSpeakerModalContinueText',
  US_BULKORDER_ADD_SPEAKERS: 'UsBulkOrderAddSpeakers',
  US_BULKORDER_EXCLUDE_SPEAKERS: 'UsBulkOrderExcludeSpeakers',
  US_BULKORDER_SPEAKER_SUB_HEADER_TEXT: 'UsBulkOrderSpeakerSubHeaderText',
  US_BULKORDER_ERROR_TOO_MANY_SPEAKERS: 'UsBulkOrderTooManySpeakers',
  US_BULKORDER_SPEAKER_ASSIGNMENT_MESSAGE: 'UsBulkOrderSpeakerAssignmentMessage',
  US_BULKORDER_SPEAKER_ASSIGNMENT_ZERO_QUANTITY_MESSAGE: 'UsBulkOrderSpeakerAssignmentZeroQtyMessage',
  US_BULKORDER_DOME_ASSIGNMENT_MESSAGE: 'UsBulkOrderDomeAssignmentMessage',
  US_BULKORDER_DOME_ASSIGNMENT_ZERO_QUANTITY_MESSAGE: 'UsBulkOrderDomeAssignmentZeroQtyMessage',
  US_BULKORDER_SPEAKER_LEVEL: 'UsBulkOrderSpeakerLevel',
  US_BULKORDER_ADD_SPEAKER: 'UsBulkOrderAddSpeaker',
  US_BULKORDER_SPEAKER_DESCRIPTION: 'UsBulkOrderSpeakerDescription',
  US_BULKORDER_PRODUCT_MESSAGE: 'UsBulkOrderProductMessage',
  US_BULKORDER_SPEAKER_MESSAGE: 'UsBulkOrderSpeakerMessage',
  US_BULKORDER_DOME_MESSAGE: 'UsBulkOrderDomeMessage',
  US_BULKORDER_CONFLICTING_PRODUCTS_HEADER_LABEL: 'UsBulkOrderConflictingProductsHeaderLabel',
  US_BULKORDER_CONFLICTING_PRODUCTS_BODY_LABEL: 'UsBulkOrderConflictingProductsBodyLabel',
  US_BULKORDER_CONFLICTING_PRODUCTS_CONTINUE_LABEL: 'UsBulkOrderConflictingProductsContinueLabel',
  US_BULKORDER_CONFLICTING_PRODUCTS_BACK_LABEL: 'UsBulkOrderConflictingProductsBackLabel',
  US_BULKORDER_INCLUDED_WARRANTIES: 'UsBulkOrderIncludedWarranties',
  US_BULKORDER_FLEXIBLE_MESSAGING_SPACE: 'UsBulkOrderFlexibleMessagingSpace',
  US_BULKORDER_ADD_RELATED_PRODUCTS: 'UsBulkOrderAddRelatedProducts',
  US_BULKORDER_TAMPER_RESISTANT_DOOR_REQUIRED: 'UsBulkOrderTamperResistantDoorRequired',
  US_BULKORDER_VALIDATION_TOO_MANY_SPEAKERS: 'UsBulkOrderValidationTooManySpeakers',
  US_BULKORDER_VALIDATION_SPEAKERS_MISSING_FIELDS: 'UsBulkOrderValidationSpeakersMissingFields',
  US_BULKORDER_VALIDATION_TOO_MANY_DOMES: 'UsBulkOrderValidationTooManyDomes',
  US_BULKORDER_VALIDATION_DOMES_MISSING_FIELDS: 'UsBulkOrderValidationDomesMissingFields',
  PRODUCT_ORDER_EXIT_EDIT: 'ProductOrderExitEdit',
  US_CART_DETAILS_REMOVE_TEXT: 'UsCartDetailsRemoveText',
  US_CART_DETAILS_SEND_IMPRESSION_SEPARATE: 'UsCartDetailsSendImpressionSeparate',
  US_CART_DETAILS_PROMOTIONS_COUPONS_HEADER: 'UsCartDetailsPromotionsCouponsHeader',
  US_CART_DETAILS_PROMOTIONS_COUPONS_DESCRIPTION: 'UsCartDetailsPromotionsCouponsDescription',
  US_CART_DETAILS_APPLY: 'UsCartDetailsApply',
  US_CART_DETAILS_VIEW_CURRENT_PROMOTIONS: 'UsCartDetailsViewCurrentPromotions',
  US_CART_DETAILS_VIEW_ALL_PROMOTIONS: 'UsCartDetailsViewAllPromotions',
  US_CART_DETAILS_PROMOTIONS_SUCCESS_MESSAGE: 'UsCartDetailsPromotionsSuccessMessage',
  US_CART_DETAILS_PROMOTIONS_ERROR_MESSAGE: 'UsCartDetailsPromotionsErrorMessage',
  US_CART_DETAILS_SHOW_PROMOTIONS_LIST: 'UsCartDetailsShowPromotionsList',
  US_PRODUCT_LIST_PLACE_BULK_ORDER: 'UsProductListPlaceBulkOrder',
  US_PRODUCT_LIST_BUY_ACCESSORIES: 'UsProductListBuyAccessories',
  US_CART_DETAILS_PROMOTIONS_COUPONS_MAX_COUPON_CODES_SELECTED_ERROR: 'UsCartDetailsPromotionsCouponsMaxCouponCodesSelectedError',
  US_CART_DETAILS_PROMOTIONS_COUPONS_TOO_MANY_ITEMS_ERROR: 'UsCartDetailsPromotionsCouponsTooManyItemsError',
  US_CART_DETAILS_PROMOTIONS_COUPONS_TOO_FEW_ITEMS_ERROR: 'UsCartDetailsPromotionsCouponsTooFewItemsError',
  US_CART_DETAILS_PROMOTIONS_COUPONS_POPUP_HEADER_TEXT: 'UsCartDetailsPromotionsCouponsPopupHeaderText',
  US_CART_DETAILS_PROMOTIONS_COUPONS_POPUP_DESCRIPTION_TEXT: 'UsCartDetailsPromotionsCouponsPopupDescriptionText',
  US_CART_DETAILS_PROMOTIONS_COUPONS_POPUP_NO_COUPONS_FOUND: 'UsCartDetailsPromotionsCouponsPopupNoCouponsFound',
  US_CART_DETAILS_APPLIED_PROMO_CODE_TEXT: 'UsCartDetailsAppliedPromoCodeText',
  US_CART_DETAILS_EDIT_PROMO_CODE_TEXT: 'UsCartDetailsEditPromoCodeText',
  ACCOUNT_MANAGEMENT_HEADER_TEXT: 'AccountManagementHeaderText',
  ACCOUNT_MANAGEMENT_DESC_TEXT: 'AccountManagementDescText',
  ACCOUNT_MANAGEMENT_REQUIRED_FIELD_TEXT: 'AccountManagementRequiredFieldText',
  ACCOUNT_MANAGEMENT_COMM_PREFERENCE: 'AccountManagementCommPreference',
  ACCOUNT_MANAGEMENT_UPDATE_NOW_TEXT: 'AccountManagementUpdateNowText',
  ACCOUNT_MANAGEMENT_ACCOUNT_INFORMATION_TEXT: 'AccountManagementAccountInformationText',
  ACCOUNT_MANAGEMENT_EDIT_TEXT: 'AccountManagementEditText',
  ACCOUNT_MANAGEMENT_ACCOUNT_NAME_TEXT: 'AccountManagementAccountNameText',
  ACCOUNT_MANAGEMENT_ACCOUNT_NO_TEXT: 'AccountManagementAccountNoText',
  ACCOUNT_MANAGEMENT_BILL_TO_TEXT: 'AccountManagementBillToText',
  ACCOUNT_MANAGEMENT_SHIPPING_ADDRESS_TEXT: 'AccountManagementShippingAddressText',
  ACCOUNT_MANAGEMENT_BUSINESS_PHONE_TEXT: 'AccountManagementBusinessPhoneText',
  ACCOUNT_MANAGEMENT_FAX_TEXT: 'AccountManagementFaxText',
  ACCOUNT_MANAGEMENT_BUSINESS_EMAIL_TEXT: 'AccountManagementBusinessEmailText',
  ACCOUNT_MANAGEMENT_BUSINESS_PHONE_REQUIRED_TEXT: 'AccountManagementBusinessPhoneRequiredText',
  ACCOUNT_MANAGEMENT_BUSINESS_EMAIL_REQUIRED_TEXT: 'AccountManagementBusinessEmailRequiredText',
  ACCOUNT_MANAGEMENT_FINANCIAL_COMM_PRE_TEXT: 'AccountManagementFinancialCommPreText',
  ACCOUNT_MANAGEMENT_STATEMENT_DELIVERY_METHOD_TEXT: 'AccountManagementStatementDeliveryMethodText',
  ACCOUNT_MANAGEMENT_DO_NOT_SEND_NON_EDIT_TEXT: 'AccountManagementDoNotSendNonEditText',
  ACCOUNT_MANAGEMENT_MAIL_TO_NON_EDIT_TEXT: 'AccountManagementMailToNonEditText',
  ACCOUNT_MANAGEMENT_EMAIL_NON_EDIT_TEXT: 'AccountManagementEmailNonEditText',
  ACCOUNT_MANAGEMENT_CHOOSE_DELIVERY_TEXT: 'AccountManagementChooseDeliveryText',
  ACCOUNT_MANAGEMENT_MAIL_OPTION_TEXT: 'AccountManagementMailOptionText',
  ACCOUNT_MANAGEMENT_EMAIL_OPTION_TEXT: 'AccountManagementEmailOptionText',
  ACCOUNT_MANAGEMENT_DO_NOT_SEND_OPTION_TEXT: 'AccountManagementDoNotSendOptionText',
  ACCOUNT_MANAGEMENT_SELECT_EMAIL_TEXT: 'AccountManagementSelectEmailText',
  ACCOUNT_MANAGEMENT_CHOOSE_EMAIL_TEXT: 'AccountManagementChooseEmailText',
  ACCOUNT_MANAGEMENT_INVOICE_DELIVERY_METHOD_TEXT: 'AccountManagementInvoiceDeliveryMethodText',
  ACCOUNT_MANAGEMENT_VALID_PHONE_ERROR_TEXT: 'AccountManagementValidPhoneErrorText',
  ACCOUNT_MANAGEMENT_PHONE_REQUIRE_ERROR_TEXT: 'AccountManagementPhoneRequireErrorText',
  ACCOUNT_MANAGEMENT_VALID_EMAIL_ERROR_TEXT: 'AccountManagementValidEmailErrorText',
  ACCOUNT_MANAGEMENT_EMAIL_REQUIRE_ERROR_TEXT: 'AccountManagementEmailRequireErrorText',
  ACCOUNT_MANAGEMENT_CHOOSE_EMAIL_ERROR_TEXT: 'AccountManagementChooseEmailErrorText',
  ACCOUNT_MANAGEMENT_MODAL_TEXT: 'AccountManagementModalText',
  ACCOUNT_MANAGEMENT_NO_ACCOUNTS_FOUND_TEXT: 'AccountManagementNoAccountsFoundText',
  ACCOUNT_MANAGEMENT_DEFAULT_LOGIN_ACCOUNT_HEADER: 'AccountManagementDefaultLoginAccountHeader',
  ACCOUNT_MANAGEMENT_DEFAULT_LOGIN_CHOOSE_LOGIN_TEXT: 'AccountManagementDefaultLoginChooseLoginText',
  ACCOUNT_MANAGEMENT_DEFAULT_LOGIN_HEADER: 'AccountManagementDefaultLoginHeader',
  USER_MANAGEMENT_ADMIN_TEXT: 'UserManagementAdminText',
  USER_MANAGEMENT_PURCHASER_WITH_PRICING_TEXT: 'UserManagementPurchaserWithPricingText',
  USER_MANAGEMENT_PURCHASER_WITHOUT_PRICING_TEXT: 'UserManagementPurchaserWithoutPricingText',
  USER_MANAGEMENT_ASSOCIATE_TEXT: 'UserManagementAssociateText',
  USER_MANAGEMENT_HEADER_TEXT: 'UserManagementHeaderText',
  USER_MANAGEMENT_HEADER_INTRO_TEXT: 'UserManagementHeaderIntoText',
  USER_MANAGEMENT_DESCRIPTION_TEXT: 'UserManagementDescriptionText',
  USER_MANAGEMENT_ADD_NEW_USER_BUTTON: 'UserManagementAddNewUserButton',
  USER_MANAGEMENT_MARKETING_TEXT: 'UserManagementMarketingText',
  USER_MANAGEMENT_VIEW_PRICING_TEXT: 'UserManagementViewPricingText',
  USER_MANAGEMENT_SHOP_TEXT: 'UserManagementShopText',
  USER_MANAGEMENT_ADD_FIRST_NAME_TEXT: 'UserManagementAddFirstNameText',
  USER_MANAGEMENT_ADD_MIDDLE_NAME_TEXT: 'UserManagementAddMiddleNameText',
  USER_MANAGEMENT_ADD_LAST_NAME_TEXT: 'UserManagementAddLastNameText',
  USER_MANAGEMENT_ADD_USER_ID_TEXT: 'UserManagementAddUserIdText',
  USER_MANAGEMENT_FAX_TEXT: 'UserManagementFaxText',
  USER_MANAGEMENT_PHONE_TEXT: 'UserManagementPhoneText',
  USER_MANAGEMENT_ADD_USER_FORM_TEXT: 'UserManagementAddUserFormText',
  USER_MANAGEMENT_ADD_USER_ADMIN_TEXT: 'UserManagementAddUserAdminText',
  USER_MANAGEMENT_ADD_USER_PUR_WITH_PRICE_DESC_TEXT: 'UserManagementAddUserPurWithPriceDescText',
  USER_MANAGEMENT_ADD_USER_PUR_WITHOUT_PRICE_DESC_TEXT: 'UserManagementAddUserPurWithoutPriceDescText',
  USER_MANAGEMENT_ADD_USER_ASSOCIATE_DESC_TEXT: 'UserManagementAddUserAssociateDescText',
  USER_MANAGEMENT_ADD_USER_MARKETING_DESC_TEXT: 'UserManagementAddUserMarketingDescText',
  USER_MANAGEMENT_ADD_USER_ACCOUNT_DESC_TEXT: 'UserManagementAddUserAccountDescText',
  USER_MANAGEMENT_ADD_USER_SAVE_MODAL_CONFIRMATION_TEXT: 'UserManagementAddUserSaveModalConfirmationText',
  USER_MANAGEMENT_ADD_USER_SAVE_MODAL_CANCEL_TEXT: 'UserManagementAddUserSaveModalCancelText',
  USER_MANAGEMENT_ADD_USER_SAVE_MODAL_OK_TEXT: 'UserManagementAddUserSaveModalOkText',
  USER_MANAGEMENT_ADD_USER_SAVE_BUTTON_TEXT: 'UserManagementAddUserSaveButtonText',
  USER_MANAGEMENT_ADD_USER_NO_ACCOUNT_FOUND_TEXT: 'UserManagementAddUserNoAccountFoundText',
  USER_MANAGEMENT_ADD_USER_DEFAULT_ACCOUNT_TEXT: 'UserManagementAddUserDefaultAccountText',
  USER_MANAGEMENT_ADD_USER_ROLE_TEXT: 'UserManagementAddUserRoleText',
  USER_MANAGEMENT_ADD_USER_ACCESS_TEXT: 'UserManagementAddUserAccessText',
  USER_MANAGEMENT_ADD_USER_DEFAULT_NOT_SELECTED_TEXT: 'UserManagementAddUserDefaultNotSelectedText',
  USER_MANAGEMENT_ADD_USER_ROLE_NOT_SELECTED_TEXT: 'UserManagementAddUserRoleNotSelectedText',
  USER_MANAGEMENT_ADD_USER_ACCOUNT_NOT_SELECTED_TEXT: 'UserManagementAddUserAccountNotSelectedText',
  USER_MANAGEMENT_ADD_USER_FORM_NOT_COMPLETE_TEXT: 'UserManagementAddUserFormNotCompleteText',
  USER_MANAGEMENT_ADD_USER_SAVE_MODAL_ERROR_TEXT: 'UserManagementAddUserSaveModalErrorText',
  USER_MANAGEMENT_ADD_USER_SAVE_MODAL_ERROR_OK_TEXT: 'UserManagementAddUserSaveModalErrorOKText',
  USER_MANAGEMENT_ADD_USER_USER_ID_NOT_VALID_TEXT: 'UserManagementAddUserUserIdNotValidText',
  USER_MANAGEMENT_ADD_USER_EMAIL_NOT_VALID_TEXT: 'UserManagementAddUserEmailNotValidText',
  USER_MANAGEMENT_ADD_USER_PHONE_NO_NOT_VALID_TEXT: 'UserManagementAddUserPhoneNoNotValidText',
  USER_MANAGEMENT_DATA_LIST_USER_NAME_TEXT: 'UserManagementDataListUserNameText',
  USER_MANAGEMENT_DATA_LIST_USER_ID_TEXT: 'UserManagementDataListUserIDText',
  USER_MANAGEMENT_DATA_LIST_ROLE_TEXT: 'UserManagementDataListRoleText',
  USER_MANAGEMENT_DATA_LIST_REMOVE_TEXT: 'UserManagementDataListRemoveText',
  USER_MANAGEMENT_DATA_LIST_REMOVE_MODAL_TEXT: 'UserManagementDataListRemoveModalText',
  USER_MANAGEMENT_DATA_LIST_MANAGE_TEXT: 'UserManagementDataListManageText',
  USER_MANAGEMENT_DATA_LIST_EDIT_TEXT: 'UserManagementDataListEditText',
  USER_MANAGEMENT_DATA_LIST_REMOVE_MODAL_OK_TEXT: 'UserManagementDataListRemoveModalOKText',
  USER_MANAGEMENT_DATA_LIST_REMOVE_MODAL_CANCEL_TEXT: 'UserManagementDataListRemoveModalCancelText',
  USER_MANAGEMENT_DATA_LIST_NO_USERS_FOUND_TEXT: 'UserManagementDataListNoUsersFoundText',
  USER_MANAGEMENT_DATA_LIST_UPDATE_FAILED_MODAL_TEXT: 'UserManagementDataListUpdateFailedModalText',
  USER_MANAGEMENT_EDIT_MODAL_ADDRESS_TEXT: 'UserManagementEditModalAddressText',
  USER_MANAGEMENT_EDIT_MODAL_BUSINESS_EMAIL_REQUIRED_TEXT: 'UserManagementEditModalBusinessEmailRequiredText',
  USER_MANAGEMENT_EDIT_MODAL_EXISTING_ROLE_TEXT: 'UserManagementEditModalExistingRoleText',
  USER_MANAGEMENT_EDIT_MODAL_FAX_TEXT: 'UserManagementEditModalFaxText',
  USER_MANAGEMENT_EDIT_MODAL_ID_TEXT: 'UserManagementEditModalIDText',
  USER_MANAGEMENT_EDIT_MODAL_NAME_TEXT: 'UserManagementEditModalNameText',
  USER_MANAGEMENT_EDIT_MODAL_NEW_ROLE_DESC_TEXT: 'UserManagementEditModalNewRoleDescText',
  USER_MANAGEMENT_EDIT_MODAL_NEW_ROLE_TEXT: 'UserManagementEditModalNewRoleText',
  USER_MANAGEMENT_EDIT_MODAL_PERSONAL_EMAIL_TEXT: 'UserManagementEditModalPersonalEmailText',
  USER_MANAGEMENT_EDIT_MODAL_BUSINESS_EMAIL_TEXT: 'UserManagementEditModalBusinessEmailText',
  USER_MANAGEMENT_EDIT_MODAL_PHONE_REQUIRED_TEXT: 'UserManagementEditModalPhoneRequiredText',
  USER_MANAGEMENT_EDIT_MODAL_PHONE_TEXT: 'UserManagementEditModalPhoneText',
  USER_MANAGEMENT_EDIT_MODAL_EDIT_USER_TEXT: 'UserManagementEditModalEditUserText',
  USER_MANAGEMENT_EDIT_MODAL_EMAIL_VALIDATE_TEXT: 'UserManagementEditModalEmailValidateText',
  USER_MANAGEMENT_EDIT_MODAL_PHONE_VALIDATE_TEXT: 'UserManagementEditModalPhoneValidateText',
  USER_MANAGEMENT_EDIT_MODAL_CHOOSE_ROLE_TEXT: 'UserManagementEditModalChooseRoleText',
  USER_MANAGEMENT_ACCOUNT_ACCESS_TEXT: 'UserManagementAccountAccessText',
  USER_MANAGEMENT_ADD_USER_ADD_USER_TEXT: 'UserManagementAddUserAddUserText',
  USER_MANAGEMENT_ORDERS_INVOICES_CREDIT_MEMO_TEXT: 'UserManagementOrdersInvoicesCreditMemoText',
  USER_MANAGEMENT_ACCOUNT_OVERVIEW_PAYMENTS_HISTORY_TEXT: 'UserManagementAccountOverviewPaymentHistoryText',
  USER_MANAGEMENT_SUPPORT_PRODUCT_TOOLS_TEXT: 'UserManagementSupportProductToolsText',
  USER_MANAGEMENT_OTICON_BUSINESS_SUPPORT_TEXT: 'UserManagementOticonBusinessSupportText',
  USER_MANAGEMENT_USER_ACCOUNT_MANAGEMENT_TEXT: 'UserManagementUserAccountManagementText',
  US_PROMOTIONS_MY_DISCOUNTS_HEADER: 'UsPromotionsMyDiscountsHeader',
  US_PROMOTIONS_MY_DISCOUNTS_HEADER_TEXT: 'UsPromotionsMyDiscountsHeaderText',
  US_PROMOTIONS_MY_DISCOUNTS_ORDER_PART_ONE: 'UsPromotionsMyDiscountsOrderPartOne',
  US_PROMOTIONS_MY_DISCOUNTS_ORDER_PART_TWO: 'UsPromotionsMyDiscountsOrderPartTwo',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_HEADER: 'UsPromotionsMyDiscountsPromotionsHeader',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_TEXT: 'UsPromotionsMyDiscountsPromotionsText',
  US_PROMOTIONS_MY_DISCOUNTS_NO_PROMOS_AVAIL: 'UsPromotionsMyDiscountsNoPromotionsAvailable',
  US_PROMOTIONS_MY_DISCOUNTS_COUPONS_HEADER: 'UsPromotionsMyDiscountsCouponsHeader',
  US_PROMOTIONS_MY_DISCOUNTS_COUPONS_TEXT: 'UsPromotionsMyDiscountsCouponsText',
  US_PROMOTIONS_MY_DISCOUNTS_NO_COUPONS_AVAIL: 'UsPromotionsMyDiscountsNoCouponsAvailable',
  US_PROMOTIONS_MY_DISCOUNTS_EDIT_PROMO_MODAL_TEXT: 'UsPromotionsMyDiscountsEditPromoModalText',
  US_PROMOTIONS_MY_DISCOUNTS_COUPONS_VALID_THRU: 'UsPromotionsMyDiscountsCouponsValidThru',
  US_PROMOTIONS_MY_DISCOUNTS_COUPONS_COUPON_CODE: 'UsPromotionsMyDiscountsCouponsCouponCodeLabel',
  US_PROMOTIONS_MY_DISCOUNTS_COUPONS_DETAILS_LINK_TEXT: 'UsPromotionsMyDiscountsCouponsDetailsLinkText',
  US_PROMOTIONS_MY_DISCOUNTS_NO_PROMOTIONS_AVAIL: 'UsPromotionsMyDiscountsNoPromotionsAvailable',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_VALID_THRU: 'UsPromotionsMyDiscountsPromotionsValidThru',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_PROMOTION_CODE: 'UsPromotionsMyDiscountsPromotionsPromotionCode',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_COPY_PROMOTION_CODE: 'UsPromotionsMyDiscountsPromotionsCopyPromotionCode',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_PROMOTION_CODE_COPIED: 'UsPromotionsMyDiscountsPromotionsPromotionCodeCopied',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_DETAILS_LINK_TEXT: 'UsPromotionsMyDiscountsPromotionsDetailsLinkText',
  US_PROMOTIONS_MY_DISCOUNTS_POPUP_HEADER_TEXT: 'UsPromotionsMyDiscountsPopupHeaderText',
  US_PROMOTIONS_MY_DISCOUNTS_APPLY_PROMO_CODE_TEXT: 'UsPromotionsMyDiscountsApplyPromoCodeText',
  US_PROMOTIONS_MY_DISCOUNTS_MODAL_EDIT_TEXT_KEEP_CODE: 'UsPromotionsMyDiscountsModalEditTextKeepCode',
  US_PROMOTIONS_MY_DISCOUNTS_MODAL_EDIT_TEXT_EDIT_CODE: 'UsPromotionsMyDiscountsModalEditTextEditCode',
  US_PROMOTIONS_MY_DISCOUNTS_PROMOTIONS_ITEMS_COUNT: 'UsPromotionsMyDiscountsPromotionsItemsCount',
  US_PROMOTIONS_MY_DISCOUNTS_LESS_ITEMS_MODAL_TEXT: 'UsPromotionsMyDiscountsLessItemsModalText',
  US_PROMOTIONS_MY_DISCOUNTS_PROMO_HINT: 'UsPromotionsMyDiscountsPromoHint',
  US_PROMOTIONS_MY_DISCOUNTS_PROMO_CODE_LABEL: 'UsPromotionsMyDiscountsPromoCodeLabel',
  US_PROMOTIONS_MY_DISCOUNTS_PLACE_ORDER_BUTTON_TEXT: 'UsPromotionsMyDiscountsPlaceOrderButtonText',
  US_CART_DETAILS_PROMOTIONS_POPUP_HEADER_TEXT: 'UsCartDetailsPromotionsPopupHeaderText',
  US_CART_DETAILS_PROMOTIONS_POPUP_DESCRIPTION_TEXT: 'UsCartDetailsPromotionsPopupDescriptionText',
  US_CART_DETAILS_PROMOTIONS_POPUP_NO_PROMOS_FOUND: 'UsCartDetailsPromotionsPopupNoPromosFound',
  US_CART_DETAILS_PROMOTIONS_EDIT_OR_REMOVE_PROMO_WARNING: 'UsCartDetailsPromotionsEditOrRemovePromoWarning',
  US_CART_DETAILS_PROMOTIONS_EDIT_PROMO_WARNING: 'UsCartDetailsPromotionsEditPromoWarning',
  US_CART_DETAILS_PROMOTIONS_EDIT_OR_REMOVE_COUPON_WARNING: 'UsCartDetailsPromotionsEditOrRemoveCouponWarning',
  US_CART_DETAILS_PROMOTIONS_EDIT_COUPON_WARNING: 'UsCartDetailsPromotionsEditCouponWarning',
  US_CART_DETAILS_PROMOTIONS_ADD_COUPON_REMOVE_PROMO_WARNING: 'UsCartDetailsPromotionsAddCouponRemovePromoWarning',
  US_CART_DETAILS_PROMOTIONS_ADD_PROMOTION_REMOVE_COUPON_WARNING: 'UsCartDetailsPromotionsAddPromoRemoveCouponWarning',
  US_CART_DETAILS_PROMOTIONS_REMOVE_COUPON_WARNING: 'UsCartDetailsPromotionsRemoveCouponWarning',
  US_CART_DETAILS_PROMOTIONS_REMOVE_PROMO_WARNING: 'UsCartDetailsPromotionsRemovePromotionWarning',
  US_CART_DETAILS_PROMOTIONS_EDIT_RELATED_PRODUCT_PROMO_WARNING: 'UsCartDetailsPromotionsEditRelatedProductWarning',
  US_CART_DETAILS_PREFERENCES_NOTE_TEXT: 'UsCartDetailsPreferencesNoteText',
  US_PROMOTIONS_FREE_ITEMS_LOAD_MORE_BUTTON_LABEL: 'UsPromotionsFreeItemsLoadMoreButtonLabel',
  US_PROMOTIONS_FREE_ITEMS_TOO_MANY_DOMES_WARNING: 'UsPromotionsFreeItemsTooManyDomesWarning',
  US_PROMOTIONS_FREE_ITEMS_TOO_MANY_SPEAKERS_WARNING: 'UsPromotionsFreeItemsTooManySpeakersWarning',
  US_MY_OTICON_ACCOUNT_SWITCHER_CONFIRMATION_MESSAGE: 'UsMyOticonAccountSwitcherConfirmationMessage',
  NOTIFICATION_WARRANTY_EXPIRING_MESSAGE: 'NotificationWarrantyExpiringMessage',
  NOTIFICATION_WARRANTY_EXPIRING_CTA_LINK: 'NotificationWarrantyExpiringCTALink',
  NOTIFICATION_WARRANTY_EXPIRING_CTA_TEXT: 'NotificationWarrantyExpiringCTAText',
  TEMP_SMART_CHARGER_WARNING_STOCK_ORDER: 'SmartChargerWarningStockOrder',
  TEMP_SMART_CHARGER_WARNING_PATIENT_ORDER: 'SmartChargerWarningPatientOrder',
  DESKTOP_CHARGER_TEXT: 'SharedDesktopChargerText',
  SMART_CHARGER_TEXT: 'SharedSmartChargerText',
  BULK_ORDER_SMART_CHARGER_HEADER_TEXT: 'BulkOrderSmartChargerHeaderText',
  TEMP_SMART_CHARGER_WARNING_PRODUCT_ORDER: 'SmartChargerWarningProductOrder',
  TEMP_SMART_CHARGER_ELIGIBLE_NOTE_STOCK_ORDER: 'SmartChargerEligibleNoteStockOrder',
  SERVICE_ORDER_HEADER_TEXT: 'ServiceOrderHeaderText',
  SERVICE_ORDER_DESCRIPTION_TEXT: 'ServiceOrderDescriptionText',
  SERVICE_ORDER_HOME_PAGE_MESSAGE_CTA_TEXT: 'ServiceOrderHomePageMessageCta',
  SERVICE_ORDER_SPEAKER_CHECKOUT_TEXT: 'ServiceOrderSpeakerCheckoutMessage',
  SERVICE_ORDER_SERIAL_NO_TEXT: 'ServiceOrderSerialNoText',
  SERVICE_ORDER_BUTTON_ENTER_TEXT: 'ServiceOrderButtonEnterText',
  SERVICE_ORDER_VALIDATION_VERIFIED_MESSAGE: 'ServiceOrderValidationVerifiedMessage',
  SERVICE_ORDER_VALIDATION_NOT_FOUND_MESSAGE: 'ServiceOrderValidationNotFoundMessage',
  SERVICE_ORDER_VALIDATION_TOO_MANY_ATTEMPTS_MESSAGE: 'ServiceOrderValidationTooManyAttemptsMessage',
  SERVICE_ORDER_VALIDATION_TOO_MANY_ATTEMPTS_CTA: 'ServiceOrderValidationTooManyAttemptsCTA',
  SERVICE_ORDER_VALIDATION_TOO_OLD_MESSAGE: 'ServiceOrderValidationTooOldMessage',
  SERVICE_ORDER_VALIDATION_DUPLICATE_IN_CART: 'ServiceOrderValidationDuplicateInCart',
  SERVICE_ORDER_VALIDATION_CANNOT_BE_BLANK: 'ServiceOrderValidationCannotBeBlank',
  SERVICE_ORDER_VALIDATION_INCORRECT: 'ServiceOrderValidationIncorrect',
  SERVICE_ORDER_VALIDATION_UNKNOWN_ERROR: 'ServiceOrderValidationUnknownError',
  SERVICE_ORDER_VALIDATION_THIRD_PARTY_PURCHASE_CTA: 'ServiceOrderValidationThirdPartyPurchaseCta',
  SERVICE_ORDER_VALIDATION_OTHER_CLINIC_PURCHASE: 'ServiceOrderValidationOtherClinicPurchase',
  SERVICE_ORDER_VALIDATION_OTHER_CLINIC_PURCHASE_CTA_OPTION_YES: 'ServiceOrderValidationOtherClinicPurchaseCtaOptionYes',
  SERVICE_ORDER_VALIDATION_OTHER_CLINIC_PURCHASE_CTA_OPTION_NO: 'ServiceOrderValidationOtherClinicPurchaseCtaOptionNo',
  SERVICE_ORDER_VALIDATION_USED_SERIAL_NUMBER: 'ServiceOrderValidationUsedSerialNumber',
  SERVICE_ORDER_IMPRESSIONS_SELECT_IMPRESSION_TEXT: 'ServiceOrderImpressionsSelectImpressionText',
  SERVICE_ORDER_IMPRESSIONS_ENTER_IMPRESSION_ON_FILE_TEXTBOX_LABEL: 'ServiceOrderImpressionsEnterImpressionOnFileTextboxLabel',
  SERVICE_ORDER_OPTIONS_SPOT_LABEL_TEXT: 'ServiceOrderOptionsSpotLabelText',
  SERVICE_ORDER_OPTIONS_SPOT_EXPRESS_EXCHANGE_LABEL_TEXT: 'ServiceOrderOptionsSpotExpressExchangeLabel',
  SERVICE_ORDER_OPTIONS_SPOT_REPAIR_LABEL_TEXT: 'ServiceOrderOptionsSpotRepairLabel',
  SERVICE_ORDER_OPTIONS_SPOT_REPLACEMENT_LABEL_TEXT: 'ServiceOrderOptionsSpotReplacementLabel',
  SERVICE_ORDER_OPTIONS_SPOT_SPEAKER_REPLACEMENT_LABEL_TEXT: 'ServiceOrderOptionsSpotSpeakerReplacementLabel',
  SERVICE_ORDER_CART_DETAILS_EXPRESS_EXCHANGE_SERVICE_HEADER_TEXT: 'ServiceOrderCartDetailsExpressExchangeServiceHeaderText',
  SERVICE_ORDER_CART_DETAILS_EXPRESS_EXCHANGE_SERVICE_SUBHEADER_TEXT: 'ServiceOrderCartDetailsExpressExchangeServiceSubHeaderText',
  SERVICE_ORDER_CART_DETAILS_SERIAL_NO_TEXT: 'ServiceOrderCartDetailsSerialNoText',
  SERVICE_ORDER_CART_DETAILS_SERVICE_CODE_TEXT: 'ServiceOrderCartDetailsServiceCodeText',
  SERVICE_ORDER_CART_DETAILS_CURRENT_DEVICE_TEXT: 'ServiceOrderCartDetailsCurrentDeviceText',
  SERVICE_ORDER_CART_DETAILS_EXCHANGED_DEVICE_TEXT: 'ServiceOrderCartDetailsExchangedDeviceText',
  SERVICE_ORDER_CART_DETAILS_WARRANTY_STATUS_TEXT: 'ServiceOrderCartDetailsWarrantyStatusText',
  SERVICE_ORDER_CART_DETAILS_FITTER_INFORMATION_TEXT: 'ServiceOrderCartDetailsFitterInformationText',
  SERVICE_ORDER_CART_DETAILS_ORDER_PLACED_BY_TEXT: 'ServiceOrderCartDetailsOrderPlacedByText',
  SERVICE_ORDER_CART_DETAILS_PATIENT_NAME_TEXT: 'ServiceOrderCartDetailsPatientNameText',
  SERVICE_ORDER_CART_DETAILS_PATIENT_AGE_TEXT: 'ServiceOrderCartDetailsPatientAgeText',
  SERVICE_ORDER_CART_DETAILS_MEDICAID_TEXT: 'ServiceOrderCartDetailsMedicaidText',
  SERVICE_ORDER_CART_DETAILS_INVOICE_DATE_TEXT: 'ServiceOrderCartDetailsInvoiceDateText',
  SERVICE_ORDER_CART_DETAILS_FITTING_DATE_TEXT: 'ServiceOrderCartDetailsFittingDateText',
  SERVICE_ORDER_CART_DETAILS_SHIPPING_TO_ADDRESS_TEXT: 'ServiceOrderCartDetailsShippingAddressText',
  SERVICE_ORDER_CART_DETAILS_SPECIAL_INSTRUCTIONS_TEXT: 'ServiceOrderCartDetailsSpecialInstructionText',
  SERVICE_ORDER_CART_DETAILS_SERVICE_PRICE_TEXT: 'ServiceOrderCartDetailsServicePriceText',
  SERVICE_ORDER_CART_DETAILS_SERVICE_QTY_TEXT: 'ServiceOrderCartDetailsServiceQtyText',
  SERVICE_ORDER_CART_DETAILS_SERVICE_TOTAL_TEXT: 'ServiceOrderCartDetailsServiceTotalText',
  SERVICE_ORDER_CART_DETAILS_COPY_ORDER_EAR_TEXT: 'ServiceOrderCartDetailsCopyOrderEarText',
  SERVICE_ORDER_CART_DETAILS_ADD_ANOTHER_ORDER_TEXT: 'ServiceOrderCartDetailsAddAnotherOrderText',
  SERVICE_ORDER_CART_DETAILS_CHANGE_COLOR_TEXT: 'ServiceOrderCartDetailsChangeColorText',
  SERVICE_ORDER_CART_DETAILS_SERIAL_NO_NOT_FOUND_TEXT: 'ServiceOrderCartDetailsSerialNoNotFoundText',
  SERVICE_ORDER_CART_DETAILS_BILL_TO_TEXT: 'ServiceOrderCartDetailsBillToText',
  SERVICE_ORDER_CART_DETAILS_SELL_TO_TEXT: 'ServiceOrderCartDetailsSellToText',
  SERVICE_ORDER_DETAILS_CART_PO_TEXT: 'ServiceOrderCartDetailsPOText',
  SERVICE_ORDER_DETAILS_CART_EDIT_ORDER_TEXT: 'ServiceOrderCartDetailsEditOrderText',
  SERVICE_ORDER_DETAILS_CART_SAVE_FOR_LATER_TEXT: 'ServiceOrderCartDetailsSaveForLaterText',
  SERVICE_ORDER_DETAILS_CART_REMOVE_TEXT: 'ServiceOrderCartDetailsRemoveText',
  SERVICE_ORDER_DETAILS_CART_SAVE_BINAURAL_TEXT: 'ServiceOrderCartDetailsSaveBinauralText',
  SERVICE_ORDER_DETAILS_CART_CANCEL_TEXT: 'ServiceOrderCartDetailsCancelText',
  SERVICE_ORDER_DETAILS_CART_UPDATE_TEXT: 'ServiceOrderCartDetailsUpdateText',
  SERVICE_ORDER_DETAILS_CART_X_REMOVE_TEXT: 'ServiceOrderCartDetailsXRemoveText',
  SERVICE_ORDER_DETAILS_CART_ADD_TO_CART_TEXT: 'ServiceOrderCartDetailsAddToCartText',
  SERVICE_ORDER_DETAILS_CART_ORDER_PLACED_BY_EMAIL_TEXT: 'ServiceOrderCartDetailsOrderPlacedByEmailText',
  SERVICE_ORDER_DETAILS_CART_ENTER_TEXT: 'ServiceOrderCartDetailsEnterText',
  SERVICE_ORDER_DETAILS_CART_BINAURAL_ORDER_WARNING_TEXT: 'ServiceOrderCartDetailsBinauralOrderWarningText',
  SERVICE_ORDER_DETAILS_CART_DIFF_CLINIC_ORDER_WARNING_TEXT: 'ServiceOrderCartDetailsDiffClinicOrderWarningText',
  SERVICE_ORDER_DETAILS_CART_DIFF_CLINIC_YES_TEXT: 'ServiceOrderCartDetailsDiffClinicYesText',
  SERVICE_ORDER_DETAILS_CART_DIFF_CLINIC_NOPE_TEXT: 'ServiceOrderCartDetailsDiffClinicNopeText',
  SERVICE_ORDER_DETAILS_CART_DIFF_CLINIC_NOPE_SELECTED_TEXT: 'ServiceOrderCartDetailsDiffClinicNopeSelectedText',
  SERVICE_ORDER_DETAILS_CART_SERIAL_NUMBER_VERIFIED_TEXT: 'ServiceOrderCartDetailsSerialNumberVerifiedText',
  SERVICE_ORDER_SHIPPING_LABEL_INSTRUCTIONS: 'ServiceOrderShippingLabelInstructions',
  SERVICE_ORDER_CHECKOUT_CONFIRMATION_NOTES: 'ServiceOrderCheckoutConfirmationNotes',
  SERVICE_ORDER_REPAIR_CHECKOUT_CONFIRMATION_NOTES: 'ServiceOrderRepairCheckoutConfirmationNotes',
  SERVICE_ORDER_DETAILS_CART_REPLACEMENT_SERVICE_TEXT: 'ServiceOrderSpeakerReplacementServiceText',
  SERVICE_ORDER_DETAILS_CART_SPEAKER_REPLACEMENT_SERVICE_TEXT: 'ServiceOrderSpeakerReplacementSpeakerServiceText',
  SERVICE_ORDER_DETAILS_CART_SPEAKER_REPLACEMENT_SERVICE_CODE_TEXT: 'ServiceOrderSpeakerReplacementServiceCodeText',
  SERVICE_ORDER_DETAILS_CART_SPEAKER_REPLACEMENT_REMOVE_SPEAKER_TEXT: 'ServiceOrderSpeakerReplacementRemoveSpeakerText',
  SERVICE_ORDER_DETAILS_CART_SPEAKER_REPLACEMENT_SAVE_SPEAKER_TEXT: 'ServiceOrderSpeakerReplacementSaveSpeakerText',
  SERVICE_ORDER_DETAILS_CART_SPEAKER_REPLACEMENT_ADD_ANOTHER_SPEAKER_TEXT: 'ServiceOrderSpeakerReplacementAddAnotherSpeakerText',
  SERVICE_ORDER_DETAILS_CART_SPEAKER_REPLACEMENT_EAR_SIDE_TEXT: 'ServiceOrderSpeakerReplacementEarSideText',
  SERVICE_ORDER_DETAILS_CART_SPEAKER_REPLACEMENT_SPEAKER_LEVEL_TEXT: 'ServiceOrderSpeakerReplacementSpeakerLevelText',
  SERVICE_ORDER_SIGNATURE_SECTION_TITLE_TEXT: 'ServiceOrderSignatureSectionTitleText',
  SERVICE_ORDER_SIGNATURE_SECTION_HEADER_TEXT: 'ServiceOrderSignatureSectionHeaderText',
  SERVICE_ORDER_SIGNATURE_INSTRUCTIONS_TEXT: 'ServiceOrderSignatureInstructionsText',
  SERVICE_ORDER_SIGNATURE_PATIENT_SIGNATURE_LABEL: 'ServiceOrderSignaturePatientSignatureLabel',
  SERVICE_ORDER_SIGNATURE_DISPENSER_SIGNATURE_LABEL: 'ServiceOrderSignatureDispenserSignatureLabel',
  SERVICE_ORDER_SIGNATURE_DATE_OF_CLAIM_LABEL: 'ServiceOrderSignatureDateOfClaimLabel',
  SERVICE_ORDER_SIGNATURE_CLEAR_BUTTON_LABEL: 'ServiceOrderSignatureClearButtonLabel',
  SERVICE_ORDER_SIGNATURE_HIPAA_NOTICE_TEXT: 'ServiceOrderSignatureHipaaNoticeText',
  SERVICE_ORDER_EXPRESS_EXCHANGE_PRODUCT_INFORMATION_NOTICE: 'ServiceOrderExpressExchangeProductInformationNotice',
  SERVICE_QUOTE_SELECT_OPTION_TEXT: 'ServiceQuoteSelectOptionText',
  SERVICE_QUOTE_SIX_MONTH_OPTION_TEXT: 'ServiceQuoteSixMonthOptionText',
  SERVICE_QUOTE_TWELVE_MONTH_OPTION_TEXT: 'ServiceQuoteTwelveMonthOptionText',
  SERVICE_ORDER_WARRANTY_DOWNLOAD_SERVICE_FORM_BUTTON_TEXT: 'ServiceOrderWarrantyDownloadServiceFormButtonText',
  SERVICE_ORDER_WARRANTY_EXPIRATION_DATE_LABEL: 'ServiceOrderWarrantyExpirationDateLabel',
  SERVICE_ORDER_WARRANTY_EXPIRED_WARNING_MESSAGE: 'ServiceOrderWarrantyExpiredWarningMessage',
  SERVICE_ORDER_WARRANTY_EXTENDED_WARRANTY_COVERAGE_LABEL: 'ServiceOrderWarrantyExtendedWarrantyCoverageLabel',
  SERVICE_ORDER_WARRANTY_EXTEND_WARRANTY_BUTTON_LABEL: 'ServiceOrderWarrantyExtendWarrantyButtonLabel',
  SERVICE_ORDER_WARRANTY_EXTEND_WARRANTY_LATER_BUTTON_LABEL: 'ServiceOrderWarrantyExtendWarrantyLaterButtonLabel',
  SERVICE_ORDER_WARRANTY_EXTEND_WARRANTY_SECTION_INSTRUCTION_TEXT: 'ServiceOrderWarrantyExtendWarrantySectionInstructionText',
  SERVICE_ORDER_WARRANTY_GET_REPAIR_QUOTE_BUTTON_TEXT: 'ServiceOrderWarrantyGetRepairQuoteButtonText',
  SERVICE_ORDER_WARRANTY_STATUS_LABEL: 'ServiceOrderWarrantyStatusLabel',
  SERVICE_ORDER_WARRANTY_SERVICE_CHARGE_MESSAGE: 'ServiceOrderWarrantyServiceChargeMessage',
  SERVICE_ORDER_WARRANTY_SERVICE_CHARGE_TOOLTIP: 'ServiceOrderWarrantyServiceChargeTooltip',
  SERVICE_ORDER_ORDER_PLACED_BY_EMAIL_CART_TEXT: 'ServiceOrderOrderPlacedEmailCartText',
  REPAIR_SERVICE_COPY_TO_OTHER_EAR_TEXT: 'RepairServiceCopyToOtherEarText',
  REPAIR_SERVICE_HEADER_CART_TEXT: 'RepairServiceHeaderCartText',
  REPAIR_SERVICE_BTE_HEADER_CART_TEXT: 'RepairServiceBTEHeaderCartText',
  REPAIR_SERVICE_ITE_HEADER_CART_TEXT: 'RepairServiceITEHeaderCartText',
  REPAIR_SERVICE_MOLD_HEADER_CART_TEXT: 'RepairServiceMoldHeaderCartText',
  REPAIR_SERVICE_ACCESSORY_HEADER_CART_TEXT: 'RepairServiceAccessoryHeaderCartText',
  REPAIR_SERVICE_CART_DOME_TEXT: 'RepairServiceCartDomeText',
  REPAIR_SERVICE_CART_CIRCUIT_TEXT: 'RepairServiceCartCircuitText',
  REPAIR_SERVICE_CART_SHELL_COLOR_TEXT: 'RepairServiceCartShellColorText',
  REPAIR_SERVICE_CART_MATERIAL_TEXT: 'RepairServiceCartMaterialText',
  REPAIR_SERVICE_CART_VENT_TYPE_TEXT: 'RepairServiceCartVentTypeText',
  REPAIR_SERVICE_CART_EAR_PIECE_VENT_TYPE_TEXT: 'RepairServiceCartEarPieceVentTypeText',
  REPAIR_SERVICE_CART_FITTING_LEVEL_TEXT: 'RepairServiceCartFittingLevelText',
  REPAIR_SERVICE_CART_OTHER_TEXT: 'RepairServiceCartOtherText',
  REPAIR_SERVICE_CART_EAR_SIDE_TEXT: 'RepairServiceCartEarSideText',
  REPAIR_SERVICE_CART_SPEAKER_LEVEL_TEXT: 'RepairServiceCartSpeakerLevelText',
  REPAIR_SERVICE_CART_IMPRESSION_TEXT: 'RepairServiceCartImpressionText',
  SHARED_FOR_ALL_SERVICES_NOT_AVAILABLE_TEXT: 'SharedForAllServicesNotAvailableText',
  US_CART_DETAILS_QUOTE_SERVICE_TIMEOUT_ERROR_HEADER_TEXT: 'UsCartDetailsQuoteServiceTimeoutErrorHeaderText',
  US_CART_DETAILS_QUOTE_SERVICE_TIMEOUT_ERROR_BODY_TEXT: 'UsCartDetailsQuoteServiceTimeoutErrorBodyText',
  US_CART_DETAILS_QUOTE_SERVICE_FAILED_ERROR_HEADER_TEXT: 'UsCartDetailsQuoteServiceFailedErrorHeaderText',
  US_CART_DETAILS_QUOTE_SERVICE_FAILED_ERROR_BODY_TEXT: 'UsCartDetailsQuoteServiceFailedErrorBodyText',
  SAVED_DRAFTS_NO_SAVED_DRAFTS_TEXT: 'SavedDraftsNoSavedDraftsText',
  SAVED_DRAFTS_REMOVE_ERROR_MSG: 'SavedDraftsRemoveErrorMsg',
  SAVED_DRAFTS_REMOVE_MODAL_HEADING: 'SavedDraftsRemoveModalHeading',
  SAVED_DRAFTS_REMOVE_MODAL_TEXT: 'SavedDraftsRemoveModalText',
  SAVED_DRAFTS_RESTORE_ERROR_MSG: 'SavedDraftsRestoreErrorMsg',
  SAVED_DRAFTS_RESTORE_MODAL_HEADING: 'SavedDraftsRestoreModalHeading',
  SAVED_DRAFTS_RESTORE_MODAL_TEXT: 'SavedDraftsRestoreModalText',
  SAVED_DRAFTS_RETRIEVE_DRAFTS_ERROR_MSG: 'SavedDraftsRetrieveDraftsErrorMsg',
  PRINT_BATTERY_LABEL_INSTRUCTIONS: 'PrintBatteryLabelInstructions',
  PRINT_SHIPPING_LABEL_INSTRUCTIONS: 'PrintShippingLabelInstructions',
  PRINT_SCHEDULE_PICKUP_SHIPPING_LABEL_INSTRUCTIONS: 'PrintSchedulePickupShippingLabelInstructions'
};
var TranslationReplacementKeys = {
  PRODUCTSEARCHSPOT_SEARCHRESULTHIGHLIGHTLABEL_RESULT: '[result]',
  PRODUCTSEARCHSPOT_PAGINGRESULTLABEL_RESULT: '[result]',
  PRODUCTLISTSPOT_PAGINGRESULTLABEL_RESULT: '[result]',
  PRODUCTSEARCHSPOT_PAGINGRESULTLABEL_NORESULTS: '[result]',
  CHECKOUTCONFIRMATIONSPOT_ORDERNUMBER: '[number]',
  NOTIFICATION_WARRANTY_EXPIRING_COUNT: '[count]',
  NOTIFICATION_WARRANTY_EXPIRING_WARRANTY_PLURAL: '[warranty-plural]'
};
var FeatureFlags = {
  FEATURE_FLAG_ATTRIBUTE: 'data-featureflag',
  CAN_SEE_PRICES: 'canSeePrices',
  CAN_SEE_PATIENT_ORDERS: 'canSeePatientOrder',
  CAN_SEE_BULK_ORDERS: 'canSeeBulkOrder',
  CHECKOUT_SHOW_PRINT: 'checkoutShowPrint',
  CAN_SEE_FAVORITES: 'canSeeFavorites',
  CAN_SEE_MANAGE_CATALOG: 'canSeeManageCatalog',
  PURCHASE_ORDER_REQUIRED: 'purchaseOrderRequired',
  PURCHASE_ORDER_VISIBLE: 'purchaseOrderVisible',
  SHOW_PRODUCT_DESCRIPTION: 'showProductDescription',
  CAN_SEE_QUOTES: 'canSeeQuotes',
  CAN_SEE_LIST_PRICE: 'canSeeListPrice',
  USE_DANISH_ADDRESS: 'useDanishAddress',
  USE_GERMAN_ADDRESS: 'useGermanAddress',
  USE_ADDRESS_TYPE: 'useAddressType',
  SOCIAL_SECURITY_NUMBER_VISIBLE: 'socialSecurityNumberVisible',
  SOCIAL_SECURITY_NUMBER_REQUIRED: 'socialSecurityNumberRequired',
  MUNICIPAL_CODE_VISIBLE: 'municipalCodeVisible',
  MUNICIPAL_CODE_REQUIRED: 'municipalCodeRequired',
  EXTENDED_CLIENT_INFORMATION_VISIBLE: 'extendedClientInformationVisible',
  EXTENDED_CLIENT_INFORMATION_REQUIRED: 'extendedClientInformationRequired',
  CAN_SEE_ORDER_NUMBER: 'canSeeOrderNumber',
  USE_END_USER_TYPE: 'useEndUserType',
  CAN_SEE_COUPONS_SECTION: 'canSeeCouponsSection',
  CAN_SEE_EXPRESS_EXCHANGE_SERVICE: 'canSeeExpressExchangeService',
  CAN_SEE_SPEAKER_REPLACEMENT_SERVICE: 'canSeeSpeakerReplacementService',
  CAN_SEE_REPAIR_SERVICE: 'canSeeRepairService',
  CAN_SEE_REMAKE_SERVICE: 'canSeeRemakeService',
  CAN_SEE_LOSS_AND_DAMAGE_SERVICE: 'canSeeLossAndDamageService'
};
var FeatureFlagValues = {
  ADDRESS_TYPE: {
    GLOBAL: 'global',
    DANISH: 'dk',
    GERMAN: 'de'
  },
  END_USER_TYPE: {
    GLOBAL: 'global',
    JAPAN: 'jp'
  }
};
var EndUserFeatureFlagKeys = {
  FIRST_NAME_KATAKANA: 'firstNameKatakana',
  LAST_NAME_KATAKANA: 'lastNameKatakana',
  SOCIAL_SECURITY_NUMBER: 'socialSecurityNumber',
  MEDICAID_NUMBER: 'medicaidNumber'
};
var Events = {
  SHOP_INIT_READY: 'SHOP_INIT_READY',
  CART_UPDATED: 'CART_UPDATED',
  REFRESH_CART_COUNT: 'REFRESH_CART_COUNT',
  FETCH_PRODUCTS: 'FETCH_PRODUCTS',
  RESET_PRODUCT_FILTERS: 'RESET_PRODUCT_FILTERS',
  ADD_MENUITEMS: 'ADD_MENUITEMS',
  QUANTITY_CHANGED: 'QUANTITY_CHANGED',
  RESET_PRODUCT_QUANTITIES: 'RESET_PRODUCT_QUANTITIES',
  PRESELECT_FAMILY_MODEL_STYLE: 'PRESELECT_FAMILY_MODEL_STYLE',
  SHOW_VALUE_SPINNER: 'SHOW_VALUE_SPINNER',
  HIDE_VALUE_SPINNER: 'HIDE_VALUE_SPINNER',
  REMOVE_CART_PRODUCT_CANCELED: 'REMOVE_CART_PRODUCT_CANCELED',
  REMOVE_CART_PRODUCT_CONFIRMED: 'REMOVE_CART_PRODUCT_CONFIRMED',
  ENABLE_UPDATE_CART_BUTTON: 'ENABLE_UPDATE_CART_BUTTON',
  FORCE_ASSIGNMENTS_CANCELED: 'FORCE_ASSIGNMENTS_CANCELED',
  VALIDATE_END_USER_INFORMATION_FORM: 'VALIDATE_END_USER_INFORMATION_FORM',
  PROMO_CODE_REMOVED: 'PROMO_CODE_REMOVED',
  PROMO_COUPON_MODAL_OPENED: 'PROMO_COUPON_MODAL_OPENED'
};
var SiteLinks = {
  HOME: 'Home',
  SEARCH_RESULTS: 'SearchResults',
  CHECKOUT: 'Checkout',
  CHECKOUTPRINT: 'CheckoutPrint',
  CARTPRINT: 'CartPrint',
  PRODUCTLIST: 'ProductList',
  MANAGECATALOGS: 'ManageCatalogs',
  CHECKOUTCONFIRMATION: 'CheckoutConfirmation',
  PRODUCTDETAILS: 'ProductDetails',
  ACCESSORIES_STOCK_ORDER_LINK: 'AccessoriesStockOrderLink',
  CLIENTORDERSTEP1: 'ClientOrderStep1',
  CLIENTORDERSTEP2: 'ClientOrderStep2',
  CLIENTORDERSTEP3: 'ClientOrderStep3',
  ORDERHISTORY: 'OrderHistory',
  CONTINUESHOPPING: 'ContinueShoppingLink',
  BULKORDER: 'BulkOrder',
  FREEPROMOITEMS: 'CheckoutFreePromoLink'
};
var QueryStrings = {
  SEARCH: 'q',
  CATEGORY_ID: 'categoryid',
  FAMILY_ID: 'familyid',
  MODEL_ID: 'modelid',
  STYLE_ID: 'styleid',
  PRODUCT_ID: 'productid',
  VARIANT_ID: 'variantid',
  ORDER_ID: 'orderid',
  OPERATION_TYPE: 'operation',
  ORDER_TYPE: 'orderType',
  NAURAL: 'naural',
  CLIENT_ORDER_ID: 'clientOrderId',
  CLIENT_ORDER_EDIT_MODE: 'isEdit',
  CLIENT_ORDER_OPEN_AUDIOGRAM: 'openAudiogram',
  BULK_ORDER_ID: 'bulkOrderId',
  PROMOTION_CODE: 'promotionCode',
  SERIAL_NUMBER: 'serialNo'
};
var Counters = {
  MinItems: 0,
  MaxItems: 99999,
  productListItems: 30,
  recentlyViewedItems: 5,
  maxItemsInCartLabel: 99,
  CHECKOUT_MAX_COMMENT_LENGTH: 500
};
var KeyCodes = {
  Return: 13
};
var EventTypes = {
  Keypress: 'keypress',
  Click: 'click',
  SHOP_INIT_READY: 'SHOP_INIT_READY'
};
var Format = {
  Date: 'MM-dd-yyyy'
};

// collection of keys used by Session Storage Manager
var SSMKeys = {
  WarrantyExpiringSoon: 'SSM_WARRANTY_EXP',
  allCoupons: 'SSM_ALL_COUPONS_DATA',
  allPromos: 'SSM_ALL_PROMOS_DATA',
  freeShipping: 'SSM_FREE_SHIPPING_DATA'
};

// Service Order Constants
// Service order Status states
var ServiceOrderStatus = {
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
  NOT_FOUND: 'NOT_FOUND',
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  TOO_OLD: 'TOO_OLD',
  PENDING: 'PENDING',
  BLANK_SERIAL: 'BLANK_SERIAL',
  OTHER_CLINIC_PURCHASE: 'OTHER_CLINIC_PURCHASE',
  THIRD_PARTY_PURCHASE: 'THIRD_PARTY_PURCHASE',
  SERIAL_INCORRECT: 'SERIAL_INCORRECT',
  DUPLICATE_IN_CART: 'DUPLICATE_IN_CART',
  USED_SERIAL_NUMBER: 'USED_SERIAL_NUMBER'
};

// Service order view states for each applicable service order type
// values are paths used by react router
var ServiceOrderViewState = {
  NONE: {
    path: '/',
    code: 'NA',
    subCode: '',
    fullName: ''
  },
  EXCHANGE: {
    path: '/exchange',
    code: 'EE',
    subCode: '',
    fullName: 'Express Exchange'
  },
  REPAIR: {
    path: '/repair',
    code: 'RP',
    // repair and remake share the same path, use subcode to differentiate the two.
    subCode: 'RP',
    fullName: 'Repair Service'
  },
  REMAKE: {
    path: '/repair',
    code: 'RP',
    subCode: 'RM',
    fullName: 'Remake Service'
  },
  SPEAKER_REPLACE: {
    path: '/speaker',
    code: 'SR',
    subCode: '',
    fullName: 'Speaker Replacement'
  },
  REPLACE: {
    path: '/replace',
    code: 'LD',
    subCode: '',
    fullName: 'Replacement Service'
  }
};

// used by patient orders to determine if an impression file is required to be sent with order.
var requiredImpressionEarpieces = new Set(['2783',
// Power Receiver mold (flex mould - global)
'1776',
// litetip/corda
'1777',
// micro mold
'7390',
// micro shell
'1775' // power mold
]);
var ServiceOrderTypes = {
  EXCHANGE: 'expressExchange',
  SPEAKER_REPLACE: 'speakerReplacement',
  REPAIR: 'repair',
  REMAKE: 'remake',
  REPLACE: 'replace'
};
var ServiceOrderConfigurations = {
  EXCHANGE: {
    type: ServiceOrderTypes.EXCHANGE,
    label: 'Express exchange',
    refLabelText: 'Exchange',
    showShippingLabel: true,
    showLionBatteryLabel: true,
    showNotes: true,
    noteTranslation: TranslationKeys.SERVICE_ORDER_CHECKOUT_CONFIRMATION_NOTES,
    orderDetailsPath: 'expressExchangeOrderDetails',
    ordersPath: 'expressExchangeOrders'
  },
  SPEAKER_REPLACE: {
    type: ServiceOrderTypes.SPEAKER_REPLACE,
    label: 'Speaker replacement',
    refLabelText: '',
    showShippingLabel: false,
    showLionBatteryLabel: false,
    showNotes: false,
    noteTranslation: '',
    orderDetailsPath: 'speakerReplacementOrderDetails',
    ordersPath: 'speakerReplacementOrders'
  },
  REPAIR: {
    type: ServiceOrderTypes.REPAIR,
    label: 'Repair',
    refLabelText: 'Repair',
    showShippingLabel: true,
    showLionBatteryLabel: true,
    showNotes: true,
    noteTranslation: TranslationKeys.SERVICE_ORDER_REPAIR_CHECKOUT_CONFIRMATION_NOTES,
    orderDetailsPath: 'repairOrderDetails',
    ordersPath: 'repairOrders'
  },
  REMAKE: {
    type: ServiceOrderTypes.REPAIR,
    label: 'Remake',
    refLabelText: 'Remake',
    showShippingLabel: true,
    showLionBatteryLabel: true,
    showNotes: true,
    noteTranslation: TranslationKeys.SERVICE_ORDER_REPAIR_CHECKOUT_CONFIRMATION_NOTES,
    orderDetailsPath: 'remakeOrderDetails',
    ordersPath: 'remakeOrders'
  }
};
var ServiceOrderQuoteOptions = {
  SIX: 'six',
  TWELVE: 'twelve'
};
var usStates = [{
  value: 'AL',
  text: 'Alabama'
}, {
  value: 'AK',
  text: 'Alaska'
}, {
  value: 'AZ',
  text: 'Arizona'
}, {
  value: 'AR',
  text: 'Arkansas'
}, {
  value: 'CA',
  text: 'California'
}, {
  value: 'CO',
  text: 'Colorado'
}, {
  value: 'CT',
  text: 'Connecticut'
}, {
  value: 'DE',
  text: 'Delaware'
}, {
  value: 'DC',
  text: 'District Of Columbia'
}, {
  value: 'FL',
  text: 'Florida'
}, {
  value: 'GA',
  text: 'Georgia'
}, {
  value: 'HI',
  text: 'Hawaii'
}, {
  value: 'ID',
  text: 'Idaho'
}, {
  value: 'IL',
  text: 'Illinois'
}, {
  value: 'IN',
  text: 'Indiana'
}, {
  value: 'IA',
  text: 'Iowa'
}, {
  value: 'KS',
  text: 'Kansas'
}, {
  value: 'KY',
  text: 'Kentucky'
}, {
  value: 'LA',
  text: 'Louisiana'
}, {
  value: 'ME',
  text: 'Maine'
}, {
  value: 'MD',
  text: 'Maryland'
}, {
  value: 'MA',
  text: 'Massachusetts'
}, {
  value: 'MI',
  text: 'Michigan'
}, {
  value: 'MN',
  text: 'Minnesota'
}, {
  value: 'MS',
  text: 'Mississippi'
}, {
  value: 'MO',
  text: 'Missouri'
}, {
  value: 'MT',
  text: 'Montana'
}, {
  value: 'NE',
  text: 'Nebraska'
}, {
  value: 'NV',
  text: 'Nevada'
}, {
  value: 'NH',
  text: 'New Hampshire'
}, {
  value: 'NJ',
  text: 'New Jersey'
}, {
  value: 'NM',
  text: 'New Mexico'
}, {
  value: 'NY',
  text: 'New York'
}, {
  value: 'NC',
  text: 'North Carolina'
}, {
  value: 'ND',
  text: 'North Dakota'
}, {
  value: 'OH',
  text: 'Ohio'
}, {
  value: 'OK',
  text: 'Oklahoma'
}, {
  value: 'OR',
  text: 'Oregon'
}, {
  value: 'PA',
  text: 'Pennsylvania'
}, {
  value: 'RI',
  text: 'Rhode Island'
}, {
  value: 'SC',
  text: 'South Carolina'
}, {
  value: 'SD',
  text: 'South Dakota'
}, {
  value: 'TN',
  text: 'Tennessee'
}, {
  value: 'TX',
  text: 'Texas'
}, {
  value: 'UT',
  text: 'Utah'
}, {
  value: 'VT',
  text: 'Vermont'
}, {
  value: 'VA',
  text: 'Virginia'
}, {
  value: 'WA',
  text: 'Washington'
}, {
  value: 'WV',
  text: 'West Virginia'
}, {
  value: 'WI',
  text: 'Wisconsin'
}, {
  value: 'WY',
  text: 'Wyoming'
}, {
  value: 'AS',
  text: 'American Samoa'
}, {
  value: 'GU',
  text: 'Guam'
}, {
  value: 'MP',
  text: 'Northern Mariana Islands'
}, {
  value: 'PR',
  text: 'Puerto Rico'
}, {
  value: 'UM',
  text: 'United States Minor Outlying Islands'
}, {
  value: 'VI',
  text: 'Virgin Islands'
}];
var TooltipPosition = {
  TOP: 'TOP',
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM: 'BOTTOM',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};
var TooltipVariant = {
  WHITE: 'WHITE',
  GREY: 'GREY'
};
export { AddToCartButtonTypes, SpinnerTypes, Restrictions, ChooseYourProductConstants, TranslationKeys, FeatureFlags, FeatureFlagValues, Events, SiteLinks, QueryStrings, TranslationReplacementKeys, AudiogramConstants, ProductTableConstants, Ids, LocalStorageKeys, ClientOrderMode, ClientOrderCommonTypes, ClientOrderAssignmentStates, AgeGroup, GenderGroup, YesNoGroup, Counters, KeyCodes, EventTypes, Format, EndUserFeatureFlagKeys, SSMKeys, ServiceOrderStatus, ServiceOrderViewState, requiredImpressionEarpieces, ServiceOrderTypes, ServiceOrderConfigurations, usStates, ServiceOrderQuoteOptions, TooltipPosition, TooltipVariant };