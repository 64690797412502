import validator from 'validator';
(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    var $component = $('.component.create-account-spot');
    var dictionary = {
      B2B: {
        isRequiredField: 'Required field',
        isValidPhone: 'Please enter a valid phone number',
        isValidEmail: 'Please enter a valid email address',
        hasErrorsMessage: 'Please fill in all mandatory information',
        helpText: 'Please enter a valid Account Number',
        timeoutError: 'Error: Request timed out.  please try again later or contact Oticon'
      }
    };
    if ($component.length) {
      DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        //  main function

        $component.each(function (index) {
          var $componentInstance = $(this),
            $helpText = '<span class="help-text"><span>' + dictionary.B2B.helpText;
          +'</span></span>';
          // help text functionality
          $(this).find('.account-number').append($helpText);
          $('.help-text').on('click', function () {
            $(this).find('span').toggle();
          });
          $(this).find('.add-another-account').click(function () {
            var $addAnotherAccount = '<div class="edit-box-content account-number new-account"><div class="edit-box-value"><input type="text" name="accountNumber' + index + '" placeholder="Account Number"><span class="close"></span></div></div>';
            $(this).parents('.edit-box-content').before($addAnotherAccount);
            index = index + 1;
            if (index >= 6) {
              $(this).hide();
            }
            $(document).on('click', '.close', function () {
              $(this).parents('div.new-account').hide();
              index = index - 1;
            });
          });
          var ui = {
            $_inputs: $('input', $componentInstance),
            $_formValidator: {
              'firstName': {
                isRequired: true,
                type: 'text'
              },
              'lastName': {
                isRequired: true,
                type: 'text'
              },
              'accountNumber': {
                isRequired: true,
                type: 'text'
              },
              'personalEmailAddress': {
                isRequired: true,
                type: 'email'
              },
              'phoneNumber': {
                isRequired: true,
                type: 'phoneNumber'
              }
            },
            validateFormFields: function validateFormFields() {
              var _this = this;
              ui.hideValidationError();
              var validInput = {
                passed: true,
                errors: false
              };
              this.$_inputs.each(function (index, elem) {
                var $input = $(elem);
                var inputName = $input.attr('name');
                var inputValue = $input.val();
                var inputErrorClass = "has-error";

                /**
                 * function to prepend an error message to our input fields if they have an error.
                 * @param msg
                 */
                var addErrorMessage = function addErrorMessage(msg) {
                  var $errorMessage = $("<p>".concat(msg, "</p>"));
                  $errorMessage.addClass('errmsg');
                  $input.before($errorMessage);
                };

                /**
                 * removes error message from our input fields.
                 */
                var removeErrorMessage = function removeErrorMessage() {
                  var $errorMessage = $input.prev('.errmsg');
                  $errorMessage.remove();
                };
                $input.removeClass(inputErrorClass);
                $input.off('blur');
                removeErrorMessage();

                // check if we have validation rules for set input
                if (_this.$_formValidator.hasOwnProperty(inputName)) {
                  var $inputValidator = _this.$_formValidator[inputName];

                  // check if field is required and that the value of field is not empty
                  if ($inputValidator.isRequired) {
                    if (validator.isEmpty(inputValue)) {
                      validInput.passed = false;
                      $input.addClass(inputErrorClass);
                      $input.on('blur', function () {
                        return ui.validateFormFields();
                      });
                      addErrorMessage(dictionary.B2B.isRequiredField);
                      validInput.errors = true;
                    }
                  }
                  if (!validator.isEmpty(inputValue)) {
                    // validate against pre-set rules
                    switch ($inputValidator.type) {
                      case 'email':
                        if (!validator.isEmail(inputValue)) {
                          validInput.passed = false;
                          $input.addClass(inputErrorClass);
                          $input.on('blur', function () {
                            return ui.validateFormFields();
                          });
                          addErrorMessage(dictionary.B2B.isValidEmail);
                          validInput.errors = true;
                        }
                        break;
                      case 'phoneNumber':
                        if (!validator.isMobilePhone(inputValue, 'en-US')) {
                          validInput.passed = false;
                          $input.addClass(inputErrorClass);
                          $input.on('blur', function () {
                            return ui.validateFormFields();
                          });
                          addErrorMessage(dictionary.B2B.isValidPhone);
                          validInput.errors = true;
                        }
                        break;
                      default:
                        break;
                    }
                  }
                }
              });
              return validInput;
            },
            showUpdateError: function showUpdateError() {
              $componentInstance.addClass('on-update-error');
            },
            showValidationError: function showValidationError() {
              $componentInstance.addClass('on-validation-error');
            },
            hideValidationError: function hideValidationError() {
              $componentInstance.removeClass('on-validation-error');
            }
          };
          var getConfigForElement = function getConfigForElement($element) {
              var $script = $('script[type="application/json"]', $element),
                config;
              config = $script.length ? JSON.parse($script.html()) : null;
              return config;
            },
            errorMessage = {
              showErrors: function showErrors(errors) {
                $('.errorMessage', $componentInstance).remove();
                if (errors) {
                  var errorsList = $('<ul></ul>');
                  errorsList.addClass('errorMessage');
                  errorsList.append("<li>".concat(dictionary.B2B.hasErrorsMessage, "</li>"));
                  $('.required-label').append(errorsList);
                  ui.showValidationError();
                }
              },
              hide: function hide() {
                $('.errorMessage', $componentInstance).remove();
              }
            },
            onInputKeyUp = function onInputKeyUp(event, validateFunc, submitFunc) {
              var isValid = validateFunc.apply();
              if (event.which == 13 && isValid) {
                event.preventDefault();
                submitFunc.apply();
              }
            },
            onSubmitBtnClick = function onSubmitBtnClick(event, validateFunc, submitFunc) {
              event.preventDefault();
              if (validateFunc.apply(null, [true])) {
                submitFunc.apply();
              }
            },
            doFieldsHaveText = function doFieldsHaveText(decorateOnError, $messagePlaceholder) {
              var $element, arg;
              for (arg = 2; arg < arguments.length; ++arg) {
                $element = arguments[arg];
                if (!$element.length || !$element.val() || $element.val().trim === '') {
                  if (decorateOnError) {
                    $element.addClass('error');
                    if ($messagePlaceholder.length > 0) {
                      $messagePlaceholder.html(errorMessages.emptyFields);
                      $messagePlaceholder.parent(".alert").show();
                    }
                  }
                  return false;
                }
              }
              return true;
            },
            hideFirstShowSecond = function hideFirstShowSecond($first, $second) {
              $first.hide();
              $second.show();
            },
            submitData = function submitData(method, endpoint, data, callback) {
              $.ajax({
                url: endpoint,
                type: method,
                data: JSON.stringify(data),
                contentType: "application/json; charset=utf-8"
              }).then(function (data, textState, jqXHR) {
                callback.apply(null, [jqXHR.status, data]);
              }, function (jqXHR) {
                callback.apply(null, [jqXHR.status, jqXHR.responseText]);
              });
            };
          $('.submit').on("click", function () {
            event.preventDefault();
            errorMessage.hide();
            ui.hideValidationError();
            // validate data
            var myValidator = ui.validateFormFields();
            if (myValidator.passed) {
              var config = getConfigForElement($componentInstance),
                $submitBtn = $('.submit', $componentInstance),
                $input = $('input', $componentInstance),
                $loader = $('.cssload-container', $componentInstance),
                $firstName = $('input[name="firstName"]', $componentInstance),
                $middleName = $('input[name="middleName"]', $componentInstance),
                $lastName = $('input[name="lastName"]', $componentInstance),
                $email = $('input[name="personalEmailAddress"]', $componentInstance),
                $phoneNumber = $('input[name="phoneNumber"]', $componentInstance),
                endpoint,
                returnUrl,
                validateInput,
                submitRequest,
                newUserAccounts = [],
                data = {};
              if (config && $input.length && $submitBtn.length) {
                endpoint = config.endpoint;
                returnUrl = config.returnUrl;
                var $accountBox = $('.b2b-create-account .edit-box-content.account-number');
                data.loginId = $email.val();
                data.name = $firstName.val() + '' + $middleName.val() + '' + $lastName.val();
                data.phoneNo = $phoneNumber.val();
                data.NewUserAccounts = [];
                $accountBox.each(function (index, value) {
                  $(this).find('input').each(function () {
                    if ($(this).val() !== '') {
                      var newuUserAccount = {};
                      newuUserAccount.customerNumber = $(this).val();
                      if ($(this).attr("name") == "accountNumber") {
                        newuUserAccount.isDefault = true;
                      } else {
                        newuUserAccount.isDefault = false;
                      }
                      data.NewUserAccounts.push(newuUserAccount);
                    }
                  });
                });
                var iterationCnt = 0;
                submitRequest = function submitRequest() {
                  submitData("POST", endpoint, data, function (responseCode, responseData) {
                    if (responseCode == "200" || responseCode == "201") {
                      hideFirstShowSecond($submitBtn, $loader);
                      var resultUrl = responseData;
                      var _accountCreate = function accountCreate() {
                        $('.sign-in').prev('p').remove();
                        if (iterationCnt >= 20) {
                          $('.sign-in').before("<p class=\"errorMessage\">".concat(dictionary.B2B.timeoutError, "</p>"));
                          return;
                        }
                        $.ajax({
                          url: resultUrl,
                          type: 'GET'
                        }).then(function (data, textState, jqXHR) {
                          switch (jqXHR.status) {
                            case 204:
                              setTimeout(function () {
                                _accountCreate();
                                ++iterationCnt;
                              }, 500);
                              break;
                            case 200:
                              var accountDoctype = data.DocCreated;
                              var resultTxt = data.ResultTxt;
                              if (accountDoctype == "true") {
                                setTimeout(function () {
                                  hideFirstShowSecond($submitBtn, $loader);
                                  window.location = returnUrl;
                                }, 500);
                              } else {
                                hideFirstShowSecond($loader, $submitBtn);
                                $('.sign-in').before("<p>" + resultTxt + "</p>");
                              }
                              break;
                            default:
                              hideFirstShowSecond($loader, $submitBtn);
                              $('.sign-in').before("<p>Unable to submit request.</p>");
                              return false;
                              break;
                          }
                        });
                      };
                      _accountCreate();
                    } else {
                      hideFirstShowSecond($loader, $submitBtn);
                    }
                  });
                };
                validateInput = function validateInput(decorateOnError) {
                  if (doFieldsHaveText(decorateOnError, $input)) {
                    $input.removeClass('required');
                    $input.removeClass('error');
                    return true;
                  } else {
                    return false;
                  }
                };
                $input.keyup(function (e) {
                  onInputKeyUp(e, validateInput, submitRequest);
                });
                onSubmitBtnClick(event, validateInput, submitRequest);
                validateInput();
              }
            } else {
              // show errors now
              errorMessage.showErrors(myValidator.errors);
            }
          });
        });
      });
    }
  });
})(jQuery);