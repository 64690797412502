(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    $('[name="hearing-loss"]').niceSelect();
    $('.component.warranty-registration-spot .register-button').each(function () {
      var $component = $(this);
      var baseWarrantyRegistrationUrl = '/webservices/warranties.svc/registerwarranty';
      var baseCheckWarrantyStatusUrl = '/webservices/warranties.svc/registrationstatus';
      var warrantyRegistration = $(this);
      $(this).on('click', registerWarrantyClick);
      $('[name="registration-date"]').change(validate);
      $('[name="registration-patient-name"]').change(validate);
      $('[name="registration-age"]').change(validate);
      function validate() {
        if ($(this).val() != '') {
          $(this).removeClass('registration-error');
        } else if ($(this).val() === '') {
          $(this).addClass('registration-error');
        }
      }
      var patientName, serialNo, serialNo2;
      function registerWarrantyClick(event) {
        event.preventDefault();
        showLoader(warrantyRegistration);
        var startDate, age, hearingLoss, serviceCardNo;
        startDate = $('#registration-date').val();
        patientName = $('[name="registration-patient-name"]').val();
        age = $('[name="registration-age"]').val();
        hearingLoss = $('[name="hearing-loss"] option:selected').text();
        serviceCardNo = $('.data-list-spot__row__column.serviceCardNo').data('value');
        serialNo = $('.data-list-spot__row__column.serialNo:first').data('value');
        var device2 = $('.data-list-spot__row__column.serialNo')[1];
        if (device2 != null) {
          var device2Val = $(device2).data('value');
          if (serialNo != device2Val) {
            serialNo2 = device2Val;
          }
        }
        if (serialNo2 && !serialNo) {
          serialNo = serialNo2;
          serialNo2 = null;
        }
        var hasError = false;
        if (startDate == '') {
          $('[name="registration-date"]').addClass('registration-error');
          hasError = true;
        }
        if (patientName == '') {
          $('[name="registration-patient-name"]').addClass('registration-error');
          hasError = true;
        }
        if (age == '') {
          $('[name="registration-age"]').addClass('registration-error');
          hasError = true;
        }
        if (hasError) {
          hideLoader(warrantyRegistration);
          return;
        }
        var resultUrl = baseWarrantyRegistrationUrl + "?startDate=" + startDate + "&patientName=" + patientName + "&age=" + age + "&hearingLoss=" + hearingLoss + "&serviceCardNo=" + serviceCardNo + "&serialNo=" + serialNo;
        var checkResultUrl = baseCheckWarrantyStatusUrl + "?serviceCardNo=" + serviceCardNo + "&request=";
        var success = true;
        var iterationCnt = 0;
        $.ajax({
          url: resultUrl,
          type: 'GET'
        }).then(function (data, textState, jqXHR) {
          switch (jqXHR.status) {
            case 202:
              setTimeout(function () {
                success = checkWarrantyStatus(checkResultUrl, data.Data, ++iterationCnt);
              }, 500);
              break;
            case 201:
              setTimeout(function () {
                success = checkWarrantyStatus(checkResultUrl, data.Data, ++iterationCnt);
              }, 500);
              break;
            default:
              hideLoader(warrantyRegistration);
              $('.error-message').appendChild('<span>Unable to register ' + serialNo + '</span>');
              break;
          }
        });
        if (serialNo2) {
          var resultUrl = baseWarrantyRegistrationUrl + "?startDate=" + startDate + "&patientName=" + patientName + "&age=" + age + "&hearingLoss=" + hearingLoss + "&serviceCardNo=" + serviceCardNo + "&serialNo=" + serialNo;
          var checkResultUrl = baseCheckWarrantyStatusUrl + "?serviceCardNo=" + serviceCardNo + "&request=";
          var iterationCnt = 0;
          $.ajax({
            url: resultUrl,
            type: 'GET'
          }).then(function (data, textState, jqXHR) {
            switch (jqXHR.status) {
              case 202:
                setTimeout(function () {
                  success = success && checkWarrantyStatus(checkResultUrl, data.Data, ++iterationCnt);
                }, 500);
                break;
              case 201:
                setTimeout(function () {
                  success = success && checkWarrantyStatus(checkResultUrl, data.Data, ++iterationCnt);
                }, 500);
                break;
              default:
                hideLoader(warrantyRegistration);
                $('.error-message').appendChild('<span>Unable to register ' + serialNo2 + '</span>');
                break;
            }
          });
        }
        // if (success) {
        //     var url = window.location.href.split('?')[0];
        //     url = url + '/warranty-confirmation?id=' + serialNo;
        //     if (serialNo2) url += ',' + serialNo2;
        //     url += "&patient=" + encodeURIComponent(patientName);
        //     window.location.href = url;
        // }
      }
      function checkWarrantyStatus(baseUrl, id, iterationCnt) {
        if (iterationCnt >= 10) {
          $(".error-message").html("Timeout Error.");
          return;
        }
        var resultUrl = baseUrl + id;
        $.ajax({
          url: resultUrl,
          type: 'GET'
        }).then(function (data, textState, jqXHR) {
          switch (jqXHR.status) {
            case 204:
              setTimeout(function () {
                checkWarrantyStatus(baseUrl, id, ++iterationCnt);
              }, 500);
              break;
            case 200:
              var warrantyStatusData = JSON.parse(data.Data);
              var warrantyDoctype = warrantyStatusData.DocCreated;
              if (warrantyDoctype == "true") {
                setTimeout(function () {
                  warrantyStatusTrue();
                }, 500);
              } else {
                hideLoader(warrantyRegistration);
                var $warrantyStatusErrorMessage = warrantyStatusData.ResultTxt;
                var $errorMessageWrapper = $('.warranty-registration-spot--search-results .component-content').find('.data-list-search-spot__adv-search-outer-container');
                $errorMessageWrapper.append('<p>' + $warrantyStatusErrorMessage + '</p>');
              }
              // return true;
              break;
            default:
              return false;
              break;
          }
        });
      }
      function warrantyStatusTrue() {
        var url = window.location.href.split('?')[0];
        url = url + '/warranty-confirmation?id=' + serialNo;
        if (serialNo2) url += ',' + serialNo2;
        url += "&patient=" + encodeURIComponent(patientName);
        window.location.href = url;
      }
      function showLoader(warrantyRegistration) {
        $(warrantyRegistration).hide();
        $(".warranty-registration__load-container").show();
      }
      function hideLoader(warrantyRegistration) {
        $(warrantyRegistration).show();
        $(".warranty-registration__load-container").hide();
      }
    });
    $('.component .find-device-button').each(function () {
      if ($('.device-list:nth-child(2)').children().not('script').length === 0) {
        $('.warranty-registration-query').hide();
      }
      var baseRegistrationDatalistUrl = '/webservices/warranties.svc/registrationdatalist';
      var config = '';
      $(this).on('keyup click', findDeviceClick);
      $('.component.warranty-registration-spot .data-list-search-spot__warranty-registration-search-input').on('keyup', findDeviceClick);
      function findDeviceClick(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (event.keyCode === 13 || event.type === 'click') {
          var deviceList = document.getElementById("device-list");
          while (deviceList.firstChild) {
            deviceList.removeChild(deviceList.firstChild);
          }
          var serialNo = $("input[name~='serial-first-device']").val();
          var serialNo2 = $("input[name~='serial-second-device']").val();
          if (!serialNo) serialNo = '';
          if (!serialNo2) serialNo2 = '';
          var resultUrl = baseRegistrationDatalistUrl + "?serialNo=" + serialNo + "&serialNo2=" + serialNo2;
          var iterationCnt = 0;
          $.ajax({
            url: resultUrl,
            type: 'GET',
            success: function success(data, textState, jqXHR) {
              if (data != null && data != '') {
                var json = JSON.parse(data);
                var datalist = document.createElement("div");
                datalist.className = "data-list-spot__table-container";
                var tableContainer = document.createElement("div");
                tableContainer.className = "data-list-spot__table-container";
                var tableHeaders = document.createElement("div");
                tableHeaders.className = "data-list-spot__table headers";
                var headers = document.createElement("div");
                headers.className = "data-list-spot__headers";
                json.fields.forEach(function (el) {
                  var th = document.createElement("div");
                  th.className = "data-list-spot__headers__header " + el.className;
                  var span = document.createElement("span");
                  span.textContent = el.label;
                  th.appendChild(span);
                  headers.appendChild(th);
                });
                tableHeaders.appendChild(headers);
                tableContainer.appendChild(tableHeaders);
                var itemContainer = document.createElement("div");
                itemContainer.className = "data-list-spot__table-container";
                var itemsSpot = document.createElement("div");
                itemsSpot.className = "data-list-spot__table items";
                var index = 0;
                json.data.items.forEach(function (item) {
                  var rowList = document.createElement("div");
                  rowList.className = "data-list-spot__row data-list-spot__row__cell remove-on-search data-list-spot__row-odd";
                  if (index % 2 === 0) rowList.className = "data-list-spot__row data-list-spot__row__cell remove-on-search data-list-spot__row-even";
                  index++;
                  rowList.style = "opacity:1;";
                  json.fields.forEach(function (header) {
                    var th = document.createElement("div");
                    th.setAttribute('data-value', item[header.fieldName]);
                    th.className = "data-list-spot__row__column " + header.fieldName + " " + header.className;
                    var span = document.createElement("span");
                    span.textContent = item[header.fieldName];
                    th.appendChild(span);
                    rowList.appendChild(th);
                  });
                  itemsSpot.appendChild(rowList);
                });
                itemContainer.appendChild(itemsSpot);
                deviceList.appendChild(tableContainer);
                if (json.data.items.length === 0) {
                  var emptyResultContainer = document.createElement("div");
                  emptyResultContainer.className = "data-list-spot__empty_result-container remove-on-search";
                  emptyResultContainer.style = "opacity:1;";
                  var emptyResult = document.createElement("div");
                  emptyResult.className = "data-list-spot__empty_result";
                  emptyResult.textContent = "No results";
                  emptyResultContainer.appendChild(emptyResult);
                  deviceList.appendChild(emptyResultContainer);
                } else {
                  deviceList.appendChild(itemContainer);
                }
                $('.warranty-registration-query').show();
              }
            }
          });
        }
        return false;
      }
    });
    if ($('.js-open-modal').length !== 0) {
      var appendthis = "<div class='modal-overlay js-modal-close'></div>";
      $('.js-open-modal').on('click', function (e) {
        e.preventDefault();
        $("body").append(appendthis);
        $(".modal-overlay").fadeTo(500);
        var modalBox = $(this).attr('data-modal-id');
        $('#' + modalBox).fadeIn($(this).data());
      });
      $(".js-modal-close, .modal-overlay").click(function () {
        $(".modal-box, .modal-overlay").fadeOut(500, function () {
          $(".modal-overlay").remove();
        });
      });
      var baseServiceURL = '/webservices/warranties.svc';
      $('.oticon-print').on('click', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var selected = [];
        $('input:checked').each(function () {
          selected.push($(this).data('value'));
        });
        selected.forEach(function (serviceCard) {
          var resultUrl = baseServiceURL + "/" + "requestprintedcertificate" + "?serviceCardNo=" + serviceCard;
          $.ajax({
            url: resultUrl,
            type: 'GET'
          }).then(function (data, textState, jqXHR) {
            if (jqXHR.status == 202) {
              $('p.error-message').html("Request successfully submitted.");
            } else {
              $('p.error-message').html("Unable to submit request.");
            }
          }, function (jqXHR, textState, msg) {
            $('p.error-message').html("Unable to submit request.");
          });
        });
      });
      DGS.Resizer.getInstance().register(function () {
        $(".modal-box").css({
          top: ($(window).height() - $(".modal-box").outerHeight()) / 2,
          left: ($(window).width() - $(".modal-box").outerWidth()) / 2
        });
      });
    }
    $('.component .warranty-register-print-button').each(function () {
      var $component = $(this);
      var serviceCardNo = $('.data-list-spot__row__column.serviceCardNo');
      serviceCardNo.each(function () {
        printWarranty($(this).data('value'));
      });
      var baseServiceURL = '/webservices/warranties.svc';
      $(this).on('click', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var serviceCard = $(this).data('servicecardno');
        var resultUrl = "/webservices/Warranties.svc" + "/" + "requestpdfcertificate" + "/" + serviceCard;
        window.location = resultUrl;
      });
      function printWarranty(serviceCard) {
        if (serviceCard) {
          var baseServiceURL = '/webservices/warranties.svc';
          var resultUrl = baseServiceURL + "/" + "requestpdf" + "/" + serviceCard;
          $.ajax({
            url: resultUrl,
            type: 'GET'
          }).then(function (data, textState, jqXHR) {}, function (jqXHR, textState, msg) {});
        }
      }
    });
    $('.component.oticon-print').each(function () {
      var serviceCardNo = $('.data-list-spot__row__column.serviceCardNo');
      var baseServiceURL = '/webservices/warranties.svc';
      $(this).on('click', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var selected = [];
        $('#modal-body input:checked').each(function () {
          selected.push($(this).data('value'));
        });
        selected.forEach(function (item) {
          var resultUrl = baseServiceURL + "/" + "requestprintedcertificate" + "?serviceCardNo=" + serviceCard;
          $.ajax({
            url: resultUrl,
            type: 'GET'
          }).then(function (data, textState, jqXHR) {
            if (jqXHR.status == 200) {
              $('p.error-message').html("Request successfully submitted.");
            } else {
              $('p.error-message').html("Unable to submit request.");
            }
          }, function (jqXHR, textState, msg) {
            $('p.error-message').html("Unable to submit request.");
          });
        });
      });
    });
    if ($('#registration-date').length !== 0) {
      $('#registration-date').datepicker({
        orientation: "bottom",
        beforeShow: function beforeShow() {
          if (!$('.registration_datepicker_wrapper').length) {
            $('#ui-datepicker-div').wrap('<span class="registration_datepicker_wrapper"></span>');
          }
        }
      });
    }
  });
})(jQuery);