import moment from 'moment';
'use strict';
(function ($) {
  var backendCalls = {
    endpoints: {
      startTransaction: '/webservices/payment.svc/starttransaction',
      addBankAccount: '/webservices/payment.svc/addbankaccount',
      updateBankAccount: '/webservices/payment.svc/updatebankaccount/',
      // This one, the id is part of the endpoint
      removeBankAccount: '/webservices/payment.svc/',
      removeCreditCard: '/webservices/payment.svc/removecreditcard/'
    },
    startTransaction: function startTransaction(data, callback) {
      $.ajax({
        type: 'POST',
        url: this.endpoints.startTransaction,
        data: JSON.stringify(data)
      }).then(function (result) {
        callback(result);
      }, function () {
        callback(false);
      });
    },
    /**
     * Does an ajax call to add a new bank account
     *
     * @param data     json with the bank account information
     * @param callback Callback function to be called once operation is done. Will pass false on failure or the newDocumentNumber
     *                 on success
     */
    addBankAccount: function addBankAccount(data, callback) {
      $.ajax({
        type: 'POST',
        url: this.endpoints.addBankAccount,
        data: JSON.stringify(data)
      }).then(function (newDocumentNumber) {
        callback(newDocumentNumber);
      }, function () {
        callback(false);
      });
    },
    /**
     * Does an ajax call to update the bank account information.
     *
     * @param data     json with the updated bank account information
     * @param callback Callback function to be called once operation is done. Will pass false on failure or the result
     *                 on success
     */
    updateBankAccount: function updateBankAccount(data, callback) {
      // What does this return??
      $.ajax({
        type: 'POST',
        url: this.endpoints.updateBankAccount + data.documentNumber,
        data: JSON.stringify(data)
      }).then(function (result) {
        callback(result);
      }, function () {
        callback(false);
      });
    },
    /**
     * Makes an ajax call to the backend to remove the credit card of the given ID and calls the callback after done
     *
     * @param creditCardId Id of the credit card
     * @param callback     Function to be called when the ajax is done, or when an error occur. It passes FALSE on error
     */
    removeCreditCard: function removeCreditCard(creditCardId, callback) {
      $.ajax({
        type: 'GET',
        url: this.endpoints.removeCreditCard + creditCardId
      }).then(function (result) {
        callback(result);
      }, function () {
        callback(false);
      });
    },
    /**
     * Makes an ajax call to the backend to remove the selected bank account and calls the callback once complete
     *
     * @param bankAccountId {string}     ID of the bank account
     * @param callback      {function}   Function to be called when the ajax call is done.
     */
    removeBankAccount: function removeBankAccount(bankAccountId, callback) {
      $.ajax({
        type: 'GET',
        url: this.endpoints.removeBankAccount + bankAccountId
      }).then(function (result) {
        callback(result);
      }, function () {
        callback(false);
      });
    }
  };
  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.component.select-payment-method').length > 0) {
      var shoppingCart = DGS.B2B.ShoppingCart;
      var dictionary = {
        B2B_Payments: {
          disclaimerText: 'You will have a chance to review details before submitting your payment on the next screen.',
          accountNumberConfirmErrorMsg: 'Account numbers do not match',
          RoutingNumberLengthErrorMsg: 'Routing number should be 9 digits in length',
          bankAccountNameLengthErrorMsg: 'Bank Account name cannot be more than 28 characters',
          cancelWarning: 'Are you sure you want to cancel the payment?',
          sharedCancelPaymentButtonLabel: 'Cancel payment',
          sharedContinuePaymentButton: 'Continue with payment',
          sharedYesButtonLabel: 'Yes',
          sharedNoButtonLabel: 'No',
          sharedOkButtonLabel: 'Ok',
          sharedCancelButtonLabel: 'Cancel'
        }
      };
      DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        // Running for each component
        $('.component.select-payment-method').each(function () {
          var $this = $(this),
            $creditCardsInput = $('#credit_cards', $this),
            $bankAccountsInput = $('#bank_accounts', $this),
            $bankAccountForm = $('.bank-account-form', $this),
            $dialogBox = $('.dialog-box', $this),
            $dictionary = $('.dictionary', $this),
            $dialogInnerBox = $('.inner-box', $dialogBox),
            $saveCardBox = $('.save-New-Card-box', $this),
            $saveCardBoxDisclaimer = $('.save-New-Card-box-disclaimer', $this),
            $submitButton = $('.left > .submit-button', $this),
            $cancelButton = $('.cancel-payment-method input', $this),
            $content = $('.component-content', $this);
          var dialogTypes = {
            YES_NO: 'yesNo',
            CANCEL_PAYMENT: 'cancelPayment',
            OK_CANCEL: 'okCancel'
          };
          var $spinner = $("<div class='spinner-wrapper'>\n            <div class=\"cssload-double-torus\">\n              <div class=\"cssload-double-torus-arrow\" />\n            </div>\n        </div>");
          $this.append($spinner);
          $saveCardBox.hide();
          $saveCardBoxDisclaimer.hide();
          showBlurredSpinner();
          shoppingCart.getCart(updateSummary); // Get the cart on page load

          $cancelButton.on('click', function (event) {
            var allowSubmit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
            if (!allowSubmit) {
              event.preventDefault();
              showDialog(dictionary.B2B_Payments.cancelWarning, function () {
                $(event.currentTarget).trigger('click', [true]);
              }, dialogTypes.CANCEL_PAYMENT);
            }
            // allow to continue unabated
          });

          // Adds the * on the required fields
          $('input.required', $this).parent('label').parent('div').addClass('required-start');

          // THIS IS WHAT TRIGGERS ALL THE SUBMIT
          function submitPage(event) {
            event.preventDefault();

            // Check that one account method has been selected
            var $selectedPaymentOption = $("input[name='payment-method']:checked", $this); // This is the selected payment method
            if (!$selectedPaymentOption.val()) {
              showDialog($dictionary.data('select-payment-method'));
              return false;
            }

            // Check that the shopping cart is not empty
            if (shoppingCart.totalAmount <= 0) {
              showDialog($dictionary.data('empty-cart'), function () {
                $('.left > .back-btn', $this)[0].click();
              });
              return false;
            }
            showBlurredSpinner();

            // Try to get the data from the BankAccountForm
            var bankData = getBankAccountFormData(),
              payload = null;
            if (!$.isEmptyObject(bankData)) {
              if (bankData.error) {
                // Here we enter if the bankData required fields are not all set
                showDialog(bankData.error);
              } else if ($selectedPaymentOption.val() === 'new-bank') {
                backendCalls.addBankAccount(bankData, function (newBankAccountId) {
                  if (newBankAccountId !== false && $.isNumeric(newBankAccountId)) {
                    payload = preparePayload(newBankAccountId);
                    backendCalls.startTransaction(payload, startTransactionCallback);
                  } else {
                    showDialog($dictionary.data('bank-add-error'));
                  }
                });
              } else {
                // Then is an update!
                bankData.documentNumber = $selectedPaymentOption.val();
                backendCalls.updateBankAccount(bankData, function (result) {
                  if (result !== false) {
                    payload = preparePayload();
                    backendCalls.startTransaction(payload, startTransactionCallback);
                  } else {
                    showDialog($dictionary.data('bank-update-error'));
                  }
                });
              }
            } else {
              // No bank account form data
              payload = preparePayload();
              backendCalls.startTransaction(payload, startTransactionCallback);
            }
          }
          $submitButton.on('click', function (event) {
            return submitPage(event);
          });

          // Filling payment information
          insertCreditCardOptions();
          insertSavedBankAccounts();
          // stickyPaymentSummary();

          // Manipulate the add bank account form to conform to new layout
          // TODO make many of these modifications in the .ascx file instead. OR convert to React :D

          // hide back button.  not in current requirements
          $('.back-btn').hide();

          // add disclaimer text above cancel/continue buttons
          var $disclaimer = $("<div class=\"disclaimer\"><p>".concat(dictionary.B2B_Payments.disclaimerText, "</p></div>"));
          $disclaimer.insertAfter($('.payment-methods'));

          // move the form to the bank account section.
          $('.payment-methods .bank-account').append($bankAccountForm);
          // clear out form fields we don't want at this time.
          var $comments = $('textarea[name=comments]');
          $comments.closest('div').hide();
          var $accountType = $('.account-type');
          $accountType.hide();

          // add new fields
          var $accountHolderName = "<div class=\"required-start\">\n        <label>\n          <span>Name on the account</span>\n          <input type=\"text\" name=\"account-holder-name\" class=\"large required\">\n        </label>\n      </div>";
          var $ConfirmAccountNo = $("<div class=\"required-start\">\n        <label>\n          <span>Confirm account no</span>\n          <input type=\"number\" name=\"account-number-confirm\" class=\"medium required\">\n        </label>\n      </div>");
          $('.bank-account-form .form').prepend($accountHolderName);
          var $accountNumberParent = $('input[name=account-number]').closest('div');
          $ConfirmAccountNo.insertAfter($accountNumberParent);
          var $routingNumberParent = $('input[name=transit-number]').closest('div');
          $routingNumberParent.insertBefore($accountNumberParent);

          // place all labels as placeholder text for inputs and hide labels
          var $inputs = $('input:not([type=hidden])', $bankAccountForm);
          $inputs.each(function (index, elem) {
            var $elem = $(elem);
            var elemParent = $elem.closest('div');
            var elemLabel = $elem.prev('span');
            elemParent.append($elem);
            var isRequired = $elem.hasClass('required') ? '*' : '';
            $elem.attr('placeholder', elemLabel.html() + isRequired);
            elemLabel.parent().remove();
          });
          var $selects = $('select', $bankAccountForm);
          $selects.each(function (index, elem) {
            var $elem = $(elem);
            var elemParent = $elem.closest('div');
            var elemLabel = $elem.prev('span');
            elemParent.append($elem);
            elemLabel.parent().remove();
          });

          // This is to show or hide the bank account form
          // NEEDS TO BE DONE AFTER INSERT CREDIT/BANK OPTIONS
          $("input[name='payment-method']", $this).on('change', function () {
            clearBankAccountDataForm();
            $saveCardBox.hide();
            $saveCardBoxDisclaimer.hide();
            if ($(this).filter(':checked').val() === 'new-bank') {
              displayNewBankAccountForm();
            } else if ($(this).filter(':checked').val() === 'new-card') {
              $saveCardBox.show();
              $saveCardBoxDisclaimer.show();
              $bankAccountForm.hide();
            } else {
              $bankAccountForm.hide();
            }
          });

          /** ********************* All the functions come after this *************************** */

          function showBlurredSpinner() {
            $content.addClass('blurred');
            $spinner.addClass('show-spinner');
          }
          function removeBlurredSpinner() {
            $content.removeClass('blurred');
            $spinner.removeClass('show-spinner');
          }

          /**
           * Function to be called inside the startTransaction method. It redirects the user to the corresponding URL
           * after clearing the shopping cart or shows the corresponding error message
           *
           * @param url String URL where the user should be redirected
           */
          function startTransactionCallback(url) {
            if (url) {
              // Clear the cart!
              // Finally redirect to the returned URL
              window.location.href = url;
            } else {
              removeBlurredSpinner();
              showDialog($dictionary.data('transaction-error'));
            }
          }

          /**
           * Gets the bank account information provided in the bank account form
           *
           * @returns {{}} A json with the form data. Returns an empty object if no bank account data was retrieved
           */
          function getBankAccountFormData() {
            var $selectedPaymentOption = $("input[name='payment-method']:checked", $this),
              // This is the selected payment method
              bankInformation = {};
            if ($selectedPaymentOption.parents('.bank-account').length > 0 && $('> .form', $bankAccountForm).is(':visible')) {
              // If the form is not visible do not send anything from there

              // We are only going to accept checking accounts at this time.
              bankInformation.accountTypeDescription = 'checking';
              bankInformation.accountTypeCode = 0;
              var errorMessages = [];

              // Now the rest (input type text and number)
              $("> .form input:not([type='radio'])", $bankAccountForm).each(function (index, input) {
                if ($(input).hasClass('required') && input.value == '') {
                  errorMessages.push("".concat($(input).attr('placeholder').replace(/\*/, ''), " ").concat($dictionary.data('required-field')));
                  return bankInformation;
                }
                bankInformation[hyphenToCamel(input.name)] = input.value;
              });

              // compare the account no and confirm account no fields
              if ($('input[name=account-number]').val() !== $('input[name=account-number-confirm]').val()) {
                errorMessages.push(dictionary.B2B_Payments.accountNumberConfirmErrorMsg);
              }

              // ensure the routing number is 9 digits long
              if ($('input[name=transit-number]').val().length !== 9) {
                errorMessages.push(dictionary.B2B_Payments.RoutingNumberLengthErrorMsg);
              }

              // ensure the bank account name is less than 29 characters
              if ($('input[name=name]').val().length > 28) {
                errorMessages.push(dictionary.B2B_Payments.bankAccountNameLengthErrorMsg);
              }

              // Check if there are required fields not set
              if (errorMessages.length > 0) {
                // bankInformation.error = errorMessages[0];   // show the first error only
                bankInformation.error = errorMessages.join('<br />'); // show all?
                return bankInformation;
              }

              // Now the select field
              var $selectState = $('.bank-account-form > .form select.us-states', $this);
              if ($selectState.length > 0 && $selectState.val() !== '') {
                bankInformation[hyphenToCamel($selectState.attr('name'))] = $selectState.val();
              }

              // Now the textarea
              var $textarea = $('.bank-account-form > .form textarea', $this);
              if ($textarea.length > 0 && $textarea.val() !== '') {
                bankInformation[hyphenToCamel($textarea.attr('name'))] = $textarea.val();
              }
            }
            return bankInformation;
          }
          function hyphenToCamel(string) {
            return string.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase();
            });
          }

          /**
           * Prepares the Bank Account Form to fill as it was a new bank account
           */
          function displayNewBankAccountForm() {
            // clearBankAccountDataForm();
            $bankAccountForm.children('.new-account').show();
            $bankAccountForm.children('.edit-account').hide();

            // Re-enable the disabled fields and add the correct type
            $('input', $bankAccountForm).attr('disabled', false);
            $("input[name='transit-number'], input[name='account-number']", $bankAccountForm).attr('type', 'number');

            // add click handlers to ensure that inputs of type number only allow numbers (no dashes or e or +/-)
            // also limit fixed length fields such as zip code and routing/transit number.
            $("input[name='transit-number'],  input[name='account-number'],  input[name='account-number-confirm'],  input[name='zipcode']").on('keypress', function (e) {
              if (e.key.match(/[^0-9]/) || e.currentTarget.attributes.name.value === 'transit-number' && e.currentTarget.value.length >= 9 || e.currentTarget.attributes.name.value === 'zipcode' && e.currentTarget.value.length >= 5) {
                e.preventDefault();
              }
            });

            // restrict bank name to stop after 28 characters
            $("input[name='name']").on('keypress', function (e) {
              if (e.currentTarget.value.length >= 28) {
                e.preventDefault();
              }
            });
            if (!$('.bank-account-controls').length) {
              var $addBankAccountFormControlWrapper = $('<div>');
              $addBankAccountFormControlWrapper.addClass('bank-account-controls');
              var $cancelBAButton = $('<input type="submit" value="Cancel" />');
              $cancelBAButton.addClass('btn-cancel');
              $addBankAccountFormControlWrapper.append($cancelBAButton);
              $cancelBAButton.on('click', function (e) {
                e.preventDefault();
                clearBankAccountDataForm();
                var $addAccountCheckbox = $("input[name='payment-method']").filter(':checked');
                if ($addAccountCheckbox.val() === 'new-bank') {
                  $addAccountCheckbox.prop('checked', false);
                }
                $bankAccountForm.hide();
              });
              var $addBAButton = $('<input type="submit" value="Add new account" />');
              $addBAButton.addClass('btn-submit');
              $addBAButton.on('click', function (e) {
                return submitPage(e);
              });
              $addBankAccountFormControlWrapper.append($addBAButton);
              $bankAccountForm.find('.form').append($addBankAccountFormControlWrapper);
            }
            scrollToBankAccountForm();
          }

          /**
           * Updates the sticky summary values with the cartData information. Used when the cart changes to reflect
           * it on the summary
           *
           * @param cartData The current state of the cart
           */

          function updateSummary(cartData) {
            // Check that there is actually something to pay
            if (shoppingCart.totalAmount <= 0) {
              removeBlurredSpinner();
              showDialog($dictionary.data('empty-cart'), function () {
                $('.left > .back-btn', $this)[0].click();
              }, dialogTypes.OK_CANCEL);
            }
            removeBlurredSpinner();
          }

          /**
           * Prepares and fills the fields to submit to the Backend. This is the only fields we submit.
           *
           * It needs to format a JSON with the following values:
           *  - isNewCreditCard: With a boolean value depending if the credit card is new or existing
           *  - creditcard.documentNo :If is an existing credit card
           *  - bank.documentNumber: If is an existing bank account or if we are updating a new one
           *  - transactions[].entryNumber: The invoice ID of each individual invoice
           *  - transactions[].amount: The amount of each individual invoice to be paid
           *  - payValue: An integer with the value specified on OtherAmount field
           *
           *  @param newBankDocumentNo [string|bool] After creating a new bank account, pass the new DocumentNo
           */
          function preparePayload(newBankDocumentNo) {
            // Clear the payload values
            var payload = {
                isNewCreditCard: false,
                creditcard: null,
                bank: null,
                transactions: [],
                payValue: 0,
                saveInNavision: false
              },
              $isSaveCreditCardOption = $("input[name='save-New-Card']:checked", $this),
              $selectedPaymentOption = $("input[name='payment-method']:checked", $this); // This is the selected payment method

            if ($selectedPaymentOption.val() === 'new-card') {
              payload.isNewCreditCard = true;
              if ($isSaveCreditCardOption.length > 0) {
                payload.saveInNavision = true;
              }
            } else if ($selectedPaymentOption.val() === 'new-bank' && newBankDocumentNo) {
              payload.bank = {
                documentNumber: newBankDocumentNo
              };
            } else if ($selectedPaymentOption.val() !== '') {
              // Handle EXISTING payment method
              if ($selectedPaymentOption.parents('.bank-account').length === 1) {
                payload.bank = {
                  documentNumber: $selectedPaymentOption.val()
                };
              } else if ($selectedPaymentOption.parents('.credit-card').length === 1) {
                payload.creditcard = {
                  documentNo: $selectedPaymentOption.val()
                };
              }
            }

            // Now the invoices
            if (shoppingCart.invoices.length > 0) {
              shoppingCart.invoices.forEach(function (invoice) {
                payload.transactions.push({
                  entryNumber: invoice.e,
                  description: invoice.desc,
                  document: {
                    documentNumber: invoice.i,
                    DueDateUtc: invoice.d
                  },
                  amount: invoice.a
                });
              });
            }
            // Now the otherAmount
            if (shoppingCart.otherAmount) {
              payload.payValue = shoppingCart.otherAmount;
            }
            return payload;
          }

          // TODO remove the invoice removal code and sticky summary functions as they are no longer used.
          /**
           * Removes an invoice from the cart, saving it and updating the summary
           *
           * @param invoiceId Id of the invoice to remove
           */
          function removeInvoice(invoiceId) {
            var removeInvoiceStatus = shoppingCart.removeInvoice(invoiceId);
            if (removeInvoiceStatus === true) {
              // Now we actually save the cart
              shoppingCart.saveCart(function (saveResult) {
                // If the element could be removed, then remove the DOM node
                if (saveResult === true) {
                  shoppingCart.getCart(updateSummary); // To double check we get the cart again
                }
              });
            } else {
              showDialog($dictionary.data('remove-error'));
            }
          }
          function removeGroup(groupIndex) {
            // Need to remove one invoice at the time
            var removeGroupResult = shoppingCart.removeGroup(groupIndex);
            if (removeGroupResult === true) {
              // Now we actually save the cart
              shoppingCart.saveCart(function (saveResult) {
                if (saveResult === true) {
                  shoppingCart.getCart(updateSummary);
                }
              });
            } else {
              showDialog($dictionary.data('remove-error'));
            }
          }

          /**
           * Removes the "otherAmount", both from the shopping cart and updates the summary
           */
          function removeOtherAmount() {
            shoppingCart.setOtherAmount(0);
            shoppingCart.saveCart(function (saveResult) {
              if (saveResult === true) {
                shoppingCart.getCart(updateSummary);
              } else {
                showDialog($dictionary.data('remove-error'));
              }
            });
          }

          /**
           * Makes the payment summary be sticky
           */
          function stickyPaymentSummary() {
            var $rightColumn = $('.component-content > .right', $this),
              stickyPosition = $rightColumn.offset().top,
              $paymentSummary = $rightColumn.find('.payment-method-summary'),
              $bottomLimitElement = $('.bottom-limit', $this); // This can also be the end of $this component

            DGS.Scroller.getInstance().register(function (scrollTop) {
              var bottomLimit = $bottomLimitElement.offset().top; // Depends on the element we want to have for limit

              if (scrollTop > stickyPosition) {
                $rightColumn.addClass('sticky');
                // Set manually the width of the element
                var newWidth = $rightColumn.width();
                $paymentSummary.innerWidth(newWidth);
                $rightColumn.removeClass('limit-reach');
              } else if (scrollTop < stickyPosition) {
                $rightColumn.removeClass('sticky');
                $paymentSummary.width('auto'); // Set manually the width of the element
              }
              // Handles the bottom limit, so the summary does not go beyond the footer
              if (scrollTop + $paymentSummary.innerHeight() > bottomLimit) {
                // Add the other class
                $rightColumn.addClass('limit-reach');
              }
            });
          }

          /**
           * Small function to scroll to the bank account form when the user selects to add a new one or edit an
           * existing one
           */
          function scrollToBankAccountForm() {
            $bankAccountForm.show();
            $('html, body').animate({
              // Smooth scroll to the "Add new bank account" form
              scrollTop: $bankAccountForm.offset().top
            }, 500);
          }

          /**
           * Inserts the user credit carts in the payment methods for selection
           */
          function insertCreditCardOptions() {
            var creditCards = $.parseJSON($creditCardsInput.val());
            var creditCardsDOM = '';
            var ccIcon = '<div class="credit-card-icon"> </div>';

            // Creates the DOM for the different credit cards
            if (creditCards) {
              $.each(creditCards, function (index, creditCard) {
                // Making sure that the information we need is present
                if (creditCard.documentNo !== '' && (creditCard.ccIssuedCompany !== '' || creditCard.ccType !== '') && creditCard.creditCardNo !== '') {
                  var input = "<input type='radio' name='payment-method' value='".concat(creditCard.documentNo, "'>");
                  var currMonth = moment().month() + 1; // 0 based months
                  var currYear = moment().year();
                  var ccExpirationDate = "exp. ".concat(creditCard.expMonth, "/").concat(creditCard.expYear);
                  var expiredDateClass = '';
                  if (isNaN(parseInt(creditCard.expMonth)) || isNaN(parseInt(creditCard.expYear)) || parseInt(creditCard.expMonth) <= currMonth && parseInt(creditCard.expYear) <= currYear) {
                    ccExpirationDate = 'expired';
                    expiredDateClass = 'expired';
                  }

                  // if ccIssuedCompany is null, use the ccType instead.
                  if (creditCard.ccIssuedCompany === '') {
                    creditCard.ccIssuedCompany = creditCard.ccType.description;
                  }

                  // const ccExpiration =
                  creditCardsDOM += "<div>\n                <label>\n                  ".concat(input, " ").concat(ccIcon, " ").concat(creditCard.ccIssuedCompany, "\n                  <span class='payment-option-ending'>\n                    ").concat(creditCard.creditCardNo.substr(creditCard.creditCardNo.length - 8), "\n                  </span>\n                  <span class=\"payment-option-ending ").concat(expiredDateClass, "\">\n                    ").concat(ccExpirationDate, "\n                  </span>\n                </label>\n                <span class='remove-payment-option'>Remove</span>\n              </div>");
                }
              });
            }
            if (creditCardsDOM !== '') {
              $('.credit-card > .payment-options > div', $this).first().before(creditCardsDOM);
              // Attach click event on the Remove links
              $('.credit-card .remove-payment-option', $this).on('click', function (e) {
                showDialog($dictionary.data('remove-payment-confirmation'), function () {
                  var $creditCardOption = $(e.currentTarget).parent();
                  backendCalls.removeCreditCard($creditCardOption.find('input').val(), function (result) {
                    if (result === true) {
                      $creditCardOption.remove();
                    } else {
                      showDialog($dictionary.data('remove-error'));
                    }
                  });
                }, dialogTypes.YES_NO);
              });
            }
          }

          /**
           * Inserts the user bank accounts in the payment methods for selection
           */
          function insertSavedBankAccounts() {
            var bankAccounts = $.parseJSON($bankAccountsInput.val());
            var bankAccountsDOM = '';
            var bankIcon = '<div class="bank-icon"> </div>';

            // Creates the DOM of the user saved bank accounts
            if (bankAccounts) {
              $.each(bankAccounts, function (index, bankAccount) {
                if (bankAccount.accountTypeDescription !== '' && bankAccount.accountNumber !== '') {
                  var input = "<input type='radio' name='payment-method' value='".concat(bankAccount.documentNumber, "'>");
                  bankAccountsDOM += "<div>\n                <label>".concat(input, " ").concat(bankIcon, " ").concat(bankAccount.name, "\n                  <span class='payment-option-ending'>").concat(bankAccount.accountTypeDescription, " ").concat(bankAccount.accountNumber.substr(bankAccount.accountNumber.length - 8), "</span>\n                </label>\n                <!--<span class='edit-payment-option'>Edit</span>-->\n                <span class='remove-payment-option' data-document-number=\"").concat(bankAccount.documentNumber, "\">Remove</span>\n              </div>");
                }
              });
            }
            if (bankAccountsDOM !== '') {
              $('.bank-account > .payment-options > div').before(bankAccountsDOM);

              // attach click event to Remove bank account link
              $('.bank-account .remove-payment-option', $this).on('click', function (e) {
                showDialog($dictionary.data('remove-payment-confirmation'), function () {
                  var $bankAccountOption = $(e.currentTarget).parent();
                  backendCalls.removeBankAccount(e.currentTarget.dataset.documentNumber, function (result) {
                    if (result === true) {
                      $bankAccountOption.remove();
                    } else {
                      showDialog($dictionary.data('remove-error'));
                    }
                  });
                }, dialogTypes.YES_NO);
              });

              // Attach click events on Edit link
              $('.bank-account .edit-payment-option').on('click', function () {
                // Get the correct bank account item
                var $bankAccountOption = $(this).parent();
                var bankAccountData = bankAccounts.find(function (bankAccount) {
                  return bankAccount.documentNumber === $bankAccountOption.find('input').val();
                });

                // If a bankAccountData is found (which should be), fill the account data information
                if (bankAccountData) {
                  fillBankAccountDataForm(bankAccountData);
                  // Auto select the radio button
                  $bankAccountOption.find('input').prop('checked', true);
                  scrollToBankAccountForm();
                }
              });
            }
          }

          /**
           * Fills the Bank Account form with existing values to edit them. Used when the user clicks edit bank account
           * Notice that some fields are set to disabled.
           *
           * @param bankAccountData Data from the bank account to edit
           */
          function fillBankAccountDataForm(bankAccountData) {
            var $form = $bankAccountForm.children('.form');

            // This two are only for the title since we reuse the form
            $bankAccountForm.children('.new-account').hide();
            $bankAccountForm.children('.edit-account').show();
            clearBankAccountDataForm();
            // Set account type (checking or savings)
            if (bankAccountData.accountTypeCode === '0') {
              $("input[name='account-type'][value='checking']", $form).prop('checked', true);
            } else {
              // Have been told that if is not 0 then is the savings
              $("input[name='account-type'][value='savings']", $form).attr('checked', true);
            }
            $("input[name='account-type']", $form).attr('disabled', true);
            // Set bank account number
            $("input[name='account-number']", $form).attr('type', 'text').attr('disabled', true).val(bankAccountData.accountNumber);
            // Set transit number
            $("input[name='transit-number']", $form).attr('type', 'text').attr('disabled', true).val(bankAccountData.transitNumber);
            // Set bank name
            $("input[name='name']", $form).attr('disabled', true).val(bankAccountData.companyId);
            // Set address
            $("input[name='address']", $form).val(bankAccountData.address);
            $("input[name='address2']", $form).val(bankAccountData.address2);
            // Set city
            $("input[name='city']", $form).val(bankAccountData.city);
            // Set state
            $("select[name='state']", $form).val(bankAccountData.state);
            // Set zip code
            $("input[name='zipcode']", $form).val(bankAccountData.zipcode);
          }

          /**
           * Resets all the input fields from the BankAccountForm
           */
          function clearBankAccountDataForm() {
            $bankAccountForm.find("input[type='text'], input[type='number']").val('');
            $bankAccountForm.find("input[name='account-type']").attr('checked', false);
            $bankAccountForm.find('textarea').val('');
          }

          /**
           * Simple dialog functionality to ask for confirmation and to show messages
           *
           * @param text     String with the text to show
           * @param callback [optional] If set it will create an OK button, when pressed it will call the callback
           *                 Used to create a confirmation modal
           * @param {string='okCancel','continuePayment','yesNo'} type    [optional] String to set button labels.
           */
          function showDialog(text, callback, type) {
            var yesButtonText = '';
            var noButtonText = '';

            // set our button texts
            switch (type) {
              case dialogTypes.YES_NO:
                yesButtonText = dictionary.B2B_Payments.sharedYesButtonLabel;
                noButtonText = dictionary.B2B_Payments.sharedNoButtonLabel;
                break;
              case dialogTypes.CANCEL_PAYMENT:
                yesButtonText = dictionary.B2B_Payments.sharedCancelPaymentButtonLabel;
                noButtonText = dictionary.B2B_Payments.sharedContinuePaymentButton;
                break;
              case dialogTypes.OK_CANCEL:
              default:
                yesButtonText = dictionary.B2B_Payments.sharedOkButtonLabel;
                noButtonText = dictionary.B2B_Payments.sharedCancelButtonLabel;
            }
            // If there is a spinner, hide it!
            removeBlurredSpinner();
            // First clear the dialog
            $dialogInnerBox.html('');
            $dialogInnerBox.append("<span>".concat(text, "</span><div class='dialog-bottom'>"));
            if (typeof callback !== 'function') {
              // set single button dialog box to use okButton text.
              noButtonText = yesButtonText;
            }
            $dialogInnerBox.children('.dialog-bottom').append($('<input>', {
              type: 'button',
              class: 'close',
              value: noButtonText
            })).bind('click', function () {
              $dialogBox.hide();
            });
            if (typeof callback === 'function') {
              $dialogInnerBox.children('.dialog-bottom').append($('<input>', {
                type: 'button',
                class: 'accept',
                value: yesButtonText
              }).bind('click', function () {
                callback();
                $dialogBox.hide();
              }));
            }
            $dialogBox.show();
          }
        });
      });
    }
  });
})(jQuery);