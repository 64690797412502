require("./wdh-legacy/richtext-submenu/js/base/component-richtext-submenu.js");
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.richtext-submenu').each(function () {
      var $this = $(this);
      $('.richtext-submenu-column .like-h3', $this).on('click', function () {
        if (window.matchMedia('(max-width: 900px)').matches) {
          $('.richtext-submenu-column', $this).css('display', 'none');
          var $thisH3 = $(this).parent(),
            $container = $thisH3.closest('.richtext-submenu-columns'),
            $elements = $('.richtext-submenu-column > *', $container),
            shouldStart = false,
            shouldBreak = false,
            isOpen = $thisH3.hasClass('open'),
            $parent = $thisH3.parent();
          if (!isOpen) $('.richtext-submenu-column', $this).css('display', 'none');else $('.richtext-submenu-column', $this).css('display', '');
          $parent.css('display', '');
          $elements.each(function () {
            var $this = $(this),
              elementName = $this.prop('nodeName').toLowerCase(),
              isH3 = $(this).children().hasClass("like-h3"),
              text = $this.text();
            if (!shouldStart) {
              if ($this[0] == $thisH3[0]) {
                shouldStart = true;
                $this.toggleClass('open');
                $this.removeClass('hide');
              } else if (!isH3) {
                $this.hide(100);
              } else {
                $this.removeClass('open');
                if (!isOpen) $this.addClass('hide');else $this.removeClass('hide');
              }
            } else {
              if (!shouldBreak) {
                if (!isH3) {
                  $this.toggle(100);
                } else if (text.trim().length > 0) {
                  shouldBreak = true;
                  $this.removeClass('open');
                  if (!isOpen) $this.addClass('hide');else $this.removeClass('hide');
                }
              } else if (!isH3) {
                $this.hide(100);
              } else {
                $this.removeClass('open');
                if (!isOpen) $this.addClass('hide');else $this.removeClass('hide');
              }
            }
          });
        }
      });
      window.DGS.Resizer.getInstance().register(function () {
        if (!window.matchMedia('(max-width: 900px)').matches) {
          $('.richtext-submenu').each(function () {
            var $this = $(this);
            $('.richtext-submenu-column .like-h3', $this).parent().removeClass('open');
            $('.richtext-submenu-column > *:not(h3)', $this).css('display', '');
            $('.richtext-submenu-column', $this).css('display', '');
          });
        } else {
          $('.richtext-submenu').each(function () {
            var $this = $(this);
            $('.richtext-submenu-column .like-h3', $this).parent().removeClass('open');
            $('.richtext-submenu-column > *:not(h3)', $this).css('display', '');
            $('.richtext-submenu-column p .like-h3').parent().addClass('header-element');
          });
        }
      });
    });
  });
})(jQuery);