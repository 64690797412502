import PropTypes from 'prop-types';
var flexSpotText = PropTypes.shape({
  type: PropTypes.string.isRequired,
  tag: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string).isRequired,
  html: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired
});
var flexSpotLink = PropTypes.shape({
  type: PropTypes.string.isRequired,
  tag: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string).isRequired,
  link: PropTypes.shape({
    content: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    title: PropTypes.string
  }).isRequired
});
var selectDictionary = PropTypes.shape({
  headline: flexSpotText,
  description: flexSpotText,
  dropdown: flexSpotText,
  helpLabel: flexSpotText,
  helpText: flexSpotText,
  helpCTA: flexSpotLink,
  secondaryHelpLabel: flexSpotText,
  secondaryHelpText: flexSpotText,
  secondaryHelpCTA: flexSpotLink
});
var selectData = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string
}));
export { flexSpotText, flexSpotLink, selectDictionary, selectData };