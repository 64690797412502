require("./wdh-legacy/link-list/js/base/component-link-list.js");
// added class to active link for custom styles

var pageURL = window.location.href,
  lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1),
  str,
  $b2bLinkSet = $('.b2b-linkset');
$b2bLinkSet.each(function () {
  $(this).find('li').each(function () {
    var aTitle = $(this).find('a').attr('title');
    if (window.location.hash) {
      str = lastURLSegment.substr(0, lastURLSegment.lastIndexOf("#"));
      str = str.replace(/-/g, ' ');
      if (typeof str === 'string' && typeof aTitle === 'string') {
        aTitle.toLowerCase() === str.toLowerCase() ? $(this).addClass('active-linkset') : $(this).removeClass('active-linkset');
      }
    } else {
      str = lastURLSegment;
      str = str.replace(/-/g, ' ');
      if (typeof str === 'string' && typeof aTitle === 'string') {
        aTitle.toLowerCase() === str.toLowerCase() ? $(this).addClass('active-linkset') : $(this).removeClass('active-linkset');
      }
    }
  });
});