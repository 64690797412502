function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
// Intro Banner config
(function ($, DGS) {
  window.DGS.Components = window.DGS.Components || {};
  window.DGS.Components.IntroBanner = window.DGS.Components.IntroBanner || {};
  window.DGS.Components.IntroBanner.overflowSelector = '.inner-pos-wrapper';
  window.DGS.Components.IntroBanner.pendingEmbeddedVideos = [];
  window.DGS.OnLoad.getInstance().register(function () {
    window.DGS.WaitForLoad.getInstance().register($('.intro-banner'));
    var IMAGE_LOADED = 'imageLoaded';
    var ytApi = 'https://www.youtube.com/iframe_api';
    var hiddenClass = 'hidden';
    function testForFallback($this) {
      var video = $('video', $this);
      var iframe = $('iframe', $this);
      function supportsVideo() {
        return !!document.createElement('video').canPlayType;
      }
      function iframeIsBlockedByGDPR() {
        if (!window.CookieConsent) return false;
        var consent = window.CookieInformation.getConsentGivenFor('cookie_cat_marketing');
        return !consent;
      }
      if (!supportsVideo() || !video.length && !iframe.length || iframe.length && iframeIsBlockedByGDPR() || window.DGS.UserAgent.getInstance().mobileTabletCheck()) {
        $this.addClass('img-fallback');
      } else if (video.length) {
        video[0].play();
        video[0].muted = true;
      }
    }
    function resizeIframe($iframe) {
      var w = $iframe.attr('width');
      var h = $iframe.attr('height');
      var prop;
      var wW = $(window).width();
      if (w !== undefined && h !== undefined && $iframe.length) {
        prop = w / h;
        w = wW;
        h = w / prop;
        $iframe.attr('width', w);
        $iframe.attr('height', h);
      }
      if ($iframe.hasClass(hiddenClass)) {
        $iframe.removeClass(hiddenClass);
      }
    }
    function youTubeGetID(url) {
      var ID = '';
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        var _ID = ID;
        var _ID2 = _slicedToArray(_ID, 1);
        ID = _ID2[0];
      } else {
        ID = url;
      }
      return ID;
    }
    function getQueryVariable(variable, query) {
      if (!query) {
        return false;
      }
      var n = query.indexOf('?');
      var attributes = query.substring(n + 1);
      var vars = attributes.split('&');
      for (var i = 0; i < vars.length; i += 1) {
        var pair = vars[i].split('=');
        if (pair[0] === variable) {
          return pair[1];
        }
      }
      return false;
    }
    function setupPlayer(iframeId, id, videoControls, playlist, loop, rel, showInfo, soundOn) {
      // eslint-disable-next-line no-undef, no-unused-vars
      var player = new YT.Player(iframeId, {
        videoId: id,
        playerVars: {
          autoPlay: 1,
          controls: videoControls,
          playlist: playlist,
          loop: loop,
          rel: rel,
          showInfo: showInfo
        },
        events: {
          onReady: function onReady(e) {
            if (soundOn !== 1) {
              e.target.mute();
            }
            e.target.playVideo();
          }
        }
      });
    }
    function addEventsTo(element) {
      var $iframe = element.find('iframe');
      var src = $iframe.attr('src');
      if (!src) return;
      var autoPlay = getQueryVariable('autoplay', src);
      var controls = getQueryVariable('controls', src);
      var playlist = getQueryVariable('playlist', src);
      var loop = getQueryVariable('loop', src);
      var rel = getQueryVariable('rel', src);
      var showinfo = getQueryVariable('showinfo', src);
      var soundOn = element.data('sound');
      if ($iframe.length && soundOn !== 1) {
        if (src.indexOf('youtube') !== -1 && autoPlay === '1') {
          var id = youTubeGetID(src);
          var iframeId = $iframe.attr('id');

          // add new div and hide to avoid visible resizing (hiddenClass removed in resizeIframe)
          element.append("<div class='yt-player ".concat(hiddenClass, "' id='").concat(iframeId, "'></div>"));

          // remove original iframe as the events were never fired here
          $iframe.remove();
          var videoControls = controls === '1' ? 1 : 0;

          // reimplement video in order to use onReady event
          try {
            setupPlayer(iframeId, id, videoControls, playlist, loop, rel, showinfo, soundOn);
          } catch (error) {
            window.DGS.Components.IntroBanner.pendingEmbeddedVideos.push({
              iframeId: iframeId,
              id: id,
              videoControls: videoControls,
              playlist: playlist,
              loop: loop,
              rel: rel,
              showinfo: showinfo,
              soundOn: soundOn
            });
            window.onYouTubeIframeAPIReady = function () {
              window.DGS.Components.IntroBanner.pendingEmbeddedVideos.forEach(function (pendingVideo) {
                setupPlayer(pendingVideo.iframeId, pendingVideo.id, pendingVideo.videoControls, pendingVideo.playlist, pendingVideo.loop, pendingVideo.rel, pendingVideo.showinfo, pendingVideo.soundOn);
              });
            };
          }
        } else if (src.indexOf('23video') !== -1 && src.indexOf('autoMute') === -1) {
          var sep = src.indexOf('?') === -1 ? '?' : '&';
          $iframe.attr('src', "".concat(src + sep, "autoMute=1"));
        }
      }
    }
    function handleForcedSize($this) {
      var $img = $('.intro-banner-image img', $this);
      var $video = $('.intro-banner-video', $this);
      var w = $img.outerWidth();
      var h = $img.outerHeight();
      var vw = $video.outerWidth();
      var vh = $video.outerHeight();
      var ww = $(window).outerWidth();
      var whProp = h / w;
      var wwhProp = h / ww;
      var vwhProp = vh / vw;
      if (whProp >= wwhProp) {
        $this.addClass('wide-image');
        $this.removeClass('tall-image');
      } else {
        $this.removeClass('wide-image');
        $this.addClass('tall-image');
      }
      if (!$this.hasClass('img-fallback')) {
        if (vwhProp >= wwhProp) {
          $this.addClass('wide-video');
          $this.removeClass('tall-video');
        } else {
          $this.removeClass('wide-video');
          $this.addClass('tall-video');
        }
      }
    }
    function onImageLoadComplete($this) {
      if ($this.hasClass('semi-full-height') || $this.hasClass('full-height')) handleForcedSize($this);
      window.DGS.Overflow.getInstance().register($this, $(window.DGS.Components.IntroBanner.overflowSelector, $this), function () {
        // because the US theme wants to force the content of intro-banners to overflow at the same viewport width we add a class to force the overflow.
        if ($this.hasClass('force-overflow')) return true;
        var $stickyDownlink = $('.sticky-downlink', $this);
        var $innerPosWrapper = $('.inner-pos-wrapper', $this);
        var rect1 = $innerPosWrapper[0].getBoundingClientRect();
        var rect2;
        if ($stickyDownlink.length) {
          rect2 = $stickyDownlink[0].getBoundingClientRect();
          if (!(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom)) {
            return true;
          }
        }
        return false;
      });
      if ($this.hasClass('has-list-spot')) {
        window.DGS.Overflow.getInstance().register($this, $('.intro-banner-list-spot-content', $this), function () {
          var $textContainer = $('.inner-pos-wrapper .intro-banner-text', $this);
          var $linkTextContainer = $('.inner-pos-wrapper .intro-banner-link', $this);
          var $listSpotContent = $('.intro-banner-list-spot-container', $this);
          var rect1 = $textContainer[0].getBoundingClientRect();
          var rect2 = $listSpotContent[0].getBoundingClientRect();
          if (!$textContainer.length || !$listSpotContent.length) {
            return false;
          }
          if (rect1.height > 0 && !(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom)) {
            return true;
          }
          if (!$linkTextContainer.length) return false;
          rect1 = $linkTextContainer[0].getBoundingClientRect();
          if (rect1.height > 0 && !(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom)) {
            return true;
          }
          return false;
        });
      }
      if ($this.hasClass('carousel-slide')) {
        // trigger event so Carousel knows to update its positioning.
        DGS.Event.getInstance().trigger(IMAGE_LOADED);
      }
      window.DGS.LoadComplete.getInstance().register($this);
    }
    $('.component.intro-banner').each(function () {
      var bannerElement = $(this);
      var bLink = bannerElement[0].dataset.bannerLink;
      if (bLink) {
        if ($(bannerElement).hasClass('content-overflow force-overflow')) {
          $(bannerElement[0].children[0].children[0]).on('click', function () {
            window.open(bLink, '_self');
          });
        }
        if ($(bannerElement).not('content-overflow force-overflow')) {
          $(bannerElement).css('cursor', 'pointer').on('click', function (e) {
            e.stopPropagation();
            window.open(bLink, '_self');
          });
          $(".intro-banner-content .outer-pos-wrapper").children('div').css('cursor', 'default').on('click', function (e) {
            e.stopPropagation();
          });
        }
      }
    });
    function checkForMobileImage() {
      $('.intro-banner').each(function () {
        var $this = $(this);
        var $bannerImage = $('.intro-banner-image', $this);
        var hasMobileImage = $bannerImage.data('mobileImage') !== undefined;
        if (hasMobileImage) {
          var $img = $('img', $this);
          if (window.innerWidth < 768) {
            if ($bannerImage.data('desktopImage') === undefined) {
              // preserve our desktop-image data
              var imgurl = $img.attr('src');
              // cleans url so it doesn't re-download it if the user goes back to a non-mobile breakpoint
              var _imgurl$split$0$split = imgurl.split('?')[0].split('#');
              var _imgurl$split$0$split2 = _slicedToArray(_imgurl$split$0$split, 1);
              imgurl = _imgurl$split$0$split2[0];
              $bannerImage.data('desktopImage', imgurl);
              $bannerImage.data('desktopImageHeight', $img.attr('height'));
              $bannerImage.data('desktopImageWidth', $img.attr('width'));
              $bannerImage.data('desktopImageAltText', $img.attr('alt'));
            }
            $img.attr('height', $bannerImage.data('mobileImageHeight'));
            $img.attr('width', $bannerImage.data('mobileImageWidth'));
            $img.attr('alt', $bannerImage.data('mobileImageAltText'));
            $img.attr('src', $bannerImage.data('mobileImage'));
          } else if ($bannerImage.data('desktopImage') !== undefined) {
            $img.attr('height', $bannerImage.data('desktopImageHeight'));
            $img.attr('width', $bannerImage.data('desktopImageWidth'));
            $img.attr('alt', $bannerImage.data('desktopImageAltText'));
            $img.attr('src', $bannerImage.data('desktopImage'));
          }
        }
      });
    }
    checkForMobileImage();
    $('.intro-banner').each(function () {
      var $this = $(this);
      var $img = $('.intro-banner-image img', $this);
      testForFallback($this);
      if ($this.hasClass('pos-bottom') && $('.sticky-downlink', $this).length) {
        $('.inner-pos-wrapper', $this).css('margin-bottom', '6em');
      }
      if ($img.length) {
        window.DGS.ImageComplete.getInstance().register($img, function () {
          onImageLoadComplete($this);
        });
      } else {
        $(this).addClass('no-image');
        window.DGS.LoadComplete.getInstance().register($this);
      }
      if ($('.intro-banner-video-external', $this).length) {
        $('.intro-banner-video-external', $this).each(function () {
          addEventsTo($(this));
        });
        if (typeof YT === 'undefined') {
          $.getScript(ytApi, function () {});
        }
      }
    });
    window.DGS.Resizer.getInstance().register(function () {
      checkForMobileImage();
      $('.intro-banner.semi-full-height, .intro-banner.full-height').each(function () {
        handleForcedSize($(this));
      });
      $('.intro-banner .intro-banner-video-external iframe').each(function () {
        resizeIframe($(this));
      });
    });
    if (window.location.href.indexOf('sc_mode=edit') > -1 && $('.intro-banner').length) {
      setInterval(function () {
        $('.intro-banner').each(function () {
          var $this = $(this);
          testForFallback($this);
        });
      }, 1000);
    }
  });
})(jQuery, window.DGS);