(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $('.component.blog-author').each(function () {
      var dummyContainer = $('<div class="blog-author-right"></div>');
      dummyContainer.append($(".blog-author-name", $(this))).append($(".blog-author-title", $(this))).append($(".blog-author-intro", $(this))).append($(".blog-author-link", $(this)));
      dummyContainer.insertAfter($('.blog-author-image', $(this)));
      $('.blog-author-about').clone().prependTo($(this).find('.blog-author-right'));
      $(this).find('.blog-author-right .blog-author-about').addClass('hide-for-mobile');
    });
  });
})(jQuery, window.DGS);