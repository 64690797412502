var AnalyticsUtils = function AnalyticsUtils() {};
AnalyticsUtils.triggerGenericEvent = function (eventCategory) {
  var eventAction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var eventLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'genericGAEvent',
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel
  });
};
AnalyticsUtils.triggerPurchaseEvent = function (category, payload) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ecommerce: null
  }); // Clear the previous ecommerce object
  window.dataLayer.push({
    event: category,
    ecommerce: payload
  });
};
AnalyticsUtils.eventCategories = {
  INVOICE_PAYMENT: 'invoice_payment',
  PURCHASE: 'purchase',
  WARRANTY_PURCHASE: 'warranty',
  // register and purchase a regular or extended warranty
  WARRANTY_REQUESTED: 'warranty_requested',
  // register warranty
  EXTEND_WARRANTY: 'extend_warranty',
  // extend warranty
  LOGIN: 'login'
};
export default AnalyticsUtils;