// WDH FEATURE B2B
import WDHFeatureB2BShopInitializationSpot from './shop-initialization-spot';
// import WDHFeatureB2BBrowseShopCategoriesSpot from './browse-shop-categories-spot';  // NOT IN USE IN B2B US
import WDHFeatureB2BCartSpot from './cart-spot';
// import WDHFeatureB2BCartDetailsSpot from './cart-details-spot';
// import WDHFeatureB2BFreePromotionalItemsSpot from './free-promotional-items';
// import WDHFeatureB2BManageCatalogsSpot from './manage-catalogs-spot';  // NOT BUILT YET
// import WDHFeatureB2BProductSearchResultSpot from './product-search-result-spot';  // NOT USED IN OTICON US B2B
// import WDHFeatureB2BProductDetailsSpot from './product-details-spot';
// import WDHFeatureB2BCheckoutConfirmationSpot from './checkout-confirmation-spot';
// import WDHFeatureB2BClientOrderStep1Spot from './client-order-step1-spot';
// import WDHFeatureB2BClientOrderStep2Spot from './client-order-step2-spot';
// import WDHFeatureB2BClientOrderStep3Spot from './client-order-step3-spot';
// import WDHFeatureB2BSelectOrderTypeSpot from './select-order-type-spot';
// import WDHFeatureB2BCheckoutSpot from './checkout-spot';
// import WDHFeatureB2BCheckoutPrintSpot from './checkout-print-spot';
// import WDHFeatureB2BCartPrintSpot from './cart-print-spot'; // NOT USED IN OTICON US B2B
// import WDHFeatureB2BRecentlyViewedProductsSpot from './recently-viewed-products-spot';
// import WDHFeatureB2BProductSearchSpot from './product-search-spot';
// import WDHFeatureB2BProductListSpot from './product-list-spot';  // NOT USED IN OTICON US B2B - replaced by WDHShopProductList
// import WDHFeatureB2BSubheaderSpot from './subheader-spot';  // HONESTLY DON'T THINK THIS IS ACTUALLY USED BUT IT IS SITECORE PAGES SO ADDING TO LAZY LOADER.
// import WDHFeatureB2BAccountInformationSpot from './account-information-spot';  // NOT USED IN OTICON US B2B

// US Specific components
// import WDHShopProductList from './wdh-shop-product-list';
// import WDHShopSearchResults from './wdh-shop-search-results';
// import WDHShopBulkOrderConfiguratorSpot from './wdh-shop-bulk-order-configurator';
// import WDHShopRelatedProductSpot from './wdh-shop-related-product';  // NOT USED IN B2B US
// import WDHFeatureB2BDiscountsSpot from './my-discounts-spot';
// import {
//   WDHPromoBannerSpot,
//   WDHPromoBannerFreeShippingSpot,
//   WDHPromoBannerLimitedTimeOffers,
//   WDHPromoBannerNewPromotionsAvailable
// } from './wdh-promo-banner-spot';

// ShopInitializationSpot depends on WDH.Feature.B2B.Authentication\B2BUI\Sublayouts\LoginSpot
new WDHFeatureB2BShopInitializationSpot().run();
// new WDHFeatureB2BBrowseShopCategoriesSpot().run();  // NOT IN USE IN B2B US

// new WDHFeatureB2BCheckoutSpot().run();
// new WDHFeatureB2BCheckoutPrintSpot().run();
// new WDHFeatureB2BCartPrintSpot().run(); // NOT USED IN OTICON US B2B
// new WDHFeatureB2BManageCatalogsSpot().run();  // NOT BUILT YET, but we will leave here for now.
// new WDHFeatureB2BProductDetailsSpot().run();
// new WDHFeatureB2BProductListSpot().run();  // NOT USED IN OTICON US B2B
// new WDHFeatureB2BProductSearchSpot().run();
// new WDHFeatureB2BRecentlyViewedProductsSpot().run();
// new WDHFeatureB2BProductSearchResultSpot().run();  // NOT USED IN OTICON US B2B
// new WDHFeatureB2BCheckoutConfirmationSpot().run();
// new WDHFeatureB2BClientOrderStep1Spot().run();
// new WDHFeatureB2BClientOrderStep2Spot().run();
// new WDHFeatureB2BClientOrderStep3Spot().run();
// new WDHFeatureB2BSelectOrderTypeSpot().run();
// new WDHFeatureB2BSubheaderSpot().run();
// new WDHFeatureB2BAccountInformationSpot().run();
new WDHFeatureB2BCartSpot().run();
// new WDHFeatureB2BCartDetailsSpot().run();
// new WDHFeatureB2BFreePromotionalItemsSpot().run();
// new WDHShopProductList().run();
// new WDHShopSearchResults().run();
// new WDHShopBulkOrderConfiguratorSpot().run();
// new WDHShopRelatedProductSpot().run();  // NOT USED IN B2B US
// new WDHFeatureB2BDiscountsSpot().run();
// const bannerSpot = new WDHPromoBannerSpot();
// bannerSpot.registerVariation(new WDHPromoBannerFreeShippingSpot());
// bannerSpot.registerVariation(new WDHPromoBannerLimitedTimeOffers());
// bannerSpot.registerVariation(new WDHPromoBannerNewPromotionsAvailable());
// bannerSpot.run();