// import validator from 'validator';

(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    // set up common function for setting locations search box
    function updateLocation() {
      var formattedAddress = '';
      var userCity = $('.userCity input').val();
      var userState = $('.userState select').val();
      var userZip = $('.userZip input').val();
      var $locationsSpot = $('.locations-spot');
      if (!$locationsSpot.hasClass('hide-map')) {
        var cityString = userCity ? "".concat(userCity, ", ") : userCity;
        formattedAddress = "".concat(cityString, " ").concat(userState, " ").concat(userZip);
        window.DGS.Event.getInstance().trigger('newSearchLocation', formattedAddress);
      }
    }
    function updateListCount(count) {
      window.DGS.Event.getInstance().trigger('changeListCount', count);
    }

    /**
     * toggles filter visibility programmatically
     *
     * @param {string} filterProperty
     * @param {boolean} isToggled
     */
    function toggleFilter() {
      var filterProperty = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var isToggled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      window.DGS.Event.getInstance().trigger('toggleFilter', {
        filterProperty: filterProperty,
        isToggled: isToggled
      });
    }
    function getUrlParameter(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
    ;

    // Functionality for our Form.
    if ($('.component.form.form-opn-s-rft-map')[0]) {
      var myForm = $('.component.form').wffm({
        niceSelectClass: 'square-corners',
        // make nice-select like rest of the wffm
        moveAsteriskToLabel: true
      });
      var animDelay = 250;

      // set up hide/show functionality for select fields.
      var $HCPName = $('.HCPName');
      var $HCPCity = $('.HCPCity');
      var $HCPCompany = $('.HCPCompany');
      var $HCPInfo = $('.hideShowHCPInfo');
      var $wearHearingAidsNo = $('.wearHearingAidsNo');
      var $wearHearingAidsYes = $('.wearHearingAidsYes');
      var $locationsSpot = $('.locations-spot');
      var $HCPSelectedClinicInput = $('.selectedClinic input[type="checkbox"]');
      var $HCPSelectedClinicWrapper = $('.selectedClinic');
      var $noProviderCloseCheckbox = $('.noProviderClose');
      var $SeeingHCPRadioButtonChecked = $('.hideShowLocationsMap input[type="radio"]:checked');
      var $WearsHASelectedRadio = $('.hideShowWearHearingAids input[type="radio"]:checked');
      var $userStateSelect = $('.userState select');
      var locationOutOfRange = false;

      // Grab the UTM params from the URL as well as Date Submitted Field, must be done programatically as Locations spot will change the url
      // IE safe
      var utmCampaign = getUrlParameter('utm_campaign');
      var utmContent = getUrlParameter('utm_content');
      var utmMedium = getUrlParameter('utm_medium');
      var utmSource = getUrlParameter('utm_source');

      // const submitDateTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
      // IE11 adds a 'right to left mark' non-printing character (\u200E) which interferes with submission.
      var submitDateTime = new Date().toLocaleString('en-US').replace(/\u200E/g, '');
      $('.component.form-opn-s-rft-map .scfSectionContent > .scfHiddenFieldBorder').each(function () {
        if ($(this).hasClass('name.UTM+Campaign') && utmCampaign != null) {
          $(this).find('input').val(utmCampaign);
        } else if ($(this).hasClass('name.UTM+Content') && utmContent != null) {
          $(this).find('input').val(utmContent);
        } else if ($(this).hasClass('name.UTM+Medium') && utmMedium != null) {
          $(this).find('input').val(utmMedium);
        } else if ($(this).hasClass('name.UTM+Source') && utmSource != null) {
          $(this).find('input').val(utmSource);
        } else if ($(this).hasClass('name.Form+Submission+Date')) {
          // needed date submitted as well so lets put it in here.
          $(this).find('input').val(submitDateTime);
        }
      });

      // disable this field.
      $HCPSelectedClinicInput.attr('disabled', true);
      $noProviderCloseCheckbox.hide();

      // rearrange some things to fit wireframes.
      myForm.scfSectionLegend().after(myForm.scfIntroBorder());
      // $('.hideShowLocationsMap').after($locationsSpot);
      $HCPSelectedClinicWrapper.after($locationsSpot);
      $userStateSelect.find('option:first').append('State*');
      $userStateSelect.addClass('square-corners');
      $userStateSelect.niceSelect('update');

      // need to add this class so that when using niceSelect update function, class doesn't get removed.
      $('.wearHearingAidsYes select').addClass('square-corners');
      $('.wearHearingAidsNo select').addClass('square-corners');
      var formHasErrors = false;

      // check if validator is active.  highlight fields with errors
      myForm.scfValidator().each(function (index, elem) {
        var $elem = $(elem);
        if ($elem.css('display') !== 'none') {
          $elem.parent().find('input').addClass('hasError');
          formHasErrors = true;
        }
        $elem.css('display', 'none');
      });
      myForm.scfValidatorRequired().each(function (index, elem) {
        var $elem = $(elem);
        var $elemParentCheckBox = $elem.parent();
        if ($elemParentCheckBox.length) {
          $elemParentCheckBox.addClass('hasError');
          formHasErrors = true;
        }
      });
      myForm.scfValidationSummary().hide();

      // Set up event triggers for our form to allow us to search based on the filled out fields.
      $('.userZip input').on('change', updateLocation);
      $('.userCity input').on('change', updateLocation);
      $userStateSelect.on('change', updateLocation);

      // catch any events coming from the locations spot
      window.DGS.Event.getInstance().on('locationClickEvent', function (e) {
        var $HCPAccountNo = $('.HCPAccountNo .scfHiddenFieldTextBox');
        var $HCPCityInput = $('.HCPCity .scfSingleLineTextBox');
        var $HCPCompanyInput = $('.HCPCompany .scfSingleLineTextBox');
        // const $HCPSelectedClinicInput = $('.selectedClinic .scfSingleLineTextBox');

        $HCPCompanyInput.val(e.Name);
        $HCPSelectedClinicInput.prop('checked', true);
        $noProviderCloseCheckbox.find('input[type="checkbox"]').prop('checked', false);
        $HCPCityInput.val(e.City);
        $HCPAccountNo.val(e.CustomerNumber);

        // reset the I do not see my provider question and related questions.
        $('.hideShowHCPInfo input[type="checkbox"]').prop('checked', false);
        $HCPName.hide(animDelay);
        $HCPCity.hide(animDelay);
        $HCPCompany.hide(animDelay);

        // store location into sessionStorage so we can get info on the thankyou page.
        sessionStorage.setItem('locationInfo', JSON.stringify(e));
      });
      window.DGS.Event.getInstance().on('firstLocationDistance', function (e) {
        var metersIn125Miles = 201168; // google distance matrix returns distances in meters.
        if (e !== undefined && e.drivingDistanceRaw) {
          locationOutOfRange = e.drivingDistanceRaw > metersIn125Miles;
          if (locationOutOfRange && $('.hideShowLocationsMap input[type="radio"]:checked').val() === 'No') {
            $noProviderCloseCheckbox.show(animDelay).css('display', 'block');
          } else {
            $noProviderCloseCheckbox.hide(animDelay);
          }
        }
      });

      // hides or shows the hidden fields in case form returns validation error(s) and the users answer
      if ($SeeingHCPRadioButtonChecked.length) {
        $locationsSpot.removeClass('hide-map');
        $HCPSelectedClinicWrapper.show(animDelay).css('display', 'block');
        updateLocation();

        // grabs the last selected location from sessionvars (if exists) and updates the map to select this location.
        var lastSelectedLocation = {};
        try {
          lastSelectedLocation = JSON.parse(sessionStorage.getItem('locationInfo'));
        } catch (e) {
          console.error('Could not parse JSON data');
          lastSelectedLocation = {};
        }
        window.DGS.Event.getInstance().trigger('deselectLocations', lastSelectedLocation);
      } else {
        $locationsSpot.addClass('hide-map');
        $HCPSelectedClinicWrapper.hide(animDelay);
      }
      if ($SeeingHCPRadioButtonChecked.length && $SeeingHCPRadioButtonChecked.val().toLowerCase() === 'yes') {
        $HCPInfo.show(animDelay).css('display', 'block');
        $noProviderCloseCheckbox.hide(animDelay);
        if ($('.hideShowHCPInfo input[type="checkbox"]').prop('checked')) {
          $HCPName.show(animDelay).css('display', 'block');
          $HCPCity.show(animDelay).css('display', 'block');
          $HCPCompany.show(animDelay).css('display', 'block');
        } else {
          // hide fields until triggered
          $HCPName.hide(animDelay);
          $HCPCity.hide(animDelay);
          $HCPCompany.hide(animDelay);
        }
      } else {
        // hide fields until triggered
        $HCPInfo.hide(animDelay);
        $HCPName.hide(animDelay);
        $HCPCity.hide(animDelay);
        $HCPCompany.hide(animDelay);
        if (locationOutOfRange) $noProviderCloseCheckbox.show(animDelay).css('display', 'block');
      }
      if ($WearsHASelectedRadio.length && $WearsHASelectedRadio.val().toLowerCase() === 'yes') {
        $wearHearingAidsNo.hide(animDelay);
        $wearHearingAidsYes.show(animDelay).css('display', 'block');
      } else if ($WearsHASelectedRadio.length && $WearsHASelectedRadio.val().toLowerCase() === 'no') {
        $wearHearingAidsNo.show(animDelay).css('display', 'block');
        $wearHearingAidsYes.hide(animDelay);
      } else {
        $wearHearingAidsNo.hide(animDelay);
        $wearHearingAidsYes.hide(animDelay);
      }
      /* END Hide Show hidden fields on form submit */

      /* Hide our map and no provider questions. */
      $('.hideShowLocationsMap input[type="radio"]').on('click', function () {
        var selectedRadio = $('.hideShowLocationsMap input[type="radio"]:checked');
        if (selectedRadio.val().toLowerCase() === 'yes') {
          $HCPInfo.show(animDelay).css('display', 'block');
          $noProviderCloseCheckbox.hide();
          updateListCount(6);
          toggleFilter('SoldQty', false);
        } else {
          $HCPInfo.hide(animDelay);
          // reset value and hide dependent questions.
          $('.hideShowHCPInfo input[type="checkbox"]').prop('checked', false);
          $HCPName.hide(animDelay);
          $HCPCity.hide(animDelay);
          $HCPCompany.hide(animDelay);
          updateListCount(3);
          toggleFilter('SoldQty', true);
          if (locationOutOfRange) $noProviderCloseCheckbox.show(animDelay).css('display', 'block');
        }
        $HCPSelectedClinicWrapper.show(animDelay).css('display', 'block');
        $locationsSpot.removeClass('hide-map');
        window.DGS.Event.getInstance().trigger('deselectLocations');
        $HCPSelectedClinicInput.prop('checked', false);
        // $HCPSelectedClinicInput.val('');
        sessionStorage.removeItem('locationInfo');
        updateLocation();
      });

      // if this check box is checked, then we count it as a location has been slected.
      $noProviderCloseCheckbox.on('click', function (e) {
        $HCPSelectedClinicInput.prop('checked', e.target.checked);
        sessionStorage.removeItem('locationInfo');
        window.DGS.Event.getInstance().trigger('deselectLocations');
      });

      /* Toggle visibility of our additional questions if I don't see my provider is checked. */
      $('.hideShowHCPInfo input[type="checkbox"]').on('click', function () {
        if ($('.hideShowHCPInfo input[type="checkbox"]').prop('checked')) {
          var $HCPNameInput = $('.HCPName .scfSingleLineTextBox');
          var $HCPCityInput = $('.HCPCity .scfSingleLineTextBox');
          var $HCPCompanyInput = $('.HCPCompany .scfSingleLineTextBox');
          var $HCPAccountNo = $('.HCPAccountNo .scfHiddenFieldTextBox');

          // remove the NA added on Submit if validation fails and user changes answer.
          $HCPNameInput.val('');
          $HCPCityInput.val('');
          $HCPCompanyInput.val('');
          $HCPAccountNo.val('');
          sessionStorage.removeItem('locationInfo');
          $HCPSelectedClinicInput.prop('checked', true);
          // $HCPSelectedClinicInput.val('Selected Clinic: Other');
          // trigger event to be caught by the locations spot.
          window.DGS.Event.getInstance().trigger('deselectLocations');
          $HCPName.show(animDelay).css('display', 'block');
          $HCPCity.show(animDelay).css('display', 'block');
          $HCPCompany.show(animDelay).css('display', 'block');
        } else {
          $HCPName.hide(animDelay);
          $HCPCity.hide(animDelay);
          $HCPCompany.hide(animDelay);
          $HCPSelectedClinicInput.prop('checked', false);
          sessionStorage.removeItem('locationInfo');
        }
      });
      $('.hideShowWearHearingAids input[type="radio"]').on('click', function () {
        var selectedRadio = $('.hideShowWearHearingAids input[type="radio"]:checked');
        var wearHADDYes = $('.wearHearingAidsYes select');
        var wearHADDNo = $('.wearHearingAidsNo select');
        if (selectedRadio.val() === 'Yes') {
          $wearHearingAidsNo.hide(animDelay);
          // reset value of hidden checkbox so it doesn't get sent to CRM.
          wearHADDNo.val('');
          wearHADDNo.niceSelect('update');
          $wearHearingAidsYes.show(animDelay).css('display', 'block');
        } else {
          $wearHearingAidsNo.show(animDelay).css('display', 'block');
          $wearHearingAidsYes.hide(animDelay);
          // reset value of hidden checkbox so it doesn't get sent to CRM.
          wearHADDYes.val('');
          wearHADDYes.niceSelect('update');
        }
      });

      // insert default values for required fields if hidden
      var $submitButton = $('.scfSubmitButton');
      if (formHasErrors) {
        var topErrorMessage = $('<p>Your submission has encountered a problem. Please scroll down to review</p>');
        var sectionContent = $('.scfSectionContent');
        sectionContent.before(topErrorMessage);
        topErrorMessage.addClass('errormessage');
        topErrorMessage.css('text-align', 'center');
        var errorMessage = $submitButton.parent().append('<p>Please fix required fields</p>');
        errorMessage.addClass('errormessage');
      }

      // move footer message after button
      var $footer = $('.scfFooterBorder');
      $submitButton.parent().after($footer);
      $submitButton.on('click', function () {
        var selectedRadio = $('.hideShowHCPInfo input[type="radio"]:checked');
        $HCPSelectedClinicInput.attr('disabled', false); // Apparently if a field is required it validates as empty if it is disabled.

        // if (selectedRadio.val() !== 'Yes') {
        if (!$('.hideShowHCPInfo input[type="checkbox"]').prop('checked')) {
          var $HCPNameInput = $('.HCPName .scfSingleLineTextBox');
          var $HCPCityInput = $('.HCPCity .scfSingleLineTextBox');
          var $HCPCompanyInput = $('.HCPCompany .scfSingleLineTextBox');
          if ($HCPNameInput.val() === '') {
            $HCPNameInput.val('NA');
          }
          if ($HCPCityInput.val() === '') $HCPCityInput.val('NA');
          if ($HCPCompanyInput.val() === '') $HCPCompanyInput.val('NA');
        }
      });
    } // if end

    // Functionality for our Thank you page.
    if ($('.component.rich-text.form-opn-s-rft-map')[0]) {
      var pathAText = $('.pathA');
      var pathBText = $('.pathB');
      var clinicInfoBox = $('.clinicInfoBox');
      var locationInfo = sessionStorage.getItem('locationInfo');
      var location = {};
      if (locationInfo) {
        try {
          location = JSON.parse(locationInfo);
        } catch (e) {
          console.error('Error while getting location data.');
        }

        // hide pathBText
        pathBText.addClass('doNotShow');
        var clinicInfoText = "<p class=\"location-info\">".concat(location.Name, " <br /> ").concat(location.Address, ", ").concat(location.City, ", ").concat(location.Region, ", ").concat(location.PostalCode, "<br /><a href=\"tel:").concat(location.Phone, "\">").concat(location.Phone, "</a></p>");
        clinicInfoBox.html(clinicInfoText);
      } else {
        pathAText.addClass('doNotShow');
      }
    }
  });
})(jQuery);