"use strict";

var DGS = window.DGS || {};
DGS.B2B = DGS.B2B || {};
DGS.B2B.ShoppingCart = function ($) {
  return {
    /**
     * Object with the API endpoints needed for the cart
     */
    endPoints: {
      getCart: "/webservices/payment.svc/getcart",
      saveCart: "/webservices/payment.svc/savecart"
    },
    // This are the properties of the cart mapped to the backend
    type: "",
    invoices: [],
    groups: [],
    otherAmount: 0,
    totalAmount: 0,
    // This should not be modifiable externally

    /**
     * Does am ajax call to get the current cart values and fills the "this" properties with them.
     *
     * @param callback Function to be called when the ajax call is done. It also calls on failure with parameter false
     */
    getCart: function getCart(callback) {
      var _this = this; // Need to do this for nested functions
      $.ajax({
        type: 'GET',
        url: this.endPoints.getCart
      }).then(function (data) {
        // Populate the HTML for the summary
        _this.type = data.type;
        _this.invoices = data.invoices;
        _this.groups = data.groups;
        _this.otherAmount = data.otherAmount;
        _this.totalAmount = data.totalAmount; // Here we can do a check to see if total amount = to the sum of other amounts
        callback(data);
      }, function (jqXHR, textState, msg) {
        callback(false);
      });
    },
    /**
     * Does an ajax call to save the current properties of the cart.
     *
     * @param callback Function called after saving the cart. It passes a boolean parameter with FALSE if there was
     *                 an issue on the operation
     */
    saveCart: function saveCart(callback) {
      $.ajax({
        type: 'POST',
        url: this.endPoints.saveCart,
        // data: JSON.stringify( data )
        data: JSON.stringify({
          type: this.type,
          invoices: this.invoices,
          groups: this.groups,
          otherAmount: this.otherAmount,
          totalAmount: this.totalAmount.toFixed(2)
        })
      }).then(function (result) {
        callback(result);
      }, function () {
        callback(false);
      });
    },
    /**
     * Removes an invoice from the cart and reduces the totalAmount by the invoice amount.
     *
     * @param invoiceId   Id of the invoice to remove
     * @returns {boolean} Returns false if the invoice could not be removed, otherwise true
     */
    removeInvoice: function removeInvoice(invoiceId) {
      var removedInvoice = null,
        newInvoices = this.invoices.filter(function (invoice) {
          if (invoice.i !== invoiceId) {
            return true;
          } else {
            removedInvoice = invoice;
            return false;
          }
        });

      // This is to check that we have remove exactly one element from the invoices array
      if (removedInvoice !== null && newInvoices.length === this.invoices.length - 1) {
        this.totalAmount = this.subtractAmount(this.totalAmount, removedInvoice.a);
        this.invoices = newInvoices;
        return true;
      } else {
        // This means it could not remove the invoice
        return false;
      }
    },
    /**
     * Add a new invoice to the cart
     *
     * @param newInvoice A small object containing invoice information
     */
    addInvoice: function addInvoice(newInvoice) {
      this.invoices.push(newInvoice);
      this.totalAmount = this.addAmount(this.totalAmount, newInvoice.a);
    },
    /**
     * Removes a group from the shopping cart. Notice that this only makes sense if the this.type is "balance"
     *
     * @param groupIndex  Index of the group to remove
     * @returns {boolean} Returns false if the group could not be removed, otherwise true.
     */
    removeGroup: function removeGroup(groupIndex) {
      var groupToRemove = this.groups.find(function (group) {
        return group.groupIndex === groupIndex;
      });
      if (groupToRemove !== null && groupToRemove !== "") {
        // Remove all invoices that belong to the group
        var _this = this;
        groupToRemove.invoices.forEach(function (invoice) {
          _this.removeInvoice(invoice);
        });

        // Finally, remove the group from the list
        this.groups = this.groups.filter(function (group) {
          return group.groupIndex !== groupIndex;
        });
        return true;
      } else {
        // Error removing the group
        return false;
      }
    },
    /**
     * Clears the cart
     */
    clearCart: function clearCart() {
      this.type = "";
      this.invoices = [];
      this.groups = [];
      this.otherAmount = 0;
      this.totalAmount = 0;
    },
    /*********************** Setters for the properties/values of the cart *******************************/

    /**
     * Sets the [type] property of the cart and validates that is one of the possible values it can be.
     * NOTE: This function does not saves the cart on the back-end. Call saveCart manually to do it
     *
     * @param  newType string String of the type of cart which can be "balance" OR "invoice"
     * @returns {boolean} Returns true if the value could be set, or false if not.
     */
    setType: function setType(newType) {
      if (newType === "balance" || newType === "invoice") {
        // We can add this to an array of supported cart types and do a inArray()
        this.type = newType;
        return true;
      } else {
        // newType only supported types allowed
        return false;
      }
    },
    /**
     * Sets the [otherAmount] property of the cart and validates that is a positive value. Updates the [totalAmount]
     * accordingly
     * NOTE: This function does not saves the cart on the back-end. Call saveCart manually to do it
     *
     * @param  newAmount int Number of the new value for the [otherAmount] property. Value 0 means that there is no
     *                       [otherAmount] set.
     * @returns {boolean} Returns true if the value could be set, or false if not.
     */
    setOtherAmount: function setOtherAmount(newAmount) {
      if (newAmount >= 0) {
        this.totalAmount = newAmount;
        this.otherAmount = newAmount;
        // Here we can call a function like "checkAmounts" that calculates that all of the amounts sum to the total Amount
        return true;
      }

      // otherAmount needs to be positive
      return false;
    },
    /**
     * function to add to currency values to prevent the floating point precision error.
     *
     * @param {number} addendOne
     * @param {number} addendTwo
     * @returns {number}
     */
    addAmount: function addAmount(addendOne, addendTwo) {
      var sum = addendOne + addendTwo;
      return Number.parseFloat(sum.toFixed(2));
    },
    /**
     * function to subtract two currency values to prevent the floating point precision error.
     *
     * @param {number} minuend
     * @param {number} subtrahend
     * @returns {number}
     */
    subtractAmount: function subtractAmount(minuend, subtrahend) {
      var difference = minuend - subtrahend;
      return Number.parseFloat(difference.toFixed(2));
    }
  };
}(jQuery);