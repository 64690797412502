import { WDHAccordionSpot } from "./../../../../../../../wdh-feature/wdh-accordion-spot";
DGS.OnLoad.getInstance().register(function () {
  var wdhAccordionSpotTabs = new WDHAccordionSpot('.accordion-spot:not(.tabs)');
  wdhAccordionSpotTabs.run();
  if (document.querySelector('.accordion-spot-with-images')) {
    document.querySelectorAll('.accordion-spot-with-images .accordion-spot-element').forEach(function (element, index) {
      var url = element.querySelector('.accordion-spot-element__body img') ? element.querySelector('.accordion-spot-element__body img').getAttribute('src') : 'https://cdn.dgs.com/oticon/demos/product%20tool/19.2/en-us//Assets/en-US/Tools/PT/styles/style_zoom/xceed/Product_Zoom_Xceed_BTE_SP_Left_C090ChromaBeige_Hook_665x625px.png';
      var title = element.querySelector('.accordion-spot-element__title').innerText;
      element.querySelector('.accordion-spot-element__title').innerHTML = "<img src=\"".concat(url, "\" alt=\"").concat(title, "\"/> ").concat(title);
    });
  }
  ;
});