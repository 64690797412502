import React from 'react';
// import { initialState, initialDictionary} from './constans';

// import {
//   PEPAccessoriesList,
//   PEPHearingInstrumentList,
//   PEPProductDetail,
//   PEPLabels
// } from "@demant/product-experience-page";
//

export var isDataLayer = function isDataLayer() {
  return window.DGS && window.DGS.GTMTracking && window.DGS.GTMTracking.getDataLayer().length;
};
export var sendCustomEvent = function sendCustomEvent(eventAction, eventLabel, element) {
  return {
    event: 'GAevent',
    eventCategory: 'PEP',
    eventAction: eventAction,
    eventLabel: eventLabel,
    eventCallback: function eventCallback(gtm) {
      if (element && gtm === 'GTM-NSW8N73') {
        window.open(element.href, element.target ? element.target : '_self');
      }
    }
  };
};
export var sendDataLayerEvent = function sendDataLayerEvent(action, label, element) {
  if (isDataLayer()) {
    if (element) {
      element.preventDefault();
    }
    window.DGS.GTMTracking.getDataLayer().push(sendCustomEvent(action, label, element && element.currentTarget ? element.currentTarget : null));
  }
};

// export const fetchFactory = (action) => {
//   let PEPComponent;
//   if(action === initialState.pepDetailsComponentName) {
//     PEPComponent = new PEPProductDetail(action);
//   }
//   if(action === initialState.pepListComponentName) {
//     PEPComponent = new PEPHearingInstrumentList(action, false);
//   }
//   if(action === initialState.pepAccListComponentName) {
//     PEPComponent = new PEPAccessoriesList(action);
//   }
//   return PEPComponent;
// };

// export const sessionStorageFactory = (action, name, data) => {
//   switch (action) {
//     case 'setItem':
//       window.sessionStorage.setItem(name, JSON.stringify(data));
//       return JSON.parse(sessionStorage.getItem(name));
//     case 'getItem':
//       return JSON.parse(sessionStorage.getItem(name));
//     case 'checkStorageItem':
//       return sessionStorage.getItem(name);
//     case 'checkLength':
//       return sessionStorageFactory('checkStorageItem', name) ? Object.keys(JSON.parse(sessionStorage.getItem(name))).length || JSON.parse(sessionStorage.getItem(name)).length : 0;
//     default:
//       return false;
//   }
// };

// export const getDictionaryItems = (component) => {
//   const dictionary = initialDictionary;
//   const pepLabels = new PEPLabels(component);
//   const labels = pepLabels.labels;
//   const isProfessional = window.location.href.indexOf(`/${initialState.professionalKey.toLowerCase()}`) > 1;
//   let newDictionary = {};
//   Object.keys(dictionary).map((val) => {
//       const professionalKey = val + initialState.professionalKey;
//       const key = isProfessional && labels[professionalKey]  ? professionalKey : val;
//       if (typeof(dictionary[key]) != "object" ) {
//         return labels[key] ? newDictionary[val] = labels[key] : newDictionary[val] = dictionary[key];
//       }
//       else {
//         return newDictionary[val] = dictionary[key];
//       }
//     });
//   return newDictionary
// };

// export const importerExternalScripts = {
//   url: (url) => {
//     return new Promise((resolve, reject) => {
//       let script = document.createElement('script');
//       script.type = 'text/javascript';
//       script.src = url;
//       script.addEventListener('load', () => resolve(script), false);
//       script.addEventListener('error', () => reject(script), false);
//       document.body.appendChild(script);
//     });
//   },
//   urls: (urls) => {
//     return Promise.all(urls.map(importerExternalScripts.url));
//   }
// };

// export const getProductId = (prod) => {
//   return prod.productId
// };
//
// export const checkIsMobile = () => {
//   return (window.innerWidth < parseFloat(initialDictionary.mobileBreakpoint))
// };
//
//
// export const createActiveFilters = (filters) => {
//   let activeFiltersLabel = [];
//   Object.keys(filters).forEach((filterValue) => {
//     filters[filterValue].values.forEach((label) => {
//       activeFiltersLabel.push({
//         value: label,
//         dataKey: filterValue
//       });
//     })
//   });
//   return activeFiltersLabel
// };
//
// export const toggleLayerFunction = (isOpen, customClass) => {
//   customClass = customClass ? customClass : 'block-scroll';
//   isOpen ? document.querySelector('body').classList.add(customClass) :  document.querySelector('body').classList.remove(customClass);
//   return isOpen;
// };
// export const dotsBackgroundColor = (color) => {
//   if (color.hex && color.secondaryHex) {
//     return `linear-gradient(to right, ${color.hex} 50%, ${color.secondaryHex} 50%)`
//   } else {
//     return color.hex || color.secondaryHex
//   }
// }
//
// export const checkIfLifestyleImg = (imgUrl) => {
//   return imgUrl.includes('Still_Life') || imgUrl.includes('Still_life');
// }