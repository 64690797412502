import React from 'react';
require('../../react/feature/wdh-mybusiness');

// TODO: remove once actual component has been built in Sitecore. This is for building/testing only
// quick code to pull our component markup out of the parent rich-text
// simulates having the component without having a component built in Sitecore. :)
$(document).ready(function () {
  var comp = $('.account-overview-summary-spot');
  var pagecontentdiv = comp.parent().parent().parent();
  pagecontentdiv.append(comp);
});
// end TODO