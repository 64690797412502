(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    // TODO: ensure that all form field types and applicable styles work as we would like.
    /**
     * common function to add label text to placeholder attribute
     * @param group
     */
    function placeLabelAsPlaceholder(group) {
      group.each(function (index, element) {
        var label = $(element).find('label').text();
        $(element).find('input').attr('placeholder', label);
      });
    }
    function addMoreFieldsFunctionality() {
      // select the first element in each contiguous block with our class
      var blocks = $(':not(.add-more-fields) + .add-more-fields, * > .add-more-fields:first-of-type');
      blocks.each(function (index, elem) {
        var siblings = $(elem).nextUntil(':not(.add-more-fields)');
        siblings.addClass('hidden');
        var group = siblings.addBack();
        group.wrapAll("<div class='add-more-group' />");
        var parent = group.parent();
        var buttonContainer = $('<div />');
        buttonContainer.addClass('button-container');
        var button = $('<div>Add Another</div>');
        button.addClass('button add-more-button');
        button.on('click', function (e) {
          e.preventDefault();
          var nextField = $('.hidden:first', parent);
          nextField.removeClass('hidden');
          if (!nextField.next('.hidden').length) {
            button.hide();
          }
        });
        buttonContainer.append(button);
        parent.append(buttonContainer);
      });
    }
    function formatPhoneWithDashes() {
      var phoneField = $('.basic-form-wrapper .scfTelephoneBorder input');
      phoneField.on('keypress', function (e) {
        var target = $(e.currentTarget);
        if (e.key !== 8 && e.key !== 9) {
          if (target.val().length === 3) {
            target.val('(' + target.val() + ') ');
          }
          if (target.val().length === 9) {
            target.val(target.val() + '-');
          }
        }
        var re = /[0-9]{3}-[0-9]{3}-[0-9]{4}/;
        if (e.key.match(/[^0-9]/) || target.val().length >= 14) {
          e.preventDefault();
        }
        return target.val().match(re);
      });

      // capture auto-fill and format number as best we can
      phoneField.on('change', function (e) {
        var target = $(e.currentTarget);
        var val = target.val();
        val = val.replace(/[^0-9]/g, '');
        if (val === '') {
          target.val('');
          return;
        }
        val = "(".concat(val.slice(0, 3), ") ").concat(val.slice(3, 6), "-").concat(val.slice(6));
        target.val(val);
      });
    }
    if ($('.component.basic-form-wrapper')[0]) {
      /* Form Placeholder */
      var myForm = $('.component.form').wffm({
        niceSelectClass: 'square-corners',
        // make nice-select like rest of the wffm
        moveAsteriskToLabel: true
      });

      // group and add a button for groups that would like additional elements shown
      addMoreFieldsFunctionality();

      // add dashes to phone numbers in the phone number field automatically
      formatPhoneWithDashes();

      // place label into first option of a drop down list if the useLAbelAs Placeholder class is present.
      // Will have to manually add this to the form field <CssClass>UseLabelAsPlaceholder</CssClass>
      var $dropDown = $('.useLabelAsPlaceholder');
      $dropDown.each(function (index, element) {
        var $elem = $(element);
        var $dropDownSelect = $elem.find('select');
        var $dropDownLabel = $elem.find('label').text();
        $elem.find('select option:first').append($dropDownLabel);
        $dropDownSelect.addClass('square-corners');
        $dropDownSelect.niceSelect('update');
      });
      var fieldWrapper = '.component.basic-form-wrapper';
      var inputBorders = '.scfSingleLineTextBorder, .scfNumberBorder, .scfSmsTelephoneBorder, .scfEmailBorder, .scfTelephoneBorder, .thirdAvailableWidth, .halfAvailableWidth, .scfPasswordBorder';

      // add label text to text input placeholder attribute.
      placeLabelAsPlaceholder($(inputBorders, fieldWrapper));

      // if the field has the UsefulInformation text set, show a help dialog box at the end of the field.
      // Textfields only at this time.
      var usefulInfo = $("".concat(fieldWrapper, " [class$=\"UsefulInfo\"]"));
      usefulInfo.each(function (index, elem) {
        var $elem = $(elem);
        if ($elem.text() !== '') {
          var $parent = $elem.parent();
          var $helpIcon = $('<span class="help-icon"> </span>');
          $helpIcon.insertAfter($('input', $parent));
          $helpIcon.on('mouseover', function () {
            $elem.css('display', 'block');
            $elem.css('opacity', 1);
          });
          $helpIcon.on('mouseout', function () {
            $elem.css('display', 'block');
            $elem.css('opacity', 0);
          });
          $parent.find('input').css('width', 'calc(100% - 1.5em)');
        }
      });

      // add error message to droplist form component if empty.
      myForm.scfValidatorRequired().each(function (index, elem) {
        var $elem = $(elem);
        var errMsg = $elem.attr('title');
        var $elemMainParent = $elem.closest('div');
        var $dd = $('.scfDropListGeneralPanel', $elemMainParent);
        $dd.append("<span class=\"scfValidator\">".concat(errMsg, "</span>"));
      });

      // Check if the parent div has the class 'removeExtraLabel'- added for custom purchase date on rebate form
      myForm.scfValidatorRequired().each(function (index, elem) {
        var $elem = $(elem);
        var $elemMainParent = $elem.closest('div');
        if ($elemMainParent.hasClass('removeExtraLabel')) {
          var errMsg = $elem.attr('title');
          var $dd = $('.scfDatePickerGeneralPanel', $elemMainParent);
          $dd.append("<span class=\"scfValidator\">".concat(errMsg, "</span>"));
          var $datePickerInput = $dd.find('.scfDatePickerTextBox');
          if ($datePickerInput.length > 0) {
            $datePickerInput.attr('placeholder', 'Purchase Date*');
          }
        }
      });
    }
  });
})(jQuery);