import { trackFormSubmit } from './tracking-gtm-ga4';
(function ($) {
  /**
   * Common form scripts.
   *
   * Grab UTM params from URL
   * Persist Data between forms
   *
   */
  window.DGS.OnLoad.getInstance().register(function () {
    /**
     * On Page Load, places data from the SessionStorage variables into a form.
     * On Form Submit, we grab the values in our form and place them into the SessionStorage for use in a later form.
     */
    var persistForm = $('.component.form.persist-form-data');
    if (persistForm[0]) {
      // add values to form
      var fields = $('[class*="name."]', persistForm);
      fields.each(function (index, field) {
        var $field = $(field);
        var className = $field.attr('class').match(/name\.[^\s]*/g)[0];
        var $input = $('input', $field);
        if (sessionStorage.getItem(className) !== '') {
          switch ($input.attr('type')) {
            case 'checkbox':
              $input.prop('checked', sessionStorage.getItem(className) === 'true');
              break;
            case 'text':
              $input.attr('value', sessionStorage.getItem(className));
              break;
            default:
              break;
          }
        }
      });

      // capture field values on submit
      $('#form').on('submit', function () {
        fields.each(function (index, field) {
          var $field = $(field);
          var className = $field.attr('class').match(/name\.[^\s]*/g)[0];
          var fieldVal = '';
          var $input = $('input', field);
          switch ($input.attr('type')) {
            case 'checkbox':
              fieldVal = $input.prop('checked');
              break;
            default:
              fieldVal = $input.val();
              break;
          }
          sessionStorage.setItem(className, fieldVal);
        });
      });
    }

    /**
     * grabs the UTM parameters from the URL string and places them into our form.  runs after above code because URL params should trump our session vars.
     */
    var utmForm = $('.component.form.getUTMParams');
    if (utmForm[0]) {
      // Hidden Field Values from URL
      var urlString = window.location.href;
      var url = new URL(urlString);
      var utmCampaign = url.searchParams.get('utm_campaign');
      var utmContent = url.searchParams.get('utm_content');
      var utmMedium = url.searchParams.get('utm_medium');
      var utmSource = url.searchParams.get('utm_source');
      $('.scfHiddenFieldBorder', utmForm).each(function () {
        var $this = $(this);
        var $input = $('input', $this);
        if (utmCampaign.length > 0 && $this.hasClass('name.UTM+Campaign')) {
          $input.attr('value', utmCampaign);
        } else if (utmContent.length > 0 && $this.hasClass('name.UTM+Content')) {
          $input.attr('value', utmContent);
        } else if (utmMedium.length > 0 && $(this).hasClass('name.UTM+Medium')) {
          $input.attr('value', utmMedium);
        } else if (utmSource.length > 0 && $(this).hasClass('name.UTM+Source')) {
          $input.attr('value', utmSource);
        }
      });
    }
    var basicForm = $('.component.basic-form-wrapper');
    if (basicForm[0]) {
      $('#form').on('submit', function () {
        // const submitDateTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
        // IE11 adds a 'right to left mark' non-printing character (\u200E) which interferes with submission.
        var submitDateTime = new Date().toLocaleString('en-US').replace(/\u200E/g, '');
        $('.component.basic-form-wrapper .scfSectionContent > .scfHiddenFieldBorder').each(function () {
          if ($(this).hasClass('name.Form+Submission+Date')) {
            // needed date submitted as well so lets put it in here.
            $(this).find('input').val(submitDateTime);
          }
        });
      });
    }

    // set up GA4 for all form submit events
    var form = document.querySelector('.scfForm');
    if (form) {
      var path = window.location.pathname;
      document.getElementById('form').addEventListener('submit', function () {
        trackFormSubmit(path);
      });
    }
  });
})(jQuery);