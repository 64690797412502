(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    // checks for marketing consent and then adds/removes class that tells other code that iframe should be enabled/disabled.
    var handleMarketingConsent = function handleMarketingConsent() {
      var $videoPlayer = $('.wrapper .content .component.video-player');
      $videoPlayer.each(function (index, component) {
        var $iframe = $(component).find('iframe');
        if (window.CookieInformation && !window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
          $iframe.removeClass('iframe-loaded');
        } else {
          $iframe.addClass('iframe-loaded');
        }
      });
    };

    // if consent changes update our iframe player
    window.addEventListener('CookieInformationConsentGiven', function () {
      handleMarketingConsent();
    });

    // update iframe on load.
    $(document).ready(function () {
      handleMarketingConsent();
    });
  });
})(jQuery);