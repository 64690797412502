/**
 * Created by mady on 16-03-2017.
 */
!function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $('.component.warranty-ordering-spot').each(function () {
      var $spot = $(this);
      DGS.Event.getInstance().trigger(DGS.Events.SELECT_ADDED, $('select', $spot));
      /*
                  $('.custom-select-container', $spot).each(function () {
                     $(this).customSelectSetValue(0);
                  });
      */
      $('.custom-select-container', $spot).customSelectSetValue(0);
    });
  });
}(jQuery, window.DGS);
(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    $('.component .warranty-ordering-btn').each(function () {
      $(this).click(warrantyOrderClick);
      var baseWarrantyOrderURL = '/webservices/warranties.svc/orderwarranty';
      function warrantyOrderClick(event) {
        event.preventDefault();
        var resultUrl = baseWarrantyOrderURL;
        var instruments = [];
        var hasError = false;
        $('.instrument').each(function () {
          var order = {};
          order.serialNo = $('.serialNo').data('value');
          order.servicecardNo = $('.serialNo').data('servicecardno');
          order.po = $('.po')[0].value;
          if ($('.po').data('required') === 'True' && order.po === '') {
            $('.po').addClass('registration-error');
            hasError = true;
            return false;
          }
          order.medicaid = $('.medicaid')[0].value;
          var selector = $('.nice-select').find('.selected');
          order.warrantyCode = selector.data('value');
          order.warrantyItemId = selector.data('itemno');
          order.warrantyDescription = selector.data('description');
          order.price = selector.data('price');
          instruments.push(order);
        });
        if (instruments.length && !hasError) {
          var data = JSON.stringify(instruments);
          $.ajax({
            url: resultUrl,
            type: 'POST',
            data: data,
            contentType: "application/json; charset=utf-8",
            success: function success(data, textStatus, xhr) {
              if (data != null && data != '' && data.orderNumber !== '' && data.orderNumber != null) {
                var url = window.location.href.split('?')[0];
                window.location.href = url + '/warranty-confirmation?orderNo=' + data.orderNumber;
              } else {
                $('.error-message').text('Unable to complete request. Please contact us if the issue persists.');
              }
            }
          });
        }
      }
    });
    $('.component .button.warranty-ordering').each(function (e) {
      var warrantyOrderingButton = $(this);
      hideLoader(warrantyOrderingButton);
      $($(this)).on('click', function (e) {
        e.preventDefault();
        showLoader(warrantyOrderingButton);
        //  var serialNo = $(this).data('url') + '?ins=';
        var serialNo = '/mybusiness/warranties/extend-warranty/warranty-ordering?ins=';
        var number = 0;
        $('input:checkbox:checked').each(function (e) {
          if (number == 0) {
            serialNo = serialNo + $(this).offsetParent('.data-list__row__column').siblings('.serialNo').data('value');
          } else {
            serialNo = serialNo + "|" + $(this).offsetParent('.data-list__row__column').siblings('.serialNo').data('value');
          }
          number++;
        });
        if (number != 0) {
          $('.warranty-ordering-error-message').html(" ");
          window.location.href = serialNo;
        } else {
          hideLoader(warrantyOrderingButton);
          $('.warranty-ordering-error-message').html("No instruments selected.");
        }
      });
    });
    function showLoader(warrantyOrdering) {
      $(warrantyOrdering).hide();
      $(".warranty-ordering__load-container").show();
    }
    function hideLoader(warrantyOrdering) {
      $(warrantyOrdering).show();
      $(".warranty-ordering__load-container").hide();
    }
  });
})(jQuery, window.DGS);