import { TimelineMax, TweenMax, Power3 } from 'gsap';
(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    var $submenu = $('#wrapper .richtext-submenu'),
      $submenuExpander = $('.richtext-submenu .submenu-expander');
    if ($submenu.length) {
      window.DGS.WaitForLoad.getInstance().register($submenu);
    }
    if ($submenu.length) {
      $submenu.css('opacity', 0);
      window.DGS.LoadComplete.getInstance().register($submenu);
    }
    window.DGS.Resizer.getInstance().register(function () {
      var $gridRow = $('#wrapper #content > .row > .grid-12'),
        curValue = $gridRow.css('padding-top'),
        newValue,
        $languageSelector = $('#wrapper #header .component.language-selector-spot');
      if ($('#wrapper #header .nav-expander').is(":visible")) {
        newValue = $('#wrapper #header').outerHeight();
      } else {
        newValue = 0;
      }
      $gridRow.css('padding-top', newValue);
      if (parseFloat(newValue).toFixed(1) != parseFloat(curValue).toFixed(1)) {
        window.DGS.Resizer.getInstance().execute();
      }
      if ($('#header').has('search-field-spot') && !$('#header').has('link.order-batteries')) {
        $('.component.link-list.nav').css('margin-right', $languageSelector.outerWidth() + 36 + 40 + 'px');
        $languageSelector.css('margin-right', '40px');
      } else {
        if ($languageSelector.has('li').length !== 0) {
          $('.component.link-list.nav').css('margin-right', $languageSelector.outerWidth() + 36 + 40 + 'px');
          $languageSelector.css('margin-right', '50px');
        } else {
          $('.component.link-list.nav').css('margin-right', $languageSelector.outerWidth() + 36 + 'px');
          $languageSelector.css('margin-right', '0px');
        }
      }
      if ($('#header').has('link.order-batteries')) {
        $('#header .link.order-batteries').css('margin-right', $languageSelector.outerWidth() + 36 + 40 + 'px');
      }
      var $headerLogo = $('#header a.logo svg'),
        oldHeight = $headerLogo.css('height'),
        newWidth = 140 / 52 * parseInt(oldHeight);
      $headerLogo.css('width', newWidth + "px");
    });
    var languageSpot = $('#header .component.language-selector-spot'),
      $ul = $('.component-content ul', languageSpot),
      tLength = $('li', $ul).length,
      sWidth,
      urlLength = 0,
      tempThis,
      tempURL = '',
      theURL,
      mySpans = '<div class="span-wrapper"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>',
      $logoHeight,
      $header = $('#header'),
      $navLi = $('.link-list.nav li', $header);
    if (tLength > 0) {
      $ul.prepend('<div class="slider"></div>');
      sWidth = 100 / tLength;
      $('.slider', $ul).css({
        'width': sWidth + '%'
      });
      theURL = document.location.href.replace('http://', '').replace('https://', '');
      $('li', $ul).each(function () {
        tempURL = $('a', $(this)).attr('href').replace('http://', '').replace('https://', '');
        if (theURL.indexOf(tempURL) === 0 && tempURL.length > urlLength) {
          urlLength = tempURL.length;
          if (tempThis !== undefined) tempThis.removeClass('active');
          tempThis = $(this);
          $('.slider', $ul).addClass('active');
          $(this).addClass('active');
          posCAL($(this));
        }
        $(this).click(function () {
          $('.slider', $ul).addClass('active');
          $('li', $ul).removeClass('active');
          $(this).addClass('active');
          posCAL($(this));
        });
      });
    } else {
      languageSpot.css('display', 'none');
    }
    $('#header .component').each(function () {
      $(this).addClass('component-ready animate');
    });
    $('.component.link-list.nav li a').each(function () {
      var href = $(this).attr('href');
      if (window.location.pathname.indexOf(href) === 0) {
        $('.component.link-list.nav li a').removeClass('active'); // reset all so last element matching gets class set. Fixes OD-1312
        $(this).addClass('active'); // add active to this item
      }
    });
    function posCAL(theDiv) {
      var newPos = (theDiv.index() - 1) * sWidth; //Math.floor(position.left)-4;
      if (newPos <= 0) {
        newPos = 2;
      } else {
        newPos += 1;
        if (tLength == theDiv.index()) newPos -= 3;
      }
      $('.slider', $ul).css('left', newPos + '%');
    }
    $('.nav-expander').append(mySpans);
    $('.nav-expander > .component-content').remove();
    $logoHeight = $('.nav-container .component.image .component-content a img').attr('height');
    $('.nav-container').css('height', $logoHeight);
    $('.nav-expander').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('nav-expanded');
      $('#header .nav').slideToggle(200);
      if ($(this).hasClass('nav-expanded')) {
        $('.search-field-spot').hide();
      } else {
        $('.search-field-spot').show('slow');
      }
    });
    $('#header .logo-svg-oticon').each(function () {
      var $a = $(this).find('a'),
        thisHref = $a.attr('href');
      $a.replaceWith('<a class="logo" aria-label="Oticon logo link" href="' + thisHref + '"> <svg id="Layer_1" data-name="Layer 1"' + ' xmlns="http://www.w3.org/2000/svg"' + ' viewBox="197 50 450 30" style="width: 129.231px"><g class="logo-rect"><polygon' + '  points="343.36 34.41 363.22 34.41 363.22 15' + ' 343.36 18.49 343.36' + ' 34.41 343.36 34.41"/></g><g class="logo-type"><g class="logo-type-oticon"><path  d="M415.64,43.68a39.47,39.47,0,1,0,28.12,67.12L430.85,97.88a21.21,21.21,0,1,1,0-29.47L443.76,55.5a39.14,39.14,0,0,0-28.12-11.82Z"/><path  d="M236.46,104.36a21.21,21.21,0,1,1,21.21-21.21,21.23,21.23,0,0,1-21.21,21.21Zm0-60.68a39.47,39.47,0,1,0,39.47,39.47,39.51,39.51,0,0,0-39.47-39.47Z"/><path  d="M288.55,28.21V93.69c0,26.14,22.32,29,31.91,29a35.84,35.84,0,0,0,5.87-.45l.44-.08V105h-3.93c-13,0-14.42-9.57-14.42-13.69V63.43h18.35V46.37H308.41V24.72l-19.86,3.49Z"/><path  d="M488.32,104.36a21.21,21.21,0,1,1,21.21-21.21,21.24,21.24,0,0,1-21.21,21.21Zm0-60.68a39.47,39.47,0,1,0,39.46,39.47,39.51,39.51,0,0,0-39.46-39.47Z"/><polygon  points="343.36 121.79 363.22 121.79 363.22 55.18 363.22 46.37 343.36 46.37 343.36 121.79 343.36 121.79"/><path  d="M593.31,121.79h19.86V72.22a25.87,25.87,0,0,0-25.84-25.85H540.62v75.42h19.86V63.43h21.86a11,11,0,0,1,11,11v47.38Z"/></g></g></svg></a>');
    });
    var tl,
      tlWait,
      index = 0;
    tlWait = new TimelineMax({
      repeat: -1
    });
    tlWait.call(function () {
      var $components = $('#wrapper #content .component');
      if ($components.length) {
        $components.each(function () {
          if (!$(this).hasClass('richtext-submenu') && !$(this).hasClass('submenu-point-spot') && $(this).hasClass('animate')) {
            tlWait.pause();
            tlWait.kill();
            tl.play();
          }
        });
      } else {
        tlWait.pause();
        tlWait.kill();
        tl.play();
      }
    }, null, null, "+=0.1");
    tl = new TimelineMax({
      paused: true
    });
    tl.set($navLi, {
      top: -100
    }).call(function () {
      $('a', $navLi).addClass('active');
      $header.addClass('animate');
    }).staggerTo($navLi, 0.4, {
      top: 0,
      ease: Power3.easeOut
    }, 0.1, "+=1");
    if ($('.search-field-spot').length) {
      tl.to($('.search-field-spot'), 0.4, {
        opacity: 1
      });
    }
    if ($submenu.length) {
      tl.to($submenu, 0.4, {
        opacity: 1
      });
    }
    $navLi.each(function () {
      var $this = $(this),
        delay = "+=" + (0.1 * index - 0.3);
      tl.call(function () {
        $('a', $this).removeClass('active');
      }, null, null, delay);
      index++;
    });
    tl.call(function () {
      var $tmpA,
        tmpurl = '';
      $navLi.each(function () {
        var $a = jQuery('a', jQuery(this)),
          href = $a.attr('href') !== undefined ? $a.attr('href') : '',
          sIndex = href.length,
          section = href.substr(0, sIndex - 1),
          location = window.location.href;
        if (location.indexOf(section) > -1) {
          if (href.length >= tmpurl.length) {
            tmpurl = href;
            $tmpA = $a;
          }
        }
      });
      if ($tmpA && $tmpA.length) $tmpA.addClass('active');
    }, null, null, "+=1");
    if ($submenu.length) {
      tl.call(function () {
        $submenuExpander.addClass('corporate');
      }, null, null);
      tl.call(function () {
        $submenuExpander.removeClass('corporate');
      }, null, null, "+=1.2");
      tl.add(TweenMax.to($submenuExpander, 3, {
        repeat: 3,
        onRepeat: function onRepeat() {
          //$submenuExpander.toggleClass('rotate360');
          //var classes = ['rotate45', 'rotate90', 'rotate135', 'rotate180', 'rotate225', 'rotate270'],
          //var classes = ['rotate90', 'rotate180', 'rotate270', 'rotate360'],
          var classes = ['rotate360', ''],
            i,
            hasClass = false;
          if (!$submenuExpander.closest('.component.richtext-submenu').hasClass('expanded')) {
            for (i = 0; i < classes.length; i++) {
              if (!hasClass && $submenuExpander.hasClass(classes[i])) {
                hasClass = true;
                $submenuExpander.removeClass(classes[i]);
                if (i < classes.length - 1) {
                  $submenuExpander.addClass(classes[i + 1]);
                  break;
                }
              }
            }
            if (!hasClass) {
              $submenuExpander.addClass(classes[0]);
            }
          } else {
            $submenuExpander.removeClass(classes[0]);
            $submenuExpander.removeClass(classes[1]);
            $submenuExpander.removeClass(classes[2]);
            $submenuExpander.removeClass(classes[3]);
            $submenuExpander.removeClass(classes[4]);
            $submenuExpander.removeClass(classes[5]);
          }
        }
      }));
    }
  });
  window.DGS.OnLoad.getInstance().register(function () {
    $('#wrapper .once-per-session.popup').each(function () {
      var $this = $(this),
        cookie = window.DGS.Cookies.getInstance().readCookie("italian-medical-alert");
      if (!cookie) {
        $this.addClass('popup-show');
        $('.popup-close', $this).click(function () {
          window.DGS.Cookies.getInstance().createCookie("italian-medical-alert");
          $this.removeClass('popup-show');
        });
      }
    });
  });

  // ****  Redesign Navigation for B2b My Business ***** //
  window.DGS.OnLoad.getInstance().register(function () {
    var parentID = '.navigation-redesign';
    var $siblingsID = '.navigation-redesign-submenu-item';
    var isTouch = 'ontouchstart' in document.documentElement;
    if ($('.navigation-redesign').length) {
      // set up our click handler
      $('.nav-expander').on('click', function (e) {
        if ($(e.currentTarget).hasClass('nav-expanded')) {
          $('.search-field-spot').show().addClass('search-open');
        } else {
          $('.search-field-spot').hide().removeClass('search-open');
        }
      });

      // setup and add our submenus to the main menu
      $('.component.link-list.navigation-redesign li').each(function (index, elem) {
        var $elem = $(elem);
        var $currentSubmenu = $elem.parents(parentID).siblings($siblingsID + index);
        $elem.append($currentSubmenu);

        // remove links from 1st level menu if on mobile device. prevents navigation when trying to expand a menu
        if (isTouch) {
          $elem.addClass('is-mobile');
          if ($elem.hasClass("item".concat(index))) {
            $elem.find('>a').removeAttr('href');
          }
        }

        // remove links from restricted items (based on style names)
        if ($(parentID).hasClass('remove-restricted-links')) {
          $elem.find('a.restricted-link').removeAttr('href');
        }

        // remove &nbsp; added from the global code to allow flexbox to work.
        $('.richtext-submenu-columns', $elem).html(function (i, html) {
          return html.replace(/&nbsp;/g, '');
        });

        // hover (desktop only)
        var myInTimer;
        var myOutTimer;
        $elem.hover(function (e) {
          // mouseover
          if ($(parentID).hasClass('mobile-only-nav')) {
            return;
          }

          // introduce a slight delay to prevent unintentional menu hovers.
          myInTimer = window.setTimeout(function () {
            window.clearTimeout(myOutTimer);
            var $this = $(e.currentTarget);
            $this.find('a').removeClass('active');
            $this.find('> a').addClass('menu-open');
            $currentSubmenu.each(function (smIndex, smElem) {
              var $smElem = $(smElem);
              $smElem.addClass('expanded').show();
              $smElem.find('.richtext-submenu-columns').addClass('active');
              $smElem.css('top', 'unset');
            });
            $('.desktop-only-nav .richtext-submenu.expanded').css('margin-top', -($(window).scrollTop() - 3));
          }, 200);
        }, function (e) {
          // mouseout
          window.clearTimeout(myInTimer);
          if ($(parentID).hasClass('mobile-only-nav')) {
            return;
          }

          // Introduce a slight delay to menu closing.  Allows users to cut across menu to access submenu items easier.
          myOutTimer = window.setTimeout(function () {
            var $this = $(e.currentTarget);
            $this.find('a').removeClass('active');
            $this.find('> a').removeClass('menu-open');
            $currentSubmenu.each(function (smIndex, smElem) {
              var $smElem = $(smElem);
              $smElem.removeClass('expanded').hide();
              $smElem.find('.richtext-submenu-columns').removeClass('active');
              $smElem.css('top', 'unset');
              $smElem.css('margin-top', '0');
            });
          }, 350);
        });

        // add click handler to our close button (touch only)
        // $('richtext-submenu-columns.active .desktop-close').off('click');
        $('.richtext-submenu-columns .desktop-close').on('click', function (evt) {
          var $this = $(evt.currentTarget);
          $this.find('a').removeClass('active');
          $this.find('> a').removeClass('menu-open');
          $currentSubmenu.each(function (smIndex, smElem) {
            var $smElem = $(smElem);
            $smElem.removeClass('expanded').hide();
            $smElem.find('.richtext-submenu-columns').removeClass('active');
            $smElem.css('top', 'unset');
            $smElem.css('margin-top', '0');
          });
        });

        // keep menu attached to parent menuitem while scrolling on desktop
        $(window).scroll(function () {
          $('.desktop-only-nav .richtext-submenu.expanded').css('margin-top', -($(window).scrollTop() - 3));
        });

        // Click (mobile only)
        $elem.click(function (e) {
          if ($(parentID).hasClass('desktop-only-nav')) {
            return;
          }
          var $childSubmenu = $elem.find($siblingsID + index);
          $childSubmenu.css('top', -$(e.currentTarget).offset().top);
          $childSubmenu.addClass('expanded showMenu').show();
          $childSubmenu.find('.richtext-submenu-columns').addClass('active');
          $('.mobile-close', $elem).off('click'); // remove old click handler to prevent memory leak.
          $elem.find('.mobile-close').click(function (evt) {
            $childSubmenu.removeClass('expanded showMenu').hide();
            $childSubmenu.find('.richtext-submenu-columns').removeClass('active');
            evt.stopPropagation();
          });
        });
      });
    }
    var deskTopNavigationOnly = function deskTopNavigationOnly() {
      if ($('.navigation-redesign-header.desktop')[0]) {
        $('.search-field-spot').removeClass('search-open');
        $('.richtext-submenu').css('top', 'unset');
      }
      $('.mobile-unlink').each(function (index, elem) {
        var $elem = $(elem);

        // remove event from mobile view if set.
        $elem.find('>a').off('click');
      });
    };
    var mobileNavigationOnly = function mobileNavigationOnly() {
      if ($('.navigation-redesign-header.mobile')[0]) {
        // remove subheading collapse code.  interfering with our menu.
        $('.richtext-submenu').each(function (index, elem) {
          var $this = $(elem);
          $('.richtext-submenu-column h3', $this).off('click');
        });

        // remove links from mobile menu to prevent accidental navigation
        $('.component.link-list.navigation-redesign li').each(function (index, elem) {
          var $elem = $(elem);

          // remove links from 1st level menu if on mobile device. prevents navigation when trying to expand a menu
          if ($elem.hasClass("item".concat(index)) && !$elem.hasClass('mobile-unlink')) {
            $elem.find('>a').on('click', function (e) {
              $elem.addClass('mobile-unlink');
              e.preventDefault();
            });
          }
        });
      }
    };
    var navigationBasedOnMediaQuery = function navigationBasedOnMediaQuery() {
      if (window.matchMedia('(max-width: 1023px)').matches) {
        if ($(parentID)[0]) {
          $(parentID).parents('#header').addClass('navigation-redesign-header mobile').removeClass('desktop');
          $(parentID).addClass('mobile-only-nav').removeClass('desktop-only-nav');
        }
        mobileNavigationOnly();
      } else {
        if ($(parentID)[0]) {
          $(parentID).parents('#header').addClass('navigation-redesign-header desktop').removeClass('mobile');
          $(parentID).addClass('desktop-only-nav').removeClass('mobile-only-nav');
        }
        deskTopNavigationOnly();
      }
      // replace with new logo
      $('.navigation-redesign-header .component.logo-svg-oticon').find('a').addClass('b2b-logo').text('').attr('href', '/myoticon');
    };
    navigationBasedOnMediaQuery();
    window.DGS.Resizer.getInstance().register(function () {
      navigationBasedOnMediaQuery();
    });
  });
})(jQuery);