require('core-js');require('regenerator-runtime/runtime');// FOUNDATION
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
window.shopInitReady = false;
window.moment = require('moment');
Array.prototype.flat;
require('@demant/legacy/custom-scroll');
require('../../../wdh-foundation/dateformat/dateformat.js');
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('./dgs-b2b/dgs-shopping-cart.js'); // MyO - payments
require('../../../wdh-foundation/dgs-sharing/dgs-sharing.js');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('iframe-resizer');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('@demant/legacy/lunametrics'); // used to track youtube video usage.  don't think this is used any longer  DEPRECATED

// FEATURE
require('../../../wdh-feature/wdh-wffm/src/js/webforms-for-marketers-api.js');
require('./feature/component-batch-printing.js'); // not currently used and likely to be deprecated in favour for react component
require('./feature/component-color-selector-spot.js');
require('./feature/component-confirm-payment'); // MyO
require('./feature/component-contact-information.js');
require('./feature/component-cookies.js');
require('./feature/component-create-account-spot.js'); // MyO
require('./feature/component-data-hierarchy.js');
require('./feature/component-data-hierarchy-appframe-component.js');
require('./feature/component-data-hierarchy-hubspot.js'); // not used and missing library -- DELETE?
require('./feature/component-data-hierarchy-multi-step.js');
require('./feature/component-data-hierarchy-dataset.js');
require('./feature/custom-component-data-list-spot.js');
require('./feature/component-data-list-spot.js');
require('./feature/component-iframe.js');
require('./feature/component-intro-banner.js');
require('./feature/component-job-list-spot.js');
require('./feature/component-lattice-spot.js');
require('./feature/component-leaving-notification.js');
require('./feature/component-link-list.js');
require('./feature/component-news-list-spot.js');
require('./feature/component-option-spot.js');
require('./feature/component-richtext-submenu.js');
require('./feature/component-search.js');
require('./feature/component-submenu.js');
require('./feature/component-text-image-spot.js');
require('./feature/component-tile.js');
require('./feature/wdh-image-spot');
require('./feature/component-video-spot.js');
require('./feature/component-warranty-registration-spot.js');
require('./feature/component-alternative-url.js');
require('./feature/component-box-spot.js');
require('./feature/component-data-hierarchy-data-list-search.js');
require('./feature/component-data-hierarchy-warranty-ordering.js');
require('./feature/component-instrument-details.js');
require('./feature/component-profile-spot.js'); // MYO
require('./feature/registration.js'); // MYO
require('./feature/component-screening-test.js');
require('./feature/component-select-payment-method.js');
require('./feature/component-payment-confirmation.js');
require('./feature/component-tagged-list-spot.js');
require('./feature/component-warranty-ordering-spot.js');
require('./feature/component-data-hierarchy-schedule-pickup.js');
require('./feature/component-form.js');
require('./feature/component-richtext-submenu.js');
require('./feature/wdh-product-order');
require('./feature/wdh-rich-text');
require('./feature/blog/index');

// new component
require('./feature/wdh-carousel');
require('./feature/wdh-tabbed-data-list');

// Shop / MyO

require('./feature/wdh-feature-b2b');
require('./feature/wdh-mybusiness');
// PROJECT
require('../../base/js/base-main-custom.js');
require('../../base/js/tracking-gtm.js');
require('./project/tracking-gtm-ga4');
require('./project/component-singlesignon.js');
require('./project/custom-a-login-spot.js');
require('./project/custom-account-overview-box-spot');
require('./project/custom-b2c-form.js');
require('./project/custom-form-opn-rft');
require('./project/custom-form-opn-s-rft-map');
require('./project/common-form-operations');
require('./project/custom-basic-form.js');
require('./project/custom-datepicker.js');
require('./project/custom-emergency-banner.js');
require('./project/custom-flexible-richtext-content');
require('./project/custom-footer.js');
require('./project/custom-header.js');
require('./project/custom-order-details.js');
require('./project/custom-select.js');
require('./project/custom-yhn-form.js');
require('./project/component-news-list-archive-spot');
require('./project/login.js');
require('./project/main.js');
require('@demant/wdh-gdpr/lib/default.implementation.js');
require('../custom-components/PEP/modules/PEP-accordion/index');
require('../custom-components/video-banner/index');
import '../custom-components/flex-spot/compatibility-guide/index';
require('./feature/component-video-player.js');
    if (document.querySelector('.locations-spot')) {
      import(/* webpackChunkName: "chunk-num-1", webpackPrefetch: true */'../react/feature/wdh-locations-spot/wdh-locations-spot')
        .then(function (foo) { console.log('.locations-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .locations-spot'); console.log(error); });
    }
    if (document.querySelector('.restricted-container')) {
      import(/* webpackChunkName: "chunk-num-2", webpackPrefetch: true */'./project/restrictions-container')
        .then(function (foo) { console.log('.restricted-container LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .restricted-container'); console.log(error); });
    }
    if (document.querySelector('.flex-spot')) {
      import(/* webpackChunkName: "chunk-num-3", webpackPrefetch: true */'./feature/flex-spot/flex-spot.js')
        .then(function (foo) { console.log('.flex-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .flex-spot'); console.log(error); });
    }
    if (document.querySelector('.edit-profile-spot')) {
      import(/* webpackChunkName: "chunk-num-4", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-my-profile-spot')
        .then(function (foo) { console.log('.edit-profile-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .edit-profile-spot'); console.log(error); });
    }
    if (document.querySelector('.account-overview-summary-spot')) {
      import(/* webpackChunkName: "chunk-num-5", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-account-overview-summary-spot')
        .then(function (foo) { console.log('.account-overview-summary-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .account-overview-summary-spot'); console.log(error); });
    }
    if (document.querySelector('.bss-overview-spot')) {
      import(/* webpackChunkName: "chunk-num-6", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-bss-overview-spot')
        .then(function (foo) { console.log('.bss-overview-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .bss-overview-spot'); console.log(error); });
    }
    if (document.querySelector('.warranties-expiring-spot')) {
      import(/* webpackChunkName: "chunk-num-7", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-warranties-expiring-spot')
        .then(function (foo) { console.log('.warranties-expiring-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .warranties-expiring-spot'); console.log(error); });
    }
    if (document.querySelector('.statements-spot')) {
      import(/* webpackChunkName: "chunk-num-8", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-statements-spot')
        .then(function (foo) { console.log('.statements-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .statements-spot'); console.log(error); });
    }
    if (document.querySelector('.resource-center')) {
      import(/* webpackChunkName: "chunk-num-9", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-resource-center')
        .then(function (foo) { console.log('.resource-center LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .resource-center'); console.log(error); });
    }
    if (document.querySelector('.warranty-extension-spot')) {
      import(/* webpackChunkName: "chunk-num-10", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-warranties-extension-spot')
        .then(function (foo) { console.log('.warranty-extension-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .warranty-extension-spot'); console.log(error); });
    }
    if (document.querySelector('.warranty-registration-spot')) {
      import(/* webpackChunkName: "chunk-num-11", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-warranties-registration-spot')
        .then(function (foo) { console.log('.warranty-registration-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .warranty-registration-spot'); console.log(error); });
    }
    if (document.querySelector('.select-payments-spot')) {
      import(/* webpackChunkName: "chunk-num-12", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-select-payments-spot')
        .then(function (foo) { console.log('.select-payments-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .select-payments-spot'); console.log(error); });
    }
    if (document.querySelector('.schedule-pick-up-spot')) {
      import(/* webpackChunkName: "chunk-num-13", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-schedule-pick-up-spot')
        .then(function (foo) { console.log('.schedule-pick-up-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .schedule-pick-up-spot'); console.log(error); });
    }
    if (document.querySelector('.business-support-services-spot')) {
      import(/* webpackChunkName: "chunk-num-14", webpackPrefetch: true */'../react/feature/wdh-mybusiness/src/js/wdh-bss-details-spot')
        .then(function (foo) { console.log('.business-support-services-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .business-support-services-spot'); console.log(error); });
    }
    if (document.querySelector('.datalist-export-spot')) {
      import(/* webpackChunkName: "chunk-num-15", webpackPrefetch: true */'./feature/component-datalist-export-spot')
        .then(function (foo) { console.log('.datalist-export-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .datalist-export-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-client-order-step1-spot')) {
      import(/* webpackChunkName: "chunk-num-16", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/client-order-step1-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-client-order-step1-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-client-order-step1-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-client-order-step2-spot')) {
      import(/* webpackChunkName: "chunk-num-17", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/client-order-step2-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-client-order-step2-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-client-order-step2-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-client-order-step3-spot')) {
      import(/* webpackChunkName: "chunk-num-18", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/client-order-step3-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-client-order-step3-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-client-order-step3-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-selectordertypespot')) {
      import(/* webpackChunkName: "chunk-num-19", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/select-order-type-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-selectordertypespot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-selectordertypespot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-cartdetailsspot')) {
      import(/* webpackChunkName: "chunk-num-20", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/cart-details-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-cartdetailsspot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-cartdetailsspot'); console.log(error); });
    }
    if (document.querySelector('.b2b-my-discounts-spot')) {
      import(/* webpackChunkName: "chunk-num-21", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/my-discounts-spot')
        .then(function (foo) { console.log('.b2b-my-discounts-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .b2b-my-discounts-spot'); console.log(error); });
    }
    if (document.querySelector('.shop-bulk-order-configurator')) {
      import(/* webpackChunkName: "chunk-num-22", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/wdh-shop-bulk-order-configurator')
        .then(function (foo) { console.log('.shop-bulk-order-configurator LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .shop-bulk-order-configurator'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-checkoutspot')) {
      import(/* webpackChunkName: "chunk-num-23", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/checkout-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-checkoutspot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-checkoutspot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-checkoutprintspot')) {
      import(/* webpackChunkName: "chunk-num-24", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/checkout-print-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-checkoutprintspot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-checkoutprintspot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-checkout-confirmation-spot')) {
      import(/* webpackChunkName: "chunk-num-25", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/checkout-confirmation-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-checkout-confirmation-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-checkout-confirmation-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-recentlyviewedproductsspot')) {
      import(/* webpackChunkName: "chunk-num-26", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/recently-viewed-products-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-recentlyviewedproductsspot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-recentlyviewedproductsspot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-productsearchspot')) {
      import(/* webpackChunkName: "chunk-num-27", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/product-search-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-productsearchspot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-productsearchspot'); console.log(error); });
    }
    if (document.querySelector('.shop-search-results')) {
      import(/* webpackChunkName: "chunk-num-28", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/wdh-shop-search-results')
        .then(function (foo) { console.log('.shop-search-results LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .shop-search-results'); console.log(error); });
    }
    if (document.querySelector('.shop-product-list')) {
      import(/* webpackChunkName: "chunk-num-29", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/wdh-shop-product-list')
        .then(function (foo) { console.log('.shop-product-list LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .shop-product-list'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-productdetailsspot')) {
      import(/* webpackChunkName: "chunk-num-30", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/product-details-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-productdetailsspot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-productdetailsspot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-subheaderspot')) {
      import(/* webpackChunkName: "chunk-num-31", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/subheader-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-subheaderspot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-subheaderspot'); console.log(error); });
    }
    if (document.querySelector('.b2b-promotional-banner-spot')) {
      import(/* webpackChunkName: "chunk-num-32", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/wdh-promo-banner-spot')
        .then(function (foo) { console.log('.b2b-promotional-banner-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .b2b-promotional-banner-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-free-promotional-items')) {
      import(/* webpackChunkName: "chunk-num-33", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/free-promotional-items')
        .then(function (foo) { console.log('.wdh-feature-b2b-free-promotional-items LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-free-promotional-items'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-notification-spot')) {
      import(/* webpackChunkName: "chunk-num-34", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/notification-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-notification-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-notification-spot'); console.log(error); });
    }
    if (document.querySelector('.print-shipping-label-spot')) {
      import(/* webpackChunkName: "chunk-num-35", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/print-shipping-label-spot')
        .then(function (foo) { console.log('.print-shipping-label-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .print-shipping-label-spot'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-spot, .hearing-test-results')) {
      import(/* webpackChunkName: "chunk-num-36", webpackPrefetch: true */'./feature/component-hearing-test')
        .then(function (foo) { console.log('.hearing-test-spot, .hearing-test-results LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-spot, .hearing-test-results'); console.log(error); });
    }
    if (document.querySelector('.wdh-stock-order-accessories-listing-page-spot')) {
      import(/* webpackChunkName: "chunk-num-37", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/components/accessories-stock-order-spot')
        .then(function (foo) { console.log('.wdh-stock-order-accessories-listing-page-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-stock-order-accessories-listing-page-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-service-order-cost-estimate-spot')) {
      import(/* webpackChunkName: "chunk-num-38", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/components/service-orders/service-order-cost-estimate-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-service-order-cost-estimate-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-service-order-cost-estimate-spot'); console.log(error); });
    }
    if (document.querySelector('.wdh-feature-b2b-service-order-spot')) {
      import(/* webpackChunkName: "chunk-num-39", webpackPrefetch: true */'../../../wdh-feature/wdh-feature-b2b-us/src/js/service-order-spot')
        .then(function (foo) { console.log('.wdh-feature-b2b-service-order-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .wdh-feature-b2b-service-order-spot'); console.log(error); });
    }
    if (document.querySelector('.pep-product-list, .pep-accessories-list, .pep-product-details')) {
      import(/* webpackChunkName: "chunk-num-40", webpackPrefetch: true */'../custom-components/PEP/pep')
        .then(function (foo) { console.log('.pep-product-list, .pep-accessories-list, .pep-product-details LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .pep-product-list, .pep-accessories-list, .pep-product-details'); console.log(error); });
    }
    if (document.querySelector('.pep-custom-product-list')) {
      import(/* webpackChunkName: "chunk-num-41", webpackPrefetch: true */'../custom-components/PEP/pep-guide-v2')
        .then(function (foo) { console.log('.pep-custom-product-list LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .pep-custom-product-list'); console.log(error); });
    }
    if (document.querySelector('.phone-compatibility:not(.custom), .phone-detection')) {
      import(/* webpackChunkName: "chunk-num-42", webpackPrefetch: true */'../../oticon/custom-components/flex-spot/compatibility-phones/index')
        .then(function (foo) { console.log('.phone-compatibility:not(.custom), .phone-detection LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .phone-compatibility:not(.custom), .phone-detection'); console.log(error); });
    }
    if (document.querySelector('.flex-spot.compatibility-guide-v3')) {
      import(/* webpackChunkName: "chunk-num-43", webpackPrefetch: true */'../../oticon/custom-components/flex-spot/compatibility-guide-V3/index')
        .then(function (foo) { console.log('.flex-spot.compatibility-guide-v3 LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .flex-spot.compatibility-guide-v3'); console.log(error); });
    }
    if (document.querySelector('.download-center')) {
      import(/* webpackChunkName: "chunk-num-44", webpackPrefetch: true */'./feature/component-download-center')
        .then(function (foo) { console.log('.download-center LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .download-center'); console.log(error); });
    }
    if (document.querySelector('.banner-spot')) {
      import(/* webpackChunkName: "chunk-num-45", webpackPrefetch: true */'./feature/component-banner-spot.js')
        .then(function (foo) { console.log('.banner-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .banner-spot'); console.log(error); });
    }
    if (document.querySelector('.spot-control-spot')) {
      import(/* webpackChunkName: "chunk-num-46", webpackPrefetch: true */'./feature/component-spot-control-spot')
        .then(function (foo) { console.log('.spot-control-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .spot-control-spot'); console.log(error); });
    }
    if (document.querySelector('.country-selector')) {
      import(/* webpackChunkName: "chunk-num-47", webpackPrefetch: true */'./feature/component-country-selector')
        .then(function (foo) { console.log('.country-selector LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .country-selector'); console.log(error); });
    }
    if (document.querySelector('.center-list-spot')) {
      import(/* webpackChunkName: "chunk-num-48", webpackPrefetch: true */'./feature/component-center-list-spot.js')
        .then(function (foo) { console.log('.center-list-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .center-list-spot'); console.log(error); });
    }
    if (document.querySelector('.centre-spot')) {
      import(/* webpackChunkName: "chunk-num-49", webpackPrefetch: true */'./feature/component-center-spot.js')
        .then(function (foo) { console.log('.centre-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .centre-spot'); console.log(error); });
    }
    if (document.querySelector('.hearing-center-locator')) {
      import(/* webpackChunkName: "chunk-num-50", webpackPrefetch: true */'./feature/component-hearing-center-locator.js')
        .then(function (foo) { console.log('.hearing-center-locator LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-center-locator'); console.log(error); });
    }
    if (document.querySelector('.datalist-export-spot')) {
      var link = document.querySelector('link[data-component^="datalist-export-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-test')) {
      var link = document.querySelector('link[data-component^="hearing-test"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.print-shipping-label-spot')) {
      var link = document.querySelector('link[data-component^="print-shipping-label-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.phone-compatibility')) {
      var link = document.querySelector('link[data-component^="phone-compatibility"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }