(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.tagged-list-spot').each(function () {
      var $this = $(this),
        itemsCount = $this.find('.tagged-page').children('.tagged-page').length,
        itemsCountVisible = $this.find('.tagged-page').children('.tagged-page').length,
        selectedFilter = $this.find('select.tagged-page-tags').val(),
        selectedSort = $this.find('select.tagged-page-sorting').val();

      // fake-select initial options

      $this.find('.fake-select.tagged-page-sorting span').text($this.find('select.tagged-page-sorting option[value="' + selectedSort + '"]').text());
      $this.find('.fake-select.tagged-page-tags span').text($this.find('select.tagged-page-tags option[value="' + selectedFilter + '"]').text());
      $this.find('.fake-select.tagged-page-sorting ul').toggle();
      $this.find('.fake-select.tagged-page-tags ul').toggle();
      filterAndSort($this, selectedFilter, selectedSort);
      $('body').click(function () {
        $this.find('.fake-select.tagged-page-sorting ul').hide();
        $this.find('.fake-select.tagged-page-tags ul').hide();
      });
      $this.find('.fake-select.tagged-page-sorting span').click(function (e) {
        e.stopPropagation();
        $this.find('.fake-select.tagged-page-sorting ul').toggle();
        $this.find('.fake-select.tagged-page-sorting span').toggleClass('span-focus');
        $this.find('.fake-select.tagged-page-tags ul').hide();
        $this.find('.fake-select.tagged-page-tags span').removeClass('span-focus');
      });
      $this.find('.fake-select.tagged-page-tags span').click(function (e) {
        e.stopPropagation();
        $this.find('.fake-select.tagged-page-tags ul').toggle();
        $this.find('.fake-select.tagged-page-tags span').toggleClass('span-focus');
        $this.find('.fake-select.tagged-page-sorting ul').hide();
        $this.find('.fake-select.tagged-page-sorting span').removeClass('span-focus');
      });
      $this.find('.fake-select.tagged-page-sorting li').click(function () {
        var $selected = $(this);
        if ($selected.attr('data-value') != $this.find('select.tagged-page-sorting').val()) {
          $this.find('.fake-select.tagged-page-sorting ul').hide();
          $this.find('.fake-select.tagged-page-sorting span').removeClass('span-focus');
          $this.find('.fake-select.tagged-page-sorting span').text($selected.text());
          selectedSort = $selected.attr('data-value');
          $this.find('select.tagged-page-sorting').val($selected.attr('data-value'));
          filterAndSort($this, selectedFilter, selectedSort);
        }
      });
      $this.find('.fake-select.tagged-page-tags li').click(function () {
        var $selected = $(this);
        if ($selected.attr('data-value') != $this.find('select.tagged-page-tags').val()) {
          $this.find('.fake-select.tagged-page-tags ul').hide();
          $this.find('.fake-select.tagged-page-tags span').removeClass('span-focus');
          $this.find('.fake-select.tagged-page-tags span').text($selected.text());
          selectedFilter = $selected.attr('data-value');
          $this.find('select.tagged-page-tags').val($selected.attr('data-value'));
          filterAndSort($this, selectedFilter, selectedSort);
        }
      });
    });
  });
  function filterAndSort(vm, filter, sort) {
    // Filtering

    var item,
      itemsArray = [],
      itemsArrayHidden = [];
    vm.find('.tagged-page').children('.tagged-page').each(function () {
      item = $(this);
      itemsArray.push(item);
      if (!(item.attr('data-tags').indexOf(filter) > -1 || filter === '')) {
        //item.hide(); // add effect; ask what kind of effect
        itemsArrayHidden.push(item);
        itemsArray.pop();
      }
    });

    // Sorting

    var aux, sortCriteria1, sortCriteria2;
    for (var i = 0; i < itemsArray.length - 1; i++) {
      for (j = i + 1; j < itemsArray.length; j++) {
        switch (sort) {
          case 'alpha':
            sortCriteria1 = itemsArray[i].attr('data-title');
            sortCriteria2 = itemsArray[j].attr('data-title');
            if (sortCriteria1 > sortCriteria2) {
              aux = itemsArray[i];
              itemsArray[i] = itemsArray[j];
              itemsArray[j] = aux;
            }
            break;
          case 'date':
            sortCriteria1 = itemsArray[i].attr('data-date');
            sortCriteria2 = itemsArray[j].attr('data-date');
            if (sortCriteria1 < sortCriteria2) {
              aux = itemsArray[i];
              itemsArray[i] = itemsArray[j];
              itemsArray[j] = aux;
            }
            break;
        }
      }
    }

    // Reinject in dom

    vm.find('.tagged-page').children('.tagged-page').remove();
    vm.find('.tagged-page').append(itemsArray);
    vm.find('.tagged-page > .tagged-page').parent().append(itemsArrayHidden);
    for (var j = 0; j < itemsArray.length; j++) {
      if (itemsArray[j].css('display') == 'none') {
        itemsArray[j].slideDown();
      }
    }
    for (var k = 0; k < itemsArrayHidden.length; k++) {
      itemsArrayHidden[k].slideUp();
    }
  }
})(jQuery);