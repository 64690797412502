require("./wdh-legacy/intro-banner/js/base/component-intro-banner.js");
import { trackDownload } from "../project/tracking-gtm-ga4";
(function ($, DGS) {
  DGS.Resizer.getInstance().register(function () {
    $('.component.intro-banner').removeClass('content-overflow force-overflow');
    if (window.matchMedia("(max-width: 767px)").matches) {
      $('.component.intro-banner').addClass('content-overflow force-overflow'); // always break at mobile device width.
    }
  });
  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.intro-banner').length) {
      $('.intro-banner').each(function () {
        var $elem = $(this);
        $('a', $elem).each(function () {
          var href = $(this).attr('href');
          if (href && href.split('.').pop().split('?').shift() === 'pdf') {
            $(this).on('click', function () {
              trackDownload('pdf', href.split('/').pop());
            });
          }
        });
      });
    }
  });
})(jQuery, window.DGS);