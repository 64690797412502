/**
 * Created by mady on 27-03-2017.
 * Component: Data Hierarchy Spot
 * Variationa: warranty-ordering
 */

(function ($, DGS) {
  var dictionary = {
      B2B: {
        webCurrencyFormat: "${0}"
      }
    },
    DHS = DGS.Components.DataHierarchySpot.getInstance(),
    builderConfigurationForStep1 = function builderConfigurationForStep1(stepData, $spot) {
      var instruments = [],
        $total = $('<div>Total: <span class="total"></span></div>'),
        $proceed = $('<a href="' + stepData.targetUrl.url + '" class="button-corporate-grey proceed">Proceed</a>'),
        entryIndex = 0,
        data = DHS.parseData(stepData.data),
        sessionData = stepData.storageData || {};
      data.instruments.forEach(function (instrument) {
        var labelTemplate = $('.label-template', $spot).html(),
          inputTemplate = $('.input-template', $spot).html(),
          optionTemplate = $('.option-template', $spot).html(),
          currentWarrantyTemplate = $('.current-warranties-template', $spot).html(),
          instrumentTemplate = $('.instrument-template', $spot).html(),
          currentWarranties = '',
          options = '',
          urlWithoutSerial = function urlWithoutSerial(serial) {
            var href = window.location.href;
            return href.replace(serial + '/', '').replace(serial, '');
          };
        instrument.warranties.forEach(function (warrantyDataEntry) {
          currentWarranties += currentWarrantyTemplate.format(warrantyDataEntry.properties.code, warrantyDataEntry.properties.expirationDate, warrantyDataEntry.properties.name);
        });
        instrument.availWarranties.forEach(function (warranty, index) {
          var selected = "";
          if (sessionData['warranty_' + entryIndex]) {
            if (sessionData['warranty_' + entryIndex] == warranty.properties.code) {
              selected = ' selected="selected"';
            }
          } else {
            if (index == 0) {
              selected = ' selected="selected"';
            }
          }
          options += optionTemplate.format(warranty.properties.code, warranty.properties.name, warranty.properties.price, selected);
        });
        instruments.push($(instrumentTemplate.format(instrument.properties.serial, labelTemplate.format("Patient name", instrument.properties.patientName) + labelTemplate.format("Device", instrument.properties.device) + labelTemplate.format("Serial Number", instrument.properties.serial), "Currenty Warranty", currentWarranties, inputTemplate.format('P.O # / Authorization', "po_" + entryIndex, sessionData['po_' + entryIndex] || "") + inputTemplate.format('Medicaid / ClientID #', "medi_" + entryIndex, sessionData['medi_' + entryIndex] || ""), "Select Warranty", "warranty_" + entryIndex, options, "#", "See warranty descriptions", urlWithoutSerial(instrument.properties.serial))));
        entryIndex++;
      });
      return {
        mainContainer: $('.component-content', $spot),
        sections: {
          desktop: {
            containers: [{
              className: "instruments editable",
              components: instruments
            }, {
              className: "bottom static",
              containers: [{
                className: "total-container",
                components: [$total]
              }, {
                className: "cta-container",
                components: [$proceed]
              }]
            }, {
              className: "bottom fixed",
              containers: [{
                className: "total-container",
                components: [$total]
              }, {
                className: "cta-container",
                components: [$proceed]
              }]
            }]
          }
        }
      };
    },
    builderConfigurationForStep2 = function builderConfigurationForStep2(stepData, $spot) {
      var instruments = [],
        $total = $('<div>Total: <span class="total"></span></div>'),
        $buy = $('<a href="#" class="button-corporate-grey buy">Buy</a>'),
        $change = $('<a href="#" class="button-blue change">Change</a>'),
        entryIndex = 0,
        data = DHS.parseData(stepData.data),
        sessionData = stepData.storageData;
      data.instruments.forEach(function (instrument) {
        var labelTemplate = $('.label-template', $spot).html(),
          optionTemplate = $('.option-template', $spot).html(),
          currentWarrantyTemplate = $('.current-warranties-template', $spot).html(),
          instrumentTemplate = $('.instrument-template-summary', $spot).html(),
          currentWarranties = '',
          options = '';
        instrument.warranties.forEach(function (warrantyDataEntry) {
          currentWarranties += currentWarrantyTemplate.format(warrantyDataEntry.properties.code, warrantyDataEntry.properties.expirationDate, warrantyDataEntry.properties.name);
        });
        instruments.push($(instrumentTemplate.format(instrument.properties.serial, labelTemplate.format("Patient name", instrument.properties.patientName) + labelTemplate.format("Device", instrument.properties.device) + labelTemplate.format("Serial Number", instrument.properties.serial), "Currenty Warranty", currentWarranties, labelTemplate.format('P.O # / Authorization', sessionData['po_' + entryIndex]) + labelTemplate.format('Medicaid / ClientID #', sessionData['medi_' + entryIndex]), labelTemplate.format("Selected Warranty", sessionData["warranty_" + entryIndex]))));
        entryIndex++;
      });
      return {
        mainContainer: $('.component-content', $spot),
        sections: {
          desktop: {
            containers: [{
              className: "instruments summary",
              components: instruments
            }, {
              className: "bottom static",
              containers: [{
                className: "total-container",
                components: [$total]
              }, {
                className: "cta-container",
                components: [$change, $buy]
              }]
            }, {
              className: "bottom fixed",
              containers: [{
                className: "total-container",
                components: [$total]
              }, {
                className: "cta-container",
                components: [$change, $buy]
              }]
            }]
          }
        }
      };
    };
  DGS.OnLoad.getInstance().register(function () {
    var $spots = $('.component.data-hierarchy-spot.warranty-ordering');
    if (!$spots.length) return;
    DGS.Dictionary.getInstance().getTexts(dictionary, function () {
      $spots.each(function () {
        var $spot = $(this),
          MS = DGS.Components.DataHierarchySpot.MultiStep.getInstance();
        $spot.append('<script class="instrument-template" type="template/text"><div class="instrument" data-serial-no="{0}"><div class="column">{1}</div><div class="column"><label>{2}</label>{3}</div><div class="column">{4}</div><div class="column"><label>{5}</label><select name="{6}">{7}</select><a href="{8}" class="link">{9}</a></div><div class="corner-action"><a class="remove" href="{10}"></a></div></div></script><script class="instrument-template-summary" type="template/text"><div class="instrument" data-serial-no="{0}"><div class="column">{1}</div><div class="column"><label>{2}</label>{3}</div><div class="column">{4}</div><div class="column">{5}</div></div></script><script class="current-warranties-template" type="template/text"><div>{0} <span class="expiration-date">{1}</span></div><div class="full-warranty-name">{2}</div></script><script class="input-template" type="template/text"><label>{0}</label><input name="{1}" type="text" value="{2}" /></script><script class="label-template" type="template/text"><label>{0}</label><div>{1}</div></script><script class="option-template" type="template/text"><option value="{0}" data-price="{2}"{3}>{1} ${2}</option></script><script class="loader" type="template/text"><div class="data-list-spot__load-container"><div class="cssload-container"><div class="cssload-double-torus"><div class="cssload-double-torus-arrow"></div></div></div></div></script>');
        $('.component-content', $spot).append($('.loader', $spot).html());
        MS.setTarget($spot).onBeforeRequest(function (params, hash) {
          if (params.ins) {
            return {
              q: 'instruments:' + params.ins
            };
          }
        }).addStep(builderConfigurationForStep1).addStep(builderConfigurationForStep2).run(function (step, stepData) {
          var $staticBottom = $('.bottom.static', $spot),
            $fixedBottom = $('.bottom.fixed', $spot),
            bottomPosition = $staticBottom.position();
          DGS.Scroller.getInstance().register(function (scrollTop) {
            var targetScrollTop = bottomPosition.top + $staticBottom.outerHeight(true) - $(window).outerHeight(true);
            if (scrollTop < targetScrollTop - 100) {
              $staticBottom.removeClass('shown');
              if (!$fixedBottom.hasClass('shown')) {
                $fixedBottom.addClass('shown');
                setTimeout(function () {
                  $fixedBottom.addClass('fade-in');
                }, 200);
              }
            } else {
              $staticBottom.addClass('shown');
              if ($fixedBottom.hasClass('shown')) {
                $fixedBottom.addClass('fade-out');
                $fixedBottom.removeClass('fade-in');
                $fixedBottom.one("transitionend", function () {
                  $fixedBottom.removeClass('shown');
                  $fixedBottom.removeClass('fade-out');
                });
              }
            }
          });
          switch (parseInt(step)) {
            case 0:
              var updateTotal = function updateTotal() {
                var total = 0;
                $('.custom-select-container .select-item.selected', $spot).each(function () {
                  var $this = $(this),
                    price = $this.attr('data-price');
                  total += parseFloat(price);
                });
                $('.total-container .total').html(dictionary.B2B.webCurrencyFormat.format(total));
              };
              $('.proceed', $spot).click(function (event) {
                event.preventDefault();
                var data = {};
                $('input', $spot).each(function () {
                  var $this = $(this);
                  data[$this.attr('name')] = $this.val();
                });
                data.step1Location = window.location;
                MS.submitStorageData(data, function () {
                  MS.nextStep();
                }, function () {
                  alert('an error occured');
                });
              });
              DGS.Event.getInstance().trigger(window.DGS.Events.SELECT_ADDED);
              $('.custom-select-container', $spot).customSelectChange(updateTotal);
              updateTotal();
              break;
            case 1:
              $('.buy', $spot).click(function (event) {
                event.preventDefault();
                MS.submitApiData(stepData, function () {
                  MS.nextStep();
                }, function () {
                  alert('an error occured');
                });
              });
              if (MS.getPrevStepLocation()) {
                $('.change', $spot).attr('href', MS.getPrevStepLocation());
              }
              break;
          }
        });
      });
    });
  });
})(jQuery, window.DGS);