(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var c = 0;
    $('.option-spot').each(function () {
      var $this = $(this);
      window.DGS.EqualHeight.getInstance().register($('.option-spot-element-header', $(this)), true);
      window.DGS.EqualHeight.getInstance().register($('.option-spot-element-text', $(this)), true);
      window.DGS.DrawSeparators.getInstance().register($('ul li', $this), 'option-spot-style' + c++);
      window.DGS.Justify.getInstance().apply($('ul', $this), 'li');
    });
  });
})(jQuery);