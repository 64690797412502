(function ($) {
  'use strict';

  DGS.OnLoad.getInstance().register(function () {
    if ($('.component.batch-print').length === 0) return;
    $('.component.batch-print').each(function () {
      var data = $('script[type="application/json"]', $(this));
      var config;
      try {
        config = JSON.parse(data.html());
      } catch (e) {
        console.error(e);
      }
      var picker = $('.batch-print--date-picker', $(this));
      if (picker.length) {
        initializeDatePicker();
      }
      function initializeDatePicker() {
        config.shortcuts = config.shortcuts != undefined || config.shortcuts != null ? eval(config.shortcuts) : config.shortcuts;
        picker.customDatePicker({
          shortcuts: config.shortcuts || [{
            label: "Last 7 days",
            expr: "-7d"
          }],
          expr: config.expr || "-7d",
          dateRangeLimit: config.dateRangeLimit || "7d",
          dateFormat: config.dateFormat ? config.dateFormat.toUpperCase() : "YYYY-MM-DD",
          customRangeLabel: config.customRangeLabel || "Custom range",
          fromDateLabel: config.fromDateLabel || "FROM",
          toDateLabel: config.toDateLabel || "TO",
          applyLabel: config.applyLabel || "Apply",
          cancelLabel: config.cancelLabel || "Cancel",
          negativeRangeLabel: config.negativeRangeLabel || "FROM must be before TO",
          invalidRangeLabel: config.invalidRangeLabel || "FROM and TO cannot be more than 7 days apart"
        });
      }
      var batchType = "";
      var serviceType = "";
      if ($(this).hasClass("batch-print-invoices")) {
        batchType = $('#batch-print-invoices-label', $(this)).val();
        serviceType = "Invoice";
      }
      if ($(this).hasClass("batch-print-creditmemos")) {
        batchType = $('#batch-print-creditmemo-label', $(this)).val();
        serviceType = "CreditMemo";
      }
      var h3 = $('h3', $(this)).html() + batchType;
      $('h3', $(this)).html(h3);
      var button = $('.button', $(this)).html() + batchType;
      $('.button', $(this)).html(button);
      var batchPrintItem = $(this);
      var baseBatchPrintURL = '/webservices/BatchPrint.svc/batchprint';
      hideLoader(batchPrintItem);
      $('.button').on('click', function (event) {
        event.preventDefault();
        $(".errorMsg", batchPrintItem).html("");
        $(".successMsg", batchPrintItem).html("");
        showLoader();
        var from = picker.customDatePickerFrom(),
          to = picker.customDatePickerTo();
        var resultUrl = baseBatchPrintURL + "/" + serviceType + "/" + formatDate(from) + "/" + formatDate(to);
        var iterationCnt = 0;
        $.ajax({
          url: resultUrl,
          type: 'GET'
        }).then(function (data, textState, jqXHR) {
          switch (jqXHR.status) {
            case 202:
              setTimeout(function () {
                callBatchPrint(baseBatchPrintURL, data.Data, ++iterationCnt);
              }, 200);
              break;
            default:
              $(".errorMsg", batchPrintItem).html($("#batch-print-error-msg").val());
              hideLoader(batchPrintItem);
              break;
          }
        }, function (jqXHR, textState, msg) {
          $(".errorMsg", batchPrintItem).html($("#batch-print-error-msg").val());
          hideLoader(batchPrintItem);
        });
      });
      function callBatchPrint(baseURl, id, iterationCnt) {
        if (iterationCnt >= 10) {
          $(".errorMsg", batchPrintItem).html($("#batch-print-error-msg").val() + "Timeout Error");
          hideLoader(batchPrintItem);
          return;
        }
        var resultUrl = baseURl + id;
        $.ajax({
          url: resultUrl,
          type: 'GET'
        }).then(function (data, textState, jqXHR) {
          switch (jqXHR.status) {
            case 204:
              setTimeout(function () {
                callBatchPrint(baseURl, id, ++iterationCnt);
              }, 200);
              break;
            case 200:
              $(".successMsg", batchPrintItem).html($("#batch-print-success-msg").val());
              hideLoader(batchPrintItem);
              break;
          }
        }, function (jqXHR, textState, msg) {
          $(".errorMsg", batchPrintItem).html($("#batch-print-error-msg").val());
          hideLoader(batchPrintItem);
        });
      }
      function showLoader(batchPrintItem) {
        $(".batch-print__submit-button a", batchPrintItem).hide();
        $(".batch-print__submit-button .batch-print__load-container", batchPrintItem).show();
      }
      function hideLoader(batchPrintItem) {
        $(".batch-print__submit-button a", batchPrintItem).show();
        $(".batch-print__submit-button .batch-print__load-container", batchPrintItem).hide();
      }
      function formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year + month + day];
      }
    });
  });
})(jQuery);