import { WDHCarousel } from '../../../../wdh-feature/wdh-carousel';
import ConfigurationManager from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/infrastructure/configuration-manager';
import ApiClient from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/infrastructure/api/api-client';
import CheckUtils from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/utilities/check-utils';
import { EventTypes } from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/infrastructure/const';
var EventBus = window.DGS.Event.getInstance();
var carousel = new WDHCarousel();
carousel.setOptions({
  hasRestrictedSlides: true,
  restrictedSlideClass: 'cannot-view-promos'
});
carousel.run();

/**
 * Checks for promotion availability
 */
var getPromotionAvailability = function getPromotionAvailability() {
  var couponPromise = ApiClient.cart.getCoupons();
  var promotionsPromise = ApiClient.promotion.getAllPromotionList();
  Promise.all([couponPromise, promotionsPromise]).then(function (response) {
    if (!CheckUtils.isNullOrEmptyArray(response[0]) || !CheckUtils.isNullOrEmptyArray(response[1].items)) {
      var options = {
        hasRestrictedSlides: false,
        restrictedSlideClass: ''
      };
      carousel.resetStack(options);
    }
  });
};

/**
 * Checks if a carousel is set up and has our restriction class.  if so, then check if we are in a shop page so we can check for promo availability.
 */
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    if (document.querySelectorAll('.carousel-slide.cannot-view-promos').length > 0) {
      // check if a carousel is set up and has our restrictions, if so then do promotion checks
      if (!ConfigurationManager.validateConfigurationLoaded(carousel.className)) {
        EventBus.on(EventTypes.SHOP_INIT_READY, function () {
          if (!ConfigurationManager.validateConfigurationLoaded(carousel.className)) {
            return;
          }
          getPromotionAvailability();
        });
      } else {
        getPromotionAvailability();
      }
    }
  });
})(jQuery, window.DGS);