(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    function accountSpotHeight() {
      if ($('.component').hasClass('account-overview-box-spot')) {
        var maxHeight = -1,
          $accountSpot = $('.component.account-overview-box-spot div[class*="section-"]');
        $accountSpot.css('height', 'auto');
        $accountSpot.each(function () {
          maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
        });
        $accountSpot.each(function () {
          $(this).css('height', maxHeight);
        });
        if (window.matchMedia('(min-width: 755px)').matches) {
          $accountSpot.css('height', maxHeight);
        } else {
          $accountSpot.css('height', 'auto');
        }
      }
    }
    accountSpotHeight();
    window.DGS.Resizer.getInstance().register(function () {
      accountSpotHeight();
    });
  });
})(jQuery);