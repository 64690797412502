(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    /**
     * HTML Structure :
     * <div class="header_text_height_calculation">
          <h3>[HEADER]</h3>
          <div class="teaser-text">[TEASER TEXT]</div>
           <div class="teaser-link"><a href="#">[IF YOU WANT TO ADD IT]</a></div>
        </div>
     */
    function setFlexibleHeight() {
      var headerWrapper = '.header_text_height_calculation > h3',
        textWrapper = '.header_text_height_calculation > .teaser-text';
      var maxHeaderHeight = -1,
        maxTextHeight = -1;
      $(headerWrapper).css('height', 'auto');
      $(textWrapper).css('height', 'auto');
      $(headerWrapper).each(function () {
        if ($(this).height() > maxHeaderHeight) {
          maxHeaderHeight = $(this).height();
        }
      });
      $(textWrapper).each(function () {
        if ($(this).height() > maxTextHeight) {
          maxTextHeight = $(this).height();
        }
      });
      if (window.matchMedia('(min-width: 755px)').matches) {
        $(headerWrapper).css('height', maxHeaderHeight);
        $(textWrapper).css('height', maxTextHeight);
      } else {
        $(headerWrapper).css('height', 'auto');
        $(textWrapper).css('height', 'auto');
      }
    }
    setFlexibleHeight();
    window.DGS.Resizer.getInstance().register(function () {
      setFlexibleHeight();
    });
  });
})(jQuery);