(function ($, DGS) {
  var newHeight = 0,
    $frontPageIntroBanner,
    $frontPageIntroBannerText,
    init = function init() {
      var zenMode = $('.on-page-editor').length;
      if (!zenMode) {
        $($('#wrapper > #content .component.intro-banner')[0]).each(function () {
          $(this).addClass('front-page');
        });
        $('#wrapper > #content .component.box-spot.simple').each(function () {
          var $this = $(this);
          DGS.EqualHeight.getInstance().register($('.box-spot-item', $this), true);
        });
        $('#wrapper > #content .component.box-spot:not(".simple")').each(function () {
          var $this = $(this),
            boxCount = $('.box-spot-container .box-spot-items-container .box-spot-item', $this).length;
          if (boxCount > 3) boxCount = 3;
          $('.box-spot-item', $this).each(function () {
            $(this).css('width', Number(100 / boxCount) + '%');
          });
          DGS.EqualHeight.getInstance().register($('.box-spot-item', $this), true);
        });
        DGS.Resizer.getInstance().register(function () {
          $('#wrapper > #content .component.box-spot:not(".simple")').each(function () {
            var $this = $(this),
              offset = $this.offset(),
              boxCount = $('.box-spot-container .box-spot-items-container .box-spot-item', $this).length,
              $boxList = $('.box-spot-item', $this),
              $firstBox = $('.box-spot-item:nth-child(1)', $this),
              tempW = $firstBox.width(),
              newHeight = $firstBox.height();
            $frontPageIntroBanner = $('#wrapper > #content .component.intro-banner.front-page');
            $frontPageIntroBannerText = $('.intro-banner-text', $frontPageIntroBanner);
            $boxList.each(function () {
              if ($(this).height() > newHeight) newHeight = $(this).height();
            });
            if ($frontPageIntroBanner.length) {
              var ih = $frontPageIntroBanner[0].getBoundingClientRect().height;
              var th = $frontPageIntroBannerText[0].getBoundingClientRect().top + $frontPageIntroBannerText[0].getBoundingClientRect().height;
              var dif = ih - th;
              if (newHeight > dif) {
                newHeight = dif - 20;
              }
            }
            if (tempW > newHeight) {
              $this.removeClass('mobile-mode');
              $this.css('margin-top', -newHeight + 'px');
            } else {
              $this.addClass('mobile-mode');
              $this.css('margin-top', '0px');
              newHeight = newHeight * boxCount;
            }
            if (window.matchMedia("(max-width: 1024px)").matches) {
              $this.addClass('mobile-mode');
              $this.css('margin-top', '0px');
            } else {
              $this.removeClass('mobile-mode');
            }
          });
        });
        DGS.Scroller.getInstance().register(function (scrollTop) {
          $('#wrapper > #content .component.box-spot:not(".simple")').each(function () {
            var $this = $(this);
            if (!$this.hasClass('mobile-mode')) {
              var theScroll = scrollTop / 4,
                newMargin = newHeight - theScroll,
                ih,
                th,
                dif;
              if ($frontPageIntroBanner.length) {
                ih = $frontPageIntroBanner[0].getBoundingClientRect().height;
                th = $frontPageIntroBannerText[0].getBoundingClientRect().top + $frontPageIntroBannerText[0].getBoundingClientRect().height;
                dif = ih - th;
                if (newMargin > dif) {
                  newMargin = dif - 20;
                }
              }
              if (theScroll < newHeight) $this.css('margin-top', -newMargin + 'px');
            } else {
              $this.css('margin-top', '0px');
            }
          });
        });
        $('#wrapper > #content .component.box-spot').each(function () {
          var $this = $(this),
            $boxes = $('.box-spot-item', $this),
            locationHref = window.location.pathname,
            locationParts = locationHref.split("/");
          if (locationParts[locationParts.length - 1] == '') {
            locationParts.pop();
          }
          $boxes.each(function () {
            var $box = $(this),
              $a = $('a', $box),
              href = $a.attr('href'),
              hrefParts = href.split("/"),
              isSamePath = true,
              i;
            if (hrefParts[hrefParts.length - 1] == '') {
              hrefParts.pop();
            }
            if (locationParts.length == hrefParts.length) {
              for (i = 0; i < locationParts.length; i++) {
                if (locationParts[i] != hrefParts[i]) {
                  isSamePath = false;
                }
              }
            } else {
              isSamePath = false;
            }
            if (isSamePath) {
              $box.addClass('is-current-page');
            }
          });
        });
      }
    };
  DGS.OnLoad.getInstance().register(init);
  DGS.Event.getInstance().on('re-init-box-spot', init);
})(jQuery, window.DGS);