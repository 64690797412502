(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    window.DGS.Justify.getInstance().apply($('#wrapper #footer > .row > .grid-12 .component.rich-text-list > .component-content > ul'), 'li');
    var toogleMode = false;
    $('.component.rich-text-list.footer-fix .component-content ul li').each(function () {
      $(this).on('click', function () {
        if (toogleMode) {
          var me = $(this).find('ul');
          me.toggle();
        }
      });
    });

    /* flexible footer for text */
    function setFlexibleFooter() {
      var footerWrapper = '.footer-fix .component-content > ul > li';
      $(footerWrapper + ':nth-child(3)').find('div.addTextli3').removeAttr("style");
      $(footerWrapper + ':nth-child(4)').find('div.addTextli4').removeAttr("style");
      var $li3_height = $(footerWrapper + ':nth-child(3)').find('div.addTextli3').height();
      var $li4_height = $(footerWrapper + ':nth-child(4)').find('div.addTextli4').height();
      if ($li3_height !== $li4_height) {
        $li3_height > $li4_height ? $(footerWrapper + ':nth-child(4)').find('div.addTextli4').css("height", $li3_height) : $(footerWrapper + ':nth-child(3)').find('div.addTextli3').css("height", $li4_height);
      }
    }
    setFlexibleFooter();
    /* End */

    $('.component.breadcrumb li:last-child').each(function () {
      if (window.location.href.indexOf('?') != -1) {
        var queryString = window.location.href.slice(window.location.href.indexOf('?') + 1);
        var _href = $(this).find('a').attr('href') + '?' + queryString;
        $(this).find('a').attr("href", _href);
      }
    });
    function setToggle() {
      if (window.matchMedia('(min-width: 600px)').matches) {
        toogleMode = false;
        $('.component.rich-text-list.footer-fix .component-content > ul > li').each(function () {
          $(this).find('ul').css('display', 'block');
        });
      } else {
        toogleMode = true;
        $('.component.rich-text-list.footer-fix .component-content > ul > li').each(function () {
          $(this).css('height', 'auto');
        });
      }
    }
    $(window).resize(function () {
      setToggle();
      setFlexibleFooter();
    });
  });
})(jQuery);