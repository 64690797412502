require("./wdh-legacy/contact-information/js/base/component-contact-information.js");
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var showStateForSpot = function showStateForSpot(targetState, items, $ul) {
      var i;
      for (i = 0; i < items.length; i++) {
        var $this = items[i],
          state = $this.attr('data-state');
        if (state == targetState) {
          $ul.append($this);
        } else {
          $this.remove();
        }
      }
      window.DGS.Resizer.getInstance().execute();
    };
    $('#wrapper .contact-information.group-by-state').each(function () {
      var $spot = $(this),
        $ul = $('ul', $spot),
        $li = $('li', $spot),
        states = [],
        $items = [],
        $selector = $('<select><option>- Select -</option></select>');
      $li.each(function () {
        var $this = $(this),
          state = $this.attr('data-country');
        $this.attr('data-state', state);
        if (states.indexOf(state) === -1) {
          states.push(state);
          $selector.append('<option>' + state + '</option>');
        }
        $items.push($this);
        $this.remove();
      });
      showStateForSpot('', $items, $ul);
      $selector.change(function () {
        showStateForSpot($selector.val(), $items, $ul);
      });
      $selector.insertAfter($('.contact-information-item-group h3', $spot));
    });
  });
})(jQuery);