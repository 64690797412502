var postProcessData = function postProcessData(result, selectedProductId, selectedDeviceId) {
  var output = {
    accessories: result.accessories.map(function (accessory) {
      if (selectedProductId || selectedDeviceId) {
        accessory.compatible = true;
      }
      return accessory;
    }),
    apps: result.apps.map(function (app) {
      if (selectedProductId || selectedDeviceId) {
        app.compatible = true;
      }
      return app;
    }),
    devices: result.devices.map(function (device) {
      if (selectedProductId) {
        device.compatible = true;
      }
      return device;
    }),
    products: result.products.map(function (product) {
      if (selectedDeviceId) {
        product.compatible = true;
      }
      return product;
    })
  };
  if (selectedProductId) {
    output.products = output.products.filter(function (product) {
      return product.id === selectedProductId;
    });
  }
  if (selectedDeviceId) {
    output.devices = output.devices.filter(function (device) {
      return device.id === selectedDeviceId;
    });
  }
  return output;
};
export default postProcessData;