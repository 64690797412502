(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    function distributeItems() {
      $('.contact-information-item-group').each(function () {
        var $this = $(this),
          docWidth = $this.width(),
          $li = $('li', $this),
          liWidth = $li.width(),
          prLine = Math.floor(docWidth / liWidth),
          excessWidth = prLine > 0 ? Math.floor((docWidth - prLine * liWidth) / (prLine - 1)) - 1 : 0,
          i = 0;
        $li.each(function () {
          if (i % prLine == 0) {
            $(this).css('clear', 'both');
          } else {
            $(this).css('clear', 'none');
          }
          if (excessWidth > 0) {
            if (i % prLine < prLine - 1) {
              $(this).css('margin-right', excessWidth + 'px');
            } else {
              $(this).css('margin-right', '0px');
            }
          } else {
            $(this).css('margin-right', '0px');
          }
          i++;
        });
      });
    }
    ;
    window.DGS.EqualWidth.getInstance().register(".contact-information-item-group li", distributeItems);
  });
})(jQuery);