import { TweenMax, TimelineMax, Power2 } from 'gsap';
(function ($, DGS) {
  var consts = {
      DATA_RESPONSE_URL: "response-url",
      DATA_FIELD_NAME: "field-name",
      DATA_SORT_ORDER: "sort-order"
    },
    dictionary = {
      B2B: {
        OrderText: 'Orders',
        InvoiceText: 'Invoices',
        CreditMemoText: 'Credit memos',
        InstrumentsText: 'Instruments',
        OrderType: 'Order type',
        OrderTypeMO: 'MO - MyOticon',
        OrderTypeSO: 'SO - Sales',
        OrderTypeSV: 'SV - Service Repair',
        WarrantyStatus: 'Warranty Status: ',
        WarrantyExpired: 'Warranty expired',
        WarrantyNearExpiration: 'Warranty expiring within 6 months',
        WarrantyExpiringSevenDays: 'Warranty expiring within 7 days',
        WarrantyActive: 'Active warranty',
        warrantyExpiringSoonPopupMsg: 'Warranty expiring within 6 months',
        warrantyExpiringSevenDaysPopupMsg: 'Warranty expiring within 7 days',
        warrantyExpiredPopupMsg: 'Warranty expired or inactive',
        warrantyActivePopupMsg: 'Active warranty'
      }
    },
    DataListSpotConfig = {
      resolveSortForField: function resolveSortForField(field, sortType) {
        return '';
      },
      decorateRowAtIndex: function decorateRowAtIndex(data, index, $row) {
        $row.addClass(index % 2 == 0 ? "data-list-spot__row-even" : "data-list-spot__row-odd");
      },
      fieldInfo: {},
      Event: {
        DATA_RESULT_READY: "dataResultReady",
        DATA_RESULT_EMPTY: "dataResultEmpty",
        DATA_LIST_PRE_SEARCH: "dataListPreSearch",
        DATA_SEARCH_QUERY_READY: "dataSearchQueryReady",
        DATA_SEARCH_QUERY_READY_NO_DATE_RANGE: "dataSearchQueryReadyNoDateRange",
        DATA_REQUEST_BUILD_QUERY: "dataRequestBuildQuery",
        DATA_REQUEST_BUILD_ADV_QUERY: "dataRequestBuildAdvQuery",
        DATA_REQUEST_INITIAL_BUILD_QUERY: "dataRequestInitialBuildQuery",
        DATA_REQUEST_INITIAL_BUILD_ADV_QUERY: "dataRequestInitialBuildAdvQuery",
        DATA_REQUEST_INITIAL_RECEIVED: "dataRequestInitialReceived",
        DATA_SEARCH_SET_GLOBAL_FILTER: "dataSearchSetGlobalFilter",
        DATA_LIST_BUILT: "dataListBuilt",
        DATA_LIST_PROMPT: 'dataListPrompt'
      },
      initialRequests: {}
    },
    savedFilterObject = {},
    getConfigurationId = function getConfigurationId($dataList) {
      return $dataList.data("configuration");
    },
    getConfigForDataList = function getConfigForDataList($dataList) {
      var configId = getConfigurationId($dataList),
        script = $('script[type="application/json"]', $dataList),
        config = JSON.parse(script.html());
      return config;
    },
    checkFieldNameSortability = function checkFieldNameSortability(fieldName) {
      return DataListSpotConfig.fieldInfo.hasOwnProperty(fieldName) ? DataListSpotConfig.fieldInfo[fieldName].IsSortable : false;
    },
    showContent = function showContent($container) {
      TweenMax.fromTo($container, 0.3, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Power2.easeIn
      });
    },
    showRows = function showRows($rows) {
      TweenMax.staggerFromTo($rows, .2, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Power2.easeIn
      }, .05);
    },
    initDataListSpot = function initDataListSpot() {
      $(".component.data-list-spot").each(function () {
        var $dataList = $(this),
          configurationId = getConfigurationId($dataList),
          config = getConfigForDataList($dataList),
          $componentContent = $(".component-content", $dataList),
          $headerContainer,
          $itemsContainer;

        // IF IS COLLAPSED. ADD LABEL
        if (isCollapsed($dataList)) {
          var _toggleCollapse = function toggleCollapse(e) {
            var $thisDataList = $(e.currentTarget).parents('.component.data-list-spot');
            var $thisComponentContent = $('.component-content', $thisDataList);
            $thisDataList.toggleClass('open');
            var getHeightIfOpen = $('.toggle-billto.open .component-content').height();
            if ($thisDataList.hasClass('open')) {
              $('.login-spot-profile-navigation').css('margin-top', getHeightIfOpen);
            } else {
              $('.login-spot-profile-navigation').css('margin-top', 0);
            }
            $('.data-list-spot-search', $thisComponentContent).val('');
            $('.data-list-spot__row').show();
            if ($thisComponentContent.hasClass('open')) {
              setTimeout(function () {
                DGS.PageClick.getInstance().register(_toggleCollapse);
              }, 0);
            } else {
              DGS.PageClick.getInstance().remove(_toggleCollapse);
            }
          };
          $componentContent.append('<div class="collapsed-outer-container"><div class="collapsed-inner-container"><div class="data-list-selected-value">Sold to...</div></div></div>');
          var $dataListTitle = $('.data-list-title', $dataList);
          if ($dataListTitle.length) {
            $dataListTitle.remove();
            $('.data-list-selected-value', $componentContent).before($dataListTitle);
          }
          $componentContent.click(function (e) {
            e.stopPropagation();
            _toggleCollapse(e);
          });
        }
        // IF IS SEARCHABLE. ADD SEARCH
        if (isSearchable($dataList)) {
          $componentContent.append('<div class="search-outer-container"><div class="search-inner-container"><input type="text" class="data-list-spot-search" placeholder="Search account no. or name"/></div></div>');
          $('.data-list-spot-search', $componentContent).keyup(function (event) {
            var query = $(this).val().toLowerCase();
            $('.data-list-spot__row', $dataList).each(function () {
              var $this = $(this),
                $column = $('.data-list-spot__row__column span', $this),
                show = false;
              $column.each(function () {
                if ($(this).text().toLowerCase().indexOf(query) != -1) {
                  show = true;
                }
              });
              if (show) $this.show();else $this.hide();
            });
          });
          $('input', $componentContent).click(function (e) {
            e.stopPropagation();
          });
        }

        // ADD HEADERS
        $componentContent.append('<div class="data-list-spot__table-container"><div class="data-list-spot__table headers"></div></div>');
        $headerContainer = $('.data-list-spot__table.headers', $dataList);
        buildHeaders($dataList, $headerContainer);

        // ADD ITEMS CONTAINER
        $componentContent.append('<div class="data-list-spot__table-container"><div class="data-list-spot__table items"></div></div>');
        $itemsContainer = $('.data-list-spot__table.items', $dataList);

        // SETUP EVENT LISTENERS - begin
        DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_RESULT_READY, function (identifier, data, resultURL) {
          if (config.endpoint && identifier == config.endpoint || identifier == configurationId) {
            if (resultURL && $dataList.data('result-url') !== resultURL) {
              return;
            }
            removeLoader($dataList);
            if (!buildRows($dataList, data, $itemsContainer)) {
              addViewAllComponent($dataList, $componentContent);
              addViewHistoryButton($dataList);
            }
            decorateCells($dataList);
            addCustomTextToDataListSpot($dataList, data, $componentContent);
            addTotalOrders($dataList, data);
            addLoadMoreComponent(config.endpoint, $dataList, data, $componentContent);
            DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_LIST_BUILT, configurationId, config.data);
            addWarrantyRegistrationChkboxValues($dataList, $componentContent);
            addWarrantyExtensionChkboxValues($dataList, $componentContent);
            addLegend($dataList, $componentContent);
          }
        });
        DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_RESULT_EMPTY, function (endpoint, resultURL) {
          if (endpoint == config.endpoint) {
            if (resultURL && $dataList.data('result-url') !== resultURL) {
              return;
            }
            removeLoader($dataList);
            addZeroOrders($dataList);
            if (!$('.data-list-spot__row', $dataList).length) {
              addEmptyResultComponent($dataList, $componentContent);
              removeViewHistory($dataList);
            }
          }
        });
        DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_LIST_PRE_SEARCH, function (endpoint) {
          if (endpoint == config.endpoint) {
            clearSort($dataList);
            clearRows($dataList);
            buildLoader($dataList);
          }
        });
        DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_SEARCH_QUERY_READY, function (endpoint, fromDate, toDate, filterObject, queryJoinType, extraQuery) {
          if (endpoint == config.endpoint) {
            clearSort($dataList);
            clearRows($dataList);
            buildLoader($dataList);
            requestResultUrl($dataList, config.endpoint, fromDate, toDate, filterObject, queryJoinType, function (responseUrl) {
              if (extraQuery) {
                if (responseUrl.indexOf('?') === -1) {
                  responseUrl += "?" + extraQuery;
                } else {
                  responseUrl += "&" + extraQuery;
                }
              }
              saveResultUrlOnDataList($dataList, responseUrl);
              if (config.sortOrder) {
                if (responseUrl.indexOf('?') !== -1) {
                  responseUrl += '&orderby=' + config.sortOrder;
                } else {
                  responseUrl += '?orderby=' + config.sortOrder;
                }
              }
              _requestResultData($dataList, config.endpoint, responseUrl, 0, true);
            });
          }
        });
        DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_SEARCH_QUERY_READY_NO_DATE_RANGE, function (endpoint, fromDate, toDate, filterObject, queryJoinType, extraQuery) {
          if (endpoint == config.endpoint) {
            clearSort($dataList);
            clearRows($dataList);
            buildLoader($dataList);
            requestResultUrlNoDateRange($dataList, config.endpoint, fromDate, toDate, filterObject, queryJoinType, function (responseUrl) {
              if (extraQuery) {
                if (responseUrl.indexOf('?') === -1) {
                  responseUrl += "?" + extraQuery;
                } else {
                  responseUrl += "&" + extraQuery;
                }
              }
              saveResultUrlOnDataList($dataList, responseUrl);
              if (config.sortOrder) {
                if (responseUrl.indexOf('?') !== -1) {
                  responseUrl += '&orderby=' + config.sortOrder;
                } else {
                  responseUrl += '?orderby=' + config.sortOrder;
                }
              }
              _requestResultData($dataList, config.endpoint, responseUrl, 0, true);
            });
          }
        });
        DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_SEARCH_SET_GLOBAL_FILTER, function (field, value) {
          savedFilterObject[field] = field + ' ' + value;
          // refresh list
          initialPopulation($dataList);
        });
        // SETUP EVENT LISTENERS - end

        // INITIAL POPULATION - begin
        initialPopulation($dataList);
        // INITIAL POPULATION - end

        if (isCollapsed($dataList)) {
          setDefaultValue($dataList, config.data);
        }
      });
    },
    initialPopulation = function initialPopulation($dataList) {
      var configurationId = getConfigurationId($dataList),
        config = getConfigForDataList($dataList),
        initialRequestData;
      if (config.endpoint) {
        if (DataListSpotConfig.initialRequests.hasOwnProperty(config.endpoint)) {
          initialRequestData = DataListSpotConfig.initialRequests[config.endpoint];
          DGS.Event.getInstance().trigger(initialRequestData.queryType, initialRequestData.$searchSpot, config.endpoint);
        } else {
          getDataForConfiguredDateRange($dataList, config.endpoint);
        }
      } else if (config.data) {
        clearRows($dataList);
        DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_READY, configurationId, config.data);
      }
    },
    requestResultUrl = function requestResultUrl($dataList, requestUrl, from, to, filterObject, queryJoinType, callback) {
      if (from == null && to == null) {
        var dates = getConfiguredDateRange($dataList);
        from = dates.from;
        to = dates.to;
      }
      var tzoffset = new Date().getTimezoneOffset() * 60000,
        data = {
          from: new Date(from - tzoffset).toISOString().substring(0, 10).replace(/-/g, ""),
          to: new Date(to - tzoffset).toISOString().substring(0, 10).replace(/-/g, "")
        },
        config = getConfigForDataList($dataList),
        requestObject = $dataList.data('requestObject'),
        currentUUID = $dataList.data('uuid'),
        uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
          return v.toString(16);
        });
      var jsonData = JSON.stringify(data);
      if (requestObject && requestObject.requestId == currentUUID) {
        try {
          requestObject.hasBeenActivelyAborted = true;
          requestObject.abort();
        } catch (error) {
          console.log(error);
        }
      }
      $dataList.data('uuid', uuid);
      var searchResult = {
        url: requestUrl,
        type: "GET",
        data: {
          date: jsonData
        },
        beforeSend: function beforeSend(jqXHR, settings) {
          jqXHR.requestId = uuid;
        }
      };
      if (window.location.href.indexOf("instrument-history") > -1 || window.location.href.indexOf("warranty-report") > -1) {
        searchResult.data = {
          parameter: jsonData
        };
      }
      requestObject = $.ajax(searchResult).then(function (data, textState, jqXHR) {
        if ($dataList.data('uuid') != jqXHR.requestId) return;
        var resultUrl = data,
          filter = Object.keys(filterObject).map(function (f) {
            return filterObject[f];
          }).join(" " + queryJoinType + " "),
          savedFilter = Object.keys(savedFilterObject).map(function (f) {
            return savedFilterObject[f];
          }).join(" and ");
        if (filter && filter.length > 0) {
          if (config.filter) filter += " and " + config.filter;
        } else {
          filter = config.filter;
        }
        if (savedFilter && savedFilter.length > 0) {
          if (filter && filter.length > 0) {
            filter += " and " + savedFilter;
          } else {
            filter = savedFilter;
          }
        }
        if (filter && filter.length > 0) {
          filter = filter.replace('(', '%28');
          filter = filter.replace(')', '%29');
          resultUrl += "?filter=" + filter;
        }
        callback.apply(null, [resultUrl]);
      }, function (jqXHR, textStatus, msg) {
        if (jqXHR.hasBeenActivelyAborted) return;
        if ($dataList.data('uuid') != jqXHR.requestId) return;
        switch (jqXHR.status) {
          case 401:
            window.location = config.renewLoginUrl;
            break;
          default:
            DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
            break;
        }
      });
      requestObject.requestId = uuid;
    },
    requestResultUrlNoDateRange = function requestResultUrlNoDateRange($dataList, requestUrl, from, to, filterObject, queryJoinType, callback) {
      if (from == null && to == null) {
        var dates = getConfiguredDateRange($dataList);
        from = dates.from;
        to = dates.to;
      }
      var tzoffset = new Date().getTimezoneOffset() * 60000;
      if ($('.reset-all')[0]) {
        filterObject['categories'] = 'All';
        filterObject['models'] = 'All';
        filterObject['styles'] = 'All';
      }
      if (filterObject['categories'] == 'All') delete filterObject.categories;
      if (filterObject['models'] == 'All') delete filterObject.models;
      if (filterObject['styles'] == 'All') delete filterObject.styles;
      if ($(".searchThroughDate")[0]) {
        filterObject["from"] = new Date(from - tzoffset).toISOString().substring(0, 10).replace(/-/g, "");
        filterObject["to"] = new Date(to - tzoffset).toISOString().substring(0, 10).replace(/-/g, "");
      }
      var config = getConfigForDataList($dataList),
        requestObject = $dataList.data('requestObject'),
        currentUUID = $dataList.data('uuid'),
        uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : r & 0x3 | 0x8;
          return v.toString(16);
        });
      var jsonData = JSON.stringify(filterObject);
      if (requestObject && requestObject.requestId == currentUUID) {
        try {
          requestObject.hasBeenActivelyAborted = true;
          requestObject.abort();
        } catch (error) {
          console.log(error);
        }
      }
      $dataList.data('uuid', uuid);
      requestObject = $.ajax({
        url: requestUrl,
        type: 'GET',
        data: {
          parameter: jsonData
        },
        beforeSend: function beforeSend(jqXHR, settings) {
          jqXHR.requestId = uuid;
        }
      }).then(function (data, textState, jqXHR) {
        if ($dataList.data('uuid') != jqXHR.requestId) return;
        var resultUrl = data,
          filter = Object.keys(filterObject).map(function (f) {
            return filterObject[f];
          }).join(" " + queryJoinType + " "),
          savedFilter = Object.keys(savedFilterObject).map(function (f) {
            return savedFilterObject[f];
          }).join(" or ");
        if (filter && filter.length > 0) {
          if (config.filter) filter += " or " + config.filter;
        } else {
          filter = config.filter;
        }
        if (savedFilter && savedFilter.length > 0) {
          if (filter && filter.length > 0) {
            filter += " or " + savedFilter;
          } else {
            filter = savedFilter;
          }
        }
        if (filter && filter.length > 0) {
          filter = filter.replace('(', '%28');
          filter = filter.replace(')', '%29');
          //resultUrl += "?parameter=" + filter;
          resultUrl += "";
        }
        if (config.filter) {
          resultUrl += "?filter=" + config.filter;
        }
        callback.apply(null, [resultUrl]);
      }, function (jqXHR, textStatus, msg) {
        if (jqXHR.hasBeenActivelyAborted) return;
        if ($dataList.data('uuid') != jqXHR.requestId) return;
        switch (jqXHR.status) {
          case 401:
            window.location = config.renewLoginUrl;
            break;
          default:
            DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
            break;
        }
      });
      requestObject.requestId = uuid;
    },
    _requestResultData = function requestResultData($dataList, requestUrl, resultUrl, iterationCount, clearRowsOnReady) {
      $dataList.data('result-url', resultUrl);
      var config = getConfigForDataList($dataList);
      if ($dataList.hasClass('noIterationCount')) {
        if (resultUrl === null || iterationCount > 0) {
          // timeout
          DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
          return;
        }
      } else {
        if (resultUrl === null || iterationCount > 10) {
          // timeout
          DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
          return;
        }
      }
      $.ajax({
        url: resultUrl,
        type: 'GET'
      }).then(function (data, textState, jqXHR) {
        switch (jqXHR.status) {
          case 204:
            setTimeout(function () {
              _requestResultData($dataList, requestUrl, resultUrl, ++iterationCount, clearRowsOnReady);
            }, 500);
            break;
          case 200:
            if (clearRowsOnReady) clearRows($dataList);
            if (!data.hasOwnProperty('items') || data.items.length == 0) {
              DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl, resultUrl);
            } else {
              DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_READY, requestUrl, data, resultUrl);
            }
            break;
        }
      }, function (jqXHR, textState, msg) {
        if (clearRowsOnReady) clearRows($dataList);
        switch (jqXHR.status) {
          case 401:
            window.location = config.renewLoginUrl;
            break;
          default:
            DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
            break;
        }
      });
    },
    buildHeaders = function buildHeaders($dataList, $container) {
      var config = getConfigForDataList($dataList),
        columns = config ? config.fields : [],
        $dataListHeaders = $('<div class="data-list-spot__headers"></div>');
      columns.forEach(function (column) {
        var classes = ['data-list-spot__headers__header', column.fieldName],
          label = column.label;
        if (column.className) {
          classes.push(column.className);
        }
        if (column.hideOnMobile) {
          classes.push('hide-on-mobile');
        }
        if (column.hideOnTablet) {
          classes.push('hide-on-tablet');
        }
        if (checkFieldNameSortability(column.fieldName) && classes.indexOf('not-sortable') === -1) {
          classes.push('sortable');
        }
        var $header = $('<div class="' + classes.join(' ') + '"><span>' + label + '</span></div>');
        $header.data(consts.DATA_FIELD_NAME, column.fieldName);
        $dataListHeaders.append($header);
      });
      $container.append($dataListHeaders);
      $('.data-list-spot__headers__header.sortable', $container).click(function (e) {
        e.stopPropagation();
        var $this = $(this),
          $allHeaders = $('.data-list-spot__headers__header'),
          sortType = $this.hasClass('ordered-asc') ? 'ordered-desc' : 'ordered-asc',
          fieldName = $this.data(consts.DATA_FIELD_NAME);
        $allHeaders.removeClass('ordered-asc');
        $allHeaders.removeClass('ordered-desc');
        $this.addClass(sortType);
        if (config.endpoint) {
          clearRows($dataList);
          buildLoader($dataList);
          _requestResultData($dataList, config.endpoint, getResultUrlFromDataListWithSort($dataList, fieldName, sortType), 0);
        } else {
          var data = [];
          $('.data-list-spot__row', $dataList).each(function () {
            var $this = $(this),
              selector = '.data-list-spot__row__column.' + fieldName + ' span';
            data.push({
              'row': $this,
              'text': $(selector, $this).text()
            });
          });
          data.sort(function (a, b) {
            if (sortType == 'ordered-asc') {
              return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1;
            }
            return b.text.toLowerCase() < a.text.toLowerCase() ? -1 : 1;
          });
          clearRows($dataList);
          buildLoader($dataList);
          data.forEach(function (item, index, array) {
            $('.data-list-spot__table.items', $dataList).append(item.row);
          });
        }
      });
    },
    buildRow = function buildRow($dataList, dataRow, index) {
      var config = getConfigForDataList($dataList),
        columns = config ? config.fields : [],
        value,
        key,
        style,
        hideOnMobile,
        hideOnTablet,
        classes,
        $header,
        $row = $('<div class="data-list-spot__row data-list-spot__row__cell remove-on-search"></div>');
      DGS.DataListSpotConfig.decorateRowAtIndex(dataRow, index, $row), columns.forEach(function (column) {
        classes = ["data-list-spot__row__column"];
        key = column.fieldName;
        classes.push(key);
        style = column.className ? column.className : '', classes.push(style);
        $header = $('.data-list-spot__headers__header.' + key, $dataList);
        hideOnMobile = $header.hasClass('hide-on-mobile');
        hideOnTablet = $header.hasClass('hide-on-tablet');
        value = dataRow.hasOwnProperty(key) ? dataRow[key] : '';
        if (value instanceof Array) {
          if (value.length > 0 && !(value[0] instanceof String)) {
            var tmpValue = [];
            value.forEach(function (arrEntry) {
              for (var arrKey in arrEntry) {
                tmpValue.push("<p class=\"".concat(arrKey, "\">").concat(arrEntry[arrKey], "</p>"));
              }
            });
            value = tmpValue.join('');
          } else {
            value = '<p>' + value.join('</p><p>') + '</p>';
          }
        }
        if (hideOnMobile) {
          classes.push('hide-on-mobile');
        }
        if (hideOnTablet) {
          classes.push('hide-on-tablet');
        }
        if (dataRow.links && dataRow.links.length) {
          dataRow.links.forEach(function (elem) {
            elem.target = column.className === 'open-link-new-window' ? '_blank' : 'self';
          });
        }
        if (dataRow.links && dataRow.links.length > 1 && classes.indexOf('documentNumber') > -1) {
          $row.append($('<a href="' + dataRow.links[1].href + '" class="' + classes.join(' ') + '" data-value="' + value.replace(/"/g, '') + '" target="' + dataRow.links[1].target + '"><span>' + value + '</span></a>'));
        } else {
          $row.append($('<div class="' + classes.join(' ') + '" data-value="' + value.replace(/"/g, '') + '"><span>' + value + '</span></div>'));
        }
      });
      if ($dataList.hasClass('toggle-billto')) {
        // SHOULD BE MOVED TO DATASET PROVIDED BY BACKEND
        dataRow.links = [{
          href: $dataList.data('endpoint').replace('$accountNo', dataRow.accountNo),
          method: "ws-prompt",
          rel: "self"
        }];
      }
      if ($dataList.hasClass('toggle-sellto')) {
        // SHOULD BE MOVED TO DATASET PROVIDED BY BACKEND
        dataRow.links = [{
          href: "$billToEndpoint/" + dataRow.accountNo,
          method: "ws",
          rel: "self"
        }];
      }
      if (dataRow.links && dataRow.links.length > 0) {
        if (dataRow.links.length <= 1) {
          $row.addClass('clickable');
          $('.data-list-spot__row__column', $row).addClass('clickable');
          $('.data-list-spot__row__column', $row).click({
            row: dataRow
          }, function (ev) {
            if (!$(this).hasClass('clickable') || $(this).hasClass('checkbox') || $(this).hasClass('printable') || $(this).hasClass('extendable_register')) return;
            if (isCollapsed($dataList)) updateSelectedCollapsedValue($dataList, ev.data.row);
            if (ev.data.row.links.length <= 1) {
              if (!gotoDetailLink(ev.data.row)) {
                setFilterValue($dataList, ev.data.row);
              }
            }
          });
        }
      }
      return $row;
    },
    setDefaultValue = function setDefaultValue($dataList, data) {
      var items = getItems(data);
      items.forEach(function (row) {
        if (row.hasOwnProperty('_selected') && row._selected) {
          updateSelectedCollapsedValue($dataList, row);
        }
      });
    },
    gotoDetailLink = function gotoDetailLink(dataRow) {
      var detailsLink = dataRow.links[0].href,
        method = dataRow.links[0].method;
      if (detailsLink) {
        if (method.toLowerCase().substr(0, 2) == "ws") {
          var $dataListsAffected = $('.data-list-spot:not(.collapsed)');
          $dataListsAffected.each(function () {
            // Set all data lists on page to show loader while waiting for webservice
            var config = getConfigForDataList($(this));
            DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_LIST_PRE_SEARCH, config.endpoint);
          });
          if (detailsLink.indexOf('$billToEndpoint') !== -1) {
            // HARDCODED for toggle-sellto
            var $toggleBillTo = $('.toggle-billto'),
              toggleBillToConfig = $toggleBillTo.length ? getConfigForDataList($toggleBillTo) : null;
            if (toggleBillToConfig !== null) {
              toggleBillToConfig.data.items.forEach(function (item) {
                if (item._selected) {
                  detailsLink = detailsLink.replace('$billToEndpoint', $toggleBillTo.data('endpoint')).replace('$accountNo', item.accountNo);
                }
              });
            }
          }

          // for account change (billto) we need to show dialog so send event to other component
          if (method.toLowerCase() === 'ws-prompt') {
            DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_LIST_PROMPT, detailsLink);
            return true;
          }

          // DO ajax and then refresh
          $.ajax(detailsLink, {
            complete: function complete() {
              if (method.toLowerCase() === "ws-refresh") {
                window.location.reload(true);
              } else {
                $dataListsAffected.each(function () {
                  // refresh data for all data lists on page
                  var config = getConfigForDataList($(this));
                  DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_SEARCH_QUERY_READY, config.endpoint, null, null, {}, 'or', '');
                });
              }
            }
          });
          return true;
        }

        // open link in new window if required
        if (dataRow.links[0].target === "_blank") {
          window.open(detailsLink);
        } else {
          window.location = detailsLink;
        }
        return true;
      }
      return false;
    },
    updateSelectedCollapsedValue = function updateSelectedCollapsedValue($dataList, dataRow) {
      var labelFormat = $dataList.attr('data-label-format'),
        s;
      for (s in dataRow) {
        if (labelFormat.indexOf('$' + s) != -1) {
          labelFormat = labelFormat.replace('$' + s, dataRow[s]);
        }
      }
      $('.data-list-selected-value', $dataList).text(labelFormat);
      $('.component-content', $dataList).removeClass('open');
    },
    setFilterValue = function setFilterValue($dataList, dataRow) {
      var detailsFilterField, detailsFilterValue;
      if (dataRow.links && dataRow.links.length > 0) {
        detailsFilterField = dataRow.links[0].filterField;
        detailsFilterValue = dataRow.links[0].filterValue;
        if (detailsFilterField && detailsFilterValue) {
          DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_SEARCH_SET_GLOBAL_FILTER, detailsFilterField, detailsFilterValue);
          return true;
        }
      }
      return false;
    },
    buildRows = function buildRows($dataList, data, $container) {
      var rowCounter = $('.data-list-spot__row', $dataList).length,
        initialRowCounter = rowCounter,
        config = getConfigForDataList($dataList),
        maxNumRows = config.maxCount || -1,
        hasMoreRows = false,
        items = getItems(data);
      items.forEach(function (row) {
        if (maxNumRows == -1 || rowCounter < maxNumRows) {
          $container.append(buildRow($dataList, row, rowCounter++));
        } else {
          hasMoreRows = true;
        }
      });
      showRows($('.data-list-spot__row:nth-child(n+' + initialRowCounter + ')', $dataList));
      return !hasMoreRows;
    },
    getItems = function getItems(data) {
      if (data.hasOwnProperty("Items")) {
        data.items = data.Items;
      }
      if (data.hasOwnProperty("items")) {
        return data.items;
      }
      return [];
    },
    isCollapsed = function isCollapsed($dataList) {
      return $dataList.hasClass('collapsed');
    },
    isSearchable = function isSearchable($dataList) {
      return $dataList.hasClass('searchable');
    },
    clearRows = function clearRows($dataList) {
      $('.remove-on-search', $dataList).remove();
    },
    decorateCells = function decorateCells($dataList) {
      // ADD TEASER FUNCTIONALITY

      // TRACKING CELLS
      $(".data-list-spot__row__cell .data-list-spot__row__column.tracking", $dataList).each(function () {
        var $this = $(this),
          text = $this.text();
        if (text.trim().substr(0, 4) == 'http') {
          $this.addClass('non-clickable');
          $this.removeClass('clickable');
          $this.html('<a href="' + text + '" target="_blank">&nbsp;</a>');
          $this.click(function (e) {
            var href = $('a', $(this)).attr('href'),
              target = $('a', $(this)).attr('target');
            if (target == '_blank') {
              window.open(href);
            } else {
              window.location = href;
            }
          });
        }
      });
      // TRACKING CELLS

      // MULTI TRACKING CELLS
      $(".data-list-spot__row__cell .data-list-spot__row__column.packing-slips", $dataList).each(function () {
        var $this = $(this),
          $packingSlipNos = $('.packingSlipNo', $this),
          $trackingUrls = $('.trackingUrl', $this);
        $this.addClass('non-clickable');
        $this.removeClass('clickable');
        var packingSlipNos = [];
        $packingSlipNos.each(function () {
          packingSlipNos.push($(this).text());
        });
        $trackingUrls.each(function () {
          var $this = $(this),
            text = $this.text();
          $this.html('<a href="' + text + '" target="_blank">' + packingSlipNos.shift() + '</a>');
        });
      });
      // TRACKING CELLS
    },
    addViewAllComponent = function addViewAllComponent($dataList, $container) {
      var config = getConfigForDataList($dataList),
        label = config.readMoreText || "View all",
        href = config.readMoreLink || "#";
      $('.data-list-spot__view-all-container', $dataList).remove();
      $container.append('<div class="data-list-spot__view-all-container remove-on-search"><div><a href="' + href + '" class="link data-list-spot__view-all">' + label + '</a></div></div>');
      setTimeout(function () {
        showContent($('.data-list-spot__view-all-container div', $container));
      }, 200);
    },
    addLoadMoreOriginalComponent = function addLoadMoreOriginalComponent(endpoint, $dataList, data, $container) {
      var config = getConfigForDataList($dataList),
        pagination = data.pagination,
        paginationLinks = pagination != null ? pagination.links : [],
        nextLink,
        link,
        i,
        countPaginationLinks = paginationLinks.length,
        loadMoreLabel = config.loadMoreText || "Load more",
        loadMoreLink,
        sortObj,
        orderBy;
      $('.data-list-spot__load-more-container', $dataList).remove();
      for (i = 0; i < countPaginationLinks; i++) {
        link = paginationLinks[i];
        if (link.rel == "next") {
          nextLink = link;
        }
      }
      if (nextLink != null && config['maxCount'] == "") {
        loadMoreLink = nextLink.href;
        sortObj = getSortFromDataList($dataList);
        if (sortObj.fieldName && sortObj.sortType) {
          orderBy = DataListSpotConfig.resolveSortForField(sortObj.fieldName, sortObj.sortType);
        }
        if (!orderBy && config['sortOrder']) {
          orderBy = 'orderby=' + config['sortOrder'];
        }
        if (orderBy) loadMoreLink += loadMoreLink.indexOf('?') !== -1 ? '&' + orderBy : '?' + orderBy;
        $container.append('<div class="data-list-spot__load-more-container remove-on-search"><div class="data-list-spot__load-more"><a class="link" href="' + loadMoreLink + '">' + loadMoreLabel + '</a></div></div>');
        showContent($('.data-list-spot__load-more-container', $container));
        $('.data-list-spot__load-more-container a', $container).click(function (event) {
          event.preventDefault();
          buildLoader($dataList);
          _requestResultData($dataList, endpoint, $(this).attr('href'), false, 0);
        });
      }
    },
    // Load More by Pagination Code
    addLoadMoreComponent = function addLoadMoreComponent(endpoint, $dataList, data, $container) {
      var config = getConfigForDataList($dataList),
        pagination = data.pagination,
        pgLinks = pagination != null ? pagination.links : [],
        countpgLinks = pgLinks.length,
        nextLink,
        prevLink,
        paginationLinks = pagination != null ? pagination.numberedLinks : [],
        link,
        linkLinks,
        i,
        j,
        countPaginationLinks = paginationLinks.length,
        sortObj,
        orderBy;
      $('.data-list-spot__load-more-container', $dataList).remove();
      if ($dataList.hasClass('add-short-abbreviation-order')) {
        $('.component-content', $dataList).append('<div class="data-list-spot__load-more-container pagination-wrapper remove-on-search"><div class="short-abbreviation"><span>' + dictionary.B2B.OrderType + '</span><span>' + dictionary.B2B.OrderTypeMO + '</span><span>' + dictionary.B2B.OrderTypeSO + '</span><span>' + dictionary.B2B.OrderTypeSV + '</span></div><div class="paginationList"></div></div>');
      } else {
        $('.component-content', $dataList).append('<div class="data-list-spot__load-more-container pagination-wrapper remove-on-search"><div class="paginationList"></div></div>');
      }
      var $paginationList = $('.paginationList', $dataList);
      for (j = 0; j < countpgLinks; j++) {
        linkLinks = pgLinks[j];
        if (linkLinks.rel == 'previous') {
          prevLink = linkLinks;
          $paginationList.append('<a class="previous-page" href="' + prevLink.href + '"></a>');
        }
        if (linkLinks.rel == 'next') {
          nextLink = linkLinks;
          $paginationList.after('<a class="next-page" href="' + nextLink.href + '"></a>');
        }
      }
      var outOfRange = false;
      for (i = 0; i < countPaginationLinks; i++) {
        if (i <= 1 || i >= countPaginationLinks - 2 || Math.abs(i - pagination.page + 1) <= 2) {
          link = paginationLinks[i];
          outOfRange = false;
          if (link.rel == pagination.page) {
            $paginationList.append("<a class=\"pagination-link active\" href=\"".concat(link.href, "\">").concat(link.rel, "</a><a href=\"\"></a>"));
          } else {
            $paginationList.append("<a class=\"pagination-link jtto\" href=\"".concat(link.href, "\">").concat(link.rel, "</a><a href=\"\"></a>"));
          }
        } else {
          // out of range
          if (!outOfRange) {
            $paginationList.append('<a class="pagination-link no-pointer">&hellip;</a>');
          }
          outOfRange = true;
        }
      }
      sortObj = getSortFromDataList($dataList);
      if (sortObj.fieldName && sortObj.sortType) {
        orderBy = DataListSpotConfig.resolveSortForField(sortObj.fieldName, sortObj.sortType);
      }
      if (!orderBy && config['sortOrder']) {
        orderBy = 'orderby=' + config['sortOrder'];
      }
      showContent($('.data-list-spot__load-more-container.pagination-wrapper', $container));
      $('.data-list-spot__load-more-container.pagination-wrapper a', $container).click(function (event) {
        event.preventDefault();
        //  clearSort($dataList);
        clearRows($dataList);
        buildLoader($dataList);
        _requestResultData($dataList, endpoint, $(this).attr('href'), false, 0);
        $('html, body').animate({
          scrollTop: 0
        }, 'slow');
      });
    },
    addLegend = function addLegend($dataList, $componentContent) {
      // add our legend (if applicable)
      if ($dataList.hasClass('has-legend')) {
        $('.legend', $dataList).remove();
        $componentContent.append("<div class=\"legend hide-on-mobile\">\n                <div class=\"title\">".concat(dictionary.B2B.WarrantyStatus, "</div>\n                <div class=\"expired\">").concat(dictionary.B2B.WarrantyExpired, "</div>\n                <div class=\"seven-day-expiring\">").concat(dictionary.B2B.WarrantyExpiringSevenDays, "</div>\n                <div class=\"near-expired\">").concat(dictionary.B2B.WarrantyNearExpiration, "</div>\n                <div class=\"active-warranty\">").concat(dictionary.B2B.WarrantyActive, "</div>\n              </div>"));
      }
    },
    addWarrantyRegistrationChkboxValues = function addWarrantyRegistrationChkboxValues($dataList, $container) {
      var $chkWrapper = $('.warranty-registration-chkBtn .warrantyRegistrable.checkbox input[type=checkbox]'),
        chkArray = JSON.parse(window.sessionStorage.getItem('warrantyReportChkBoxes')) || [];
      $chkWrapper.on('change', function (e) {
        var registerNumberChk = $(this).offsetParent('.data-list__row__column').siblings('.serialNo').data('value').toString();
        if ($(this).prop("checked") === true) {
          chkArray.push(registerNumberChk);
          window.sessionStorage.setItem('warrantyReportChkBoxes', JSON.stringify(chkArray));
        } else {
          for (var i = 0; i < chkArray.length; i++) {
            if (chkArray[i] == registerNumberChk) {
              chkArray.splice(i, 1);
              window.sessionStorage.setItem('warrantyReportChkBoxes', JSON.stringify(chkArray));
            }
          }
        }
        if (registerNumberChk != undefined) {
          $('.register-selected-device-btn').on('click', function (e) {
            e.preventDefault();
            buildLoader($dataList);
            window.location.href = $('.register-selected-device-btn').attr("data-src") + chkArray.join('|');

            /* modified to not to use sessionStorage.clear() in case other scripts are using it. */
            sessionStorage.removeItem('warrantyReportChkBoxes');
            sessionStorage.removeItem('warrantyReportChkBoxesExtension');
          });
        }
      });

      // re-select any checked items on refresh.
      if (chkArray.length > 0) {
        var $serialNumberRows = $('.warranty-registration-chkBtn .data-list-spot__row__column.serialNo');
        $serialNumberRows.each(function (index, elem) {
          var $this = $(elem);
          if (chkArray.indexOf($this.data('value').toString()) > -1) {
            $this.siblings('.checkbox').find('input[type=checkbox]').attr('checked', true);
          }
        });
      }
    },
    addWarrantyExtensionChkboxValues = function addWarrantyExtensionChkboxValues($dataList, $container) {
      var $chkWrapperExtensionWrapper = $('.warranty-extension-chkBtn .warrantyPurchasable.checkbox input[type=checkbox]'),
        $chkWrapperExtension = JSON.parse(window.sessionStorage.getItem('warrantyReportChkBoxesExtension')) || [];
      $('.warranty-extension-chkBtn').each(function () {
        $chkWrapperExtensionWrapper.on('change', function (e) {
          $chkWrapperExtension = JSON.parse(window.sessionStorage.getItem('warrantyReportChkBoxesExtension')) || [];
          var registerNumberChkExtension = $(this).offsetParent('.data-list__row__column').siblings('.serialNo').data('value').toString();
          if ($(this).prop("checked") === true) {
            $chkWrapperExtension.push(registerNumberChkExtension);
            window.sessionStorage.setItem('warrantyReportChkBoxesExtension', JSON.stringify($chkWrapperExtension));
          } else {
            for (var i = 0; i < $chkWrapperExtension.length; i++) {
              if ($chkWrapperExtension[i] == registerNumberChkExtension) {
                $chkWrapperExtension.splice(i, 1);
                window.sessionStorage.setItem('warrantyReportChkBoxesExtension', JSON.stringify($chkWrapperExtension));
              }
            }
          }
          if (registerNumberChkExtension != undefined) {
            $('.extend-selected-device-btn').on('click', function (e) {
              e.preventDefault();
              buildLoader($dataList);
              window.location.href = $('.extend-selected-device-btn').attr("data-src") + $chkWrapperExtension.join('|');
              sessionStorage.removeItem('warrantyReportChkBoxes');
              sessionStorage.removeItem('warrantyReportChkBoxesExtension');
            });
          }
        });
      });

      // re-select any checked items on refresh.
      if ($chkWrapperExtension.length > 0) {
        var $serialNumberRows = $('.warranty-extension-chkBtn .data-list-spot__row__column.serialNo');
        $serialNumberRows.each(function (index, elem) {
          var $this = $(elem);
          if ($chkWrapperExtension.indexOf($this.data('value').toString()) > -1) {
            $this.siblings('.checkbox').find('input[type=checkbox]').attr('checked', true);
          }
        });
      }
    },
    // total orders

    addTotalOrders = function addTotalOrders($dataList, data) {
      var pagination = data.pagination,
        totalItems = data.items,
        itemsTotal = totalItems != null ? totalItems : [],
        countTotalItems = itemsTotal.length,
        $totalPageHeader,
        noRows = pagination != null ? pagination.numberedLinks : [],
        pageNumber = pagination != null ? pagination.page : [],
        orderSize = pagination != null ? pagination.pageSize : [],
        totalOrder = pagination != null ? pagination.total : [];
      if ($dataList.hasClass('orders')) {
        if (countTotalItems < 25) {
          $totalPageHeader = $('<div class="totalPage"><b>' + totalOrder + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.OrderText + '</div>');
        } else {
          $totalPageHeader = $('<div class="totalPage"><b>' + pageNumber * orderSize + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.OrderText + '</div>');
        }
      } else if ($dataList.hasClass('invoices')) {
        if (countTotalItems < 25) {
          $totalPageHeader = $('<div class="totalPage"><b>' + totalOrder + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.InvoiceText + '</div>');
        } else {
          $totalPageHeader = $('<div class="totalPage"><b>' + pageNumber * orderSize + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.InvoiceText + '</div>');
        }
      } else if ($dataList.hasClass('credit-memos')) {
        if (countTotalItems < 25) {
          $totalPageHeader = $('<div class="totalPage"><b>' + totalOrder + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.CreditMemoText + '</div>');
        } else {
          $totalPageHeader = $('<div class="totalPage"><b>' + pageNumber * orderSize + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.CreditMemoText + '</div>');
        }
      } else if ($dataList.hasClass('instruments') || $dataList.hasClass('warranties')) {
        if (countTotalItems < 25) {
          $totalPageHeader = $('<div class="totalPage"><b>' + totalOrder + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.InstrumentsText + '</div>');
        } else {
          $totalPageHeader = $('<div class="totalPage"><b>' + pageNumber * orderSize + '</b> of <b>' + totalOrder + ' </b> ' + dictionary.B2B.InstrumentsText + '</div>');
        }
      }
      $('.component-content', $dataList).prepend($totalPageHeader);
    },
    addZeroOrders = function addZeroOrders($dataList) {
      var $totalPageHeader;
      if ($dataList.hasClass('orders')) {
        $totalPageHeader = $('<div class="totalPage"><b>0 </b> of <b> 0 </b>' + dictionary.B2B.OrderText + '</div>');
      } else if ($dataList.hasClass('invoices')) {
        $totalPageHeader = $('<div class="totalPage"><b>0 </b> of <b> 0 </b>' + dictionary.B2B.InvoiceText + '</div>');
      } else if ($dataList.hasClass('credit-memos')) {
        $totalPageHeader = $('<div class="totalPage"><b>0 </b> of <b> 0 </b>' + dictionary.B2B.CreditMemoText + '</div>');
      } else if ($dataList.hasClass('instruments') || $dataList.hasClass('warranties')) {
        $totalPageHeader = $('<div class="totalPage"><b>0 </b> of <b> 0 </b>' + dictionary.B2B.InstrumentsText + '</div>');
      }
      $('.component-content', $dataList).prepend($totalPageHeader);
    },
    removeViewHistory = function removeViewHistory($dataList) {
      var $viewPaymentHistoryBtn = $(".account-overview-box-spot .section-one .component.data-list-spot");
      $viewPaymentHistoryBtn.each(function () {
        var $dataList = $(this);
        $dataList.siblings().find($('.rich-text .payment-history-btn')).hide();
      });
    },
    addViewHistoryButton = function addViewHistoryButton($dataList) {
      var $viewPaymentHistoryBtn = $(".account-overview-box-spot .section-one .component.data-list-spot");
      $viewPaymentHistoryBtn.each(function () {
        var $dataList = $(this);
        $dataList.siblings().find($('.rich-text .payment-history-btn')).show();
      });
    },
    addCustomTextToDataListSpot = function addCustomTextToDataListSpot($dataList, data, $container) {
      var config = getConfigForDataList($dataList);
      var $warrantyExtebdableDiv = $('.data-list-spot__row__column.warrantyExtendable');
      var $warrantyExtendableCheckbox = $('.data-list-spot__row__column.warrantyPurchasable');
      var $registerDevice = $('.data-list-spot__row__column.warrantyRegistrable');
      var $ChangeOrderDateClass = $('.data-list-spot__row__column.isWarrantyExpiring');
      var $expiresDate = $('.data-list-spot__row__column.ExpiresDate, .data-list-spot__row__column.finalWarrantyEndDate');
      var $addCheckBox = $('.data-list-spot__row__column.status');
      $warrantyExtebdableDiv.each(function () {
        var childSpan = $(this).children('span').text('');
        var serialNoPara = $(this).closest('.data-list-spot__row').children('.serialNo:first').data('value');
        if ($(this).data('value') == "True") {
          childSpan.append('<a class="extendWarranty" href="' + config.warrantyExtensionUrl + serialNoPara + '">Extend warranty</a>');
        }
      });
      $warrantyExtendableCheckbox.each(function () {
        var childSpan = $(this).children('span').text('');
        if ($(this).data('value') == "1") {
          childSpan.append('<input type="checkbox" class="selectable" />');
        }
      });
      $registerDevice.each(function () {
        var serialNoPara = $(this).closest('.data-list-spot__row').children('.serialNo:first').data('value');
        if ($(this).data('value') == "1") {
          $(this).siblings('.warrantyExtendable').find('span').append('<a class="registerDevice" href="' + config.warrantyRegistrationUrl + serialNoPara + '">Register device</a>');
        }
        var childSpan = $(this).children('span').text('');
        if ($(this).data('value') == "1") {
          childSpan.append('<input type="checkbox" class="selectable" />');
        }
      });
      $ChangeOrderDateClass.each(function () {
        if ($(this).data('value') == "3") {
          $(this).siblings('.ExpiresDate, .finalWarrantyEndDate').addClass('WillExpireWithinSevenDays');
        } else if ($(this).data('value') == "2") {
          $(this).siblings('.ExpiresDate, .finalWarrantyEndDate').addClass('extendWarranty');
        } else if ($(this).data('value') == "1") {
          $(this).siblings('.ExpiresDate, .finalWarrantyEndDate').addClass('warrantyWillExpire');
        } else {
          $(this).siblings('.ExpiresDate, .finalWarrantyEndDate').addClass('warrantyExpired');
        }
      });

      // add popups for warranty status icons
      $expiresDate.each(function (index, elem) {
        var $elem = $(elem);
        var $popUpMsg = '';
        if ($elem.hasClass('warrantyWillExpire')) {
          $popUpMsg = dictionary.B2B.warrantyExpiringSoonPopupMsg;
        } else if ($elem.hasClass('warrantyExpired')) {
          $popUpMsg = dictionary.B2B.warrantyExpiredPopupMsg;
        } else if ($elem.hasClass('WillExpireWithinSevenDays')) {
          $popUpMsg = dictionary.B2B.warrantyExpiringSevenDaysPopupMsg;
        } else {
          $popUpMsg = dictionary.B2B.warrantyActivePopupMsg;
        }
        $elem.append("<div class=\"legend-pop-up\">".concat($popUpMsg, "</div>"));
      });
    },
    buildLoader = function buildLoader($dataList) {
      $('.data-list-spot__load-more-container', $dataList).remove();
      $('.legend', $dataList).remove();
      $('.data-list-spot__load-container', $dataList).remove();
      $(".component-content", $dataList).append('<div class="data-list-spot__load-container"></div>');
      $('.data-list-spot__load-container', $dataList).append('<div class="cssload-container"><div class="cssload-double-torus"><div class="cssload-double-torus-arrow"></div></div></div>');
      showContent($('.data-list-spot__load-container', $dataList));
    },
    removeLoader = function removeLoader($dataList) {
      $('.data-list-spot__load-container', $dataList).remove();
    },
    addEmptyResultComponent = function addEmptyResultComponent($dataList, $container) {
      var config = getConfigForDataList($dataList),
        label = config['emptyResultText'] || "No records match your search.";
      $('.data-list-spot__empty_result-container', $dataList).remove();
      $container.append('<div class="data-list-spot__empty_result-container remove-on-search"><div class="data-list-spot__empty_result">' + label + '</div></div>');
      showContent($('.data-list-spot__empty_result-container', $container));
    },
    getConfiguredDateRange = function getConfiguredDateRange($dataList) {
      var toDate = new Date(),
        fromDate = new Date(),
        config = getConfigForDataList($dataList),
        dateRange = config['dateRange'] ? config['dateRange'] : "60d";

      // if dateRange has a + sign, we want to look ahead.
      if (dateRange.indexOf('+') > -1) {
        if (dateRange.indexOf('m') > -1) {
          toDate.setMonth(toDate.getMonth() + parseInt(dateRange));
        } else if (dateRange.indexOf('y') > -1) {
          toDate.setYear(toDate.getYear() + parseInt(dateRange));
        } else {
          toDate.setDate(toDate.getDate() + parseInt(dateRange));
        }
      } else {
        if (dateRange.indexOf('m') > -1) {
          fromDate.setMonth(fromDate.getMonth() - parseInt(dateRange));
        } else if (dateRange.indexOf('y') > -1) {
          fromDate.setYear(fromDate.getYear() - parseInt(dateRange));
        } else {
          fromDate.setDate(fromDate.getDate() - parseInt(dateRange));
        }
      }
      return {
        from: fromDate,
        to: toDate
      };
    },
    getDataForConfiguredDateRange = function getDataForConfiguredDateRange($dataList, endpoint) {
      DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_SEARCH_QUERY_READY, endpoint, null, null, {}, 'and', null);
    },
    saveResultUrlOnDataList = function saveResultUrlOnDataList($dataList, responseUrl) {
      $dataList.data(consts.DATA_RESPONSE_URL, responseUrl);
    },
    getResultUrlFromDataListWithSort = function getResultUrlFromDataListWithSort($dataList, fieldName, sortType) {
      saveSortOnDataList($dataList, fieldName, sortType);
      var responseUrl = $dataList.data(consts.DATA_RESPONSE_URL),
        orderBy;
      if (responseUrl !== null) {
        orderBy = DataListSpotConfig.resolveSortForField(fieldName, sortType);
        return responseUrl.indexOf('?') !== -1 ? responseUrl + '&' + orderBy : responseUrl + '?' + orderBy;
      }
    },
    getSortFromDataList = function getSortFromDataList($dataList) {
      return {
        fieldName: $dataList.data(consts.DATA_FIELD_NAME),
        sortType: $dataList.data(consts.DATA_SORT_ORDER)
      };
    },
    saveSortOnDataList = function saveSortOnDataList($dataList, fieldName, sortType) {
      $dataList.data(consts.DATA_FIELD_NAME, fieldName);
      $dataList.data(consts.DATA_SORT_ORDER, sortType);
    },
    clearSort = function clearSort($dataList) {
      var sortObj = getSortFromDataList($dataList);
      if (sortObj.fieldName && sortObj.sortType) {
        $('.data-list-spot__headers__header.' + sortObj.fieldName, $dataList).removeClass(sortObj.sortType);
      }
      $dataList.removeData(consts.DATA_FIELD_NAME);
      $dataList.removeData(consts.DATA_SORT_ORDER);
    },
    ExtendWarrantyPreSelected = function ExtendWarrantyPreSelected($dataList) {
      var extendWarrantyBoolean = window.location.href.indexOf('extend-warranty') > -1;
      var targetRendered = $('.warranty-extension-chkBtn .data-list-spot__table.items');
      var observer = new MutationObserver(function () {
        var $ChangeOrderDateClass = $('.data-list-spot__row__column.isWarrantyExpiring');
        if (!$('.observer-already-done').length) {
          $ChangeOrderDateClass.each(function () {
            if ($(this).data('value') == "3") {
              var checkBoxExtended = $(this).siblings('.warranty-extension-chkBtn .warrantyPurchasable.checkbox').find('input[type=checkbox]');
              var $chkWrapperExtension = JSON.parse(window.sessionStorage.getItem('warrantyReportChkBoxesExtension')) || [];
              checkBoxExtended.each(function () {
                $(this).prop('checked', true);
                var registerNumberChkExtension = $(this).offsetParent('.data-list__row__column').siblings('.serialNo').data('value').toString();
                $chkWrapperExtension.push(registerNumberChkExtension);
                window.sessionStorage.setItem('warrantyReportChkBoxesExtension', JSON.stringify($chkWrapperExtension));
                $('.warranty-registration-and-extension-redesign').addClass('observer-already-done');
                // pass the data on extend button click
                if (registerNumberChkExtension != undefined) {
                  $('.extend-selected-device-btn').on('click', function (e) {
                    e.preventDefault();
                    window.location.href = $('.extend-selected-device-btn').attr("data-src") + $chkWrapperExtension.join('|');
                    sessionStorage.removeItem('warrantyReportChkBoxes');
                    sessionStorage.removeItem('warrantyReportChkBoxesExtension');
                  });
                }
              });
            }
          });
        }
      });
      if ($('.warranty-registration-and-extension-redesign').length && extendWarrantyBoolean) {
        observer.observe(targetRendered[0], {
          childList: true
        });
      }
    },
    // To remove clickable PDF links and hide the shipment date for released status from My repair pages on MYO
    RemoveEmptyPDF = function RemoveEmptyPDF($dataList) {
      var targetRendered = $('.repairs-pdf-download .data-list-spot__table.items');
      var observer = new MutationObserver(function () {
        var repairOrderLines = $('.data-list-spot__row__column.document_No.documentNumber').siblings(".status");
        repairOrderLines.each(function () {
          var statusCode = $(this).data('value');
          if (statusCode !== 'Invoiced') {
            $(this).siblings('a').removeAttr("href").addClass("hide-pdf-repairs");
          }
          if (statusCode === 'Released') {
            $(this).siblings(".shipmentDate").children("span").text('N/A');
          }
        });
      });
      if ($('.repairs-pdf-download').length) {
        observer.observe(targetRendered[0], {
          childList: true
        });
      }
    },
    ExtendWarrantyModal = function ExtendWarrantyModal($dataList) {
      var extendWarrantyBoolean = window.location.href.indexOf('extend-warranty') > -1;
      var targetRendered = $('.warranty-extension-chkBtn .data-list-spot__table.items');
      var observer = new MutationObserver(function () {
        if (!$('.dialog-box-extend-warranty').length) {
          var checkExtendedWarrantyInput = function checkExtendedWarrantyInput() {
            var extendWarrantyTab = $('button.tab-links[data-tabname="extend-warranty"]');
            extendWarrantyTab.click();
            $ChangeOrderDateClass.each(function () {
              if ($(this).data('value') == "3") {
                var checkBoxExtended = $(this).siblings('.warranty-extension-chkBtn .warrantyPurchasable.checkbox').find('input[type=checkbox]');
                var $chkWrapperExtension = JSON.parse(window.sessionStorage.getItem('warrantyReportChkBoxesExtension')) || [];
                checkBoxExtended.each(function () {
                  $(this).prop('checked', true);
                  var registerNumberChkExtension = $(this).offsetParent('.data-list__row__column').siblings('.serialNo').data('value').toString();
                  $chkWrapperExtension.push(registerNumberChkExtension);
                  window.sessionStorage.setItem('warrantyReportChkBoxesExtension', JSON.stringify($chkWrapperExtension));
                  // pass the data on extend button click
                  if (registerNumberChkExtension != undefined) {
                    $('.extend-selected-device-btn').on('click', function (e) {
                      e.preventDefault();
                      window.location.href = $('.extend-selected-device-btn').attr("data-src") + $chkWrapperExtension.join('|');
                      sessionStorage.removeItem('warrantyReportChkBoxes');
                      sessionStorage.removeItem('warrantyReportChkBoxesExtension');
                    });
                  }
                });
              }
            });
          };
          var $ChangeOrderDateClass = $('.warranty-extension-chkBtn .data-list-spot__row__column.isWarrantyExpiring');
          var dialogBox = $('<div class="dialog-box-extend-warranty"></div>');
          var innerBox = $('<div class="inner-box"></div>');
          var closeSign = $('<p class="close" role="button" tabindex="0"/>');
          var NumberOfExpiringWarranties = 0;
          $ChangeOrderDateClass.each(function () {
            if ($(this).data('value') == "3") {
              NumberOfExpiringWarranties += 1;
            }
          });
          var message = $("<p class=\"message\">You have ".concat(NumberOfExpiringWarranties, " warranties expiring this week.</p>"));
          var dialogBottom = $('<div class="dialog-bottom"></div>');
          var okButton = $('<input type="button" class="accept" value="Extend now" />');
          innerBox.append(closeSign);
          innerBox.append(message);
          innerBox.append(dialogBottom);
          dialogBottom.append(okButton);
          dialogBox.append(innerBox);
          $('.warranty-registration-and-extension-redesign').append(dialogBox);
          closeSign.off('click');
          okButton.off('click');
          closeSign.on('click', function () {
            dialogBox.removeClass('is-visible');
          });
          okButton.on('click', function () {
            dialogBox.removeClass('is-visible');
            checkExtendedWarrantyInput();
          });
          $ChangeOrderDateClass.each(function () {
            if ($(this).data('value') == "3") {
              dialogBox.addClass('is-visible');
            }
          });
        }
      });
      if ($('.warranty-registration-and-extension-redesign').length && !extendWarrantyBoolean) {
        observer.observe(targetRendered[0], {
          childList: true
        });
      }
    },
    removeSessionVariable = function removeSessionVariable($dataList) {
      if ($('.warranty-registration-and-extension-redesign')) {
        sessionStorage.removeItem('warrantyReportChkBoxes');
        sessionStorage.removeItem('warrantyReportChkBoxesExtension');
      }
    };
  DGS.OnLoad.getInstance().register(initDataListSpot);
  DGS.OnLoad.getInstance().register(RemoveEmptyPDF);
  DGS.OnLoad.getInstance().register(ExtendWarrantyModal);
  DGS.OnLoad.getInstance().register(ExtendWarrantyPreSelected);
  DGS.OnLoad.getInstance().register(removeSessionVariable);
  DGS.Event.getInstance().on('re-init-data-list-spot', initDataListSpot);
  DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_BUILD_QUERY, function ($dataListSearchSpot, endpoint) {
    DataListSpotConfig.initialRequests[endpoint] = {
      queryType: DataListSpotConfig.Event.DATA_REQUEST_BUILD_QUERY,
      $searchSpot: $dataListSearchSpot
    };
    DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_RECEIVED, endpoint);
  });
  DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_BUILD_ADV_QUERY, function ($dataListSearchSpot, endpoint) {
    DataListSpotConfig.initialRequests[endpoint] = {
      queryType: DataListSpotConfig.Event.DATA_REQUEST_BUILD_ADV_QUERY,
      $searchSpot: $dataListSearchSpot
    };
    DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_RECEIVED, endpoint);
  });
  DGS.DataListSpotConfig = DataListSpotConfig;
})(jQuery, window.DGS);