import { resolveThumbnailImage } from '@demant/product-experience-page/lib/visual-oticon/configuration/utils';
import { fastlyCompressionQuery } from '@demant/product-experience-page/lib/visual-oticon/configuration/constans';
var isIOSDevice = function isIOSDevice(name) {
  return name.indexOf('iPhone') > -1 || name.indexOf('iPad') > -1 || name.indexOf('Apple') > -1 || name.indexOf('iPod') > -1;
};
var mapResults = function mapResults(result) {
  var output = {
    accessories: result.accessories.map(function (accessory) {
      return {
        id: accessory.id,
        title: accessory.marketingTitle,
        description: accessory.marketingDescription,
        imageLink: accessory.image ? resolveThumbnailImage(accessory.image, fastlyCompressionQuery.compatibilityGuideOverview) : null,
        directCompatibility: accessory.directCompatibility,
        directStreaming: accessory.directStreaming,
        lea2Support: accessory.lea2Support,
        telVsBlue: false,
        iosVersion: accessory.iosVersion,
        androidVersion: accessory.androidVersion
      };
    }),
    apps: result.apps.map(function (app) {
      return {
        id: app.id,
        title: app.marketingTitle,
        description: app.marketingDescription,
        imageLink: app.image ? resolveThumbnailImage(app.image, fastlyCompressionQuery.compatibilityGuideOverview) : null,
        iosVersion: app.iosVersion,
        androidVersion: app.androidVersion
      };
    }),
    devices: result.devices.map(function (device) {
      return {
        id: device.id,
        title: device.name,
        directCompatibility: device.directCompatibility,
        directStreaming: device.directStreaming,
        lea2Compatibility: device.lea2Compatibility,
        minimumSoftwareVersion: device.minimumSoftwareVersion,
        isIOSDevice: isIOSDevice(device.name)
      };
    }),
    products: result.products.map(function (product) {
      return {
        id: product.id,
        title: product.marketingTitle,
        subTitle: product.marketingSubtitle,
        description: product.marketingDescription,
        brand: product.brand,
        family: product.family,
        style: product.style,
        imageLink: product.image ? resolveThumbnailImage(product.image, fastlyCompressionQuery.compatibilityGuideOverview) : null,
        directCompatibility: product.directCompatibility,
        directStreaming: product.directStreaming,
        lea2Support: product.lea2Support,
        telVsBlue: product.wirelessRf === null,
        iosVersion: product.iosVersion,
        androidVersion: product.androidVersion
      };
    })
  };
  document.querySelector('.reverse-products') ? output.products.reverse() : null;
  return output;
};
export default mapResults;