require("./wdh-legacy/text-image-spot/js/base/component-text-image-spot.js");
import { trackDownload } from "../project/tracking-gtm-ga4";
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.text-image-spot').length) {
      $('.text-image-spot').each(function () {
        var $elem = $(this);
        $('a', $elem).each(function () {
          var href = $(this).attr('href');
          if (href && href.split('.').pop().split('?').shift() === 'pdf') {
            $(this).on('click', function () {
              trackDownload('pdf', href.split('/').pop());
            });
          }
        });
      });
    }
  });
})(jQuery); // eslint-disable-line no-undef