var event = 'genericGAEvent';
var _window = window,
  DGS = _window.DGS;
DGS.GTMTracking = DGS.GTMTracking || {
  getDataLayer: function getDataLayer() {
    if (typeof dataLayer !== 'undefined') {
      return dataLayer;
    }
    return {
      push: function push(data) {
        console.log('GTM not implemented. Debug data: ', data);
      }
    };
  }
};
export var isDataLayer = function isDataLayer() {
  return window.DGS && window.DGS.GTMTracking && window.DGS.GTMTracking.getDataLayer();
};
export var pushEventToDataLayer = function pushEventToDataLayer(eventData) {
  if (isDataLayer) {
    window.DGS.GTMTracking.getDataLayer().push(eventData);
  } else {
    console.log("DGS GTM not implemented. Debug data: ".concat(eventData));
  }
};
export var buildAndPushGenericGAEvent = function buildAndPushGenericGAEvent(eventCategory, eventAction, eventLabel, eventValue) {
  var eventData = {
    event: event,
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel,
    event_value: eventValue
  };
  pushEventToDataLayer(eventData);
};

// HCL tracking
export var trackHCLSearch = function trackHCLSearch(searchTerm) {
  return buildAndPushGenericGAEvent('hcl', 'search', searchTerm, '');
};
export var trackHCLClinicAction = function trackHCLClinicAction(actionClick, clinicName) {
  return buildAndPushGenericGAEvent('hcl', actionClick, clinicName, '');
};
export var trackHCLClinicClick = function trackHCLClinicClick(clinicName) {
  return buildAndPushGenericGAEvent('hcl', 'click_clinic', clinicName, '');
};

// Download center tracking
export var trackDownload = function trackDownload(fileType, fileName) {
  return buildAndPushGenericGAEvent('download', fileType, fileName, '');
};

// Generic Form Tracking
export var trackFormSubmit = function trackFormSubmit(formName) {
  return buildAndPushGenericGAEvent('form_submitted', formName, '', '');
};

// basic video spot Tracking
// because most videos are from 23 video we cannot at this time track if video is playing or not.  So we will show status of player overlay (open, closed)
export var trackVideo = function trackVideo(interaction_type) {
  buildAndPushGenericGAEvent('video', interaction_type, '', '');
};

// Hearing Test tracking
export var trackHearingTest = function trackHearingTest(stepNumber, stepLabel) {
  return buildAndPushGenericGAEvent('hearing_test', stepNumber, stepLabel, '');
};
export var trackHearingTestScore = function trackHearingTestScore(stepNumber, stepLabel, stepScore) {
  return buildAndPushGenericGAEvent('hearing_test', stepNumber, stepLabel, stepScore);
};