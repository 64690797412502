// import validator from 'validator';

(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.component.form-opn-rft')[0]) {
      var myForm = $('.component.form').wffm({
        niceSelectClass: 'square-corners',
        // make nice-select like rest of the wffm
        moveAsteriskToLabel: true
      });

      // need to add this class so that when using niceSelect update function, class doesn't get removed.
      $('.wearHearingAidsYes select').addClass('square-corners');
      $('.wearHearingAidsNo select').addClass('square-corners');
      var $userStateSelect = $('.userState select');
      $userStateSelect.find('option:first').append('State*');
      $userStateSelect.addClass('square-corners');
      $userStateSelect.niceSelect('update');
      var formHasErrors = false;

      // check if validator is active.  highlight fields with errors
      myForm.scfValidator().each(function (index, elem) {
        var $elem = $(elem);
        if ($elem.css('display') !== 'none') {
          $elem.parent().find('input').addClass('hasError');
          formHasErrors = true;
        }
        $elem.css('display', 'none');
      });
      myForm.scfValidatorRequired().each(function (index, elem) {
        var $elem = $(elem);
        var $elemParentCheckBox = $elem.parent();
        if ($elemParentCheckBox.length) {
          $elemParentCheckBox.addClass('hasError');
          formHasErrors = true;
        }
      });
      myForm.scfValidationSummary().hide();
      var animDelay = 250;

      // set up hide/show functionality for select fields.
      var $HCPName = $('.HCPName');
      var $HCPCity = $('.HCPCity');
      var $HCPCompany = $('.HCPCompany');
      var $wearHearingAidsNo = $('.wearHearingAidsNo');
      var $wearHearingAidsYes = $('.wearHearingAidsYes');
      var $HCPSelectedRadio = $('.hideShowHCPInfo input[type="radio"]:checked');
      var $WearsHASelectedRadio = $('.hideShowWearHearingAids input[type="radio"]:checked');

      // hides or shows the hidden fields in case form returns validation error and the users answer
      if ($HCPSelectedRadio.length && $HCPSelectedRadio.val().toLowerCase() === 'yes') {
        $HCPName.show(animDelay).css('display', 'block');
        $HCPCity.show(animDelay).css('display', 'block');
        $HCPCompany.show(animDelay).css('display', 'block');
      } else {
        // hide fields until triggered
        $HCPName.hide(animDelay);
        $HCPCity.hide(animDelay);
        $HCPCompany.hide(animDelay);
      }
      if ($WearsHASelectedRadio.length && $WearsHASelectedRadio.val().toLowerCase() === 'yes') {
        $wearHearingAidsNo.hide(animDelay);
        $wearHearingAidsYes.show(animDelay).css('display', 'block');
      } else if ($WearsHASelectedRadio.length && $WearsHASelectedRadio.val().toLowerCase() === 'no') {
        $wearHearingAidsNo.show(animDelay).css('display', 'block');
        $wearHearingAidsYes.hide(animDelay);
      } else {
        $wearHearingAidsNo.hide(animDelay);
        $wearHearingAidsYes.hide(animDelay);
      }
      $('.hideShowHCPInfo input[type="radio"]').on('click', function () {
        var selectedRadio = $('.hideShowHCPInfo input[type="radio"]:checked');
        if (selectedRadio.val() === 'Yes') {
          var $HCPNameInput = $('.HCPName .scfSingleLineTextBox');
          var $HCPCityInput = $('.HCPCity .scfSingleLineTextBox');
          var $HCPCompanyInput = $('.HCPCompany .scfSingleLineTextBox');

          // remove the NA added on Submit if validation fails and user changes answer.
          $HCPNameInput.val('');
          $HCPCityInput.val('');
          $HCPCompanyInput.val('');
          $HCPName.show(animDelay).css('display', 'block');
          $HCPCity.show(animDelay).css('display', 'block');
          $HCPCompany.show(animDelay).css('display', 'block');
        } else {
          $HCPName.hide(animDelay);
          $HCPCity.hide(animDelay);
          $HCPCompany.hide(animDelay);
        }
      });
      $('.hideShowWearHearingAids input[type="radio"]').on('click', function () {
        var selectedRadio = $('.hideShowWearHearingAids input[type="radio"]:checked');
        var wearHADDYes = $('.wearHearingAidsYes select');
        var wearHADDNo = $('.wearHearingAidsNo select');
        if (selectedRadio.val() === 'Yes') {
          $wearHearingAidsNo.hide(animDelay);
          // reset value of hidden checkbox so it doesn't get sent to CRM.
          wearHADDNo.val("");
          wearHADDNo.niceSelect('update');
          $wearHearingAidsYes.show(animDelay).css('display', 'block');
        } else {
          $wearHearingAidsNo.show(animDelay).css('display', 'block');
          $wearHearingAidsYes.hide(animDelay);
          // reset value of hidden checkbox so it doesn't get sent to CRM.
          wearHADDYes.val("");
          wearHADDYes.niceSelect('update');
        }
      });

      // insert default values for required fields if hidden
      var $submitButton = $('.scfSubmitButton');
      if (formHasErrors) {
        var errorMessage = $submitButton.parent().append('<p>Please fix required fields</p>');
        errorMessage.addClass('errormessage');
      }

      // move footer message after button
      var $footer = $('.scfFooterBorder');
      $submitButton.parent().after($footer);
      $submitButton.on('click', function (event) {
        var selectedRadio = $('.hideShowHCPInfo input[type="radio"]:checked');
        if (selectedRadio.val() !== 'Yes') {
          var $HCPNameInput = $('.HCPName .scfSingleLineTextBox');
          var $HCPCityInput = $('.HCPCity .scfSingleLineTextBox');
          var $HCPCompanyInput = $('.HCPCompany .scfSingleLineTextBox');
          $HCPNameInput.val('NA');
          $HCPCityInput.val('NA');
          $HCPCompanyInput.val('NA');
        }
      });

      // Hidden Field Values from URL
      var urlString = window.location.href;
      var url = new URL(urlString);
      var utmCampaign = url.searchParams.get('utm_campaign');
      var utmContent = url.searchParams.get('utm_content');
      var utmMedium = url.searchParams.get('utm_medium');
      var utmSource = url.searchParams.get('utm_source');
      $('.component.form-opn-rft .scfSectionContent>.scfHiddenFieldBorder').each(function () {
        if ($(this).hasClass('name.UTM+Campaign')) {
          $(this).find('input').attr('value', utmCampaign);
        } else if ($(this).hasClass('name.UTM+Content')) {
          $(this).find('input').attr('value', utmContent);
        } else if ($(this).hasClass('name.UTM+Medium')) {
          $(this).find('input').attr('value', utmMedium);
        } else if ($(this).hasClass('name.UTM+Source')) {
          $(this).find('input').attr('value', utmSource);
        }
      });
    } // if end
  });
})(jQuery);