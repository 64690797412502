import axios from 'axios';
import AnalyticsUtils from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/utilities/analytics-utils';
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.payment-confirmation-spot').each(function () {
      var $this = $(this);
      var $dialogBox = $('.dialog-box', $this);
      var $dialogBoxContentContainer = $('.emailform', $dialogBox);
      var $dialogCloseButton = $('.close', $dialogBox);
      var $mailButton = $('.payment-confirmation-spot--mail', $this);
      var $printButton = $('.payment-confirmation-spot--print', $this);
      var cardType = $('.payment-confirmation-spot--type', $this);
      var cardTypeText = cardType.text().trim();
      var dictionary = {
        B2B: {
          cardTypeVisa: 'VISA',
          cardTypeMastercard: 'MASTERCARD',
          cardTypeAmEx: 'AMERICAN EXPRESS',
          paymentConfirmationEmailConfirmationTitle: 'Email Confirmation',
          paymentConfirmationEmailConfirmationText: 'A confirmation email has been sent.',
          paymentConfirmationEmailFailureTitle: 'Email Failed',
          paymentConfirmationEmailFailureText: 'An unknown error occurred.  Please try again or contact support',
          paymentConfirmationEmailConfirmationButton: 'Ok'
        }
      };
      AnalyticsUtils.triggerGenericEvent(AnalyticsUtils.eventCategories.INVOICE_PAYMENT); // GA4

      window.DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        var okBtn = $("<a href=\"#\" class=\"button\">".concat(dictionary.B2B.paymentConfirmationEmailConfirmationButton, "</a>"));
        var successMessage = "<div class=\"title\">".concat(dictionary.B2B.paymentConfirmationEmailConfirmationTitle, "</div>\n<div class=\"body\">").concat(dictionary.B2B.paymentConfirmationEmailConfirmationText, "</div>");
        var errorMsg = "<h3>".concat(dictionary.B2B.paymentConfirmationEmailFailureTitle, "</h3>\n<div class=\"body\">").concat(dictionary.B2B.paymentConfirmationEmailFailureText, "</div>");
        $printButton.on('click', function () {
          window.print();
        });
        $mailButton.on('click', function () {
          var endpoint = '/webservices/payment.svc/sendemail';
          axios.get(endpoint).then(function (response) {
            if (response.data) {
              $dialogBoxContentContainer.html(successMessage);
            } else {
              $dialogBoxContentContainer.html(errorMsg);
            }
            $dialogBoxContentContainer.append(okBtn);
            okBtn.on('click', function () {
              $dialogBox.hide();
            });
          }).catch(function () {
            $dialogBoxContentContainer.html(errorMsg);
            $dialogBoxContentContainer.append(okBtn);
            okBtn.on('click', function () {
              $dialogBox.hide();
            });
          });
          $dialogBoxContentContainer.html($("<div class=\"cssload-double-torus\">\n<div class=\"cssload-double-torus-arrow\" />\n</div>"));
          $dialogBox.show();
        });
        $dialogCloseButton.on('click', function () {
          $dialogBox.hide();
        });
        var cardTypeString = '';
        switch (cardTypeText) {
          case '001':
            cardTypeString = dictionary.B2B.cardTypeVisa;
            break;
          case '002':
            cardTypeString = dictionary.B2B.cardTypeMastercard;
            break;
          case '003':
            cardTypeString = dictionary.B2B.cardTypeAmEx;
            break;
          default:
            break;
        }
        cardType.text(cardTypeString);
      });
    });
  });
})(jQuery);