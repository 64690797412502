(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.lattice-spot').each(function () {
      var $spot = $(this);
      window.DGS.EqualHeight.getInstance().register($('.lattice-spot-item', $spot), true);

      /*to target the lattice spot item that has the lattice-spot-link and obtain the lattice image
      and make it clickable*/
      $('.lattice-spot-item').each(function () {
        var lat = $(this).find('.lattice-spot-link');
        if (lat[0]) {
          var latticeLink = lat[0].firstChild.href;
          var latticeTarget = lat[0].firstChild.target ? lat[0].firstChild.target : '_self';
          if (lat[0].className === 'lattice-spot-link') {
            var latItem = $(this).find('.lattice-spot-item');
            $(latItem.prevObject[0].children[1]).css('cursor', 'pointer').on('click', function () {
              window.open(latticeLink, latticeTarget);
            });
          }
        }
      });
    });
  });
})(jQuery);