import { WDHSitecoreHelper } from "../../../../wdh-foundation/wdh";

/**
 * Created by mady on 26-04-2017.
 */
(function ($) {
  var socialSharingConfig = {
    layout: "float",
    insertAfter: "#content",
    serviceElementsHeaderClass: "service-elements-header",
    serviceElementClass: "service-element",
    shareWindowDimensions: "width=626,height=436"
  };
  DGS.useCustomSelect(function () {
    if (window.DGS.UserAgent.getInstance().mobileTabletCheck()) {
      var $parent,
        $selects = $('select');
      $selects.each(function () {
        $parent = $(this).parent();
        $parent.addClass('add-arrow-for-mobile');
        $parent.css('position', 'relative');
      });
    }
  });

  // allow links from Clickdimensions campaigns to go to section of website
  DGS.OnContentLoaded.getInstance().register(function () {
    var url = window.location.href;
    var urlFragment = url.match(/#(.+?)&/);
    if (urlFragment) {
      var $hash = $("#".concat(urlFragment[1])).length ? $("#".concat(urlFragment[1])) : $("a[name=\"".concat(urlFragment[1], "\"]"));
      if ($hash.length > 0) {
        setTimeout(function () {
          if ($hash.offset().top > 0) {
            $('html, body').animate({
              scrollTop: $hash.offset().top
            }, 200);
          }
        }, 500); // This is the time we wait for the components to load and other scrolltop event to fire
      }
    }

    // KEEP FOP FORM IN VIEW FOR FOP VOTING FORM 2020
    var votingForm = $('.focus-on-people-voting');
    if (votingForm.length > 0) {
      if ($('input[type=submit]', votingForm).length === 0 || $('.scfValidationSummary > ul').length > 0) {
        // assume form was submitted if no submit button or we have validation errors.
        window.setTimeout(function () {
          var elemTop = votingForm.offset().top - 150;

          // 800 pretty high for timeout but appears we are fighting for other scoll events on the page.
          $('html, body').animate({
            scrollTop: elemTop
          });
        }, 800);
      }
    }
  });
  window.DGS.OnLoad.getInstance().register(function () {
    // SETUP GLOBAL A TAG ANCHOR SCROLLING
    $('a').each(function () {
      var href = $(this).attr('href');
      if (href !== undefined && href.indexOf('#') !== -1) {
        var targetName = href.substr(href.indexOf('#') + 1);
        if (targetName !== '') {
          $(this).on('click', function (e) {
            var aTargetName = $("a[name=\"".concat(targetName, "\"]"));
            var $target = aTargetName.length ? aTargetName : $("#".concat(targetName)); // name attribute is obsolete in HTML5.  start looking for id equivalent.
            var position = $target.position();
            var top = position ? position.top : null;
            var $submenuSpot = $('.component.submenu-spot');
            var $submenu = $submenuSpot.length ? $submenuSpot : $('.component.richtext-submenu');
            var submenuHeight = $submenu.length ? $submenu.outerHeight() : 0;
            if (top != null) {
              e.preventDefault();
              $('html, body').animate({
                scrollTop: top - submenuHeight
              }, 600);
            }
          });
        }
      }
    });
    WDHSitecoreHelper.fixRTESpanLinks(["button-white"]);
    if ($("#social-sharing").length) {
      $("#social-sharing").sharingTools({
        layout: socialSharingConfig.layout,
        insertAfter: socialSharingConfig.insertAfter,
        serviceElementsHeaderClass: socialSharingConfig.serviceElementsHeaderClass,
        serviceElementClass: socialSharingConfig.serviceElementClass,
        shareWindowDimensions: socialSharingConfig.shareWindowDimensions
      });
      //if social media icons are present add identifying class to cookie-warning for manipulation purposes
      $(".component.cookie-warning").addClass("so-me");
    }

    // if floating button is present add identifying class to cookie-warning for manipulation purposes
    if ($(".opn-floating-button").length) {
      $(".component.cookie-warning").addClass("show-me");
    }

    // append any url parameters to the link this is applied to.
    if ($('.pass-params-from-url').length) {
      var $linkTag = $('.pass-params-from-url');
      var $href = $linkTag.attr('href');
      var $search = window.location.search;
      $href += $search;
      $linkTag.attr('href', $href);
    }
    window.DGS.Resizer.getInstance().register(function () {
      if ($("#social-sharing").hasClass("float")) {
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO FOOTER
        var mq = window.matchMedia("(min-width: 992px)"); //OTICON SPECIFIC WIDTH
        var socialSharingHeight = 0;
        if (mq.matches) {} else {
          socialSharingHeight = $("#social-sharing.float").outerHeight();
        }
        $('#footer').css('margin-bottom', socialSharingHeight);
      }
    });
  });
  window.DGS.OnLoad.getInstance().register(function () {
    var showEnvRibbon = false;
    var ribbonContents = '';
    if (window.location.href.indexOf('qa.cas.dgs.com') !== -1) {
      // env is QA
      showEnvRibbon = true;
      ribbonContents = 'qa';
    } else if (window.location.href.indexOf('test.cas.dgs.com') !== -1) {
      // Env is TEST
      showEnvRibbon = true;
      ribbonContents = 'test';
    } else if (window.location.href.indexOf('dev.cas.dgs.com') !== -1) {
      // Env is DEV
      showEnvRibbon = true;
      ribbonContents = 'dev';
    }
    if (showEnvRibbon) {
      var ribbonNode = document.createElement('div');
      ribbonNode.classList.add('env-ribbon');
      ribbonNode.classList.add("env-ribbon-".concat(ribbonContents));
      ribbonNode.innerText = ribbonContents.toUpperCase();
      document.body.appendChild(ribbonNode);
    }
  });
})(jQuery, window.DGS);