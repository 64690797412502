import moment from 'moment';
(function (DGS) {
  DGS.OnLoad.getInstance().register(function () {
    /**
     * formats a date in an expected string format to MMMM D, YYYY format
     * @param elem {HTMLElement}
     * @param expectedDateFormat {string} - D. MMMM YYYY default
     * @param returnFormat {string} - default MMMM D, YYYY
     * @returns {string}
     */
    function reformatDate(elem) {
      var expectedDateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'D. MMMM YYYY';
      var returnFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'MMMM D, YYYY';
      return moment(elem.innerText, expectedDateFormat).format(returnFormat);
    }

    /**
     * B2B News Archive Summary Spot (News Archive Spot)
     * displays a summary of news items on the MyO homepage
     */
    var summaryComponents = document.querySelectorAll('.news-archive-spot.b2b-news-summary-spot');
    summaryComponents.forEach(function (component) {
      var newsItems = component.querySelectorAll('li');
      newsItems.forEach(function (newsItem) {
        var newsDate = newsItem.querySelector('.news-date');
        var newsLink = newsItem.querySelector('.news-link a');
        newsDate.innerText = reformatDate(newsDate);
        newsLink.innerText = 'Read more';
        newsLink.classList.remove('link');
      });
    });

    /**
     * B2B News Archive Spot (News Archive Spot)
     * displays a fuller listing of news items on the MyO Press Room page
     */
    var archiveComponents = document.querySelectorAll('.news-archive-spot.b2b-news-archive-spot');
    archiveComponents.forEach(function (component) {
      var newsItems = component.querySelectorAll('li');
      newsItems.forEach(function (newsItem) {
        var newsDate = newsItem.querySelector('.news-date');
        var newsLink = newsItem.querySelector('.news-link a');
        newsDate.innerText = reformatDate(newsDate);
        newsLink.innerText = 'Read more';
      });
    });
    /**
     * Oticon News Article (Press releases)
     * Displays load more pagination.
     */
    var newsOticonArticles = $('.news-archive-spot.news-press-releases .component-content ul li');
    var allNewsOticonArticles = $('.news-archive-spot.news-press-releases .component-content');
    allNewsOticonArticles.addClass('pagination-ready');
    var maxArticlesPerPages = 10;
    var currentPages = 1;
    function paginateNewsArticles() {
      if (newsOticonArticles.length > maxArticlesPerPages) {
        newsOticonArticles.removeClass('hide-from-list');
        for (var i = maxArticlesPerPages * currentPages; i < newsOticonArticles.length; i++) {
          $(newsOticonArticles[i]).addClass('hide-from-list');
        }
      }
      if (maxArticlesPerPages * currentPages >= newsOticonArticles.length) {
        $('#addMoreButtonNew').remove();
      }
    }
    var viewMoreNewsButtons = $('<div class="loadMorePressNewsBtn"><button class="button" id="addMoreButtonNew">Load more</button></div>');
    allNewsOticonArticles.append(viewMoreNewsButtons);
    viewMoreNewsButtons.on('click', function (e) {
      e.preventDefault();
      currentPages += 1;
      paginateNewsArticles();
    });
    paginateNewsArticles();

    /**
     * B2B News Article (Press room)
     * Displays load more pagination.
     */
    var newsArticles = $('.news-archive-spot.b2b-news-archive-spot .component-content ul li');
    var allNewsArticles = $('.news-archive-spot.b2b-news-archive-spot .component-content');
    allNewsArticles.addClass('pagination-ready');
    var maxArticlesPerPage = 6;
    var currentPage = 1;
    function paginateArticles() {
      if (newsArticles.length > maxArticlesPerPage) {
        newsArticles.removeClass('hide-from-list');
        for (var i = maxArticlesPerPage * currentPage; i < newsArticles.length; i++) {
          $(newsArticles[i]).addClass('hide-from-list');
        }
      }
      if (maxArticlesPerPage * currentPage >= newsArticles.length) {
        $('#addMoreButtonNews').remove();
      }
    }
    var viewMoreButton = $('<div class="loadMorePressNewsBtn"><button class="button" id="addMoreButtonNews">Load more</button></div>');
    allNewsArticles.append(viewMoreButton);
    viewMoreButton.on('click', function (e) {
      e.preventDefault();
      currentPage += 1;
      paginateArticles();
    });
    paginateArticles();

    /**
     * B2B News Article (News Spot)
     * Displays a single news Item.
     */
    var newsArticleComponents = document.querySelectorAll('.b2b-news-article');
    var backButtonUrl = '/myoticon/press-room';
    newsArticleComponents.forEach(function (component) {
      var componentContent = component.querySelector('.component-content');
      var buttonDiv = document.createElement('div');
      var backButton = document.createElement('a');
      backButton.classList.add('button');
      backButton.classList.add('dark-bg');
      backButton.setAttribute('href', backButtonUrl);
      backButton.innerText = 'View all news';
      buttonDiv.insertAdjacentElement('beforeend', backButton);
      componentContent.insertAdjacentElement('beforeend', buttonDiv);
      var newsDate = component.querySelector('.field-date');
      newsDate.innerText = reformatDate(newsDate, 'D MMMM YYYY'); // Date format slightly different than others.
    });
  });
})(window.DGS);