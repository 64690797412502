import { TweenMax, Power2 } from 'gsap';
(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.rich-text.order-details .help').each(function () {
      var $this = $(this),
        $content = $('.help-content', $this.parent()),
        $overlay = $('.help-content-overlay', $this.parent()),
        $contentButton = $('.help-content-button', $content);
      $.merge($this, $contentButton).click(function () {
        if (!$overlay.length) {
          $overlay = $('<div class="help-content-overlay"></div>');
          $content.before($overlay);
        }
        if (!$content.is(':visible')) {
          $overlay.show();
          $content.show();
          TweenMax.fromTo($content, 0.3, {
            alpha: 0
          }, {
            alpha: 1,
            ease: Power2.easeIn
          });
        } else {
          TweenMax.fromTo($content, 0.3, {
            alpha: 1
          }, {
            alpha: 0,
            ease: Power2.easeIn,
            onComplete: function onComplete() {
              $content.hide();
              $overlay.hide();
            }
          });
        }
      });
      var isFirst = true;
      $('.component.top-margin').each(function () {
        if (!isFirst) $(this).removeClass('top-margin');
        if (isFirst) {
          isFirst = false;
        }
      });
    });

    // moving quick button to bottom
    var $ftButtonWrapper = document.createElement('div'),
      $topHeaderButtons = $('.component.rich-text.order-details.b2b-usa-print-and-quick-buttons .component-content').html();
    $ftButtonWrapper.className = 'ftButtonWrapper';
    $('.component.b2b-entry-details.order-details .order-details-footer-entry + a').after($ftButtonWrapper);
    if ($topHeaderButtons !== undefined) {
      $($ftButtonWrapper).append($topHeaderButtons);
    }
    $('.ftButtonWrapper').find('a.button-blue').removeClass('button-blue').addClass('button');

    // invoice and credit memos disclaimer

    var $disclaimerWrapper = $('.b2b-detail-disclaimer.b2b-order-detail.b2b-usa-move-down').html();
    if ($disclaimerWrapper !== undefined) {
      $('.component.b2b-order-detail').last().append('<div class="disclaimer-wrapper">' + $disclaimerWrapper + '</div>');
    }
  });
})(jQuery);