(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    // error message dynamic div
    var emptyMessageDiv = document.createElement('div');
    emptyMessageDiv.className = 'data-list-spot__empty_result-container';
    emptyMessageDiv.innerHTML = "No records match your search.";
    emptyMessageDiv.setAttribute("style", "text-align: center;");
    var serviceHistoryEmptyMessageDiv = document.createElement('div');
    serviceHistoryEmptyMessageDiv.className = 'data-list-spot__empty_result-container';
    serviceHistoryEmptyMessageDiv.innerHTML = 'There is no information regarding service history avalable on this device';

    // check if warranty history  has no data
    if (!$('.data-list-spot__row__column')) {
      $('.warranty-history').find('.component-content .data-list-spot__table-container').last().append(emptyMessageDiv);
    }
    $('.component.instrument-details-data-list.service-history').hide();
    $('.component.instrument-details-tabs .tab').each(function (e) {
      $(this).on('click', tabClicked);
    });
    $('#warranty-history .buttons .printable').on('click', function (e) {
      var serviceCard = $(".instrument-details.warranty-history").find(".data-list-spot__row:first").children('.serviceCardNo:first').data('value');
      if (serviceCard) {
        var baseServiceURL = "/webservices/Warranties.svc";
        var resultUrl = baseServiceURL + "/" + "requestpdf" + "/" + serviceCard;
        var attempt = 10;
        attemptPDF(attempt, resultUrl, serviceCard);
      }
    });
    function tabClicked(e) {
      e.preventDefault();
      $('.instrument-details-data-list').hide();
      $('.tab').toggleClass('selected');
      var listToShow = $(this).data('list');
      var emptyMessage = listToShow === 'service-history' ? serviceHistoryEmptyMessageDiv : emptyMessageDiv;
      var elementToFind = '[id="' + listToShow + '"]';
      $(elementToFind).toggle();
      // error message if no data found
      if ($(elementToFind).find('.data-list-spot__row__column').length === 0) {
        $(elementToFind).find('.component-content .data-list-spot__table-container').last().append(emptyMessage);
      }
    }
    function attemptPDF(attempt, url, serviceCard) {
      if (attempt < 0) return false;
      if (attempt === 0) getPDF(serviceCard);
      $.ajax({
        url: url,
        type: 'GET'
      }).then(function (data, textState, jqXHR) {
        switch (jqXHR.status) {
          case 201:
            getPDF(serviceCard);
            break;
          case 202:
            setTimeout(getPDF(serviceCard), 200);
            break;
          default:
            return false;
            break;
        }
      }).then(function (data, textState, jqXHR) {
        return false;
      });
      return false;
    }
    function getPDF(serviceCard) {
      var resultUrl = "/webservices/Warranties.svc" + "/" + "RequestPDFCertificate" + "/" + serviceCard;

      // window.location = resultUrl;
      window.open(resultUrl, '_blank');
    }
  });
})(jQuery);