(function ($, DGS, moment) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $('.component.blog-list').each(function () {
      $('#blog-list li', $(this)).each(function () {
        // Now change the date format
        var $newsDate = $(".news-date", $(this));

        // Moves the date to after the image
        $newsDate.insertAfter($(".news-image", $(this)), null);
      });
    });
    var articles = $('.blog-list ul#blog-list li');
    var blogList = $('.component-content.bloglist');
    $('.component.blog-list').addClass('pagination-ready');
    var maxArticlesPerPage = 12;
    var currentPage = 1;
    function paginateArticles() {
      if (articles.length > maxArticlesPerPage) {
        articles.removeClass('hide-from-list');
        for (var i = maxArticlesPerPage * currentPage; i < articles.length; i++) {
          $(articles[i]).addClass('hide-from-list');
        }
      }
      if (maxArticlesPerPage * currentPage >= articles.length) {
        $('#addMoreButton').remove();
      }
    }
    var viewMoreButton = $('<button id="addMoreButton">Load more</button>');
    viewMoreButton.addClass('button');
    blogList.append(viewMoreButton);
    viewMoreButton.on('click', function (e) {
      e.preventDefault();
      currentPage += 1;
      paginateArticles();
    });
    paginateArticles();
  });
})(jQuery, window.DGS, window.moment);