(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var dontShowBannerCookie = window.DGS.Cookies.getInstance().readCookie("dontShowBanner");
    if (window.location.href.indexOf("professionals") > -1 && $('.emergency-banner')[0] && $.trim($('.emergency-banner .component-content').html()).length !== 0) {
      var startTimer = function startTimer() {
        var timeElapsed = (Date.now() - sessionStartCookie) / 1000; // get the number of secconds that have passed
        if (timeElapsed > 60) {
          emergencyWrapper.slideUp();
          emergencyWrapperContainer.removeClass('emergencyBannerTrue');
          window.DGS.Cookies.getInstance().createCookie("dontShowBanner");
          window.clearInterval(intervalID);
        }
      };
      var emergencyWrapperContainer = $('#wrapper');
      var emergencyWrapper = $('.emergency-banner');
      var emergencyClose = $('.emergency-close a');
      var sessionStartCookie = window.DGS.Cookies.getInstance().readCookie("sessionStart");
      var intervalID = null;
      if (!dontShowBannerCookie) {
        emergencyWrapperContainer.addClass('emergencyBannerTrue');
        emergencyWrapper.show();
        emergencyClose.on('click', function () {
          emergencyWrapper.slideUp();
          window.DGS.Cookies.getInstance().createCookie("dontShowBanner");
          emergencyWrapperContainer.removeClass('emergencyBannerTrue');
        });
        if (sessionStartCookie === "" || sessionStartCookie == null) {
          window.DGS.Cookies.getInstance().createCookie("sessionStart", Date.now(), '');
          sessionStartCookie = window.DGS.Cookies.getInstance().readCookie("sessionStart");
          intervalID = window.setInterval(startTimer, 1000);
        } else {
          intervalID = window.setInterval(startTimer, 1000);
        }
      } else {
        emergencyWrapper.slideUp();
        emergencyWrapperContainer.removeClass('emergencyBannerTrue');
      }
    } else {
      // if not professional pages
      if (dontShowBannerCookie === "" || dontShowBannerCookie == null) {
        window.DGS.Cookies.getInstance().createCookie("dontShowBanner", "", "");
        window.DGS.Cookies.getInstance().createCookie("sessionStart", "", "");
      }
    }
  });
})(jQuery);