(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    // confirmation-payment-spot

    if ($('.confirmation-payment-spot').length > 0) {
      var dictionary = {
        B2B: {
          paymentsConfirmPaymentShowAll: 'show all'
        },
        B2B_Payments: {
          sharedCancelPaymentButtonLabel: 'Cancel payment',
          sharedContinuePaymentButton: 'Continue with payment - test',
          cancelWarning: 'Are you sure you want to cancel the payment?'
        }
      };
      DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        var $invoiceContainer = $('.confirmation-payment-spot--invoice');
        var invoices = $('.confirmation-payment-spot--text', $invoiceContainer);
        var invoicesLength = invoices.length / 3; // accounts for invoice number and balance.
        var showMaxInvoices = 10;
        var $cancelButton = $('input[id$=Cancel]');
        var $dialogBox = $('<div class="dialog-box" />');
        var $dialogInnerBox = $('<div class="inner-box" />');
        var dialogTypes = {
          YES_NO: 'yesNo',
          CANCEL_PAYMENT: 'cancelPayment',
          OK_CANCEL: 'okCancel'
        };
        var $content = $('.component-content', '.component.confirmation-payment-spot');
        var $submitButton = $('.confirmation-payment-spot--submit [id*="Confirmation"]');
        var $spinner = $("<div class='spinner-wrapper'>\n            <div class=\"cssload-double-torus\">\n              <div class=\"cssload-double-torus-arrow\" />\n            </div>\n        </div>");
        $('.component.confirmation-payment-spot').each(function (index, elem) {
          var $this = $(elem);
          $this.append($spinner);
        });
        function showBlurredSpinner() {
          $content.addClass('blurred');
          $spinner.addClass('show-spinner');
        }
        function removeBlurredSpinner() {
          $content.removeClass('blurred');
          $spinner.removeClass('show-spinner');
        }
        $submitButton.on('click', function (event) {
          showBlurredSpinner();
        });
        $dialogBox.append($dialogInnerBox);
        $('.confirmation-payment-spot .component-content').append($dialogBox);
        $cancelButton.on('click', function (event) {
          var allowSubmit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
          if (!allowSubmit) {
            event.preventDefault();
            showDialog(dictionary.B2B_Payments.cancelWarning, function () {
              $(event.currentTarget).trigger('click', [true]);
            }, dialogTypes.CANCEL_PAYMENT);
          }
          // allow to continue unabated
        });
        if (invoicesLength > showMaxInvoices) {
          invoices.hide(); // hide all

          invoices.each(function (index, invoice) {
            if (index === showMaxInvoices * 3) {
              // break out of the loop
              return false;
            }
            $(invoice).show();
            return true;
          });
          $invoiceContainer.append("<div class=\"show-all-container\"><div class=\"show-all-button\">".concat(dictionary.B2B.paymentsConfirmPaymentShowAll, "</div></div>"));
          var showAllButton = $('.show-all-button');
          showAllButton.on('click', function () {
            invoices.show();
            showAllButton.hide();
          });
        }

        /**
         * Simple dialog functionality to ask for confirmation and to show messages
         *
         * @param text     String with the text to show
         * @param callback [optional] If set it will create an OK button, when pressed it will call the callback
         *                 Used to create a confirmation modal
         * @param {string='okCancel','continuePayment','yesNo'} type    [optional] String to set button labels.
         */
        function showDialog(text, callback, type) {
          var yesButtonText = '';
          var noButtonText = '';

          // set our button texts
          switch (type) {
            case dialogTypes.YES_NO:
              yesButtonText = dictionary.B2B_Payments.sharedYesButtonLabel;
              noButtonText = dictionary.B2B_Payments.sharedNoButtonLabel;
              break;
            case dialogTypes.CANCEL_PAYMENT:
              yesButtonText = dictionary.B2B_Payments.sharedCancelPaymentButtonLabel;
              noButtonText = dictionary.B2B_Payments.sharedContinuePaymentButton;
              break;
            case dialogTypes.OK_CANCEL:
            default:
              yesButtonText = dictionary.B2B_Payments.sharedOkButtonLabel;
              noButtonText = dictionary.B2B_Payments.sharedCancelButtonLabel;
          }

          // First clear the dialog
          $dialogInnerBox.html('');
          $dialogInnerBox.append("<span>".concat(text, "</span><div class='dialog-bottom'>"));
          if (typeof callback !== 'function') {
            // set single button dialog box to use okButton text.
            noButtonText = yesButtonText;
          }
          $dialogInnerBox.children('.dialog-bottom').append($('<input>', {
            type: 'button',
            class: 'close',
            value: noButtonText
          })).bind('click', function () {
            $dialogBox.hide();
          });
          if (typeof callback === 'function') {
            $dialogInnerBox.children('.dialog-bottom').append($('<input>', {
              type: 'button',
              class: 'accept',
              value: yesButtonText
            }).bind('click', function () {
              callback();
              $dialogBox.hide();
            }));
          }
          $dialogBox.show();
        }
      });
    }
  });
})(jQuery, window.DGS);