import uuidv1 from 'uuid';
(function ($) {
  var visualRowIndex = -1,
    $prevRow = null;
  window.DGS.DataListSpotConfig.resolveSortForField = function (fieldName, sortType) {
    var sortOrder = sortType == 'ordered-asc' ? ' asc' : ' desc';
    return 'orderby=' + fieldName + sortOrder;
  };
  window.DGS.DataListSpotConfig.decorateRowAtIndex = function (data, index, $row) {
    var oddEven,
      type,
      oddClass = "data-list-spot__row-odd",
      evenClass = "data-list-spot__row-even";
    if (!data.attachedToLineId) {
      oddEven = index % 2 == 0 ? evenClass : oddClass;
    } else {
      if (index == 0) {
        visualRowIndex = -1;
        $prevRow = null;
      }
      if (data.attachedToLineId == 0) {
        visualRowIndex++;
        type = "main-order";
        if ($prevRow) $prevRow.addClass('last-attached-order-line');
      } else {
        type = "attached-order";
      }
      oddEven = visualRowIndex % 2 == 0 ? evenClass : oddClass;
    }
    $row.addClass(oddEven);
    $row.addClass(type);
    $prevRow = $row;
  };
  window.DGS.DataListSpotConfig.fieldInfo["id"] = {
    IsKey: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["searchId"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["companyId"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["accountId"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["orderNumber"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["customerReferenceNumber"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["orderDateUtc"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["orderDateFormatted"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["status"] = {
    IsFilterable: true,
    IsSearchable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["endUsers"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsCollection: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["descriptions"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsCollection: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["partNos"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsCollection: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["billToId"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["sellToId"] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["type"] = {
    IsFilterable: true,
    IsSearchable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["created"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["validTo"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["data"] = {};
  window.DGS.DataListSpotConfig.fieldInfo["searchPeriodFrom"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["searchPeriodTo"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["trackingNumber"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["shipmentDate"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["documentNumber"] = {
    IsFilterable: true,
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["serialNumbers"] = {
    IsFilterable: true,
    IsCollection: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["accountName"] = {
    IsSortable: true
  };
  window.DGS.DataListSpotConfig.fieldInfo["accountNo"] = {
    IsSortable: true
  };
  DGS.Event.getInstance().on(window.DGS.DataListSpotConfig.Event.DATA_LIST_BUILT, function () {
    $('.data-list-spot__row__column.checkbox').each(function () {
      var extendable = false;
      var childSpan = $(this).children('span').text('');
      var uuid = uuidv1();
      if ($(this).data('value') == 1) {
        childSpan.append("<input type=\"checkbox\" class=\"selectable\" id=\"".concat(uuid, "\" /><label for=\"").concat(uuid, "\"></label>"));
        extendable = true;
      }
      if (extendable) {
        $(this).closest('.data-list-spot').data('isextendable', extendable);
      }
    });
    $('.data-list-spot__row').each(function () {
      var expireDate = $(this).children('.ExpiresDate, .finalWarrantyEndDate').data('value');
      //Get 1 day in milliseconds
      var one_day = 1000 * 60 * 60 * 24;
      var now = new Date();
      var diff = new Date(expireDate).getTime() - now.getTime();
      if (diff <= 14 * one_day) {
        $(this).addClass('expiring');
        $(this).children('.data-list-spot__row__column').each(function () {
          $(this).addClass('expiring');
        });
      }
    });
    $('.data-list-spot__row__column.printable').each(function () {
      var childSpan = $(this).children('span').text('');
      if ($(this).data('value') == 1) {
        childSpan.append('<a class="printable" target="_blank">Download/Print warranty</a>');
      } else {
        $(this).removeClass('printable');
      }
    });
    var baseServiceURL = "/webservices/Warranties.svc";
    $('.data-list-spot__row__column.hascoverage').each(function () {
      var childSpan = $(this).children('span');
      if ($(this).data('value') == 'NO' && childSpan.children().length === 0) {
        childSpan.append('<img class="hascoverage" src="/design/build/oticon_us/assets/badge-no.png" width="20px"/>');
      } else if ($(this).data('value') == 'YES' && childSpan.children().length === 0) {
        childSpan.append('<img class="hascoverage" src="/design/build/oticon_us/assets/badge-yes.png" width="20px"/>');
      }
    });
    $('.data-list-spot').on('click', function (e) {
      if ($(e.target).is('input.selectable')) {
        e.stopImmediatePropagation();
      }
      if ($(e.target).hasClass('printable')) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var serviceCard = $(e.target).closest('.data-list-spot__row').children('.serviceCardNo:first').data('value');
        if (serviceCard) {
          var resultUrl = baseServiceURL + "/" + "requestpdf" + "/" + serviceCard;
          var attempt = 20;
          attemptPDF(attempt, resultUrl, serviceCard);
        }
        return false;
      }
    });
    function attemptPDF(attempt, url, serviceCard) {
      if (attempt < 0) return false;
      if (attempt === 0) getPDF(serviceCard);
      $.ajax({
        url: url,
        type: 'GET'
      }).then(function (data, textState, jqXHR) {
        switch (jqXHR.status) {
          case 201:
            getPDF(serviceCard);
            break;
          case 202:
            setTimeout(getPDF(serviceCard), 200);
            break;
          default:
            return false;
            break;
        }
      }).then(function (data, textState, jqXHR) {
        return false;
      });
      return false;
    }
    function getPDF(serviceCard) {
      var resultUrl = "/webservices/Warranties.svc" + "/" + "RequestPDFCertificate" + "/" + serviceCard;

      // window.location = resultUrl;
      window.open(resultUrl, '_blank');
    }
  });
})(jQuery);