(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.component.yhn-form-wrapper')[0]) {
      /* Form Placeholder */

      var fieldWrapper = '.component.yhn-form-wrapper .scfSectionContent';
      var txtField = $(fieldWrapper + ' > .scfSingleLineTextBorder');
      var numField = $(fieldWrapper + ' > .scfNumberBorder');
      var emailField = $(fieldWrapper + ' > .scfEmailBorder');
      txtField.each(function (index, element) {
        var label = $(element).find('.scfSingleLineTextLabel').text();
        $(element).find('input').attr('placeholder', label);
      });
      numField.each(function (index, element) {
        var label = $(element).find('.scfNumberLabel').text();
        $(element).find('input').attr('placeholder', label);
      });
      emailField.each(function (index, element) {
        var label = $(element).find('.scfEmailLabel').text();
        $(element).find('input').attr('placeholder', label);
      });
    } //if end
  });
})(jQuery);