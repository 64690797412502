/**
 * @module wdh-mybusiness-us
 *
 * create new React component in ./src/js/ folder.  then import it here and .run();
 */
import WDHDynamicShopNavigation from './src/js/wdh-shop-navigation';
import WdhUserManagement from './src/js/wdh-user-management';
import WdhUserManagementAddUser from './src/js/wdh-user-management-add-user';
import WDHBillToSellToSpot from './src/js/BillToSellToRedesign';
import WdhAccountManagement from './src/js/wdh-account-management';
new WDHDynamicShopNavigation().run();
new WdhUserManagement().run();
new WdhUserManagementAddUser().run();
new WDHBillToSellToSpot().run();
new WdhAccountManagement().run();