import { TweenMax, Power0 } from 'gsap';
import TranslationManager from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/infrastructure/translation-manager';
import { TranslationKeys } from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/infrastructure/const';
import NavigationManager from '../../../../wdh-feature/wdh-feature-b2b-us/src/js/infrastructure/navigation-manager';

/**
 * Created by mady on 03-10-2016.
 */
(function ($) {
  "use strict";

  var getConfig = function getConfig($component) {
      var $config = $('script[type="application/json"]', $component);
      try {
        if ($config.length) return JSON.parse($config.html());
      } catch (e) {
        return {};
      }
    },
    showProfile = function showProfile($profile, onComplete) {
      $profile.show();
      var $innerContainer = $('.login-spot-profile-inner-container', $profile);
      TweenMax.set($innerContainer, {
        "height": "auto"
      });
      TweenMax.fromTo($profile, 0.2, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Power0.easeNone
      });
      TweenMax.from($innerContainer, 0.6, {
        height: 0,
        ease: Power0.easeNone,
        onComplete: onComplete
      });
    },
    hideProfile = function hideProfile($profile, _onComplete) {
      var $innerContainer = $('.login-spot-profile-inner-container', $profile);
      TweenMax.to($innerContainer, 0.4, {
        height: 0,
        ease: Power0.easeNone
      });
      TweenMax.to($profile, 0.2, {
        alpha: 0,
        ease: Power0.easeNone,
        delay: 0.2,
        onComplete: function onComplete() {
          $profile.hide();
          _onComplete.apply();
        }
      });
    },
    turnOffHandlers = function turnOffHandlers($profile) {
      $('.login-spot-profile-close', $profile).off('click');
      $('body').off('click');
    };
  window.DGS.OnLoad.getInstance().register(function () {
    $('#wrapper > #header .component.login-spot').each(function () {
      var $this = $(this),
        $loggedInContainer = $('.logged-in', $this),
        $navList = $('#header .component.link-list.nav .component-content ul'),
        config = getConfig($this),
        navigation,
        $nameButton = $('.fill-name', $this);
      var loginName = $('.fill-name').text();
      var dialogBox = $('<div class="dialog-box-acct-change"></div>');
      var innerBox = $('<div class="inner-box"></div>');
      var message = $('<span class="message"></span>');
      var dialogBottom = $('<div class="dialog-bottom"></div>');
      var closeButton = $("<input type=button class=\"close\" value=\"".concat(TranslationManager.getTranslation(TranslationKeys.SHARED_NO), "\" />"));
      var okButton = $("<input type=\"button\" class=\"accept\" value=\"".concat(TranslationManager.getTranslation(TranslationKeys.SHARED_YES), "\" />"));
      innerBox.append(message);
      innerBox.append(dialogBottom);
      dialogBottom.append(closeButton);
      dialogBottom.append(okButton);
      dialogBox.append(innerBox);
      $('#wrapper').append(dialogBox);
      window.DGS.Event.getInstance().on(window.DGS.DataListSpotConfig.Event.DATA_LIST_PROMPT, function (url) {
        var $spinner = $("<div class='spinner-wrapper'>\n            <div class=\"cssload-double-torus\">\n              <div class=\"cssload-double-torus-arrow\" />\n            </div>\n        </div>");
        function addSpinner() {
          $('#wrapper').prepend($spinner);
        }
        function showBlurredSpinner() {
          $spinner.addClass('show-spinner');
          $('#wrapper #content').addClass('blurred-custom-a-login');
          $('#wrapper #header').addClass('blurred-custom-a-login');
        }
        function removeBlurredSpinner() {
          $spinner.removeClass('show-spinner');
          $('#wrapper #content').removeClass('blurred-custom-a-login');
          $('#wrapper #header').removeClass('blurred-custom-a-login');
        }
        dialogBox.addClass('is-visible');
        addSpinner();
        closeButton.off('click');
        okButton.off('click');
        message.html(TranslationManager.getTranslation(TranslationKeys.US_MY_OTICON_ACCOUNT_SWITCHER_CONFIRMATION_MESSAGE));
        closeButton.on('click', function () {
          return dialogBox.removeClass('is-visible');
        });
        okButton.on('click', function () {
          dialogBox.removeClass('is-visible');
          showBlurredSpinner();
          $.ajax(url, {
            cache: false,
            complete: function complete() {
              removeBlurredSpinner();
              NavigationManager.navigate('/myoticon');
            }
          }).fail(function () {
            removeBlurredSpinner();
          });
        });
      });
      if ($loggedInContainer.length) {
        navigation = config.Navigation;
        if (navigation && $navList.length) {
          navigation.forEach(function (element) {
            $navList.append('<li class="login-spot"><a href="' + element.Href + '" title="' + element.Label + '">' + element.Label + '</a></li>');
          });
        }
        if ($navList.length) {
          // append our account selector to mobile menu
          $('.data-list-spot.toggle-billto', $this).each(function (index, elem) {
            var $elem = $(elem).clone(true, true);
            $('.data-list-spot__row.data-list-spot__row__cell', $elem).css('opacity', 1);
            var li = $('<li class="login-spot mobile-only">');
            li.append("<div class=\"mobile-login-name\" >".concat(loginName, "</div>"));
            li.append($elem);
            $navList.append(li);
          });
        }
      }
      if ($navList.length) {
        $('.login-spot-profile-link', $this).each(function () {
          var $this = $(this),
            href = $this.attr('href'),
            label = $('.login-spot-profile-link-label', $this).text();
          var isLogout = false;
          if (label.toLowerCase() === 'logout') {
            $this.addClass('logout-link');
            isLogout = true;
          }
          $navList.append("<li class=\"login-spot mobile-only\"><a href=\"".concat(href, "\" title=\"").concat(label, "\" ").concat(isLogout ? 'class="logout-link"' : '', ">").concat(label, "</a></li>"));
        });
      }
      $('.logout-link').click(function () {
        sessionStorage.clear(); // clear all data from session storage.
      });
      $nameButton.click(function (evt) {
        evt.preventDefault();
        var $profileContainer = $('.login-spot-profile-outer-container', $this);
        if (!$profileContainer.is(":visible")) {
          $nameButton.addClass('active');
          showProfile($profileContainer, function () {
            var id = $profileContainer.attr('id');
            if (!id) {
              id = "profile" + Math.round(Math.random() * 10000);
              $profileContainer.attr('id', id);
            }
            $('.login-spot-profile-close', $profileContainer).click(function () {
              hideProfile($profileContainer, function () {
                turnOffHandlers($profileContainer);
              });
            });
            $('body').click(function (e) {
              if (e.target.id == id || $(e.target).parents("#" + id).length) {
                // click inside div. DO NOTHING
              } else {
                // click outside div. Close div.
                hideProfile($profileContainer, function () {
                  turnOffHandlers($profileContainer);
                  $nameButton.removeClass('active');
                });
              }
            });
          });
        } else {
          $nameButton.removeClass('active');
          hideProfile($profileContainer, function () {
            turnOffHandlers($profileContainer);
          });
        }
      });
    });
  });
  window.DGS.Scroller.getInstance().register(function (scrollTop) {
    $('#wrapper > #header .component.login-spot').each(function () {
      var $this = $(this);
      var $profileContainer = $('.login-spot-profile-outer-container', $this);
      var $nameButton = $('.fill-name', $this);
      if ($profileContainer.is(":visible")) {
        if (scrollTop > $profileContainer.position().top * 2) {
          $nameButton.removeClass('active');
          hideProfile($profileContainer, function () {
            turnOffHandlers($profileContainer);
          });
        }
      }
    });
  });
})(jQuery);