import WDHRichText from "../../../../wdh-feature/wdh-rich-text/src/js/wdh-rich-text";
import WDHInlineImage from "../../../../wdh-feature/wdh-rich-text/src/js/variations/inline-image";
import WDHRichTextPanel from "./wdh-rich-text-panel";
import { trackDownload } from "../project/tracking-gtm-ga4";
var richText = new WDHRichText();
richText.registerVariation(new WDHInlineImage());
richText.registerVariation(new WDHRichTextPanel());
richText.run();
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.rich-text').length) {
      $('.rich-text').each(function () {
        var $elem = $(this);
        $('a', $elem).each(function () {
          var href = $(this).attr('href');
          if (href && href.split('.').pop().split('?').shift() === 'pdf') {
            $(this).on('click', function () {
              trackDownload('pdf', href.split('/').pop());
            });
          }
        });
      });
    }
    if ($('.two-column-spot').length) {
      $('.two-column-spot').each(function () {
        var $elem = $(this);
        $('a', $elem).each(function () {
          var href = $(this).attr('href');
          if (href && href.split('.').pop().split('?').shift() === 'pdf') {
            $(this).on('click', function () {
              trackDownload('pdf', href.split('/').pop());
            });
          }
        });
      });
    }
  });
})(jQuery); // eslint-disable-line no-undef