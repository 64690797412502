(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $('.component.latest-blog-posts a').each(function () {
      var hrefAttr = $(this).attr('href');
      if (window.location.href.indexOf(hrefAttr) !== -1) {
        $(this).parent('li').remove();
      } else {
        var date = $(this).find('.news-date').remove();
        $(this).find('.news-image').append(date);
        $(this).find('.news-title').html($(this).find('.news-title').text());
      }
    });
  });
})(jQuery, window.DGS);