(function ($, DGS, moment) {
  var ON_PICK_UP_ACTIVATED = "PICK_UP_ACTIVATED";
  var ON_PICK_UP_DEACTIVATED = "PICK_UP_DEACTIVATED";
  var ON_OPTIONS_CHANGED = "ON_OPTIONS_CHANGED";
  var ON_DATE_CHANGED = "ON_DATE_CHANGED";
  var ON_LATEST_TIME_CHANGED = "ON_LATEST_TIME_CHANGED";
  var ON_EARLIEST_TIME_CHANGED = "ON_EARLIEST_TIME_CHANGED";
  var PICK_UP_AND_SHIPPING_LABEL = "PICK_UP_AND_SHIPPING_LABEL";
  var SHIPPING_LABEL_ONLY = "SHIPPING_LABEL_ONLY";
  var ON_CTA_CLICK = "ON_CTA_CLICK";
  var ON_SUBMIT_FORM = "ON_SUBMIT_FORM";
  var ON_FORM_COMPLETED = "ON_FORM_COMPLETED";
  var ON_PICK_UP_AND_SHIPPING_LABEL_SUCCESS = "ON_PICK_UP_AND_SHIPPING_LABEL_SUCCESS";
  var ON_PICK_UP_FAILURE_SHIPPING_LABEL_SUCCESS = "ON_PICK_UP_FAILURE_SHIPPING_LABEL_SUCCESS";
  var ON_SHIPPING_LABEL_SUCCESS = "ON_SHIPPING_LABEL_SUCCESS";
  var ON_SHIPPING_LABEL_FAILURE = "ON_SHIPPING_LABEL_FAILURE";
  DGS.OnLoad.getInstance().register(function () {
    DGS.Components.DataHierarchySpot.getInstance().register('schedule-pickup', getBuilderSettings, onBuildComplete);
  });
  function getBuilderSettings(data, $spot) {
    var $timeSelect = $('<select>').addClass('square-corners');
    for (var i = 0; i < 24; i++) {
      if (data['useAM-PM']) {
        var ext = i < 12 ? "AM" : "PM",
          realI = i < 12 ? i : i - 12;
        realI = realI == 0 ? 12 : realI;
        $timeSelect.append($('<option>').text((realI < 10 ? "0" + realI : realI) + ":00 " + ext).val((i < 10 ? "0" + i : i) + "00"));
        $timeSelect.append($('<option>').text((realI < 10 ? "0" + realI : realI) + ":30 " + ext).val((i < 10 ? "0" + i : i) + "30"));
      } else {
        $timeSelect.append($('<option>').text((i < 10 ? "0" + i : i) + ":00").val((i < 10 ? "0" + i : i) + "00"));
        $timeSelect.append($('<option>').text((i < 10 ? "0" + i : i) + ":30").val((i < 10 ? "0" + i : i) + "30"));
      }
    }
    return {
      mainContainer: $('.component-content', $spot),
      sections: {
        desktop: {
          containers: [{
            className: "panel shipping-label",
            containers: [{
              className: "headline",
              components: [$('<div>').html(data.packageAndServiceInformationLabel)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('label').html(data.weightLabel), $('<div>').addClass('value').html(data.weightValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('label').html(data.packagingTypeLabel), $('<div>').addClass('value').html(data.packagingTypeValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('label').html(data.serviceSelectedLabel), $('<div>').addClass('value').html(data.serviceSelectedValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('label').html(data.packageContentsLabel), $('<div>').addClass('value').html(data.packageContentsValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('check').data('value', data.newItemOrderValue), $('<div>').addClass('option').html(data.newItemOrderValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('check').data('value', data.newMoldOrderValue), $('<div>').addClass('option').html(data.newMoldOrderValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('check').data('value', data.repairRemakeValue), $('<div>').addClass('option').html(data.repairRemakeValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('check').data('value', data.returnForCreditValue), $('<div>').addClass('option footnote-1').html(data.returnForCreditValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('check').data('value', data.rushOrderValue), $('<div>').addClass('option footnote-2').html(data.rushOrderValue)]
            }, {
              className: "content labelValues",
              components: [$('<div>').addClass('check checked').data('value', data.otherValue), $('<div>').addClass('option').html(data.otherValue)]
            }, {
              className: "content footnotes",
              components: [$('<div>').addClass('footnote').html(data.returnForCreditFootnote), $('<div>').addClass('footnote').html(data.rushOrderFootnote)]
            }]
          }, {
            className: "panel pick-up",
            containers: [{
              className: "headline",
              components: [$('<div>').html(data.pickUpDateAndTimeLabel)]
            }, {
              className: "content inputs",
              components: [$('<div>').addClass('input').append($('<div>').addClass("label").html(data.pickUpDateLabel)).append($('<input>').addClass('datepicker-input')).append($('<div>').addClass('calendar')), $('<div>').addClass('input').append($('<div>').addClass("label").html(data.earliestPickUpLabel)).append($timeSelect.clone().addClass('earliest-pick-up')), $('<div>').addClass('input').append($('<div>').addClass("label").html(data.latestPickUpLabel)).append($timeSelect.clone().addClass('latest-pick-up'))]
            }, {
              className: "content footnotes",
              components: [$('<div>').addClass('footnote').html(data.pickUpDataAndTimeFootnote)]
            }]
          }, {
            className: "cta",
            components: [$('<a>').addClass('button').html(data.printCTALabel).data('pickup-cta', data.scheduleAndPrintCTALabel)]
          }, {
            className: "popup",
            components: [$('<div>').addClass('shipping-label-success popup-panel').append($('<div>').addClass('headline').text(data.popUpShippingLabelOnlySuccessHeadline)).append($('<div>').addClass('close').text('X')).append($('<div>').addClass('body').text(data.popUpShippingLabelOnlySuccessHeadline)).append($('<a>').addClass('button').text(data.popUpShippingLabelOnlySuccessCTA)), $('<div>').addClass('shipping-label-failure popup-panel').append($('<div>').addClass('headline').text(data.popUpShippingLabelOnlyFailureHeadline)).append($('<div>').addClass('close').addClass('close-popup').text('X')).append($('<div>').addClass('body').text(data.popUpShippingLabelOnlyFailureBody)).append($('<a>').addClass('button').addClass('close-popup').text(data.popUpShippingLabelOnlyFailureCTA)), $('<div>').addClass('shipping-label-pick-up-success popup-panel').append($('<div>').addClass('headline').text(data.popUpShippingAndPickupSuccessHeadline)).append($('<div>').addClass('close').text('X')).append($('<div>').addClass('body').text(data.popUpShippingAndPickupSuccessBody)).append($('<a>').addClass('button').text(data.popUpShippingAndPickupSuccessCTA)), $('<div>').addClass('shipping-label-success-pick-up-failure popup-panel').append($('<div>').addClass('headline').text(data.popUpShippingSuccesButPickupFailureHeadline)).append($('<div>').addClass('close').text('X')).append($('<div>').addClass('body').text(data.popUpShippingSuccesButPickupFailureBody)).append($('<a>').addClass('button').text(data.popUpShippingSuccesButPickupFailureCTA))]
          }, {
            className: "loader",
            components: [$('<div class="data-list-spot__load-container"></div>').append('<div class="cssload-container"><div class="cssload-double-torus"><div class="cssload-double-torus-arrow"></div></div></div>')]
          }]
        }
      }
    };
  }
  function onBuildComplete($spot, data) {
    DGS.Event.getInstance().trigger(DGS.Events.SELECT_ADDED, $('select', $spot));

    // INITIALIZE PAYLOAD
    var payloadManager = {
      _shippingLabelData: {
        ServiceCode: "03",
        ShipFrom: {
          Address: {
            AddressLine: "-",
            //"Ship From Street",
            City: "-",
            //"Neuss",
            ZipCode: "-",
            //"21093",
            State: "-",
            //"MD",
            CountryCode: "-" //"US"
          },
          Name: "-",
          //"Ship From Name",
          Phone: "-" //"1234567890"
        },
        ShipTo: {
          Address: {
            AddressLine: "-",
            //"GOERLITZER STR.1",
            City: "-",
            //"Neuss",
            State: "-",
            //"MD",
            ZipCode: "-",
            //"41456",
            CountryCode: "-" //"DE"
          },
          Name: "-",
          //"DEF Associates",
          Phone: "-" //"1234567890"
        },
        Package: {
          Description: "",
          //"Description",
          PackagingCode: "02",
          PackagingDescription: "",
          //"Description",
          Dimensions: {
            UnitOfMeasurementCode: "IN",
            UnitOfMeasurementDescription: "Inches",
            Length: "7",
            Width: "5",
            Height: "2"
          },
          PackageWeight: {
            UnitOfMeasurementCode: "LBS",
            UnitOfMeasurementDescription: "Pounds",
            Weight: data.weightValue
          }
        }
      },
      _pickupData: {
        DateInfo: {
          CloseTime: "-",
          //"1700",
          ReadyTime: "-",
          //"1000",
          PickupDate: "-" //"20160524"
        },
        Address: {
          CompanyName: "-",
          //"EIVR TEST NON-ACCOUNT PL 3",
          ContactName: "-",
          //"EIVR TES PL",
          AddressLine: "-",
          //"12380 Morris Rd",
          City: "-",
          //"Louisville",
          StateProvince: "-",
          //"KY",
          PostalCode: "-",
          //"40201",
          CountryCode: "-",
          //"US",
          ResidentialIndicator: "N",
          PhoneNumber: "-" //"+48223803070"
        }
      },
      init: function init() {
        var that = this;
        DGS.Event.getInstance().on(ON_DATE_CHANGED, function (date) {
          that._pickupData.DateInfo.PickupDate = moment(date).format('YYYYMMDD');
        });
        DGS.Event.getInstance().on(ON_OPTIONS_CHANGED, function (options) {
          var description = [];
          for (var key in options) {
            if (options[key]) {
              description.push(key);
            }
          }
          that._shippingLabelData.Package.Description = description.join(', ');
          that._shippingLabelData.Package.PackagingDescription = description.join(', ');
        });
        DGS.Event.getInstance().on(ON_EARLIEST_TIME_CHANGED, function (value) {
          that._pickupData.DateInfo.ReadyTime = value;
          //that._data.earliest = value;
        });
        DGS.Event.getInstance().on(ON_LATEST_TIME_CHANGED, function (value) {
          that._pickupData.DateInfo.CloseTime = value;
          //that._data.latest = value;
        });
        DGS.Event.getInstance().on('ON_B2B_ADDRESSES_REQUESTED', function (addresses) {
          that._shippingLabelData.ShipFrom.Address.AddressLine = addresses.shipFrom.addressLine;
          that._shippingLabelData.ShipFrom.Address.City = addresses.shipFrom.city;
          that._shippingLabelData.ShipFrom.Address.ZipCode = addresses.shipFrom.zipCode;
          that._shippingLabelData.ShipFrom.Address.State = addresses.shipFrom.state;
          that._shippingLabelData.ShipFrom.Address.CountryCode = addresses.shipFrom.countryCode;
          that._shippingLabelData.ShipFrom.Name = addresses.shipFrom.name;
          that._shippingLabelData.ShipFrom.Phone = addresses.shipFrom.phone;
          that._shippingLabelData.ShipTo.Address.AddressLine = addresses.shipTo.addressLine;
          that._shippingLabelData.ShipTo.Address.City = addresses.shipTo.city;
          that._shippingLabelData.ShipTo.Address.ZipCode = addresses.shipTo.zipCode;
          that._shippingLabelData.ShipTo.Address.State = addresses.shipTo.state;
          that._shippingLabelData.ShipTo.Address.CountryCode = addresses.shipTo.countryCode;
          that._shippingLabelData.ShipTo.Name = addresses.shipTo.name;
          that._shippingLabelData.ShipTo.Phone = addresses.shipTo.phone;
          that._pickupData.Address.CompanyName = addresses.shipFrom.name.substr(0, 27); // max. 27 characters per UPS documentation
          that._pickupData.Address.ContactName = addresses.shipFrom.contactName;
          that._pickupData.Address.AddressLine = addresses.shipFrom.addressLine;
          that._pickupData.Address.City = addresses.shipFrom.city;
          that._pickupData.Address.StateProvince = addresses.shipFrom.state;
          that._pickupData.Address.PostalCode = addresses.shipFrom.zipCode;
          that._pickupData.Address.CountryCode = addresses.shipFrom.countryCode;
          that._pickupData.Address.PhoneNumber = addresses.shipFrom.phone;
        });
        DGS.Event.getInstance().trigger('REQUEST_B2B_ADDRESSES');
      },
      getShippingLabelData: function getShippingLabelData() {
        return this._shippingLabelData;
      },
      getPickUpData: function getPickUpData() {
        return this._pickupData;
      }
    };
    payloadManager.init();
    var submitManager = {
      _shippingLabelEndpoint: "/webservices/shipping.svc/getlabel",
      _pickUpEndpoint: "/webservices/shipping.svc/orderpickup",
      _formState: SHIPPING_LABEL_ONLY,
      _payloadManager: null,
      _requestPrintLabel: function _requestPrintLabel(callback) {
        var that = this,
          payload = that._payloadManager.getShippingLabelData(),
          data = JSON.stringify(payload) || null;
        if (data) {
          $.ajax(this._shippingLabelEndpoint, {
            contentType: "application/json",
            data: data,
            method: 'POST'
          }).done(function (data, textStatus, jqXHR) {
            callback.apply(that, [true, data]);
          }).fail(function (jqXHR, textStatus, errorThrown) {
            callback.apply(that, [false, textStatus]);
          });
        } else {
          callback.apply(that, [false, textStatus]);
        }
      },
      _requestPickUp: function _requestPickUp(callback) {
        var that = this,
          payload = that._payloadManager.getPickUpData(),
          data = JSON.stringify(that._payloadManager.getPickUpData()) || null;
        if (data) {
          $.ajax(this._pickUpEndpoint, {
            contentType: "application/json",
            data: data,
            method: 'POST'
          }).done(function (data, textStatus, jqXHR) {
            callback.apply(that, [true, data]);
          }).fail(function (jqXHR, textStatus, errorThrown) {
            callback.apply(that, [false, textStatus]);
          });
        } else {
          callback.apply(that, [false, textStatus]);
        }
      },
      _submitForm: function _submitForm() {
        var that = this;
        DGS.Event.getInstance().trigger(ON_SUBMIT_FORM);
        this._requestPrintLabel(function (success, shippingLabelData) {
          if (success) {
            if (that._formState == PICK_UP_AND_SHIPPING_LABEL) {
              that._requestPickUp(function (success, pickUpRequestData) {
                DGS.Event.getInstance().trigger(ON_FORM_COMPLETED);
                if (success) {
                  DGS.Event.getInstance().trigger(ON_PICK_UP_AND_SHIPPING_LABEL_SUCCESS, shippingLabelData);
                } else {
                  DGS.Event.getInstance().trigger(ON_PICK_UP_FAILURE_SHIPPING_LABEL_SUCCESS, shippingLabelData, pickUpRequestData);
                }
              });
            } else {
              DGS.Event.getInstance().trigger(ON_FORM_COMPLETED);
              DGS.Event.getInstance().trigger(ON_SHIPPING_LABEL_SUCCESS, shippingLabelData);
            }
          } else {
            DGS.Event.getInstance().trigger(ON_FORM_COMPLETED);
            DGS.Event.getInstance().trigger(ON_SHIPPING_LABEL_FAILURE, shippingLabelData);
          }
        });
      },
      init: function init(payloadManager) {
        var that = this;
        this._payloadManager = payloadManager;
        DGS.Event.getInstance().on(ON_PICK_UP_ACTIVATED, function () {
          that._formState = PICK_UP_AND_SHIPPING_LABEL;
        });
        DGS.Event.getInstance().on(ON_PICK_UP_DEACTIVATED, function () {
          that._formState = SHIPPING_LABEL_ONLY;
        });
        DGS.Event.getInstance().on(ON_CTA_CLICK, function () {
          that._submitForm();
        });
      }
    };
    submitManager.init(payloadManager);
    setupUI($spot, data);
  }
  function setupUI($spot, data) {
    // SETUP PANEL SHOW/HIDE LOGIC
    var panelManager = {
      $_shippingLabelPanel: $('.panel.shipping-label', $spot),
      $_shippingLabelHeadline: null,
      $_shippingLabelContent: null,
      $_shippingLabelOpen: true,
      $_pickUpPanel: $('.panel.pick-up', $spot),
      $_pickUpPanelHeadline: null,
      $_pickUpPanelContent: null,
      $_pickUpPanelActivated: false,
      update: function update() {
        if (this.$_pickUpPanelActivated) {
          this.$_pickUpPanelContent.show();
          this.$_pickUpPanel.addClass('checked');
          DGS.Event.getInstance().trigger(ON_PICK_UP_ACTIVATED, $spot);
        } else {
          this.$_pickUpPanelContent.hide();
          this.$_pickUpPanel.removeClass('checked');
          DGS.Event.getInstance().trigger(ON_PICK_UP_DEACTIVATED, $spot);
        }
        if (this.$_shippingLabelOpen) {
          this.$_shippingLabelContent.show();
          this.$_shippingLabelPanel.addClass('open');
        } else {
          this.$_shippingLabelContent.hide();
          this.$_shippingLabelPanel.removeClass('open');
        }
      },
      init: function init() {
        var that = this;
        this.$_shippingLabelHeadline = $('.headline', this.$_shippingLabelPanel);
        this.$_shippingLabelContent = $('.content', this.$_shippingLabelPanel);
        this.$_pickUpPanelHeadline = $('.headline', this.$_pickUpPanel);
        this.$_pickUpPanelContent = $('.content', this.$_pickUpPanel);
        this.$_pickUpPanelHeadline.click(function () {
          that.$_pickUpPanelActivated = !that.$_pickUpPanelActivated;
          that.update();
        });
        this.$_shippingLabelHeadline.click(function () {
          that.$_shippingLabelOpen = !that.$_shippingLabelOpen;
          that.update();
        });
        this.update();
      }
    };

    // SETUP OPTIONS CLICK LOGIC
    var optionsManager = {
      _numOptionsAllowed: 4,
      // GET FROM DATA
      $_optionsCheckboxes: $('.check', $spot),
      $_options: $('.shipping-label .option', $spot),
      _data: {},
      _updateData: function _updateData(value) {
        if (this._data[value]) {
          this._data[value] = false;
        } else {
          // determine if more than num options allowed have been clicked
          var c = 0;
          for (var key in this._data) {
            if (this._data[key]) {
              c++;
            }
          }
          // ONLY SET OPTION CHECKED IF NO MORE THAN NUM OPTIONS ALLOWED HAVE ALREADY BEEN CHECKED
          if (c < this._numOptionsAllowed) {
            this._data[value] = true;
          }
        }
        DGS.Event.getInstance().trigger(ON_OPTIONS_CHANGED, this._data);
        this._updateUI();
      },
      _updateUI: function _updateUI() {
        var that = this,
          c = 0;
        this.$_optionsCheckboxes.each(function () {
          var $this = $(this);
          if (that._data[$(this).data('value')]) {
            $this.addClass('checked');
            c++;
          } else {
            $this.removeClass('checked');
          }
        });
        if (c >= this._numOptionsAllowed) {
          this.$_optionsCheckboxes.each(function () {
            var $this = $(this);
            if (!that._data[$(this).data('value')]) {
              $this.addClass('disabled');
            }
          });
        } else {
          this.$_optionsCheckboxes.removeClass('disabled');
        }
      },
      init: function init() {
        var that = this;
        this.$_optionsCheckboxes.each(function () {
          that._data[$(this).data('value')] = false;
        });
        this.$_options.click(function () {
          // LABEL CLICKED
          var value = $(this).prev().data('value');
          that._updateData(value);
        });
        this.$_optionsCheckboxes.click(function () {
          // CHECKBOX CLICKED
          var value = $(this).data('value');
          that._updateData(value);
        });
        this._updateUI();
      }
    };

    // SETUP CTA TEXT LOGIC
    var ctaManager = {
      $_button: $('.cta a'),
      shippingOnlyLabel: null,
      pickupAndShippingLabel: null,
      init: function init() {
        var that = this;
        this.shippingOnlyLabel = this.$_button.text();
        this.pickupAndShippingLabel = this.$_button.data('pickup-cta');
        DGS.Event.getInstance().on(ON_PICK_UP_ACTIVATED, function () {
          that.$_button.text(that.pickupAndShippingLabel);
        });
        DGS.Event.getInstance().on(ON_PICK_UP_DEACTIVATED, function () {
          that.$_button.text(that.shippingOnlyLabel);
        });
        this.$_button.click(function () {
          DGS.Event.getInstance().trigger(ON_CTA_CLICK);
        });
      }
    };

    // SETUP CALENDAR
    var calendarManager = {
      $_input: $('input.datepicker-input'),
      $_calendar: $('.calendar'),
      _calendarActive: false,
      _selectedDate: null,
      _format: data.dateformat || "DD.MM.YYYY",
      _updateUI: function _updateUI() {
        var that = this;
        if (this._calendarActive) {
          this.$_calendar.datepicker('destroy');
          this.$_calendar.datepicker({
            onSelect: function onSelect(selectedDate) {
              that._selectedDate = new Date(Date.parse(selectedDate));
              DGS.Event.getInstance().trigger(ON_DATE_CHANGED, that._selectedDate);
              that._calendarActive = false;
              that._updateUI();
            }
          });
          this.$_calendar.datepicker('setDate', that._selectedDate);
          this.$_calendar.show();
        } else {
          this.$_calendar.datepicker('destroy');
          this.$_calendar.hide();
        }
        that.$_input.val(moment(this._selectedDate).format(this._format));
      },
      init: function init() {
        var that = this;
        this._selectedDate = new Date();
        this.$_input.click(function () {
          that._calendarActive = !that._calendarActive;
          $(this).blur();
          that._updateUI();
        });
        DGS.Event.getInstance().trigger(ON_DATE_CHANGED, this._selectedDate);
        that._updateUI();
      }
    };

    // SETUP TIME DROPDOWNS
    var timeManager = {
      $_earliest: $('.earliest-pick-up', $spot),
      $_latest: $('.latest-pick-up', $spot),
      init: function init() {
        var that = this;
        this.$_earliest.change(function () {
          DGS.Event.getInstance().trigger(ON_EARLIEST_TIME_CHANGED, that.$_earliest.val());
        });
        this.$_latest.change(function () {
          DGS.Event.getInstance().trigger(ON_LATEST_TIME_CHANGED, that.$_latest.val());
        });
        DGS.Event.getInstance().trigger(ON_EARLIEST_TIME_CHANGED, that.$_earliest.val());
        DGS.Event.getInstance().trigger(ON_LATEST_TIME_CHANGED, that.$_latest.val());
      }
    };

    // SETUP LOADER
    var loadManager = {
      _loader: $('.loader', $spot),
      _showLoader: function _showLoader() {
        this._loader.show();
      },
      _hideLoader: function _hideLoader() {
        this._loader.hide();
      },
      init: function init() {
        var that = this;
        DGS.Event.getInstance().on(ON_SUBMIT_FORM, function () {
          that._showLoader();
        });
        DGS.Event.getInstance().on(ON_FORM_COMPLETED, function () {
          that._hideLoader();
        });
      }
    };

    // SETUP POPUPS
    var popupManager = {
      $_container: $('.popup', $spot),
      $_labelOnlySuccess: $('.shipping-label-success', this.$_container),
      $_labelOnlyFailure: $('.shipping-label-failure', this.$_container),
      $_labelAndPickUpSuccess: $('.shipping-label-pick-up-success', this.$_container),
      $_labelSuccessButPickUpFailure: $('.shipping-label-success-pick-up-failure', this.$_container),
      $_closeBtns: $('.close', this.$_container),
      _showPopUp: function _showPopUp($popup) {
        $popup.show();
        this.$_container.css('display', 'flex');
      },
      _closePopUp: function _closePopUp() {
        $('.button', this.$_container).off('click');
        $('.popup-panel', this.$_container).css('display', 'none');
        this.$_container.css('display', 'none');
      },
      _getPrintWindowUrl: function _getPrintWindowUrl(data) {
        var url = null;
        if (data.links) {
          data.links.forEach(function (link) {
            if (link.rel === "html-page") {
              url = link.href;
            }
          });
        }
        return url;
      },
      _openPrintWindow: function _openPrintWindow(url) {
        window.open(url);
      },
      init: function init() {
        var that = this;
        that.$_closeBtns.click(function () {
          that._closePopUp();
        });
        DGS.Event.getInstance().on(ON_PICK_UP_AND_SHIPPING_LABEL_SUCCESS, function (shippingLabelData) {
          var url = that._getPrintWindowUrl(shippingLabelData);
          that._showPopUp(that.$_labelAndPickUpSuccess);
          $('.button', that.$_labelAndPickUpSuccess).click(function () {
            that._closePopUp();
            that._openPrintWindow(url);
          });
        });
        DGS.Event.getInstance().on(ON_PICK_UP_FAILURE_SHIPPING_LABEL_SUCCESS, function (shippingLabelData, pickUpRequestData) {
          var url = that._getPrintWindowUrl(shippingLabelData);
          that._showPopUp(that.$_labelSuccessButPickUpFailure);
          $('.button', that.$_labelSuccessButPickUpFailure).click(function () {
            that._closePopUp();
            that._openPrintWindow(url);
          });
        });
        DGS.Event.getInstance().on(ON_SHIPPING_LABEL_SUCCESS, function (shippingLabelData) {
          var url = that._getPrintWindowUrl(shippingLabelData);
          that._showPopUp(that.$_labelOnlySuccess);
          $('.button', that.$_labelOnlySuccess).click(function () {
            that._closePopUp();
            that._openPrintWindow(url);
          });
        });
        DGS.Event.getInstance().on(ON_SHIPPING_LABEL_FAILURE, function (shippingLabelData) {
          that._showPopUp(that.$_labelOnlyFailure);
          $('.button', that.$_labelOnlyFailure).click(function () {
            that._closePopUp(that.$_labelOnlyFailure);
          });
        });
      }
    };
    panelManager.init();
    optionsManager.init();
    ctaManager.init();
    calendarManager.init();
    timeManager.init();
    loadManager.init();
    popupManager.init();
  }
})(jQuery, window.DGS, window.moment);